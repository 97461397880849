import { ReactNode, useCallback } from 'react'
import { MSError2 } from 'msutils'

type Props = {
  children: ReactNode
}

export default function WidgetBoundary({ children }: Props) {
  const Fallback = useCallback(() => <></>, [])
  return <MSError2.Boundary CustomUi={Fallback}>{children}</MSError2.Boundary>
}
