import { createContext, ReactNode, useContext } from 'react'

type TLayoutFlagContext = {
  disabledTableInset?: boolean
  disablePaginatorBarPadding?: boolean
  enableSectionPadding?: boolean
  disableCardsListPadding?: boolean
}

const Context = createContext<TLayoutFlagContext>({})

export function useLayoutFlagContext() {
  return useContext(Context)
}

export function LayoutFlagContextProvider({
  children,
  ...props
}: TLayoutFlagContext & { children: ReactNode }) {
  return <Context.Provider value={props}>{children}</Context.Provider>
}
