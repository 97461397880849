/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListDealSummarysOrdering =
  (typeof ListDealSummarysOrdering)[keyof typeof ListDealSummarysOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListDealSummarysOrdering = {
  new_deals_count: 'new_deals_count',
  '-new_deals_count': '-new_deals_count',
  estimate_drafted_deals_count: 'estimate_drafted_deals_count',
  '-estimate_drafted_deals_count': '-estimate_drafted_deals_count',
  estimate_sent_deals_count: 'estimate_sent_deals_count',
  '-estimate_sent_deals_count': '-estimate_sent_deals_count',
  approved_deals_count: 'approved_deals_count',
  '-approved_deals_count': '-approved_deals_count',
  project_deals_count: 'project_deals_count',
  '-project_deals_count': '-project_deals_count',
  lost_deals_count: 'lost_deals_count',
  '-lost_deals_count': '-lost_deals_count',
  archived_deals_count: 'archived_deals_count',
  '-archived_deals_count': '-archived_deals_count',
  archived_deals_total_amount: 'archived_deals_total_amount',
  '-archived_deals_total_amount': '-archived_deals_total_amount',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
