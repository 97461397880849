/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListSaasTiersOrdering =
  (typeof ListSaasTiersOrdering)[keyof typeof ListSaasTiersOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListSaasTiersOrdering = {
  tier: 'tier',
  '-tier': '-tier',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
