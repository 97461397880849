import { Cb, Q } from 'cb'
import { t } from 'content'
import { unreachable } from 'msutils/misc'

export namespace BusinessUtils {
  export function roleToString(role: Cb.EmployeeRole) {
    switch (role) {
      case 'admin':
        return t('Owner')
      case 'member':
        return t('Bookkeeper')
      case 'estimator':
        return t('Estimator')
      case 'project_manager':
        return t('Project manager')
      case 'cardholder':
        return t('Cardholder')
      case 'field_crew_member':
        return t('Field crew')
      case 'salesperson':
        return t('Salesperson')
      default:
        return unreachable(role)
    }
  }

  export function roleToPluralString(role: Cb.EmployeeRole) {
    switch (role) {
      case 'admin':
        return t('Owners')
      case 'member':
        return t('Bookkeepers')
      case 'estimator':
        return t('Estimators')
      case 'project_manager':
        return t('Project managers')
      case 'cardholder':
        return t('Cardholders')
      case 'field_crew_member':
        return t('Field crew')
      case 'salesperson':
        return t('Salespeople')
      default:
        return unreachable(role)
    }
  }

  export function getContactTypeName(type: Cb.PayerContactContactType) {
    switch (type) {
      case 'contractor':
        return t('Contractor')
      case 'developer':
        return t('Business')
      case 'homeowner':
        return t('Homeowner')
      default:
        return unreachable(type)
    }
  }

  export function useDefaultCostCode(vendor: Cb.PayeeContact | null) {
    const costCodes = Cb.useListCostCodes().data ?? []
    const defaultCostCodeId = Cb.useListVendorBillingConfigs({
      params: { payee_id: vendor?.payee_id ?? Q.NullUuid },
      enabled: !!vendor,
      select: vendor ? Q.opt : Q.none,
    }).data?.default_cost_code_id
    return costCodes.find((x) => x.id === defaultCostCodeId) ?? null
  }

  export function useDefaultCostType(type: 'subcontract' | 'expense') {
    const generalProjectConfig = Cb.useRetrieveSingletonGeneralProjectConfig().data ?? null
    const costTypes = Cb.useListCostTypes().data ?? []
    if (generalProjectConfig !== null) {
      const costTypeId =
        type === 'subcontract'
          ? generalProjectConfig.default_subcontract_cost_type_id
          : type === 'expense'
          ? generalProjectConfig.default_expense_cost_type_id
          : unreachable(type)
      return costTypes.find((x) => x.id === costTypeId) ?? null
    } else {
      return null
    }
  }

  export async function getDefaultCostCode(vendor: Cb.PayeeContact) {
    const [vendorBillingConfig, costCodes] = await Promise.all([
      Cb.listVendorBillingConfigs({ payee_id: vendor.payee_id }).then(Q.opt),
      Cb.listCostCodes().then(Q.all),
    ])

    return costCodes.find((x) => x.id === vendorBillingConfig?.default_cost_code_id) ?? null
  }

  export function useBusinessLogo() {
    const businessLogoOptQ = Cb.useListBusinessLogoEmbeddedFiles({ select: Q.opt })
    return (
      Cb.useBusinessLogoEmbeddedFileDownload(businessLogoOptQ.data?.id as string, {
        enabled: !!businessLogoOptQ.data?.id,
        select: (x) => x as File,
      }).data ?? null
    )
  }
}
