import React, { isValidElement, ReactNode } from 'react'
import { unreachable } from 'msutils/misc'
import { cn } from 'msutils/classnames'
import { BaseOptionUI, CardOptionUI, TableOptionUI } from '../component-utils'
import { TabGroupUtils } from '../utils'

type Props<T extends TabGroupUtils.Option<string>[]> = TabGroupUtils.OptionListProps<
  T[number]['id']
> & {
  value: T[number]['id']
  update: (newVal: T[number]['id']) => void
  options: T
  minimizeTabs?: boolean
  disableReverseInset?: boolean
  ui:
    | {
        [K in T[number]['id']]: ReactNode
      }
    | ReactNode
}

function isReactElement(x: Props<any>['ui']): x is ReactNode {
  return isValidElement(x)
}

export default function TabGroup<T extends TabGroupUtils.Option<string>[]>(props: Props<T>) {
  const { value, update, type, minimizeTabs = false, disableReverseInset, options, ui } = props
  const inner = <>{isReactElement(ui) ? ui : ui?.[value] ?? null}</>

  if (type === 'card' || type === undefined) {
    if (options.length <= 1 && minimizeTabs) {
      return inner
    }

    const metrics = props.metrics
    return (
      <div className={cn('vflex bg-th-bg-slate', !disableReverseInset && '-mx-5 -mt-5')}>
        <div className="flex divide-x divide-th-coolgrey-2.5">
          {options.map((x) => (
            // TODO: why does typing break here?
            <CardOptionUI
              key={x.id}
              spec={x}
              onClick={() => update(x.id)}
              metric={(metrics as any)[x.id]}
              isSelected={x.id === value}
            />
          ))}
        </div>
        <div className="bg-th-bg-white pt-8 px-5 vflex gap-5">{inner}</div>
      </div>
    )
  } else if (type === 'table') {
    const descriptions = props.descriptions
    if (options.length <= 1 && minimizeTabs) {
      return (
        <div
          className={cn(
            'vflex overflow-x-auto gap-5 bg-th-bg-white"',
            !disableReverseInset && '-mx-5 -mt-5 pt-5 px-5',
          )}
        >
          {inner}
        </div>
      )
    }
    return (
      <div className={cn('vflex overflow-x-auto', !disableReverseInset && '-mx-5 -mt-5')}>
        <div className="flex divide-x divide-th-coolgrey-2.5 bg-th-bg-slate">
          {options.map((x) => (
            // TODO: why does typing break here?
            <TableOptionUI
              key={x.id}
              spec={x}
              onClick={() => update(x.id)}
              description={(descriptions as any)[x.id]}
              isSelected={x.id === value}
            />
          ))}
        </div>
        <div className="bg-th-bg-white pt-8 px-5 vflex gap-5">{inner}</div>
      </div>
    )
  } else if (type === 'section') {
    if (options.length <= 1 && minimizeTabs) {
      return inner
    }

    return (
      <div className="vflex -mx-5">
        <div className="flex md:gap-9 md:px-6 border-b border-b-th-warmgrey-dark overflow-x-auto md:overflow-x-visible">
          {options.map((x) => (
            <BaseOptionUI
              key={x.id}
              spec={x}
              onClick={() => update(x.id)}
              isSelected={x.id === value}
              capitalize
            />
          ))}
        </div>
        <div className="py-5 px-5 vflex gap-5">{inner}</div>
      </div>
    )
  } else if (type === 'top-section') {
    return (
      <div className="vflex bg-th-bg-slate -m-5">
        {options.length <= 1 && minimizeTabs ? (
          inner
        ) : (
          <>
            <div className="flex md:gap-9 md:px-5 border-b border-b-th-warmgrey-dark overflow-x-auto md:overflow-x-visible">
              {options.map((x) => (
                <BaseOptionUI
                  key={x.id}
                  spec={x}
                  onClick={() => update(x.id)}
                  isSelected={x.id === value}
                  capitalize
                />
              ))}
            </div>
            <div className="py-5 vflex gap-5">{inner}</div>
          </>
        )}
      </div>
    )
  } else {
    return unreachable(type)
  }
}
