import { createContext, useContext as useBaseContext, FC, ReactNode } from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

type TContext = {
  Link: FC<{ href: string; children: ReactNode }>
}

const Context = createContext<TContext | undefined>(undefined)

export function useContext() {
  const ctx = useBaseContext(Context)
  if (!ctx) throw new Error('Context is undefined in Compass')
  return ctx
}

export default function CompassProvider({
  value,
  children,
}: {
  value: TContext
  children: ReactNode
}) {
  return (
    <Context.Provider value={value}>
      <DndProvider backend={HTML5Backend}>{children}</DndProvider>
    </Context.Provider>
  )
}
