import { ReactNode } from 'react'
import Typography from 'compass/data/Typography'
import { t } from 'content'
import { MSError2 } from 'msutils'

function Fallback() {
  return (
    <div className="p-5 bg-white">
      <Typography className="text-th-text-secondary">{t('Something went wrong')}</Typography>
    </div>
  )
}

type Props = {
  children: ReactNode
}

export default function SectionBoundary({ children }: Props) {
  return <MSError2.Boundary CustomUi={Fallback}>{children}</MSError2.Boundary>
}
