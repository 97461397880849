import { cn } from 'msutils/classnames'

export default function Divider({ layout = 'h' }: { layout?: 'h' | 'v' }) {
  return (
    <div
      className={cn(
        'shrink-0 bg-th-warmgrey-1',
        layout === 'h' && 'h-[1px]',
        layout === 'v' && 'w-[1px] h-full',
      )}
    />
  )
}
