import { createContext, ReactNode, useContext as useBaseContext, useMemo } from 'react'
import Icon from '../../data/Icon'

export namespace InputContainerUtils {
  export type Theme = {
    border: string
    errorBorder: string
    errorOutline: string
    errorOutlineOffset: number
    errorColor: string
    focusedBorder: string
    focusedOutline: string
    focusedOutlineOffset: number
    focusedShadow: string
    /** Note: inset is defined here, but applied at the input tag */
    inset: [number, number]
    background: string
    textColor: string
    disabledTextColor: string
    disabledBackground: string
  }

  type TInputContainerContext = {
    defaultTheme: Theme
    titleConfig?: 'always-hide' | 'standard'
    annotationConfig?: 'border-only' | 'standard'
    alignOverride?: 'right' | 'left'
    fitContentOverride?: boolean
  }

  const Context = createContext<TInputContainerContext | undefined>(undefined)

  export function useContext() {
    const ctx = useBaseContext(Context)
    if (!ctx) throw new Error('Expected InputContainer context')
    return ctx
  }

  export function Provider({
    value,
    children,
  }: {
    value: TInputContainerContext
    children: ReactNode
  }) {
    const parentCtx = useBaseContext(Context)
    const mergedValue = useMemo(() => ({ ...parentCtx, ...value }), [parentCtx, value])
    return <Context.Provider value={mergedValue}>{children}</Context.Provider>
  }

  export type CustomAnnotation = {
    name: string
    color: string
    icon?: Parameters<typeof Icon>[0]['name']
  }
}
