/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type QBPullRequestFailureReason =
  (typeof QBPullRequestFailureReason)[keyof typeof QBPullRequestFailureReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QBPullRequestFailureReason = {
  unknown: 'unknown',
  no_parent_object: 'no_parent_object',
  missing_project: 'missing_project',
  no_vendor: 'no_vendor',
  no_number: 'no_number',
  expense_already_has_attachment: 'expense_already_has_attachment',
  unknown_cost_code: 'unknown_cost_code',
  duplicate_email: 'duplicate_email',
  revoked_access: 'revoked_access',
  bad_item_type: 'bad_item_type',
  multiple_matches: 'multiple_matches',
  null: null,
} as const
