import { FC, ReactNode, useState } from 'react'
import Image from 'next/image'
import { cn } from 'msutils/classnames'
import LinkDONOTUSE, { Link2Utils } from 'compass-local/Link2'
import { Module } from 'modules/routes'
import { Collapsable, MSArray } from 'msutils/array'
import useScreenSize from 'compass/theme/useScreenSize'
import Logo from 'assets/logo-orange.svg'
import Typography from 'compass/data/Typography'
import Menu from 'compass-local/Menu'
import Divider from 'compass-local/Divider'
import { MosaicHref } from 'modules/routes/types'
import { Hamburger, X } from 'compass-local/legacy/icons'

type WithAlertDotProps = {
  show: boolean
  borderColor: string
  offsets?: string
  children: ReactNode
}

function WithAlertDot({
  show,
  offsets = '-top-0.75 -right-1',
  borderColor,
  children,
}: WithAlertDotProps) {
  return (
    <div className="relative">
      {show && (
        <div
          className={cn(
            'absolute bg-notification h-2 w-2 box-content',
            'rounded-full border border-2',
            offsets,
            borderColor,
          )}
        />
      )}
      {children}
    </div>
  )
}

type PassthroughProps<T extends object> =
  | {
      passthrough: true
      children: ReactNode
    }
  | {
      passthrough: false
      Component: FC<T & { children: ReactNode }>
      props: T
      children: ReactNode
    }

function Passthrough<T extends object>(props: PassthroughProps<T>) {
  if (props.passthrough) {
    return <>{props.children}</>
  } else {
    return <props.Component {...props.props}>{props.children}</props.Component>
  }
}

type AppScrollerProps = {
  pt?: boolean
  children: ReactNode
}

export function PageScroller({ children }: AppScrollerProps) {
  return (
    <div
      id="app-scroller"
      className="overflow-y-auto overflow-x-hidden md:overflow-x-auto w-full grow vflex z-10"
    >
      {children}
    </div>
  )
}

type AppMaxWidthProps = {
  children: ReactNode
}

export function PageMaxWidth({ children }: AppMaxWidthProps) {
  return (
    <div className="w-full max-w-[1360px] mx-auto flex justify-center md:px-4 lg:px-8 grow">
      {children}
    </div>
  )
}

type PageHeightProps = {
  children: ReactNode
}
export function PageHeight({ children }: PageHeightProps) {
  return <div className="pt-4 md:pt-10 vflex grow">{children}</div>
}

type TNavAction = {
  icon: ReactNode
  isActive: boolean
  name: ReactNode
  href?: MosaicHref
  onClick?: () => void
  caption?: ReactNode
  alert?: boolean
  badge?: ReactNode
  type?: undefined
}

function NavItem({ href, name, isActive, icon, badge, caption, onClick }: TNavAction) {
  return (
    <Passthrough
      {...(href
        ? { passthrough: false, Component: LinkDONOTUSE, props: { href } }
        : { passthrough: true })}
    >
      <div
        className={cn(
          'flex gap-3 py-3 pr-6 items-center box-border border-th-orange-beam transition-all duration-100 hover:bg-th-bg-dark-active cursor-pointer',
          isActive ? 'border-l-3 pl-[21px] bg-th-bg-dark-active' : 'pl-6',
          isActive ? 'text-th-bg-white' : 'text-th-text-darkmode',
        )}
        onClick={onClick}
      >
        <div className="flex gap-3 items-center">
          {icon}
          <div className="flex gap-1 items-center">
            <div className="vflex gap-1">
              <Typography>{name}</Typography>
              {caption && <Typography variant="caption">{caption}</Typography>}
            </div>
            {badge}
          </div>
        </div>
      </div>
    </Passthrough>
  )
}

type NavDivider = {
  type: 'divider'
}

export function NavAction(name: string, action: Omit<TNavAction, 'name'>) {
  return { name, ...action }
}

function sanitizeActions(actions: Collapsable<(TNavAction | NavDivider)[]>) {
  const results = MSArray.collapse(actions).reduce(
    (p, c) => {
      if (c?.type === 'divider') {
        if (MSArray.isEmpty(p)) return p
        if (p[p.length - 1]?.type === 'divider') return p
        return p.concat([c])
      } else {
        return p.concat([c])
      }
    },
    [] as (TNavAction | NavDivider)[],
  )
  if (results[results.length - 1]?.type === 'divider') {
    results.pop()
  }
  return results
}

type NavigationProps = {
  name: string
  accountHref: MosaicHref
  mainActions: Collapsable<(TNavAction | NavDivider)[]>
  secondaryActions: Collapsable<(TNavAction | NavDivider)[]>
  showAlert: boolean
  children: ReactNode
}

export function WithNavigation({
  name,
  accountHref,
  mainActions: mainActions_,
  secondaryActions: secondaryActions_,
  showAlert,
  children,
}: NavigationProps) {
  const sz = useScreenSize()
  const [menuIsShowing, setMenuIsShowing] = useState(false)
  const push = Link2Utils.usePush()

  const mainActions = sanitizeActions(mainActions_)
  const secondaryActions = sanitizeActions(secondaryActions_)

  if (sz === 'sm') {
    return (
      <div className="relative vflex h-full">
        <div className="border-th-warmgrey-1 shadow-[0px_2px_4px_2px_rgb(221,221,221,0.3)] border-b px-6 flex gap-4 items-center justify-between relative w-full bg-th-bg-beam-black">
          <div className="flex gap-3 items-center justify-between w-full py-[15px]">
            <div className="shrink-0">
              <LinkDONOTUSE href={Module('/').href}>
                <Image src={Logo} alt="Beam logo" />
              </LinkDONOTUSE>
            </div>
            <div className="bg-th-coolgrey-dark w h-6" />
            <div className="truncate">
              <LinkDONOTUSE href={accountHref}>
                <Typography
                  variant="label"
                  className="truncate transition-all duration-100 text-th-bg-white hover:text-th-text-darkmode"
                >
                  {name}
                </Typography>
              </LinkDONOTUSE>
            </div>
            <div className="grow" />
            <Menu
              open={menuIsShowing}
              onClose={() => setMenuIsShowing(false)}
              showDividerPadding
              options={[...mainActions, { type: 'divider' as const }, ...secondaryActions].map(
                (x) =>
                  x.type === 'divider'
                    ? x
                    : {
                        iconLeft: (
                          <WithAlertDot
                            show={x.alert ?? false}
                            borderColor="border-th-bg-white hover:border-th-orange-light2"
                          >
                            {x.icon}
                          </WithAlertDot>
                        ),
                        label: <Typography className="px-1">{x.name}</Typography>,
                        onClick: () => {
                          if (x.href) push(x.href)
                          else x.onClick?.()
                          setMenuIsShowing(false)
                        },
                      },
              )}
              autoFocus={false}
              overlayClassName="w-screen !rounded-b-8 !bg-white !border-th-warmgrey-1 !border !border-t-0 !absolute !inset-x-0 !top-[55px] [&_.ant-dropdown-menu]:overflow-y-auto [&_.ant-dropdown-menu]:max-h-[calc(100dvh-55px)]"
              itemClassName="py-1"
            >
              <div
                className="cursor-pointer text-th-text-darkmode hitbox-lg"
                onClick={() => setMenuIsShowing(!menuIsShowing)}
              >
                {menuIsShowing ? (
                  <X height={16} />
                ) : (
                  <WithAlertDot
                    show={showAlert}
                    borderColor="border-th-bg-beam-black"
                    offsets="-top-[5.5px] -right-[5.5px]"
                  >
                    <Hamburger height={13} />
                  </WithAlertDot>
                )}
              </div>
            </Menu>
          </div>
        </div>
        {children}
      </div>
    )
  } else {
    return (
      <div className="relative vflex h-full">
        <div className="absolute inset-0 flex">
          <div className="h-full w-[170px] lg:w-[256px] bg-th-bg-slate shrink-0">
            <div className="w-full h-full bg-th-bg-beam-black vflex test-navbar overflow-y-auto py-3">
              <div className="px-6 py-2.5 vflex gap-3">
                <LinkDONOTUSE href={Module('/').href}>
                  <Image src={Logo} alt="Beam logo" className="text-th-orange-beam" />
                </LinkDONOTUSE>
                <LinkDONOTUSE href={accountHref}>
                  <Typography className="text-th-bg-white">{name}</Typography>
                </LinkDONOTUSE>
              </div>
              {[{ type: 'divider' as const }, ...mainActions].map((x, i) =>
                x.type === 'divider' ? (
                  <div className="py-2.5" key={i}>
                    <Divider color="gray" />
                  </div>
                ) : (
                  <NavItem {...x} key={i} />
                ),
              )}
              <div className="grow" />
              {[{ type: 'divider' as const }, ...secondaryActions].map((x, i) =>
                x.type === 'divider' ? (
                  <div className="py-2.5" key={i}>
                    <Divider color="gray" />
                  </div>
                ) : (
                  <NavItem {...x} key={i} />
                ),
              )}
            </div>
          </div>
          {children}
        </div>
      </div>
    )
  }
}
