import { Cb, Q } from 'cb'
import { EstimateInputUtils } from 'components/inputs/EstimateInput'
import { RichAddressInputUtils } from 'components/inputs/RichAddressInput'
import { t } from 'content'
import { F } from 'msutils'
import { MSForm } from 'utils/form'
import { showToast } from 'utils/toast'

export namespace UpdateEstimateFormUtils {
  type Props = {
    estimate: Cb.Estimate
  }

  export const fc = MSForm.Controller({
    useQueries: ({ estimate }: Props) =>
      Q.group({
        generalProjectConfig: Cb.useRetrieveSingletonGeneralProjectConfig(),
        client: Cb.useListPayerContacts({
          select: ({ results }) => {
            return estimate.payer_id
              ? results.filter((x) => x.payer_id === estimate.payer_id).at(0) ?? null
              : null
          },
        }),
        addressExactMatch: RichAddressInputUtils.useExactMatch(estimate.address.raw_address ?? ''),
        estimate: Cb.useRetrieveEstimate(estimate.id, { refetchInterval: 5 * 1000 }),
        costCodes: Cb.useListCostCodes(),
        costTypes: Cb.useListCostTypes(),
        renofiAd: Cb.useListRenofiAds({ params: { estimate_id: estimate.id }, select: Q.opt }),
        mostRecentEstimateWithCoverPage: Cb.useListEstimates({
          params: { has_cover_page: 'true' },
          select: Q.opt,
        }),
      }),
  })

  type GetDefaultEmailBodyProps = {
    client: Cb.PayerContact
    address: string
    isSent: boolean
  }
  export function getDefaultEmailBody({ client, address, isSent }: GetDefaultEmailBodyProps) {
    return isSent
      ? `Hi ${client.name},\n\nHere is the updated estimate for ${address}. Please follow the link below to approve or reject it.`
      : `Hi ${client.name},\n\nHere is the estimate for ${address}. Please follow the link below to approve or reject it before it expires.`
  }

  export function useSendEstimate(estimateRef: Cb.Estimate) {
    const { renofiAd } = fc.useContext()
    const patchEstimate = Cb.usePartialUpdateEstimateHook()
    const createRenofiAd = Cb.useCreateRenofiAdHook()
    const patchRenofiAd = Cb.usePartialUpdateRenofiAdHook()
    const publishEstimate = Cb.usePublishEstimateHook()
    const patchClient = Cb.usePartialUpdatePayerContactHook()

    return async (validData: F.OutputShape<typeof EstimateInputUtils.schema>) => {
      const data = EstimateInputUtils.toApi(validData)
      const { overrideEmail, replacementEmail } =
        EstimateInputUtils.getClientEmailResults(validData)
      if (validData.client && replacementEmail) {
        await patchClient(validData.client.id, {
          email: validData.newClientEmail,
        })
      }

      const estimate = await patchEstimate(estimateRef.id, {
        ...data,
        update_version: estimateRef.update_version,
      })
      await publishEstimate({
        estimate_id: estimate.id,
        send_email: true,
        email_body: validData.emailBody,
        cc_emails: validData.ccEmails,
        ...(overrideEmail && {
          recipient_email_override: overrideEmail,
        }),
      })

      if (renofiAd) {
        await patchRenofiAd(renofiAd.id, { enabled: validData.enableRenofi })
      } else {
        await createRenofiAd({
          estimate_id: estimate.id,
          enabled: validData.enableRenofi,
        })
      }
      showToast({
        title: t('Updated estimate sent'),
        label: t('Updated estimate for {{ X1 }} sent to {{ X2 }}', {
          X1: RichAddressInputUtils.formatApi(estimate.address) ?? '',
          X2: validData.client?.name ?? '',
        }),
      })
      return estimate
    }
  }

  export function useSaveEstimateDraft(estimateRef: Cb.Estimate) {
    const { renofiAd } = fc.useContext()
    const patchEstimate = Cb.usePartialUpdateEstimateHook()
    const createRenofiAd = Cb.useCreateRenofiAdHook()
    const patchRenofiAd = Cb.usePartialUpdateRenofiAdHook()
    return async (validData: F.OutputShape<typeof EstimateInputUtils.schema>) => {
      const estimate = await patchEstimate(estimateRef.id, {
        ...EstimateInputUtils.toApi(validData),
        update_version: estimateRef.update_version,
      })
      if (renofiAd) {
        await patchRenofiAd(renofiAd.id, { enabled: validData.enableRenofi })
      } else {
        if (validData.enableRenofi) {
          await createRenofiAd({
            estimate_id: estimate.id,
            enabled: true,
          })
        }
      }
      showToast({
        title: t('Estimate draft updated'),
        label: t('Estimate draft for {{ X }} updated', {
          X: RichAddressInputUtils.formatApi(estimate.address) ?? '',
        }),
      })
      return estimate
    }
  }

  export function useAutosaveEstimate(estimateRef: Cb.Estimate) {
    const { renofiAd } = fc.useContext()
    const patchEstimate = Cb.usePartialUpdateEstimateHook()
    const createRenofiAd = Cb.useCreateRenofiAdHook()
    const patchRenofiAd = Cb.usePartialUpdateRenofiAdHook()
    return async (validData: F.OutputShape<typeof EstimateInputUtils.schema>) => {
      const estimate = await patchEstimate(estimateRef.id, {
        ...EstimateInputUtils.toApi(validData),
        update_version: estimateRef.update_version,
      })

      if (renofiAd?.enabled !== validData.enableRenofi) {
        if (renofiAd) {
          await patchRenofiAd(renofiAd.id, { enabled: validData.enableRenofi })
        } else {
          await createRenofiAd({ estimate_id: estimate.id, enabled: validData.enableRenofi })
        }
      }
      return estimate
    }
  }
}
