import BigNumber from 'bignumber.js'
import Alert from 'compass-local/Alert'
import Checkbox from 'compass-local/Checkbox'
import Modal from 'compass-local/Modal'
import { PercentInput, TextInput } from 'compass/input/TextInput'
import { EstimateInputUtils } from 'components/inputs/EstimateInput'
import { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import { t } from 'content'
import { F, MSArray, random } from 'msutils'
import { Action } from 'utils/actions'
import { useFormState } from 'utils/form-input'
import { SetCommissionFormUtils as Utils } from '../utils'

type Props = {
  setInactive?: () => void
  state: F.Input<typeof EstimateInputUtils.schema>
}

function setCommission(
  state: F.Input<typeof EstimateInputUtils.schema>,
  validData: F.OutputShape<typeof Utils.schema>,
) {
  if (validData.sections.filter((x) => x.included)) {
    state.showMarkup.update(false)
    state.showUnitCosts.update(false)
    state.showSubSectionPricing.update(false)
  }
  state.lineItems.children
    .filter((x) => x.type.value === 'group' && x.isSection.value)
    .map((section) => {
      const inputSection = MSArray.assertFind(
        validData.sections,
        (x) => x.sectionId === section.listId.value,
      )
      const existingCommissionItems = section.children.filter((x) => x.isCommission.value)
      if (inputSection.included) {
        if (existingCommissionItems.length) {
          existingCommissionItems[0].bufferReverseMultiplier.update(validData.maximumCommission)
          existingCommissionItems[0].discountMultiplier.update(
            validData.maximumCommission.minus(validData.newCommission),
          )
          existingCommissionItems[0].description.update(validData.description)
        } else {
          section.children._controller.append({
            listId: random(),
            type: 'buffered-discount',
            postSubtotal: true,
            description: validData.description,
            bufferReverseMultiplier: validData.maximumCommission,
            discountMultiplier: validData.maximumCommission.minus(validData.newCommission),
            isCommission: true,
            children: [],
          })
        }
      } else {
        if (existingCommissionItems.length) {
          EstimateItemsInputUtils.removeItemWithId(
            state.lineItems,
            existingCommissionItems[0].listId.value,
          )
        }
      }
      return null
    })
}

function SetCommissionForm({ setInactive, state: outerState }: Props) {
  const existingCommissionItems = MSArray.collapse(
    outerState.lineItems.children.map((c) => c.children.find((x) => x.isCommission.value)),
  )
  const firstExistingCommissionItem = existingCommissionItems[0]
  const initialValues = firstExistingCommissionItem
    ? {
        maximumCommission: firstExistingCommissionItem.bufferReverseMultiplier.value,
        newCommission: (
          firstExistingCommissionItem.bufferReverseMultiplier.value as BigNumber
        ).minus(firstExistingCommissionItem.discountMultiplier.value),
        description: firstExistingCommissionItem.description.value,
      }
    : {
        maximumCommission: BigNumber('0.1'),
        newCommission: BigNumber('0.1'),
        description: 'Personal discount',
      }

  const { state, useValidatedAction } = useFormState(Utils.schema, {
    initValue: {
      ...initialValues,
      sections: outerState.lineItems.children
        .filter((x) => x.type.value === 'group' && x.isSection.value)
        .map((section) => ({
          sectionId: section.listId.value,
          included:
            !firstExistingCommissionItem ||
            MSArray.isNonEmpty(section.children.filter((x) => x.isCommission.value)),
        })),
    },
  })

  const setCommissionAction = useValidatedAction(
    (validData) => {
      setCommission(outerState, validData)
      return Promise.resolve(null)
    },
    { disableSetInactiveOnSuccess: true },
  )

  return (
    <Modal.Body
      title={t('Set commission')}
      setInactive={() => setInactive?.()}
      actions={[
        Action.button(t('Set'), {
          onClick: async () => {
            await setCommissionAction.mutateAsync()
            setInactive?.()
          },
        }),
      ]}
    >
      {setCommissionAction.error && <Alert>{setCommissionAction.error.message}</Alert>}
      <PercentInput
        title={t('Maximum commission')}
        {...F.props(state.maximumCommission)}
        disabled
      />
      <PercentInput title={t('New commission')} {...F.props(state.newCommission)} />
      <TextInput title={t('Description')} {...F.props(state.description)} />
      {state.sections.map((row, i) => {
        const section = MSArray.assertFind(
          outerState.lineItems.children,
          (x) => x.listId.value === row.sectionId.value,
        )
        return <Checkbox key={i} title={section.description.value} {...F.props(row.included)} />
      })}
    </Modal.Body>
  )
}

export default Modal.asModal(SetCommissionForm)
