export const colors = {
  'beam-black': '#051027',
  'dark-active': '#152038',
  slate: '#F9FAFB',
  white: '#FFFFFF',
  toast: '#1A1D22',
  'white-transparent': 'rgba(255, 255, 255, 0.9)',
  'black-transparent': 'rgba(0, 0, 0, 0.7)',
  text: '#353A4A',
  primary: '#353A4A',
  focus: '#0B111E',
  disabled: '#909296',
  hint: '#ADAFB4',
  secondary: '#6D7180',
  darkmode: 'rgb(221 225 233 / 0.7)', // #DDE1E9
  'coolgrey-dark': '#6F6F78',
  'coolgrey-1': '#9CA0A7',
  'coolgrey-2': 'rgb(156 163 175 / 0.5)', // #9CA3AF
  'coolgrey-2.5': '#E8EBF0',
  'coolgrey-3': '#F2F4F8',
  'coolgrey-dark3': '#E7EAEF',
  'warmgrey-dark': '#AAA6A4',
  'warmgrey-1': 'rgb(170 166 164 / 0.5)', // #AAA6A4
  'warmgrey-2': 'rgb(208 203 201 / 0.5)', // #D0CBC9
  'warmgrey-3': 'rgb(229 224 223 / 0.5)', // #E5E0DF
  'brown-1': '#633A23',
  'brown-2': '#726562',
  'gold-1': '#A8800A',
  'gold-2': '#BA9422',
  'gold-3': '#F9E3A1',
  'orange-dark1': '#C44501',
  'orange-beam': '#F87029',
  'orange-light1': '#FF9158',
  'orange-light2': '#FEE2C8',
  'blue-2': '#0046D1',
  'blue-light0': '#3E89C2',
  'blue-light1': '#BFE8FF',
  'green-dark1': '#008932',
  'green-dark2': '#2EBE63',
  'green-success': '#008932',
  'green-light1': '#B6FFB5',
  'red-warning': '#BD0002',
  'red-light1': '#FDCFCF',
  'red-dark1': '#E46D6D',
  'yellow-light1': '#FEF9C8',
  'yellow-dark1': '#CF9907',
  'yellow-dark2': '#E4BC6D',
  shadow: 'hsla(0, 0%, 87%, .3)',
}
