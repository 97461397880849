/* eslint-disable mosaic-js/unnamed-args */
import { MSError2 } from './error2'

export function toBe<S>(otherVal: S): <T>(val: T) => val is S extends T ? S : never {
  return <T>(val: T): val is S extends T ? S : never => (val as any) === otherVal
}

export function notToBe<S>(otherVal: S): <T>(val: T) => val is S extends T ? Exclude<T, S> : never {
  return <T>(val: T): val is S extends T ? Exclude<T, S> : never => (val as any) === otherVal
}

export function present(): <T>(val: T) => val is NonNullable<T> {
  return <T>(val: T): val is NonNullable<T> => val !== null && val !== undefined
}

export function truthy(): <T>(val: T) => val is Exclude<T, false | 0 | '' | null | undefined> {
  return <T>(val: T): val is Exclude<T, false | 0 | '' | null | undefined> => !!val
}

// eslint-disable-next-line mosaic-js/no-unnamed-boolean-arg
export function assert(condition: boolean, message?: string) {
  if (!condition) {
    throw new MSError2(message ?? 'Assertion error')
  }
}

export function expect<T, S extends T = NonNullable<T>>(
  val: T,
  cond?: (val2: T) => val2 is S,
): asserts val is S {
  const condition: any = cond ?? present()
  if (!condition(val)) throw new MSError2('Expectation error')
}

export function expectReturn<T, S extends T = NonNullable<T>>(
  val: T,
  cond?: (val2: T) => val2 is S,
): S {
  const condition: any = cond ?? present()
  if (!condition(val)) throw new MSError2('Expectation error')
  return val as S
}

export function expectReturn2<T, S extends T = NonNullable<T>>(
  val: T,
  cond?: (val2: T) => val2 is S,
): (message?: string) => S {
  return (message: string = 'Expectation error') => {
    const condition: any = cond ?? present()
    if (!condition(val)) throw new MSError2(message)
    return val as S
  }
}

export function guard<T, S extends T>(
  val: T,
  cond: (val2: T) => val2 is S,
  message?: string,
): val is S {
  if (cond(val)) {
    return true
  } else {
    MSError2.report(new MSError2(`[Guard] ${message ?? 'Unexpected failure'}`))
    return false
  }
}
