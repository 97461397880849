import { ReactNode, useState } from 'react'
import Tooltip from '../../layout/Tooltip'

// TODO: this component should probably be within BaseLayout and allow you to specify ViewProps based on some user interaction state

type IState = 'idle' | 'disabled' | 'hovered'

type Props = {
  onClick?: () => void
  onFocus?: () => void
  onBlur?: () => void
  disabled?: boolean | string
  background?: (interactionState: IState) => string | undefined
  fillHeight?: boolean
  children: ReactNode
}

export default function InteractionTarget({
  onClick,
  onFocus,
  onBlur,
  disabled,
  background,
  fillHeight,
  children,
}: Props) {
  const disabledMessage = disabled && typeof disabled === 'string' ? disabled : null
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Tooltip message={disabledMessage} inactive={!disabledMessage}>
      <div
        onClick={disabled ? undefined : onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          cursor: onClick ? (disabled ? 'not-allowed' : 'pointer') : undefined,
          background: background?.(disabled ? 'disabled' : isHovered ? 'hovered' : 'idle'),
          transition: 'all',
          transitionDuration: '100ms',
          height: fillHeight ? '100%' : undefined,
        }}
      >
        {children}
      </div>
    </Tooltip>
  )
}
