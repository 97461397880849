import * as Cb from '../../client'
import { config } from './config'
import * as ProjectUtils from './project'
import * as BusinessUtils from './business'

type CreateBillProps = {
  auth: string
  business: Cb.Business
  props?: Partial<{
    projectId?: string
    payeeId?: string
    contractId?: string | null
    dueDate?: string
    date?: string
    number?: string
    lineItems?: Partial<{
      workCompleted: string
      description: string
    }>[]
  }>
}

export async function createBill({ auth, business, props }: CreateBillProps) {
  const projectId = props?.projectId ?? (await ProjectUtils.createProject({ auth })).id
  const payeeId = props?.payeeId ?? (await BusinessUtils.createVendor({ business, auth })).payee_id

  const contract = await (props?.contractId
    ? Cb.retrieveContract(props.contractId, config(auth))
    : ProjectUtils.createSubcontract({
        business,
        auth,
        props: { projectId, payeeId },
      }))

  const bill = await Cb.createBill(
    {
      payer_id: business.id,
      payee_id: payeeId,
      contract_id: contract.id,
      invoice_type: 'progress',
      line_items: (contract.items ?? []).map((x) => ({
        invoice_id: null,
        cost_code_id: x.cost_code_id,
        project_id: contract.project_id,
        contract_item_id: x.id,
        description: x.description,
        work_completed: x.current_amount,
        retainage_billed: '0',
        work_completed_source: null,
      })),
      due_date: props?.dueDate ?? '2022-10-20',
      date: props?.date ?? '2022-10-20',
      number: props?.number ?? '1',
      memo: '',
      dest_transaction_account_id: null,
      bank_payment_allowed: true,
      card_payment_allowed: false,
      payer_pays_card_fees: false,
      primary_file: null,
      send_lien_waiver_email: false,
    },
    config(auth),
  )
  return bill
}

type CreateInvoiceProps = {
  auth: string
  business: Cb.Business
  props?: Partial<{
    projectId?: string
    payerId?: string
    contractId?: string | null
    dueDate?: string
    date?: string
    number?: string
    lineItems?: Partial<{
      workCompleted: string
      description: string
    }>[]
  }>
}

export async function createInvoice({ auth, business, props }: CreateInvoiceProps) {
  const projectId = props?.projectId ?? (await ProjectUtils.createProject({ auth })).id
  const payerId = props?.payerId ?? (await BusinessUtils.createClient({ business, auth })).payer_id

  const contract = await (props?.contractId
    ? Cb.retrieveContract(props.contractId, config(auth))
    : ProjectUtils.createSupContract({
        auth,
        business,
        props: { projectId, payerId },
      }))

  const invoice = await Cb.createInvoice(
    {
      payer_id: payerId,
      payee_id: business.id,
      contract_id: contract.id,
      invoice_type: 'progress',
      line_items: (contract.items ?? []).map((x) => ({
        invoice_id: null,
        cost_code_id: x.cost_code_id,
        project_id: contract.project_id,
        contract_item_id: x.id,
        description: x.description,
        work_completed: x.current_amount,
        retainage_billed: '0',
        work_completed_source: null,
      })),
      due_date: props?.dueDate ?? '2022-10-20',
      date: props?.date ?? '2022-10-20',
      number: props?.number ?? '1',
      memo: '',
      dest_transaction_account_id: null,
      bank_payment_allowed: true,
      card_payment_allowed: false,
      payer_pays_card_fees: false,
      primary_file: null,
      guest_email_body: '',
      send_email: true,
    },
    config(auth),
  )
  return invoice
}
