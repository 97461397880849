import { useState } from 'react'
import { baseIso, MSDate } from 'msutils'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker } from 'antd'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import BaseTextInput from '../BaseTextInput'

// what in the world is this
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

function toDayjs(value: MSDate) {
  const d = new Date(value.valueOf())
  return dayjs(`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`)
}

function toMSDate(value: Dayjs) {
  return MSDate.init(
    baseIso(value.toDate().getFullYear(), value.toDate().getMonth() + 1, value.toDate().getDate()),
  )
}

type Props = {
  title?: string
  value: MSDate | null
  update?: (newValue: MSDate | null) => void
  focus?: () => void
  blur?: () => void
  error?: string | null
  placeholder?: string | MSDate
  min?: MSDate
  max?: MSDate
  optional?: boolean
  hidden?: boolean
  disabled?: boolean
  disableSelection?: (value: MSDate) => boolean
}

export default function DateInput({
  title,
  hidden,
  disabled,
  value,
  update,
  focus,
  blur,
  optional,
  error,
  placeholder,
  min,
  max,
  disableSelection,
}: Props) {
  const [textInputValue, setTextInputValue] = useState('')
  const [pickerIsOpen, setPickerIsOpen] = useState(false)

  const isAcceptableSelection = (newValue: MSDate) => {
    return !disableSelection?.(newValue) && !(min && newValue.lt(min)) && !(max && newValue.gt(max))
  }

  const tryUpdateWithRestrictions = (newValue: MSDate | null) => {
    if (!newValue) {
      update?.(newValue)
    } else {
      // TODO: this shouldn't be a UI level thing
      if (isAcceptableSelection(newValue)) {
        update?.(newValue)
      }
    }
  }

  if (hidden) return null
  return (
    <div className="relative min-w-[150px]">
      <BaseTextInput
        title={title}
        typeProps={{
          serializationConfig: {
            toDisplay: (x) => x,
            fromDisplay: (x) => x,
            isAcceptableAsIntermediateValue: (val) => !val || /^[0-9/]*$/.test(val),
            isAcceptableAsExternalValue: () => true,
            eq: (a, b) => a === b,
          },
        }}
        maxLength={10}
        value={textInputValue || value?.format('numeric') || ''}
        update={(newValue) => {
          setTextInputValue(newValue)
          if (!newValue) {
            tryUpdateWithRestrictions(null)
          }
        }}
        disabled={disabled}
        focus={() => {
          focus?.()
          setPickerIsOpen(true)
        }}
        blur={() => {
          blur?.()
          setPickerIsOpen(false)
          try {
            const [m, d, y] = textInputValue.split('/')
            setTextInputValue('')
            tryUpdateWithRestrictions(
              MSDate.init([y, m.padStart(2, '0'), d.padStart(2, '0')].join('-')),
            )
          } catch (e: any) {
            // pass
          }
        }}
        optional={optional}
        error={error}
        placeholder={
          typeof placeholder === 'string'
            ? placeholder
            : placeholder?.format('numeric') ?? 'MM/DD/YYYY'
        }
      />
      <DatePicker
        open={pickerIsOpen}
        value={value ? toDayjs(value) : null}
        onChange={(newValue) => {
          tryUpdateWithRestrictions(newValue ? toMSDate(newValue) : null)
          setPickerIsOpen(false)
          blur?.()
          ;(document.activeElement as any)?.blur()
        }}
        defaultPickerValue={placeholder instanceof MSDate ? toDayjs(placeholder) : undefined}
        popupClassName="z-[10201]"
        inputRender={() => null}
        rootClassName="[&.ant-picker]:invisible [&.ant-picker]:absolute top-10"
        disabledDate={(date) => !isAcceptableSelection(toMSDate(date))}
      />
    </div>
  )
}
