import { createContext, isValidElement, ReactNode, useContext, useMemo } from 'react'
import { cn } from 'msutils/classnames'
import { CompassTypes } from 'compass-local'
import { Chevron, DragIndicator } from 'compass-local/legacy/icons'
import LinkDONOTUSE from 'compass-local/Link2'
import Typography from 'compass/data/Typography'
import { TypographyUtils } from 'compass/data/Typography/utils'
import { unreachable } from 'msutils/misc'
import Checkbox from 'compass-local/Checkbox'
import { theme2 } from 'theme2'
import useScreenSize from 'compass/theme/useScreenSize'
import { BorderStyle } from 'compass-local/input/utils'
import Button from 'compass/data/Button'
import { useLayoutFlagContext } from 'compass/layout/LayoutFlagContext'

export type Style = 'estimate' | 'item-list' | 'input-table' | 'preview'

// column utils
export type CellMetaCallback<T, TData, TCtx> = (
  props: { row: TData; depth: number },
  ctx: TCtx,
) => T
type CellMetaProp<T, TData, TCtx> = T | CellMetaCallback<T, TData, TCtx>

export type RowMetaCallback<T, TData, TCtx> = (
  value: TData,
  props: { ctx: TCtx; depth: number; index: number; id: string; parent: TData | null },
) => T

export function getPropFromMeta<T, TData, TCtx>(
  prop: CellMetaProp<T, TData, TCtx>,
  {
    cellMeta,
    ctx,
  }: {
    cellMeta: { row: TData; depth: number }
    ctx: TCtx
  },
): T {
  if (typeof prop !== 'function') {
    return prop
  } else {
    return (prop as CellMetaCallback<T, TData, TCtx>)(cellMeta, ctx)
  }
}

export type ExternallyUsableColumnProps<T, TData, TCtx> = {
  format?: (x: T) => ReactNode
  tooltip?: CellMetaProp<string, TData, TCtx>
  bold?: CellMetaProp<boolean, TData, TCtx>
  /** Cell renders empty */
  hidden?: CellMetaProp<boolean, TData, TCtx>
  /** Cell renders empty and will be filled by the right-most non-void column */
  void?: CellMetaProp<boolean, TData, TCtx>
  border?: CellMetaProp<BorderStyle, TData, TCtx>
  position?: CellMetaProp<'row' | 'title' | 'description' | 'badge' | 'status', TData, TCtx>
  whitespace?: CellMetaProp<'nowrap' | 'default', TData, TCtx>
  desktopOnly?: CellMetaProp<boolean, TData, TCtx>
  /** Only applicable for input-table */
  nonInput?: CellMetaProp<boolean, TData, TCtx>
  /** Only applicable for input-table */
  inputTitle?: CellMetaProp<string | undefined, TData, TCtx>
  align?: 'right' | 'left'
  size?: {
    minWidth?: CellMetaProp<`min-w-[${string}]`, TData, TCtx>
    maxWidth?: CellMetaProp<`max-w-[${string}]`, TData, TCtx>
    minimize?: CellMetaProp<boolean, TData, TCtx>
    maximize?: CellMetaProp<boolean, TData, TCtx>
  }
  dynamic?: {
    header?: (ctx: TCtx) => string
    omit?: (ctx: TCtx) => boolean
    mobileOnly?: (ctx: TCtx) => boolean
  }
}

export type ColumnDef<T, TData, TCtx> = ExternallyUsableColumnProps<T, TData, TCtx> & {
  header: string
  accessor: CellMetaCallback<T, TData, TCtx>
}

export type Row<TData> = {
  data: TData
  depth: number
  hasChildren: boolean
  path: number[]
  parent: { data: TData; index: number } | null
}

// style utils
type Children = {
  children?: ReactNode
}

type ClassName = {
  className?: string
}

type OnClick = {
  onClick?: () => void
}

type Bordered = {
  border: BorderStyle
}

export function getBorderClassNames(border: BorderStyle) {
  if (!border) return ''
  return cn(
    border.width === 'sm'
      ? 'border'
      : border.width === 'lg'
      ? 'border outline outline-1.5 -outline-offset-2'
      : unreachable(border.width),
    border.color === 'grey'
      ? 'border-th-warmgrey-2 outline-th-warmgrey-2'
      : border.color === 'red'
      ? 'border-double outline-th-red-warning'
      : unreachable(border.color),
  )
}

type ColSpan = {
  colSpan?: number
}

const trContext = createContext<{ href?: CompassTypes['href'] } | undefined>(undefined)

export function TD({
  border,
  colSpan,
  maximize,
  minimize,
  children,
}: Children & Bordered & ColSpan & { maximize?: boolean; minimize?: boolean }) {
  const { href } = useContext(trContext) ?? {}
  const content = href ? <LinkDONOTUSE href={href}>{children}</LinkDONOTUSE> : children

  return (
    <td
      className={cn(
        'first:pl-3 last:pr-3 p-0 m-0 first:border-l-0 last:border-r-0',
        getBorderClassNames(border),
        maximize && 'w-full',
        minimize && 'w-[1px]',
      )}
      colSpan={colSpan}
    >
      {content}
    </td>
  )
}

export function TH({ children, border, colSpan }: Children & Bordered & ColSpan) {
  return (
    <td
      className={cn(
        'first:pl-3 last:pr-3 first:border-l-0 last:border-r-0',
        getBorderClassNames(border),
      )}
      colSpan={colSpan}
    >
      {children}
    </td>
  )
}

export function TR({
  className,
  rowRef,
  onClick,
  href,
  onMouseEnter,
  onMouseLeave,
  children,
}: Children &
  OnClick &
  ClassName & {
    href?: CompassTypes['href']
    rowRef?: any
    onMouseEnter?: () => void
    onMouseLeave?: () => void
  }) {
  const ctxValue = useMemo(() => ({ href }), [href])
  return (
    <tr
      className={className}
      onClick={onClick}
      ref={rowRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <trContext.Provider value={ctxValue}>{children}</trContext.Provider>
    </tr>
  )
}

export function Table({ style, children }: Children & { style: Style }) {
  const { disabledTableInset } = useLayoutFlagContext()
  return (
    <div
      className={cn(
        'flex overflow-x-auto',
        disabledTableInset ? '' : style === 'input-table' ? '-mx-8' : '-mx-5',
      )}
    >
      <table className="grow">{children}</table>
    </div>
  )
}

export function getHeaderBorderForStyle(style: Style) {
  return style === 'item-list' || style === 'estimate'
    ? 'border-b-2 border-th-warmgrey-2'
    : style === 'preview'
    ? 'border-b-2 border-black'
    : style === 'input-table'
    ? ''
    : unreachable(style)
}

export function THead({ style, children }: Children & { style: Style }) {
  return <thead className={cn(getHeaderBorderForStyle(style))}>{children}</thead>
}

export function TBody({ children }: Children) {
  return <tbody>{children}</tbody>
}

export function getAlignment(align: 'right' | 'left' | undefined) {
  return align === 'right'
    ? // note: !flex because this is in a Typography, which would overwrite the flex otherwise
      'text-right !flex justify-end'
    : align === 'left' || align === undefined
    ? 'text-left !flex justify-start'
    : unreachable(align)
}

export function getWhitespace(whitespace: 'nowrap' | 'default' | undefined) {
  return whitespace === 'nowrap'
    ? 'whitespace-nowrap'
    : whitespace === 'default'
    ? ''
    : !whitespace
    ? undefined
    : unreachable(whitespace)
}

export function getHeaderPaddingForStyle(style: Style) {
  return style === 'item-list' || style === 'estimate'
    ? 'px-3 py-2'
    : style === 'input-table'
    ? 'px-3 py-2'
    : style === 'preview'
    ? 'px-3 py-2'
    : unreachable(style)
}

export function getPaddingForStyle(style: Style) {
  return style === 'item-list' || style === 'estimate'
    ? 'px-3 py-4'
    : style === 'input-table'
    ? 'p-0'
    : style === 'preview'
    ? 'px-3 py-2'
    : unreachable(style)
}

export function getHeaderColorForStyle(style: Style) {
  return style === 'item-list' || style === 'estimate' || style === 'input-table'
    ? 'text-th-coolgrey-1'
    : style === 'preview'
    ? 'text-black'
    : unreachable(style)
}

export function getHeaderVariantForStyle(style: Style): TypographyUtils.Variant {
  return style === 'item-list' || style === 'estimate' || style === 'input-table'
    ? 'label'
    : style === 'preview'
    ? 'bodybold'
    : unreachable(style)
}

export function getBorderForStyle(style: Style) {
  return style === 'item-list' || style === 'estimate' || style === 'input-table'
    ? 'border-b border-th-warmgrey-2'
    : style === 'preview'
    ? 'border-b border-black'
    : unreachable(style)
}

export function getVerticalAlignmentForStyle(style: Style) {
  return style === 'item-list' || style === 'input-table'
    ? ''
    : style === 'estimate'
    ? 'align-top'
    : style === 'preview'
    ? 'align-top'
    : unreachable(style)
}

export function HrefIndicator() {
  return <Chevron className="text-th-brown-2" />
}

type CellProps<TData, TCtx> = {
  column: ColumnDef<any, TData, TCtx>
  props: { ctx: TCtx; cellMeta: { row: TData; depth: number } }
}

export function Cell<TData, TCtx>({ column, props: { ctx, cellMeta } }: CellProps<TData, TCtx>) {
  const val = column.accessor(cellMeta, ctx)
  const formattedValue = column.format ? column.format(val) : val ?? '--'

  if (getPropFromMeta(column.hidden, { ctx, cellMeta })) {
    return null
  } else if (typeof formattedValue === 'string' || isValidElement(formattedValue)) {
    return <>{formattedValue}</>
  } else {
    // eslint-disable-next-line mosaic-js/no-raw-text-jsx
    return <>-</>
  }
}

type TableElementProps = {
  title: string
  position?: 'title' | 'row'
  secondary?: boolean
  subtitle?: string | null | false
  status?: ReactNode
  href?: CompassTypes['href'] | null
  icon?: ReactNode
}

export function TableElement({
  title,
  secondary,
  position,
  href,
  icon,
  status,
  subtitle,
}: TableElementProps) {
  const sz = useScreenSize()

  return (
    <div className="flex gap-2">
      {icon}
      <div className={cn(subtitle && 'vflex gap-1', 'self-center')}>
        <div className={cn(status && 'flex gap-1')}>
          {href ? (
            <LinkDONOTUSE href={href}>
              <div className="w-min">
                <Button theme={theme2.ButtonThemeTextDarkSmall}>{title}</Button>
              </div>
            </LinkDONOTUSE>
          ) : (
            <Typography variant={secondary ? 'body' : 'bodybold'}>{title}</Typography>
          )}
          {status}
        </div>
        {subtitle && (sz !== 'sm' || position === 'title') && (
          <Typography variant="label" className="text-th-text-secondary">
            {subtitle}
          </Typography>
        )}
      </div>
    </div>
  )
}

type DragControlProps = {
  dragRef: any
  visible: boolean
  isDragging: boolean
}

export function DragControl({ dragRef, visible, isDragging }: DragControlProps) {
  return (
    <div
      ref={dragRef}
      className={cn(
        'h-fit text-th-brown-2 transition-all duration-200',
        isDragging ? 'cursor-grabbing' : 'cursor-grab',
        !visible && 'opacity-0',
      )}
    >
      <DragIndicator height={14} />
    </div>
  )
}

type ExpandControlProps = {
  expanded: boolean
  setExpanded: (newValue: boolean) => void
}

export function ExpandControl({ expanded, setExpanded }: ExpandControlProps) {
  return (
    <Chevron
      className={cn('hitbox transition-all text-th-brown-2 w-min', expanded && 'rotate-90')}
      height={16}
      onClick={() => setExpanded(!expanded)}
    />
  )
}

type SelectControlProps = {
  selected: boolean | 'partial' | 'indeterminate'
  setSelected: (newValue: boolean) => void
  disabledMessage: string | null
}

export function SelectControl({ selected, setSelected, disabledMessage }: SelectControlProps) {
  return (
    <Checkbox
      value={selected === 'partial' || selected === 'indeterminate' ? false : selected}
      disabled={selected === 'indeterminate'}
      update={setSelected}
      showDashWhenUnselected={selected === 'partial'}
      expandHitbox
      title={null}
      {...(disabledMessage && { disabled: true, disabledMessage })}
    />
  )
}
