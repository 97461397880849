import { ReactNode } from 'react'
import { cn } from 'msutils/classnames'
import { TypographyUtils } from './utils'

type VariantProps = {
  variant: TypographyUtils.Variant
}

type CustomProps = {
  size: `text-${string} md:text-${string}`
  weight: `font-${string} md:font-${string}`
}

type CoreProps = {
  className: string
  rawColor?: string
  onClick: () => void
  preserveWhitespace: boolean
  children: ReactNode
}

type Props = Partial<CoreProps> & (Partial<VariantProps> | CustomProps)

export default function Typography({
  className,
  onClick,
  preserveWhitespace,
  children,
  rawColor,
  ...props
}: Props) {
  const variantProps =
    'size' in props
      ? `${props.size} ${props.weight}`
      : TypographyUtils.Styles[props.variant ?? 'body']

  return (
    <div
      className={cn('inline', variantProps, className, preserveWhitespace && 'whitespace-pre-wrap')}
      onClick={onClick}
      style={{ color: rawColor }}
    >
      {children}
    </div>
  )
}
