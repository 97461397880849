/* eslint-disable mosaic-js/unnamed-args */
import { t } from 'content'
import { MSError2 } from '../error2'

// TODO: this should include 0
export type Collapsable<T extends any[]> = (T[number] | false | undefined | null | '')[]
export type Collapsed<T> = Exclude<T, false | undefined | null | ''>

type Comparable = number | string | boolean
type KeyFn<T> = (a: T) => Comparable
type KeyFnWithMeta<T> = { fn: KeyFn<T>; order: 'asc' | 'desc' }
type KeyFnSpec<T> = KeyFn<T> | KeyFnWithMeta<T>

export class MSArray<T> {
  private arr: readonly T[]

  private constructor(arr: readonly T[]) {
    this.arr = arr
  }

  private assertFind(fn: (val: T) => boolean): T {
    const value = this.arr.find(fn)
    if (!value) {
      throw new MSError2('Error in assert find')
    } else {
      return value
    }
  }

  private toObject<S, K extends string>(fn: (val: T) => [K, S]): Record<K, S> {
    return Object.fromEntries(this.arr.map(fn)) as Record<K, S>
  }

  private collapse(): Collapsed<T>[] {
    const checkIsPresent = (val: T): val is Collapsed<T> => {
      if (val === false || val === undefined || val === null || val === '') return false
      else return true
    }
    return this.arr.flatMap((val) => (checkIsPresent(val) ? [val] : []))
  }

  private removeAt(i: number): T[] {
    return this.arr.filter((_, j) => i !== j)
  }

  private dedupe(): T[] {
    return this.arr.filter((x, i, self) => self.indexOf(x) === i)
  }

  private replace(i: number, newItem: T): T[] {
    return this.arr.map((item, j) => (i === j ? newItem : item))
  }

  static assertFind<T>(arr: readonly T[], fn: (val: T) => boolean): T {
    return new MSArray(arr).assertFind(fn)
  }

  static toObject<T, S, K extends string>(arr: readonly T[], fn: (val: T) => [K, S]): Record<K, S> {
    return new MSArray(arr).toObject(fn)
  }

  static collapse<T>(arr: readonly T[]): Collapsed<T>[] {
    return new MSArray(arr).collapse()
  }

  static removeAt<T>(arr: readonly T[], i: number): T[] {
    return new MSArray(arr).removeAt(i)
  }

  static dedupe<T>(arr: readonly T[]): T[] {
    return new MSArray(arr).dedupe()
  }

  static print<T extends string>(
    arr: readonly T[],
    {
      noElementsMessage,
      maxNumItems = 3,
    }: Partial<{ noElementsMessage: string; maxNumItems: number }> = {},
  ) {
    if (arr.length === 0) {
      return noElementsMessage ?? t('No items')
    } else if (arr.length === 1) {
      // eslint-disable-next-line
      return arr[0]
    } else if (arr.length === 2) {
      return arr.join(` and `)
    } else if (arr.length <= maxNumItems) {
      return t('{{ X1 }} and {{ X2 }}', {
        X1: arr.slice(0, maxNumItems - 1).join(', '),
        X2: arr[arr.length - 1],
      })
    } else {
      return t('{{ X1 }} and {{ X2 }} more items', {
        X1: arr.slice(0, maxNumItems - 1).join(', '),
        X2: arr.length - (maxNumItems - 1),
      })
    }
  }

  static sum<T extends string | number>(arr: readonly T[]) {
    return arr.map((v) => Number(v)).reduce((p, c) => p + c, 0)
  }

  static sumAmount<T extends string | number>(arr: readonly T[]) {
    return Number(
      arr
        .map((v) => Number(v))
        .filter((v) => !Number.isNaN(v))
        .reduce((p, c) => p + c, 0)
        .toFixed(2),
    )
  }

  static replace<T>(arr: readonly T[], i: number, newItem: T): T[] {
    return new MSArray(arr).replace(i, newItem)
  }

  static findLast<T>(arr: readonly T[], fn: (el: T) => boolean): T | undefined {
    const matches = arr.filter(fn)
    if (matches.length === 0) return undefined
    else return matches[matches.length - 1]
  }

  static last<T>(arr: readonly T[]): T | undefined {
    return arr.length === 0 ? undefined : arr[arr.length - 1]
  }

  static groupsOfSize<T>(arr: readonly T[], num: number): T[][] {
    return arr.reduce((p, c) => {
      if (p.length === 0) {
        return [[c]]
      } else if (p[p.length - 1].length < num) {
        return [...p.slice(0, -1), [...p[p.length - 1], c]]
      } else {
        return [...p, [c]]
      }
    }, [] as T[][])
  }

  static count<T>(arr: readonly T[], fn: (val: T) => string) {
    return arr.reduce(
      (p, c) => {
        const id = fn(c)
        return { ...p, [id]: (p[id] ?? 0) + 1 }
      },
      {} as { [key: string]: number },
    )
  }

  static isEmpty<T>(arr: readonly T[]) {
    return arr.length === 0
  }

  static isNonEmpty<T>(arr: readonly T[]) {
    return arr.length > 0
  }

  static sort<T>(arr: T[], keyFns: KeyFnSpec<T> | KeyFnSpec<T>[]) {
    const keyFnsArray = Array.isArray(keyFns) ? keyFns : [keyFns]
    const keyFnsWithMetaArray: KeyFnWithMeta<T>[] = keyFnsArray.map((x) =>
      typeof x === 'function' ? { fn: x, order: 'asc' } : x,
    )
    return [...arr].sort((a, b) => {
      const unequalKeyFn = keyFnsWithMetaArray.find(({ fn }) => fn(a) !== fn(b))
      if (unequalKeyFn) {
        const comparison = unequalKeyFn.fn(a) > unequalKeyFn.fn(b) ? 1 : -1
        return unequalKeyFn.order === 'asc' ? comparison : comparison * -1
      } else {
        return 0
      }
    })
  }

  static argMax<T>(arr: T[], key: KeyFn<T>) {
    return MSArray.sort(arr, key).at(-1)
  }

  static join<T extends string>(arr: Collapsable<T[]>) {
    return MSArray.collapse(arr).join(' • ')
  }
}
