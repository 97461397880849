import { Cb, Q } from 'cb'
import { InputBaseUtils } from 'compass-local/InputBase'
import SelectInput from 'compass-local/SelectInput'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import { t } from 'content'
import { F, Format, Zero } from 'msutils'
import { useBusinessContext } from 'root/user'
import { includesIgnoreCase } from 'utils/string'
import BigNumber from 'bignumber.js'
import Select2 from 'compass-local/input/Select2'
import { BudgetingUtils } from 'features/budgeting'
import { BillingUtils } from 'features/billing'

type Props = InputProps<Cb.CostCode | null> &
  InputBaseUtils.ExternalProps & {
    perspective: Cb.ContractPerspective
    descriptionInput?: F.InputCell<string>
    amountInput?: F.InputCell<string>
    amountInput2?: F.InputCell<BigNumber | ''>
    filter?: (option: Cb.CostCode) => boolean
    projectId?: string | null
    v2?: boolean
  }

export default function AccountingCodeSelect(props: Props) {
  const {
    value,
    update,
    focus,
    blur,
    perspective,
    didUpdate,
    descriptionInput,
    amountInput,
    amountInput2,
    projectId,
  } = getInputProps(props)
  const { business } = useBusinessContext()
  const q = Q.group({
    costCodes: Cb.useListCostCodes({
      params: { is_archived: 'false' },
      select: Q.filter((x) => (perspective === 'payer' ? x.expense_supported : x.income_supported)),
    }),
  })

  const options =
    q.status === 'success'
      ? props.filter
        ? q.queries.costCodes.filter(props.filter)
        : q.queries.costCodes
      : null

  const budgetMetadataQ = Q.group({
    budget: Cb.useListProjectBudgets({
      params: { project_id: projectId ?? Q.NullUuid },
      select: Q.opt,
      enabled: !!projectId && perspective === 'payer',
    }),
    bills: Cb.useListBills({
      params: { project_id: projectId ?? Q.NullUuid },
      enabled: !!projectId && perspective === 'payer',
    }),
    timeEntries: Cb.useListTimeEntrys({
      params: { project_id: projectId ?? Q.NullUuid },
      enabled: !!projectId && perspective === 'payer',
    }),
    expenses: Cb.useListProjectExpenses({
      params: { project_id: projectId ?? Q.NullUuid },
      enabled: !!projectId && perspective === 'payer',
    }),
    subcontracts: Cb.useListContracts({
      params: { payer_id: business.id, project_id: projectId ?? Q.NullUuid },
      enabled: !!projectId && perspective === 'payer',
    }),
  })

  if (props.v2) {
    return (
      <Select2
        value={value}
        update={update}
        focus={focus}
        blur={blur}
        title={t('Accounting code')}
        placeholder={t('Select accounting code...')}
        isLoading={q.status === 'loading'}
        getId={(x) => x.id}
        getTitle={(c) => c.name}
        getValueFromOption={(x) => x}
        getOptionProps={(x) => ({ subtitle: x.description })}
        options={options ?? []}
      />
    )
  } else {
    return (
      <SelectInput
        placeholder={t('Select accounting code...')}
        error={q.status === 'error' ? 'There was an error loading accounting codes' : undefined}
        {...props}
        title={props.title === null ? null : props.title ?? t('Accounting code')}
        didUpdate={(oldValue, newValue) => {
          didUpdate?.(oldValue, newValue)
          if (descriptionInput) {
            const newLabel = newValue?.description || newValue?.name
            if (newLabel && !descriptionInput.value) {
              descriptionInput.update(newLabel)
            }
          }

          if (amountInput) {
            const oldAmount = oldValue?.unit_cost
            const newAmount = newValue?.unit_cost
            if (
              newAmount &&
              (!amountInput.value ||
                amountInput.value === oldAmount ||
                Number(amountInput.value) === 0)
            ) {
              amountInput.update(newAmount)
            }
          }

          if (amountInput2) {
            const oldAmount = oldValue?.unit_cost
            const newAmount = newValue?.unit_cost
            if (
              newAmount &&
              (!amountInput2.value ||
                amountInput2.value.eq(BigNumber(oldAmount || 0)) ||
                amountInput2.value.eq(Zero))
            ) {
              amountInput2.update(BigNumber(newAmount))
            }
          }
        }}
        filter={(search, c) =>
          includesIgnoreCase(c.name, search) || includesIgnoreCase(c.description, search)
        }
        isLoading={q.status === 'loading'}
        getId={(x) => x.id}
        getTitle={(c) => c.name}
        getOptionProps={(c) => {
          const budgetForCode =
            budgetMetadataQ.status === 'success' && budgetMetadataQ.queries.budget
              ? BudgetingUtils.getBudgetForCostCodeOrType({
                  budget: budgetMetadataQ.queries.budget,
                  costCode: c,
                  costType: 'any',
                  clientFacing: false,
                })
              : null
          const costForCode =
            budgetMetadataQ.status === 'success'
              ? BigNumber.sum(
                  Zero,
                  ...(budgetMetadataQ.queries.bills ?? []).map((x) =>
                    BillingUtils.getBillCostToCostCodeOrType(x, { costCode: c, costType: 'any' }),
                  ),
                  ...(budgetMetadataQ.queries.subcontracts ?? []).map((x) =>
                    BillingUtils.getSubcontractCostToCostCodeOrType(x, {
                      costCode: c,
                      costType: 'any',
                    }),
                  ),
                  ...(budgetMetadataQ.queries.timeEntries ?? []).map((x) =>
                    BillingUtils.getTimeEntryCostToCostCodeOrType(x, {
                      costCode: c,
                      costType: 'any',
                    }),
                  ),
                  ...(budgetMetadataQ.queries.expenses ?? []).map((x) =>
                    BillingUtils.getExpenseCostToCostCodeOrType(x, {
                      costCode: c,
                      costType: 'any',
                    }),
                  ),
                )
              : null
          const remainingBudgetForCode =
            !!budgetForCode && !!costForCode ? budgetForCode.minus(costForCode) : null

          return {
            value: c,
            label: c.description,
            metric: remainingBudgetForCode
              ? {
                  k: t('Remaining budget'),
                  v: remainingBudgetForCode.lt(Zero) ? (
                    <span className="text-th-red-warning">
                      {Format.currency(remainingBudgetForCode)}
                    </span>
                  ) : (
                    Format.currency(remainingBudgetForCode)
                  ),
                }
              : undefined,
          }
        }}
        options={options}
      />
    )
  }
}
