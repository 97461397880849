import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import Typography from 'compass/data/Typography'
import Spinner from 'compass-local/Spinner'
import { Arrow, Gear } from 'compass-local/legacy/icons'
import fixtures from 'devtools/fixtures'
import Checkbox from 'compass-local/Checkbox'
import { getAuth } from 'root/utils'
import Button from 'compass/data/Button'
import { theme2 } from 'theme2'
import { NumericInput, TextInput } from 'compass/input/TextInput'

function Trigger({ conf, expanded, setExpanded }: any) {
  const [isLoading, setIsLoading] = useState(false)
  const [input, setInput] = useState({
    ...Object.fromEntries(
      (conf.inputs ?? []).map((x: any) => {
        return [x.id, x.default]
      }),
    ),
    n: 1,
  } as any)
  const qc = useQueryClient()

  return (
    <div className="vflex gap-2">
      <div className="flex gap-3 items-center">
        <Typography>{conf.name}</Typography>
        <div className="w-fit">
          <Button
            icon={
              isLoading ? (
                <Spinner />
              ) : (
                <div className="rotate-90">
                  <Arrow height={14} />
                </div>
              )
            }
            theme={theme2.ButtonThemeTextOrange}
            onClick={async () => {
              setIsLoading(true)
              await Promise.all(
                Array.from(Array(input.n).keys()).map((_, i) => conf.trigger({ ...input, i })),
              )
              setTimeout(() => {
                setIsLoading(false)
                qc.resetQueries([])
              }, 200)
            }}
          />
        </div>
        {!!conf.inputs && (
          <div className="cursor-pointer text-th-brown-2">
            <Gear height={14} onClick={setExpanded} />
          </div>
        )}
      </div>
      {expanded && (
        <div className="flex wrap gap-3 items-center w-fit">
          {!conf.single && (
            <div className="w-min min-w-[45px]">
              <NumericInput
                title="N"
                value={input.n}
                update={(newValue) => setInput({ ...input, n: Number(newValue) })}
              />
            </div>
          )}
          {(conf.inputs ?? []).map((x: any, i: number) => {
            const props = {
              title: x.label,
              value: input[x.id],
              update: (newValue: any) => setInput({ ...input, [x.id]: newValue }),
            }

            switch (x.type) {
              case 'string':
                return <TextInput {...props} key={i} />
              case 'boolean':
                return <Checkbox {...props} key={i} />
              default:
                return null
            }
          })}
        </div>
      )}
    </div>
  )
}

export default function Cinderblock() {
  const isAuthed = !!getAuth()
  const [expanded, setExpanded] = useState<number | null>(null)

  return (
    <div className="vflex gap-2">
      {fixtures
        .filter((x) => isAuthed || x.unauthed)
        .map((f: any, i) => (
          <Trigger
            key={i}
            conf={f}
            expanded={expanded === i}
            setExpanded={() => setExpanded(i === expanded ? null : i)}
          />
        ))}
    </div>
  )
}
