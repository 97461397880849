import { ReactNode, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { unreachable } from './misc'
import { Format, Zero } from './common'
import { MSDate } from './date'
import { F } from './field'

type Section<TDataRow> = {
  id: string
  rows: TDataRow[]
}

type RowMeta<TDataRow, TCtx> = {
  depth: number
  parent: TDataRow | null
  id: string
  indexPath: number[]
  row: TDataRow
  ctx: TCtx
}

type CellProp<TDataRow, TCtx, TColumnType, V> =
  | V
  | ((rowMeta: RowMeta<TDataRow, TCtx>, value: TColumnType) => V)

function resolveCellProp<TDataRow, TCtx, TColumnType, V>(
  prop: CellProp<TDataRow, TCtx, TColumnType, V> | undefined,
  props: [RowMeta<TDataRow, TCtx>, TColumnType],
): V | undefined {
  return typeof prop === 'function' ? (prop as any)(...props) : prop
}

type MobilePosition = 'standard' | 'title' | 'subtitle' | 'widget' | 'none'
type MobileLabel = 'standard' | 'hidden'

type ColumnSpec<TDataRow, TCtx, TColumnType> = {
  accessor: (rowMeta: RowMeta<TDataRow, TCtx>) => TColumnType
  void?: (rowMeta: RowMeta<TDataRow, TCtx>) => boolean
  name: string | ((ctx: TCtx) => string)
  format: (value: TColumnType, rowMeta: RowMeta<TDataRow, TCtx>) => ReactNode
  hidden?: (ctx: TCtx) => boolean
  compare?: (a: TColumnType, b: TColumnType) => number
  mobilePosition?: CellProp<TDataRow, TCtx, TColumnType, MobilePosition>
  mobileLabel?: CellProp<TDataRow, TCtx, TColumnType, MobileLabel>
  disableDepthIndentation?: CellProp<TDataRow, TCtx, TColumnType, boolean>
  // cell-level ui props
  icon?: CellProp<TDataRow, TCtx, TColumnType, ReactNode>
  badge?: CellProp<TDataRow, TCtx, TColumnType, ReactNode>
  tooltip?: CellProp<TDataRow, TCtx, TColumnType, string>
  inset?: CellProp<TDataRow, TCtx, TColumnType, number | null>
  borderStyle?: CellProp<TDataRow, TCtx, TColumnType, 'error' | 'standard' | null>
  href?: CellProp<TDataRow, TCtx, TColumnType, string | null>
  color?: CellProp<TDataRow, TCtx, TColumnType, string | null>
  bold?: CellProp<TDataRow, TCtx, TColumnType, boolean>
  caption?: CellProp<TDataRow, TCtx, TColumnType, string | null>
  nowrap?: CellProp<TDataRow, TCtx, TColumnType, boolean>
  // column-level ui props
  align?: 'right' | 'left' | ((ctx: TCtx) => 'right' | 'left')
  maximizeWidth?: (ctx: TCtx) => boolean
  fixedWidthDONOTUSE?: (ctx: TCtx) => number | null
  minWidthOverrideDONOTUSE?: (ctx: TCtx) => number | null
}

export function colSpec<TDataRow, TCtx = {}>() {
  function baseCol<TColumnType extends ReactNode>(
    name: string | ((ctx: TCtx) => string),
    props: Omit<ColumnSpec<TDataRow, TCtx, TColumnType>, 'name' | 'format'>,
  ): ColumnSpec<TDataRow, TCtx, TColumnType> {
    return { name, format: (x) => x, ...props }
  }

  function custom<TColumnType>(
    name: string | ((ctx: TCtx) => string),
    props: Omit<ColumnSpec<TDataRow, TCtx, TColumnType>, 'name'>,
  ): ColumnSpec<TDataRow, TCtx, TColumnType> {
    return { name, ...props }
  }

  function title(
    name: string | ((ctx: TCtx) => string),
    props: Omit<ColumnSpec<TDataRow, TCtx, string>, 'name' | 'format' | 'accessor'> & {
      title: (rowMeta: RowMeta<TDataRow, TCtx>) => string
    },
  ): ColumnSpec<TDataRow, TCtx, string> {
    return {
      name,
      accessor: props.title,
      bold: true,
      mobilePosition: 'title',
      format: (x) => x,
      compare: (a, b) => (a < b ? -1 : 1),
      ...props,
    }
  }

  function date<T extends MSDate | null>(
    name: string | ((ctx: TCtx) => string),
    props: Omit<ColumnSpec<TDataRow, TCtx, T>, 'name' | 'format'> & {
      format?: (value: T, rowMeta: RowMeta<TDataRow, TCtx>) => ReactNode
    },
  ) {
    return custom(name, {
      align: 'right',
      nowrap: true,
      format: props.format ?? ((x) => x?.format() ?? '--'),
      compare: (a, b) => ((a ?? MSDate.today()).gt(b ?? MSDate.today()) ? -1 : 1),
      ...props,
    })
  }

  function currency<T extends BigNumber | null>(
    name: string | ((ctx: TCtx) => string),
    props: Omit<ColumnSpec<TDataRow, TCtx, T>, 'name' | 'format'> & {
      format?: (value: T, rowMeta: RowMeta<TDataRow, TCtx>) => ReactNode
    },
  ) {
    return custom(name, {
      align: 'right',
      nowrap: true,
      format: props.format ?? ((x) => (x ? Format.currency(x) : '$ --')),
      compare: (a, b) => ((a ?? Zero).gt(b ?? Zero) ? -1 : 1),
      ...props,
    })
  }

  function decimal(
    name: string | ((ctx: TCtx) => string),
    props: Omit<ColumnSpec<TDataRow, TCtx, BigNumber | null>, 'name' | 'format'> & {
      format?: (value: BigNumber | null, rowMeta: RowMeta<TDataRow, TCtx>) => ReactNode
    },
  ) {
    return custom(name, {
      align: 'right',
      nowrap: true,
      format: props.format ?? ((x) => (x ? Format.decimal(x, 'at_most_3') : '--')),
      ...props,
    })
  }

  function percent(
    name: string | ((ctx: TCtx) => string),
    props: Omit<ColumnSpec<TDataRow, TCtx, BigNumber | null>, 'name' | 'format'> & {
      format?: (value: BigNumber | null, rowMeta: RowMeta<TDataRow, TCtx>) => ReactNode
    },
  ) {
    return custom(name, {
      align: 'right',
      nowrap: true,
      compare: (a, b) => ((a ?? Zero).gt(b ?? Zero) ? -1 : 1),
      format: props.format ?? ((x) => (x ? Format.percent(x) : '-- %')),
      ...props,
    })
  }

  function status(
    name: string | ((ctx: TCtx) => string),
    props: Omit<ColumnSpec<TDataRow, TCtx, ReactNode>, 'name' | 'format' | 'mobilePosition'>,
  ): ColumnSpec<TDataRow, TCtx, ReactNode> {
    return { name, mobilePosition: 'widget', format: (x) => x, ...props }
  }

  function input<T extends F.InputCell<any>>(
    name: string | ((ctx: TCtx) => string),
    props: Omit<ColumnSpec<TDataRow, TCtx, T>, 'name'>,
  ): ColumnSpec<TDataRow, TCtx, T> {
    return {
      name,
      borderStyle: (_, value) =>
        value.error && value.status !== 'clean' && value.status !== 'editing'
          ? 'error'
          : 'standard',
      ...props,
    }
  }

  return Object.assign(baseCol, { title, custom, date, currency, decimal, percent, status, input })
}

type EnrichedColumn<TColumnType> = {
  name: string
  align: 'right' | 'left'
  maximizeWidth: boolean
  fixedWidthDONOTUSE: number | null
  minWidthOverrideDONOTUSE: number | null
  compare?: (a: TColumnType, b: TColumnType) => number
}

type EnrichedCell<TColumnType> = {
  column: EnrichedColumn<TColumnType>
  mobilePosition: MobilePosition
  mobileLabel: MobileLabel
  value: TColumnType
  formattedValue: ReactNode
  icon: ReactNode
  badge: ReactNode
  nowrap: boolean
  inset: number | null
  borderStyle: 'error' | 'standard' | null
  href: string | null
  color: string | null
  bold: boolean
  caption: string | null
  void: boolean
  colspan: number
}

export type EnrichedRow<TDataRow, TCtx> = {
  type: 'row'
  id: string
  rowMeta: RowMeta<TDataRow, TCtx>
  href: string | null
  tooltip: string | null
  dragId: string | null
  dropIds: string[]
  header: ReactNode
  footer: ReactNode
  endWidget: ReactNode
  startWidget: ReactNode
  cells: EnrichedCell<unknown>[]
  // eslint-disable-next-line
  subdata: (EnrichedRow<TDataRow, TCtx> | EnrichedSection<TDataRow, TCtx>)[]
}

type EnrichedSection<TDataRow, TCtx> = {
  type: 'section'
  id: string
  header: ReactNode
  footer: ReactNode
  rows: EnrichedRow<TDataRow, TCtx>[]
}

type BuildProps<TDataRow, TCtx> = {
  context?: TCtx
  rows?: TDataRow[]
  sections?: Section<TDataRow>[]
  getSubrows?: (rowMeta: RowMeta<TDataRow, TCtx>) => TDataRow[]
  getSubsections?: (rowMeta: RowMeta<TDataRow, TCtx>) => Section<TDataRow>[]
  getRowEndWidget?: (rowMeta: RowMeta<TDataRow, TCtx>) => ReactNode
  getRowStartWidget?: (rowMeta: RowMeta<TDataRow, TCtx>) => ReactNode
  id?: (rowData: TDataRow, indexPath: number[]) => string
  footer?: ReactNode
  footerForRow?: (rowMeta: RowMeta<TDataRow, TCtx>) => ReactNode
  headerForRow?: (rowMeta: RowMeta<TDataRow, TCtx>) => ReactNode
  footerForSection?: (section: Section<TDataRow>, ctx: TCtx) => ReactNode
  headerForSection?: (section: Section<TDataRow>, ctx: TCtx) => ReactNode
  href?: (rowMeta: RowMeta<TDataRow, TCtx>) => string | null
  tooltip?: (rowMeta: RowMeta<TDataRow, TCtx>) => string | null
  dragId?: (rowMeta: RowMeta<TDataRow, TCtx>) => string | null
  dropIds?: (rowMeta: RowMeta<TDataRow, TCtx>) => string[]
  columns: ColumnSpec<TDataRow, TCtx, any>[]
}

export type TableDataBuild<TDataRow, TCtx> = {
  context: TCtx
  data: (EnrichedSection<TDataRow, TCtx> | EnrichedRow<TDataRow, TCtx>)[]
  sort: (
    compare: (rmA: EnrichedRow<TDataRow, TCtx>, rmB: EnrichedRow<TDataRow, TCtx>) => number,
  ) => (EnrichedSection<TDataRow, TCtx> | EnrichedRow<TDataRow, TCtx>)[]
  map: <U>(fn: (rowMeta: RowMeta<TDataRow, TCtx>) => U) => Record<string, U>
  footer: ReactNode
  columns: EnrichedColumn<unknown>[]
}

export function build<TDataRow, TCtx = {}>({
  context = {} as TCtx,
  rows = [],
  sections = [],
  getSubrows = () => [],
  getSubsections = () => [],
  getRowEndWidget = () => null,
  getRowStartWidget = () => null,
  id: getId = (_, ip) => ip.join('-'),
  footer = null,
  footerForRow = () => null,
  headerForRow = () => null,
  footerForSection = () => null,
  headerForSection = () => null,
  href = () => null,
  tooltip = () => null,
  dragId = () => null,
  dropIds = () => [],
  columns,
}: BuildProps<TDataRow, TCtx>): TableDataBuild<TDataRow, TCtx> {
  const visibleColumns = columns.filter((x) => !x.hidden?.(context))
  const enrichedColumns = visibleColumns.map((col) => ({
    column: col,
    enrichedColumn: {
      name: typeof col.name === 'string' ? col.name : col.name(context),
      align: typeof col.align === 'string' ? col.align : col.align?.(context) ?? 'left',
      maximizeWidth: col.maximizeWidth?.(context) ?? false,
      minWidthOverrideDONOTUSE: col.minWidthOverrideDONOTUSE?.(context) ?? null,
      fixedWidthDONOTUSE: col.fixedWidthDONOTUSE?.(context) ?? null,
      compare: col.compare,
    },
  }))
  function enrich(
    innerRows: TDataRow[],
    {
      depth,
      indexPath,
      parent,
    }: {
      depth: number
      indexPath: number[]
      parent: Omit<EnrichedRow<TDataRow, TCtx>, 'subdata'> | null
    },
  ): EnrichedRow<TDataRow, TCtx>[] {
    return innerRows.map((r, i) => {
      const ip = [...indexPath, i]
      const id = getId(r, ip)
      const rowMeta: RowMeta<TDataRow, TCtx> = {
        row: r,
        id,
        indexPath: ip,
        ctx: context,
        depth,
        parent: parent ? parent.rowMeta.row : null,
      }

      const enrichedData: Omit<EnrichedRow<TDataRow, TCtx>, 'subdata'> = {
        id,
        type: 'row',
        rowMeta,
        href: href(rowMeta),
        tooltip: tooltip(rowMeta),
        header: headerForRow(rowMeta),
        footer: footerForRow(rowMeta),
        dragId: dragId(rowMeta),
        dropIds: dropIds(rowMeta),
        endWidget: getRowEndWidget(rowMeta),
        startWidget: getRowStartWidget(rowMeta),
        cells: enrichedColumns.map(({ column: col, enrichedColumn }, j) => {
          const remainingColumns = enrichedColumns.slice(j + 1)
          let colspan = 1
          for (let k = 0; k < remainingColumns.length; k += 1) {
            if (remainingColumns[k].column.void?.(rowMeta)) {
              colspan += 1
            } else {
              break
            }
          }

          const isVoid = col.void?.(rowMeta) ?? false
          const value = isVoid ? null : col.accessor(rowMeta)

          return {
            value,
            column: enrichedColumn,
            formattedValue: isVoid
              ? null
              : col.format
              ? col.format(value, rowMeta)
              : (value as any),
            name: typeof col.name === 'string' ? col.name : col.name(context),
            mobilePosition: resolveCellProp(col.mobilePosition, [rowMeta, value]) ?? 'standard',
            mobileLabel: resolveCellProp(col.mobileLabel, [rowMeta, value]) ?? 'standard',
            icon: isVoid ? null : resolveCellProp(col.icon, [rowMeta, value]) ?? null,
            badge: isVoid ? null : resolveCellProp(col.badge, [rowMeta, value]) ?? null,
            nowrap: isVoid ? false : resolveCellProp(col.nowrap, [rowMeta, value]) ?? false,
            inset: isVoid ? null : resolveCellProp(col.inset, [rowMeta, value]) ?? null,
            borderStyle: isVoid ? null : resolveCellProp(col.borderStyle, [rowMeta, value]) ?? null,
            href: isVoid ? null : resolveCellProp(col.href, [rowMeta, value]) ?? null,
            color: isVoid ? null : resolveCellProp(col.color, [rowMeta, value]) ?? null,
            bold: isVoid ? false : resolveCellProp(col.bold, [rowMeta, value]) ?? false,
            caption: isVoid ? null : resolveCellProp(col.caption, [rowMeta, value]) ?? null,
            void: isVoid,
            colspan,
          }
        }),
      }

      const nestedProps = { depth: depth + 1, indexPath: ip, parent: enrichedData }

      return {
        ...enrichedData,
        subdata: [
          ...enrich(getSubrows(rowMeta), nestedProps),
          ...getSubsections(rowMeta).map(
            (section): EnrichedSection<TDataRow, TCtx> => ({
              id: section.id,
              type: 'section',
              rows: enrich(section.rows, nestedProps),
              header: headerForSection(section, context),
              footer: footerForSection(section, context),
            }),
          ),
        ],
      }
    })
  }

  const enrichedRows = enrich(rows, { depth: 0, indexPath: [], parent: null })

  const enrichedSections = sections.map(
    (section): EnrichedSection<TDataRow, TCtx> => ({
      id: section.id,
      type: 'section',
      rows: enrich(section.rows, { depth: 0, indexPath: [], parent: null }),
      header: headerForSection(section, context),
      footer: footerForSection(section, context),
    }),
  )

  const data = [...enrichedRows, ...enrichedSections]
  return {
    data,
    columns: enrichedColumns.map(({ enrichedColumn }) => enrichedColumn),
    context,
    footer,
    sort: (compare) => {
      function sortLayer(
        d: (EnrichedRow<TDataRow, TCtx> | EnrichedSection<TDataRow, TCtx>)[],
      ): (EnrichedRow<TDataRow, TCtx> | EnrichedSection<TDataRow, TCtx>)[] {
        const subsorted: (EnrichedRow<TDataRow, TCtx> | EnrichedSection<TDataRow, TCtx>)[] = d.map(
          (r) => {
            if (r.type === 'row') {
              return { ...r, subdata: sortLayer(r.subdata) } as EnrichedRow<TDataRow, TCtx>
            } else if (r.type === 'section') {
              return { ...r, rows: sortLayer(r.rows) } as EnrichedSection<TDataRow, TCtx>
            } else {
              return unreachable(r)
            }
          },
        )

        return subsorted.sort((a, b) => {
          if (a.type === 'section' && b.type === 'section') {
            return a.id > b.id ? 1 : -1
          } else if (a.type === 'section') {
            return 1
          } else if (b.type === 'section') {
            return -1
          } else {
            return compare(a, b)
          }
        })
      }

      return sortLayer(data)
    },
    map: <U>(fn: (rm: RowMeta<TDataRow, TCtx>) => U) => {
      const results: Record<string, U> = {}
      function addRowsToResults(
        rowsOrSections: (EnrichedRow<TDataRow, TCtx> | EnrichedSection<TDataRow, TCtx>)[],
      ) {
        rowsOrSections.forEach((r) => {
          if (r.type === 'row') {
            results[r.id] = fn(r.rowMeta)
            addRowsToResults(r.subdata)
          } else if (r.type === 'section') {
            addRowsToResults(r.rows)
          } else {
            unreachable(r)
          }
        })
      }

      addRowsToResults(enrichedRows)
      enrichedSections.forEach((s) => {
        addRowsToResults(s.rows)
      })

      return results
    },
  }
}

export type RowState = {
  isActive: (id: string) => boolean
  setIsActive: (ids: string | string[], newValue: boolean) => void
  reset: () => void
}

export function useRowState(initialValue?: Record<string, boolean>): RowState {
  const [state, setState] = useState(initialValue ?? {})

  return useMemo(
    () => ({
      isActive: (id) => state[id] ?? false,
      setIsActive: (id, newValue) => {
        const ids = Array.isArray(id) ? id : [id]
        setState((oldValue) => ({
          ...oldValue,
          ...Object.fromEntries(ids.map((x) => [x, newValue])),
        }))
      },
      reset: () => setState({}),
    }),
    [state],
  )
}
