import AccountingCodeSelect from 'components/inputs/AccountingCodeSelect'
import ClientSelect from 'components/inputs/ClientSelect'
import InvoiceNumberInput from 'components/inputs/InvoiceNumberInput'
import RichAddressInput from 'components/inputs/RichAddressInput'
import { t } from 'content'
import { F, MSDate } from 'msutils'
import { useBusinessContext } from 'root/user'
import useScreenSize from 'compass/theme/useScreenSize'
import { MSForm } from 'utils/form'
import { Mutator } from 'utils/form-input'
import { Action } from 'utils/actions'
import { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import DateInput from 'compass/input/DateInput'
import { TextInput } from 'compass/input/TextInput'
import { EstimateInputUtils as Utils } from '../utils'
import Preview from './Preview'

type Props = {
  state: F.Input<typeof Utils.schema>
  calculationContext: EstimateItemsInputUtils.EstimateCalculationContext
  copy: Utils.CopySpec
  autosave: Mutator<any>
  globalError: string | null
  pageNumber: `${number} / ${number}`
}

export function DetailsPage({
  state,
  calculationContext,
  copy,
  autosave,
  globalError,
  pageNumber,
}: Props) {
  const { push } = Utils.pageManager.useContext()
  const { trigger, error } = F.useSubgroupValidation(Utils.schema, state, [
    'client',
    'number',
    'expiration',
    'address',
    'displayName',
    'title',
  ])
  const sz = useScreenSize()
  const { hasAccountingCodes } = useBusinessContext()

  return (
    <MSForm.Body
      title={copy.title}
      warnOnClose={state._controller.hasChanged}
      pageName={t('Basic information')}
      pageNumber={pageNumber}
      autosave={{ isSaved: !state._controller.hasChanged, isLoading: autosave.isLoading }}
      error={error || globalError}
      actions={[Action.next(t('Line items'), { onClick: () => trigger(() => push('amounts')) })]}
    >
      <MSForm.Panels
        rightContent={
          sz !== 'sm' && (
            <Preview
              copy={copy}
              state={state}
              calculationContext={calculationContext}
              showFormatOptions={false}
            />
          )
        }
      >
        <ClientSelect title={t('Prospective client')} allowCreate {...F.props(state.client)} />
        <RichAddressInput state={state.address} title={t('Project address')} disableExpandOnBlur />
        <TextInput title={t('Project name')} {...F.props(state.displayName)} />
        <TextInput
          title={t('Title')}
          subtitle={t('This description will be added to the schedule of values upon approval.')}
          placeholder={t('Enter a title')}
          {...F.props(state.title)}
        />
        <DateInput
          title={t('Expires on')}
          min={MSDate.today()}
          optional
          {...F.props(state.expiration)}
        />
        {hasAccountingCodes && (
          <AccountingCodeSelect
            {...F.props(state.changeOrderAccountingCode)}
            title={t('Income accounting code')}
            subtitle={t(
              'This accounting code will be used for any payments against the change order upon approval',
            )}
            perspective="payee"
          />
        )}
        <InvoiceNumberInput {...F.props(state.number)} />
      </MSForm.Panels>
    </MSForm.Body>
  )
}
