import { Fragment, ReactNode } from 'react'
import { BaseLayout } from 'compass/baseLayout'
import Typography from 'compass/data/Typography'
import Divider from 'compass/data/Divider'

type KanbanColumn = {
  id: string
  title: string
  content: ReactNode
  subtitle?: string
  footer?: ReactNode
}

type Props = {
  columns: KanbanColumn[]
}

export function KanbanBoard({ columns }: Props) {
  return (
    <BaseLayout.View inset={[0, 5]} fillHeight>
      <BaseLayout.Scroller scrollX ignoreNaturalSize>
        <BaseLayout.HStack gap={3} view={{ fillHeight: true }}>
          {columns.map((col, i) => (
            <Fragment key={col.id}>
              {i > 0 && <Divider layout="v" />}
              <BaseLayout.VStack view={{ fillHeight: true, fixedWidth: 312 }}>
                <BaseLayout.VStack gap={1} view={{ inset: [4, 0] }}>
                  <Typography variant="subtitle">{col.title}</Typography>
                  {col.subtitle && (
                    <Typography variant="label" className="text-th-text-secondary">
                      {col.subtitle}
                    </Typography>
                  )}
                </BaseLayout.VStack>
                <BaseLayout.Scroller scrollY>{col.content}</BaseLayout.Scroller>
              </BaseLayout.VStack>
            </Fragment>
          ))}
        </BaseLayout.HStack>
      </BaseLayout.Scroller>
    </BaseLayout.View>
  )
}
