/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ProjectImportRequestState =
  (typeof ProjectImportRequestState)[keyof typeof ProjectImportRequestState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectImportRequestState = {
  pending: 'pending',
  complete: 'complete',
  failed: 'failed',
} as const
