import BigNumber from 'bignumber.js'
import { Cb } from 'cb'
import { t } from 'content'
import { unreachable } from 'msutils/misc'

export namespace RenofiUtils {
  export type Perspective = 'offerer' | 'offeree'
  type GetOfferUrlProps = {
    type: Cb.RenofiAdOfferType
    offerAmount: BigNumber
    perspective: Perspective
  }
  export function getOfferUrl({ type, offerAmount, perspective }: GetOfferUrlProps) {
    const offerAmountTruncated = offerAmount.toFixed(0)
    const utmSource = perspective === 'offerer' ? 'beam-gc' : 'beam-ho'
    const utmContent = type === 'heloc' ? 'renofi-heloc' : 'unsecured'
    return `https://www.renofi.com?utm_medium=partner-site&utm_source=${utmSource}&utm_campaign=estimate-insert&utm_content=${utmContent}&loanAmount=${offerAmountTruncated}`
  }

  export function getOfferDisclaimer(type: Cb.RenofiAdOfferType) {
    switch (type) {
      case 'personal_loan':
        return t(
          'Personal Loan Disclaimer\n\nNOT PERSONALIZED LOAN OFFER. Sample payment based on lowest interest rate offered by lender of 11.24% (as of 5/9/24) for fixed rate, 20-year personal renovation loan, available to applicants with excellent credit (credit score of at least 740 and debt-to-income ratio of 50% or less). RenoFi charges $599-$999 processing fee where allowed by state law. Rate and loan program terms subject to change without notice.\n\nRenoFi is a broker; loans underwritten and funded by third party lenders. Whether you qualify for a loan and actual loan details, including loan type, loan amount, interest rate, APR, term, monthly payments, and closing costs are determined by the lender based on their credit and eligibility criteria.  Not available in all states and not all borrowers qualify.  Loan terms and conditions apply, including acceptable membership requirements stipulated by third-party credit union lenders. NMLS # 1802847. Loans made or arranged pursuant to California Finance Lender License # 60DBO-148207 and Maine Loan Broker License # 1802847. Vermont:  THIS IS A LOAN SOLICITATION ONLY. RENOFI IS NOT A LENDER. INFORMATION RECEIVED WILL BE SHARED WITH ONE OR MORE THIRD PARTIES IN CONNECTION WITH YOUR LOAN INQUIRY.  LENDER IS SUBJECT TO FEDERAL LENDING LAWS BUT MAY NOT BE SUBJECT TO ALL VERMONT LENDING LAWS. RenoFi and contractor are not affiliated.',
        )
      case 'heloc':
        return t(
          'RenoFi HELOC Disclaimer\n\nNOT PERSONALIZED LOAN OFFER.  Sample payment based on lowest interest rate offered by lender of 8.5% (as of 5/9/24) for a variable-rate HELOC with a 10-year draw period (interest-only monthly payments required) and 15-year repayment period (principal + interest monthly payments required), available to applicants with a credit score of at least 740, and assumes debt-to-income and combined loan-to-value ratios within lender guidelines and 100% return of investment on renovation financing. Variable rate determined by prime rate plus lender’s margin. Rate and loan program terms subject to change without notice. Closing costs and fees apply.\n\nRenoFi is a broker; loans underwritten and funded by third party lenders. Whether you qualify for a loan and actual loan details, including loan type, loan amount, interest rate, APR, term, monthly payments, and closing costs are determined by the lender based on their credit and eligibility criteria.  Not available in all states. Not all borrowers or property types qualify.  Loan terms and conditions apply, including acceptable membership requirements stipulated by third-party credit union lenders. NMLS # 1802847 / # 2412747 (visit {{ X }}); in CA: Renovation Technologies Holdings Inc., CA Department of Real Estate Broker License # 02195141. RenoFi and contractor are not affiliated.',
          { X: 'www.nmlsconsumeraccess.org' },
        )
      default:
        return unreachable(type)
    }
  }
}
