import { Cb } from 'cb'
import { Action } from 'utils/actions'
import { t } from 'content'
import { F } from 'msutils'
import { Mutator } from 'utils/form-input'
import { MSForm } from 'utils/form'
import { EmailInput, PhoneInput, TextInput } from 'compass/input/TextInput'
import { AdditionalContactInputUtils as Utils } from '../utils'

type Props = {
  state: F.Input<typeof Utils.schema>
  submit: Mutator<Cb.AdditionalContact>
  copy: Utils.CopySpec
}

export default function AdditionalContactInput(props: Props) {
  const { state, submit, copy } = props

  return (
    <MSForm.Body
      title={copy.title}
      size="small"
      error={submit.error?.message}
      warnOnClose={state._controller.hasChanged}
      actions={[Action.mutation(copy.submit, { mutate: submit.mutateAsync })]}
    >
      <TextInput title={t('Name')} placeholder={t('Joe Smith')} {...F.props(state.name)} />
      <TextInput
        title={t('Job title')}
        placeholder={t('Enter contact job title')}
        optional
        {...F.props(state.jobTitle)}
      />
      <EmailInput {...F.props(state.email)} optional />
      <PhoneInput {...F.props(state.phone)} optional />
    </MSForm.Body>
  )
}
