import BigNumber from 'bignumber.js'
import { t } from 'content'
import { MSString } from 'msutils'
import BaseTextInput, { Props } from './BaseTextInput'

export function TextInput(props: Omit<Props<string>, 'typeProps'>) {
  return <BaseTextInput {...props} />
}

export function EmailInput(props: Omit<Props<string>, 'typeProps'>) {
  return (
    <BaseTextInput
      placeholder={t('joe@business-name.com')}
      title={t('Email')}
      {...props}
      typeProps={{ inputMode: 'email' }}
    />
  )
}

function RegexInput(props: Props<string> & { re: RegExp }) {
  return (
    <BaseTextInput
      {...props}
      typeProps={{
        ...props.typeProps,
        serializationConfig: {
          toDisplay: (x) => x,
          fromDisplay: (x) => x,
          isAcceptableAsIntermediateValue: (val) => !val || props.re.test(val),
          isAcceptableAsExternalValue: () => true,
          eq: (a, b) => a === b,
        },
      }}
    />
  )
}

export function NumericInput(props: Omit<Props<string>, 'typeProps'>) {
  return <RegexInput {...props} re={/^[0-9]*$/} typeProps={{ inputMode: 'numeric' }} />
}

export function AccountNumberInput(props: Omit<Props<string>, 'typeProps'>) {
  return (
    <NumericInput
      placeholder={t('5-17 digit number')}
      title={t('Account number')}
      maxLength={17}
      {...props}
    />
  )
}

export function RoutingNumberInput(props: Omit<Props<string>, 'typeProps'>) {
  return (
    <NumericInput
      placeholder={t('9 digit number')}
      title={t('Routing number')}
      maxLength={9}
      {...props}
    />
  )
}

export function ZipCodeInput(props: Omit<Props<string>, 'typeProps'>) {
  return (
    <NumericInput
      placeholder={t('Enter ZIP code')}
      title={t('ZIP code')}
      maxLength={5}
      {...props}
    />
  )
}

export function PhoneInput(props: Omit<Props<string>, 'typeProps'>) {
  return (
    <BaseTextInput
      placeholder={t('(234) 123-4567')}
      title={t('Phone')}
      maxLength={14}
      {...props}
      typeProps={{
        inputMode: 'tel',
        serializationConfig: {
          mapInputValue: (val) =>
            MSString.insertCharacters(MSString.stripNonNumeric(val), [
              ['(', 0],
              [')', 3],
              [' ', 3],
              ['-', 6],
            ]),
          toDisplay: (val) =>
            MSString.insertCharacters(val, [
              ['(', 0],
              [')', 3],
              [' ', 3],
              ['-', 6],
            ]),
          fromDisplay: (val) => MSString.stripNonNumeric(val),
          isAcceptableAsIntermediateValue: (val) => !/[^0-9-() ]/.test(val),
          isAcceptableAsExternalValue: () => true,
          eq: (a, b) => a === b,
        },
      }}
    />
  )
}

export function PasswordInput(props: Omit<Props<string>, 'typeProps'>) {
  return (
    <BaseTextInput
      title={t('Password')}
      {...props}
      typeProps={{ inputType: 'password', autoselectOnFocus: true }}
    />
  )
}

export function EinInput(props: Omit<Props<string>, 'typeProps'>) {
  return (
    <BaseTextInput
      placeholder={t('12-3456789')}
      title={t('EIN')}
      maxLength={10}
      {...props}
      typeProps={{
        inputMode: 'numeric',
        serializationConfig: {
          toDisplay: (val) => MSString.insertCharacters(val, [['-', 2]]),
          fromDisplay: (val) => MSString.stripNonNumeric(val),
          isAcceptableAsIntermediateValue: (val) => !/[^0-9-]/.test(val),
          isAcceptableAsExternalValue: () => true,
          eq: (a, b) => a === b,
        },
      }}
    />
  )
}

export function CurrencyInput(
  props: Omit<
    Props<BigNumber | ''> & {
      maxDecimalPlaces?: number
    },
    'typeProps'
  >,
) {
  const { maxDecimalPlaces = 2 } = props
  const maximum = BigNumber(100000000000)

  return (
    <BaseTextInput
      placeholder={t('$0')}
      maxLength={20}
      {...props}
      typeProps={{
        inputMode: 'decimal',
        autoselectOnFocus: true,
        serializationConfig: {
          // mapInputValue: (val) => (val ? `$${val.replaceAll('$', '')}` : val),
          mapInputValue: (val) => {
            if (!val) return val
            const stripped = val.replaceAll('$', '').replaceAll('-', '')
            if (val.includes('-')) {
              return `-$${stripped}`
            } else {
              return `$${stripped}`
            }
          },
          toDisplay: (val) => {
            if (!val) return ''
            const str = val.abs().toFixed(Math.min(val.decimalPlaces() ?? 2, maxDecimalPlaces))
            // eslint-disable-next-line
            if (val.isNegative()) {
              return `-$${str}`
            } else {
              return `$${str}`
            }
          },
          fromDisplay: (val) => {
            const stripped = val.replaceAll('$', '')
            if (!stripped) return ''
            const num = BigNumber(stripped)
            if (num.isNaN()) {
              // eslint-disable-next-line
              console.error(`Received NaN input from ${val}`)
              return ''
            }
            const decimalPlaces = num.decimalPlaces()
            if (decimalPlaces && decimalPlaces > maxDecimalPlaces) {
              // eslint-disable-next-line
              console.error(`Received too many decimal places from ${val}`)
              return ''
            }
            return num
          },
          isAcceptableAsIntermediateValue: (val) => {
            const stripped = val.replaceAll('$', '')
            if (stripped === '-' || stripped === '' || stripped === '.' || stripped === '-.')
              return true
            if (stripped.trim() !== stripped) return false

            const num = BigNumber(stripped)
            if (num.gt(maximum)) {
              return false
            }
            const decimalPlaces = num.decimalPlaces()
            if (decimalPlaces && decimalPlaces > maxDecimalPlaces) {
              return false
            }
            return !num.isNaN()
          },
          isAcceptableAsExternalValue: (val) => {
            const stripped = val.replaceAll('$', '')
            return stripped === '' || !BigNumber(stripped).isNaN()
          },
          mapPastedData: (val) => {
            const stripped = val.replaceAll('$', '')
            const num = BigNumber(stripped)
            if (num.isNaN()) return null
            return num.toFixed(maxDecimalPlaces)
          },
          eq: (a, b) => (a === '' || b === '' ? a === b : a.eq(b)),
        },
      }}
    />
  )
}

export function PercentInput(
  props: Omit<
    Props<BigNumber | ''> & {
      maxDecimalPlaces?: number
    },
    'typeProps'
  >,
) {
  const { maxDecimalPlaces = 4 } = props
  const maximum = BigNumber(100000000000)

  return (
    <BaseTextInput
      placeholder={t('0%')}
      maxLength={20}
      {...props}
      typeProps={{
        inputMode: 'decimal',
        autoselectOnFocus: true,
        getSelectionRangeBounds: (val) => [0, val.length],
        serializationConfig: {
          mapInputValue: (val) => (val ? `${val.replaceAll('%', '')}%` : val),
          toDisplay: (val) => {
            if (!val) return ''
            const percentageVal = val.multipliedBy(100)
            const str = percentageVal.toFixed(
              Math.min(percentageVal.decimalPlaces() ?? 0, maxDecimalPlaces - 2),
            )
            return `${str}%`
          },
          fromDisplay: (val) => {
            const stripped = val.replaceAll('%', '')
            if (!stripped) return ''
            const num = BigNumber(stripped).dividedBy(100)
            if (num.isNaN()) {
              // eslint-disable-next-line
              console.error(`Received NaN input from ${val}`)
              return ''
            }
            const decimalPlaces = num.decimalPlaces()
            if (decimalPlaces && decimalPlaces > maxDecimalPlaces) {
              // eslint-disable-next-line
              console.error(`Received too many decimal places from ${val}`)
              return ''
            }
            return num
          },
          isAcceptableAsIntermediateValue: (val) => {
            const stripped = val.replaceAll('%', '')
            if (stripped === '-' || stripped === '' || stripped === '.') return true
            if (stripped.trim() !== stripped) return false

            const num = BigNumber(stripped).dividedBy(100)
            if (num.gt(maximum)) return false
            const decimalPlaces = num.decimalPlaces()
            if (decimalPlaces && decimalPlaces > maxDecimalPlaces) return false
            return !num.isNaN()
          },
          isAcceptableAsExternalValue: (val) => {
            const stripped = val.replaceAll('%', '')
            return stripped === '' || !BigNumber(stripped).isNaN()
          },
          mapPastedData: (val) => {
            const stripped = val.replaceAll('%', '')
            const num = BigNumber(stripped)
            if (num.isNaN()) return null
            return num.toFixed(maxDecimalPlaces)
          },
          eq: (a, b) => (a === '' || b === '' ? a === b : a.eq(b)),
        },
      }}
    />
  )
}

export function DecimalInput(
  props: Omit<
    Props<BigNumber | ''> & {
      maxDecimalPlaces?: number
    },
    'typeProps'
  >,
) {
  const { maxDecimalPlaces = 2 } = props
  const maximum = BigNumber(100000000000)

  return (
    <BaseTextInput
      placeholder="1"
      maxLength={20}
      {...props}
      typeProps={{
        inputMode: 'decimal',
        autoselectOnFocus: true,
        serializationConfig: {
          toDisplay: (val) => {
            if (!val) return ''
            return val.toFixed(Math.min(val.decimalPlaces() ?? 0, maxDecimalPlaces))
          },
          fromDisplay: (val) => {
            if (!val) return ''
            const num = BigNumber(val)
            if (num.isNaN()) {
              // eslint-disable-next-line
              console.error(`Received NaN input from ${val}`)
              return ''
            }
            const decimalPlaces = num.decimalPlaces()
            if (decimalPlaces && decimalPlaces > maxDecimalPlaces) {
              // eslint-disable-next-line
              console.error(`Received too many decimal places from ${val}`)
              return ''
            }
            return num
          },
          isAcceptableAsIntermediateValue: (val) => {
            if (val === '-' || val === '' || val === '.') return true
            if (val.trim() !== val) return false

            const num = BigNumber(val)
            if (num.gt(maximum)) return false
            const decimalPlaces = num.decimalPlaces()
            if (decimalPlaces && decimalPlaces > maxDecimalPlaces) return false
            return !num.isNaN()
          },
          isAcceptableAsExternalValue: (val) => {
            return val === '' || !BigNumber(val).isNaN()
          },
          mapPastedData: (val) => {
            const num = BigNumber(val)
            if (num.isNaN()) return null
            return num.toFixed(maxDecimalPlaces)
          },
          eq: (a, b) => (a === '' || b === '' ? a === b : a.eq(b)),
        },
      }}
    />
  )
}
