import { Cb } from 'cb'
import { F, Format } from 'msutils'
import useScreenSize from 'compass/theme/useScreenSize'
import { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import Select2 from 'compass-local/input/Select2'
import StandardBase from 'compass-local/input/StandardBase'
import { getBorderForInput } from 'compass-local/input/utils'
import { t } from 'content'
import { confirm } from 'utils/confirm'
import { FileUtils } from 'components/misc/FileUtils'
import { switchInline } from 'utils/misc'
import { EstimateInputUtils as Utils } from '../utils'

function doPrefill({
  estimate,
  state,
  costCodes,
  costTypes,
}: {
  estimate: Cb.Estimate
  state: F.Input<typeof Utils.schema>
  costCodes: Cb.CostCode[]
  costTypes: Cb.CostType[]
}) {
  state.lineItems._controller.update({
    type: 'group',
    children: EstimateItemsInputUtils.fromApi(estimate.line_item_nodes, { costCodes, costTypes }),
  })
  if (estimate.additional_information) {
    state.additionalInformation.update(estimate.additional_information)
  }
  state.showMarkup.update(estimate.show_markup)
  state.showSubSectionPricing.update(estimate.show_sub_section_pricing)
  state.showUnitCosts.update(estimate.show_unit_costs)
  state.hideEstimateTotal.update(estimate.hide_total)
  if (estimate.cover_page) {
    state.coverPageType.update('beam')
    state.coverPage.title.update(estimate.cover_page.title)
    state.coverPage.body.update(estimate.cover_page.body)
    if (estimate.cover_page.cover_photo) {
      state.coverPage.photo.update(FileUtils.fromApi([estimate.cover_page.cover_photo]))
    }
  } else if (estimate.uploaded_cover_page) {
    state.coverPageType.update('uploaded')
    state.uploadedCoverPage.file.update(FileUtils.fromApi([estimate.uploaded_cover_page]))
  } else {
    state.coverPageType.update('none')
  }
}

type Props = {
  variant: 'project' | 'change-order'
  state: F.Input<typeof Utils.schema>
  estimateRef: Cb.Estimate | null
}

export function PrefillFromEstimate({ variant, state, estimateRef }: Props) {
  const sz = useScreenSize()
  const estimateInput = F.useInputState(F.Choice<Cb.Estimate>())
  const estimates =
    Cb.useListEstimates({
      params: {
        type: switchInline(variant, {
          project: 'project',
          'change-order': 'change_order',
        }),
      },
    }).data ?? []
  const costCodes = Cb.useListCostCodes().data ?? []
  const costTypes = Cb.useListCostTypes().data ?? []

  return (
    <>
      <StandardBase
        border={getBorderForInput(estimateInput)}
        maxWidth={sz !== 'sm' ? 'max-w-[320px]' : undefined}
      >
        <Select2
          {...F.props(estimateInput)}
          update={async (newValue) => {
            estimateInput.update(newValue)
            if (newValue) {
              if (
                await confirm(
                  t('Are you sure you want to copy from this estimate?'),
                  t('Any items on the current estimate will be replaced and cannot be recovered.'),
                )
              ) {
                doPrefill({ estimate: newValue, state, costCodes, costTypes })
              }
            }
          }}
          options={estimates.filter((x) => x.id !== estimateRef?.id)}
          getId={(x) => x.id}
          getTitle={(x) => x.display_name}
          getValueFromOption={(x) => x}
          getOptionProps={(x) => ({ subtitle: Format.currency(x.total_amount) })}
          placeholder={t('Copy from estimate...')}
        />
      </StandardBase>
    </>
  )
}
