import type { AppProps } from 'next/app'
import 'styles/globals.css'
import { t } from 'content'
import { STORAGE_SOURCE_ORIGIN, STORAGE_TARGET_ORIGIN, REDIRECT_TO_TARGET } from 'env'
import { RedirectToTarget } from 'utils/shared-storage/Source'
import { MSError2, MSTimestamp, useOnMount } from 'msutils'
import { SentryClient } from 'lib/sentry'
import 'navigator'
import 'core-js/actual/array/at'
import Root2 from 'root'
import { getAuth } from 'root/utils'
import ErrorState from 'components/misc/ErrorState'

const AssumeStaleAfterHours = 48

function Fallback({ error }: { error?: MSError2 }) {
  return <ErrorState error={error?.message ?? t('An unexpected error has occurred')} />
}

export default function App(props: AppProps) {
  const isImpersonator = getAuth()?.type === 'impersonator'

  useOnMount(() => {
    MSError2.report = (errorOrString) => {
      let error: Error
      if (typeof errorOrString === 'string') {
        error = new Error(errorOrString)
      } else {
        error = errorOrString
      }
      const originalMessage = error.message
      if (error instanceof MSError2) error.message = error.reportMessage
      SentryClient.report2(error, {
        tags: {
          isImpersonator,
          componentStack: error instanceof MSError2 ? error.componentStack : 'None',
        },
      })
      error.message = originalMessage
    }

    MSError2.Fallback = Fallback
  })
  MSError2.useGlobalErrorHandling()

  useOnMount(() => {
    const mountedAtTime = MSTimestamp.now()
    const intervalId = setInterval(() => {
      const currentTime = MSTimestamp.now()
      const isStale = mountedAtTime.add(AssumeStaleAfterHours, 'hour').lt(currentTime)

      if (isStale) {
        // eslint-disable-next-line
        console.warn('Forcing an app refresh to avoid stale client')
        window.location.reload()
      }
    }, 30000)

    return () => {
      clearInterval(intervalId)
    }
  })

  return (
    <>
      {typeof window !== 'undefined' &&
        window.location.origin === STORAGE_SOURCE_ORIGIN &&
        STORAGE_TARGET_ORIGIN &&
        REDIRECT_TO_TARGET && <RedirectToTarget target={STORAGE_TARGET_ORIGIN} />}
      <Root2 {...props} />
    </>
  )
}
