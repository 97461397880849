import { BadgeUtils } from 'compass/data/Badge/utils'
import { ButtonUtils } from 'compass/data/Button/utils'
import { InputContainerUtils } from 'compass/input/InputContainer/utils'
import { colors } from './colors'

export const InputBaseThin = {
  name: 'thin',
  padding: 'px-2 py-2',
  height: 17,
}

export const BannerGold = {
  primary: 'bg-th-gold-3',
  secondary: 'bg-th-gold-3',
  text: 'text-th-gold-1',
}

export const BannerWarning = {
  primary: 'bg-th-yellow-light1',
  secondary: 'text-th-orange-light1',
  text: 'text-th-text',
}

export const BannerRejection = {
  primary: 'bg-th-orange-light2',
  secondary: 'text-th-red-warning',
  text: 'text-th-text',
}

export const ButtonThemeDefault: ButtonUtils.Theme = {
  bgColor: 'bg-th-orange-beam',
  textColor: 'text-th-bg-white',
  iconColor: 'text-th-bg-white',
  borderColor: 'outline-th-orange-dark1',
  borderWidth: 1,
  disabledBgColor: 'bg-th-orange-beam',
  disabledTextColor: 'text-th-bg-white',
  disabledIconColor: 'text-th-bg-white',
  disabledBorderColor: 'outline-th-orange-dark1',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-th-orange-dark1',
  hoverTextColor: 'hover:text-th-bg-white',
  hoverIconColor: 'hover:text-th-bg-white',
  hoverBorderColor: 'hover:outline-th-orange-dark1',
  hoverShadow: 'hover:shadow-none',
  size: 'large',
  variant: 'primary',
}

export const ButtonThemeLight: ButtonUtils.Theme = {
  bgColor: 'bg-th-bg-white',
  textColor: 'text-th-orange-dark1',
  iconColor: 'text-th-orange-dark1',
  borderColor: 'outline-th-orange-light1',
  borderWidth: 2,
  disabledBgColor: 'bg-th-coolgrey-3',
  disabledTextColor: 'text-th-orange-dark1',
  disabledIconColor: 'text-th-orange-dark1',
  disabledBorderColor: 'outline-th-orange-light1',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-th-bg-white',
  hoverTextColor: 'hover:text-th-orange-dark1',
  hoverIconColor: 'hover:text-th-orange-dark1',
  hoverBorderColor: 'hover:outline-th-orange-light1',
  hoverShadow: 'hover:shadow-md',
  size: 'large',
  variant: 'primary',
}

export const ButtonThemeTextOrange: ButtonUtils.Theme = {
  bgColor: 'bg-transparent',
  textColor: 'text-th-orange-dark1',
  iconColor: 'text-th-orange-dark1',
  borderColor: 'outline-transparent',
  borderWidth: 0,
  disabledBgColor: 'bg-transparent',
  disabledTextColor: 'text-th-orange-dark1',
  disabledIconColor: 'text-th-orange-dark1',
  disabledBorderColor: 'outline-transparent',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-transparent',
  hoverTextColor: 'hover:text-th-orange-dark1',
  hoverIconColor: 'hover:text-th-orange-dark1',
  hoverBorderColor: 'hover:outline-transparent',
  hoverShadow: 'hover:shadow-none',
  size: 'small',
  variant: 'secondary',
}

export const ButtonThemeTextDark: ButtonUtils.Theme = {
  bgColor: 'bg-transparent',
  textColor: 'text-th-brown-1',
  iconColor: 'text-th-brown-2',
  borderColor: 'outline-transparent',
  borderWidth: 0,
  disabledBgColor: 'bg-transparent',
  disabledTextColor: 'text-th-brown-1',
  disabledIconColor: 'text-th-brown-2',
  disabledBorderColor: 'outline-transparent',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-transparent',
  hoverTextColor: 'hover:text-th-brown-1',
  hoverIconColor: 'hover:text-th-brown-2',
  hoverBorderColor: 'hover:outline-transparent',
  hoverShadow: 'hover:shadow-none',
  size: 'large',
  variant: 'secondary',
}

export const ButtonThemeTextDarkBordered: ButtonUtils.Theme = {
  bgColor: 'bg-transparent',
  textColor: 'text-th-brown-1',
  iconColor: 'text-th-brown-2',
  borderColor: 'outline-brown-2',
  borderWidth: 2,
  disabledBgColor: 'bg-transparent',
  disabledTextColor: 'text-th-brown-1',
  disabledIconColor: 'text-th-brown-2',
  disabledBorderColor: 'outline-brown-2',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-transparent',
  hoverTextColor: 'hover:text-th-brown-1',
  hoverIconColor: 'hover:text-th-brown-2',
  hoverBorderColor: 'hover:outline-brown-2',
  hoverShadow: 'hover:shadow-none',
  size: 'large',
  variant: 'secondary',
}

export const ButtonThemeTextDarkSmall: ButtonUtils.Theme = {
  bgColor: 'bg-transparent',
  textColor: 'text-th-brown-1',
  iconColor: 'text-th-brown-2',
  borderColor: 'outline-transparent',
  borderWidth: 0,
  disabledBgColor: 'bg-transparent',
  disabledTextColor: 'text-th-brown-1',
  disabledIconColor: 'text-th-brown-2',
  disabledBorderColor: 'outline-transparent',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-transparent',
  hoverTextColor: 'hover:text-th-brown-1',
  hoverIconColor: 'hover:text-th-brown-2',
  hoverBorderColor: 'hover:outline-transparent',
  hoverShadow: 'hover:shadow-none',
  size: 'small',
  variant: 'secondary',
}

export const ButtonThemeGold: ButtonUtils.Theme = {
  bgColor: 'bg-th-gold-2',
  textColor: 'text-white',
  iconColor: 'text-white',
  borderColor: 'outline-th-gold-1',
  borderWidth: 1,
  disabledBgColor: 'bg-th-gold-2',
  disabledTextColor: 'text-white',
  disabledIconColor: 'text-white',
  disabledBorderColor: 'outline-th-gold-1',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-th-gold-1',
  hoverTextColor: 'hover:text-white',
  hoverIconColor: 'hover:text-white',
  hoverBorderColor: 'hover:outline-th-gold-1',
  hoverShadow: 'hover:shadow-none',
  size: 'large',
  variant: 'primary',
}

export const ButtonThemeTextRed: ButtonUtils.Theme = {
  bgColor: 'bg-transparent',
  textColor: 'text-th-red-warning',
  iconColor: 'text-th-red-warning',
  borderColor: 'outline-transparent',
  borderWidth: 0,
  disabledBgColor: 'bg-transparent',
  disabledTextColor: 'text-th-red-warning',
  disabledIconColor: 'text-th-red-warning',
  disabledBorderColor: 'outline-transparent',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-transparent',
  hoverTextColor: 'hover:text-th-red-warning',
  hoverIconColor: 'hover:text-th-red-warning',
  hoverBorderColor: 'hover:outline-transparent',
  hoverShadow: 'hover:shadow-none',
  size: 'large',
  variant: 'secondary',
}

export const BadgeThemeLightGray: BadgeUtils.Theme = {
  bgColor: 'bg-th-coolgrey-3',
  textColor: 'text-th-coolgrey-1',
}

export const BadgeThemeDarkGray: BadgeUtils.Theme = {
  bgColor: 'bg-th-coolgrey-1',
  textColor: 'text-white',
}

export const BadgeThemeLightGreen: BadgeUtils.Theme = {
  bgColor: 'bg-th-green-light1',
  textColor: 'text-th-green-dark1',
}

export const BadgeThemeDarkGreen: BadgeUtils.Theme = {
  bgColor: 'bg-th-green-dark1',
  textColor: 'text-white',
}

export const BadgeThemeLightBlue: BadgeUtils.Theme = {
  bgColor: 'bg-th-blue-light1',
  textColor: 'text-th-blue-2',
}

export const BadgeThemeDarkBlue: BadgeUtils.Theme = {
  bgColor: 'bg-th-blue-2',
  textColor: 'text-white',
}

export const BadgeThemeLightYellow: BadgeUtils.Theme = {
  bgColor: 'bg-th-yellow-light1',
  textColor: 'text-th-orange-dark1',
}

export const BadgeThemeLightOrange: BadgeUtils.Theme = {
  bgColor: 'bg-th-orange-light2',
  textColor: 'text-th-red-warning',
}

export const BadgeThemeLightRed: BadgeUtils.Theme = {
  bgColor: 'bg-th-red-light1',
  textColor: 'text-th-red-warning',
}

export const BadgeThemeDarkRed: BadgeUtils.Theme = {
  bgColor: 'bg-th-red-warning',
  textColor: 'text-white',
}

export const RadioSelectInlineTheme = {
  selectedColor: '#F87029',
  unselectedColor: '#AAA6A4',
  disabledColor: 'rgb(208 203 201 / 0.5)',
  targetHeight: 18,
  inset: 0,
  selectedOutlineColor: '',
  unselectedOutlineColor: '',
  borderWidth: 0,
  outlineWidth: 0,
}

export const RadioSelectCardTheme = {
  selectedColor: '#F87029',
  unselectedColor: '#AAA6A4',
  disabledColor: 'rgb(208 203 201 / 0.5)',
  targetHeight: 18,
  inset: 4,
  selectedOutlineColor: '#C44501',
  unselectedOutlineColor: 'rgb(170 166 164 / 0.5)',
  borderWidth: 1,
  outlineWidth: 1,
}

export const ProgressListTheme = {
  completeColor: '#353A4A',
  incompleteColor: '#909296',
  dotWidth: 8,
  lineWidth: 2,
}

export const TabGroupThemeInline = {
  contentGap: 4,
  labelGap: 6,
  contentBackground: 'transparent',
}

export const TabGroupThemeTopSection = {
  contentGap: 4,
  labelGap: 6,
  contentBackground: colors.white,
}

export const TabGroupThemeCard = {
  background: colors['coolgrey-3'],
  hoverBackground: colors['coolgrey-dark3'],
  selectedBackground: colors.white,
  selectedBorderColor: colors['brown-1'],
  unselectedTextColor: colors.secondary,
  subtitleColor: colors.secondary,
  unselectedSubtitleColor: colors['coolgrey-1'],
}

export const TableTheme = {
  cardInset: 5,
  cardBackground: 'transparent',
  cardDivider: true,
  cardHideNullItems: false,
  tableBorderColor: colors['warmgrey-2'],
  tableColumnHeaderInset: 2,
  tableCellDefaultInset: [4, 2] as [number, number],
  tableContentXInset: 2,
  tableVerticalAlign: undefined,
  tableHeader: 'standard' as const,
  tableFooterBackground: 'transparent',
  tableErrorBorderStyle: '',
  tableStandardBorderStyle: '',
  tableHeaderBorderStyle: 'standard' as const,
  tableShowRowBorder: true,
  showEmptyState: true,
  linkColor: colors['brown-1'],
}

export const TableThemeInputTable = {
  ...TableTheme,
  tableHeaderBorderStyle: 'full' as const,
  tableErrorBorderStyle: `2px solid ${colors['red-warning']}`,
  tableStandardBorderStyle: `1px solid ${colors['warmgrey-2']}`,
  tableFooterBackground: colors.slate,
  tableCellDefaultInset: 0,
  showEmptyState: false,
}

export const TableThemeLegacyInputTable = {
  ...TableTheme,
  cardBackground: colors.slate,
  tableContentXInset: 1,
  tableShowRowBorder: false,
  tableCellDefaultInset: [1, 1] as [number, number],
  showEmptyState: false,
}

export const TableThemeNoHeader = {
  ...TableTheme,
  tableHeader: 'hidden' as const,
}

export const EstimateTableTheme = {
  ...TableTheme,
  tableVerticalAlign: 'top' as const,
}

export const InputExperimentalTheme: InputContainerUtils.Theme = {
  border: `1px solid ${colors['warmgrey-1']}`,
  focusedBorder: `1px solid ${colors['warmgrey-1']}`,
  errorBorder: `1px solid ${colors['red-warning']}`,
  focusedOutline: `2px solid ${colors['warmgrey-1']}`,
  focusedOutlineOffset: -3,
  errorOutline: `1px solid ${colors['red-warning']}`,
  errorOutlineOffset: -2,
  errorColor: colors['red-warning'],
  focusedShadow: '0px 2px 4px 2px rgb(221 221 221 / 0.5)',
  inset: [4, 3],
  background: colors.white,
  textColor: colors.text,
  disabledTextColor: colors.disabled,
  disabledBackground: colors['coolgrey-3'],
}

export const InputExperimentalThemeInputTableLegacy: InputContainerUtils.Theme = {
  border: `1px solid ${colors['warmgrey-1']}`,
  focusedBorder: `1px solid ${colors['warmgrey-1']}`,
  errorBorder: `1px solid ${colors['red-warning']}`,
  focusedOutline: `2px solid ${colors['warmgrey-1']}`,
  focusedOutlineOffset: -3,
  errorOutline: `1px solid ${colors['red-warning']}`,
  errorOutlineOffset: -2,
  errorColor: colors['red-warning'],
  focusedShadow: '0px 2px 4px 2px rgb(221 221 221 / 0.5)',
  inset: [2.5, 3],
  background: colors.white,
  textColor: colors.text,
  disabledTextColor: colors.disabled,
  disabledBackground: colors['coolgrey-3'],
}

export const InputExperimentalThemeNone: InputContainerUtils.Theme = {
  ...InputExperimentalTheme,
  border: '',
  focusedBorder: '',
  errorBorder: '',
  focusedOutline: '',
  focusedOutlineOffset: -3,
  errorOutline: '',
  errorOutlineOffset: -2,
  errorColor: colors['red-warning'],
  inset: [2.5, 3],
  focusedShadow: '',
  background: 'transparent',
}

export const DropdownTheme = {
  border: `1px solid ${colors['warmgrey-1']}`,
  borderRadius: 1,
  background: colors.white,
  inset: 3,
  shadow: '0px 2px 4px 2px rgb(221 221 221 / 0.5)',
}

export const DropdownThemeCustom = {
  border: `1px solid ${colors['warmgrey-1']}`,
  borderRadius: 1,
  background: colors.white,
  inset: 0,
  shadow: '0px 2px 4px 2px rgb(221 221 221 / 0.5)',
}
