import BigNumber from 'bignumber.js'
import { Cb } from 'cb'
import { groupByKeyAndMap, MSArray, Zero } from 'msutils'

export namespace BudgetingUtils {
  export function isInGate(tier: Cb.SaasTierTier) {
    return tier === 'plus' || tier === 'scale' || tier === 'plus_with_coi'
  }

  export function getBudgetItemsFromEstimate(estimate: Cb.Estimate) {
    return groupByKeyAndMap({
      items: estimate.line_item_nodes.flatMap((x) =>
        MSArray.isNonEmpty(x.children) ? x.children : x,
      ),
      keyFn: (x) => [
        x.amount_details?.cost_code_id ?? null,
        x.amount_details?.cost_type_id ?? null,
      ],
      transformFn: (values) => {
        const cost = BigNumber.sum(
          ...values.map((x) =>
            BigNumber(x.amount_details?.unit_cost ?? 0)
              .multipliedBy(x.amount_details?.quantity ?? 0)
              .toFixed(2),
          ),
        )
        const price = BigNumber.sum(
          ...values.map((x) =>
            BigNumber(x.amount_details?.markup_multiplier ?? 1)
              .plus(1)
              .multipliedBy(
                BigNumber(x.amount_details?.unit_cost ?? 0)
                  .multipliedBy(x.amount_details?.quantity ?? 0)
                  .toFixed(2),
              ),
          ),
        )

        // Note: this is broken in the case that the client budget total ends up losing precision with the estimate total
        const averageMarkup = BigNumber(price.dividedBy(cost).toFixed(4)).minus(1)

        return {
          costCodeId: values[0].amount_details?.cost_code_id ?? null,
          costTypeId: values[0].amount_details?.cost_type_id ?? null,
          cost: BigNumber(price.dividedBy(averageMarkup.plus(1)).toFixed(2)),
          averageMarkup,
        }
      },
    })
  }

  export function getBudgetTotal(budget: Cb.ProjectBudget) {
    return BigNumber.sum(
      BigNumber(budget.overhead_cost ?? '0'),
      ...budget.initial_items.map((x) => BigNumber(x.amount)),
      ...budget.update_items.map((x) => BigNumber(x.amount)),
    )
  }

  export function getOriginalBudgetTotal(budget: Cb.ProjectBudget) {
    return BigNumber.sum(
      BigNumber(budget.overhead_cost ?? '0'),
      ...budget.initial_items.map((x) => BigNumber(x.amount)),
    )
  }

  export function getBudgetAutoUpdatedCost(budget: Cb.ProjectBudget) {
    return BigNumber.sum(Zero, ...budget.update_items.map((bi) => BigNumber(bi.amount)))
  }

  export function getBudgetAutoUpdatedPrice(budget: Cb.ProjectBudget) {
    return BigNumber.sum(
      Zero,
      ...budget.update_items.map((bi) => BigNumber(bi.client_amount ?? Zero)),
    )
  }

  export function getRemainingBudget(props: {
    budget: Cb.ProjectBudget
    projectSummary: Cb.ProjectProgressSummaryV2
  }) {
    const budgetTotal = getBudgetTotal(props.budget)
    const expectedPayable = BigNumber(props.projectSummary.payable.expected_amount)

    return budgetTotal.minus(expectedPayable)
  }

  export function getNetProfit(props: {
    budget: Cb.ProjectBudget
    projectSummary: Cb.ProjectProgressSummaryV2
  }) {
    const expectedReceivable = BigNumber(props.projectSummary.receivable.expected_amount)
    const expectedPayable = BigNumber(props.projectSummary.payable.expected_amount)

    return expectedReceivable.minus(BigNumber.max(expectedPayable, getBudgetTotal(props.budget)))
  }

  export function getBudgetForCostCodeOrType(props: {
    budget: Cb.ProjectBudget
    costCode: Cb.CostCode | 'none' | 'any'
    costType: Cb.CostType | 'none' | 'any'
    clientFacing: boolean
  }) {
    const { costCode, costType, budget, clientFacing } = props
    const matches = (c: Cb.CostCode | Cb.CostType | 'none' | 'any', y: string | null) =>
      c === 'any' || (c === 'none' && y === null) || (typeof c !== 'string' && c.id === y)

    const items = [...budget.initial_items, ...budget.update_items]
    return BigNumber.sum(
      Zero,
      ...items
        .filter((x) => matches(costCode, x.cost_code_id) && matches(costType, x.cost_type_id))
        .map((x) => BigNumber(clientFacing ? x.client_amount : x.amount)),
    )
  }

  export function getOriginalBudgetForCostCodeOrType(props: {
    budget: Cb.ProjectBudget
    costCode: Cb.CostCode | 'none' | 'any'
    costType: Cb.CostType | 'none' | 'any'
    clientFacing: boolean
  }) {
    const { costCode, costType, budget, clientFacing } = props
    const matches = (c: Cb.CostCode | Cb.CostType | 'none' | 'any', y: string | null) =>
      c === 'any' || (c === 'none' && y === null) || (typeof c !== 'string' && c.id === y)

    return BigNumber.sum(
      Zero,
      ...budget.initial_items
        .filter((x) => matches(costCode, x.cost_code_id) && matches(costType, x.cost_type_id))
        .map((x) => BigNumber(clientFacing ? x.client_amount : x.amount)),
    )
  }
}
