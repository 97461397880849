import { ReactNode } from 'react'
import { cn } from 'msutils/classnames'
import { Dropdown, MenuProps } from 'antd'
import { Collapsable, MSArray } from 'msutils/array'
import ComponentPropType from 'utils/types/ComponentPropTypes'
import Typography from 'compass/data/Typography'
import Tooltip from 'compass/layout/Tooltip'

export type MenuOption =
  | { type: 'divider' }
  | {
      type?: 'button'
      label?: ReactNode
      iconLeft?: ReactNode
      iconRight?: ReactNode
      onClick?: () => void
      disabled?: boolean
      disabledMessage?: ReactNode
      closeOnClick?: boolean
      variant?: 'normal' | 'danger'
    }

type AntdProps = ComponentPropType<typeof Dropdown>

type Props = {
  open?: boolean
  onClose?: () => void
  options: Collapsable<MenuOption[]>
  autoFocus?: boolean
  children: ReactNode
  overlayClassName?: string
  itemClassName?: string
  wrapperClassName?: string
  showDividerPadding?: boolean
  placement?: AntdProps['placement']
}
export default function Menu({
  open,
  onClose,
  options,
  autoFocus,
  children,
  overlayClassName,
  itemClassName,
  wrapperClassName,
  showDividerPadding,
  placement,
}: Props) {
  const collapsedOptions = MSArray.collapse(options)

  const items: MenuProps['items'] = collapsedOptions.flatMap((item, i) => {
    if (item.type === 'divider') {
      return {
        type: 'divider',
        style: showDividerPadding
          ? {
              borderTop: '8px solid white',
              borderBottom: '8px solid white',
              boxSizing: 'content-box',
            }
          : undefined,
      }
    }

    return {
      key: i,
      label: (
        <Tooltip inactive={!item.disabled || !item.disabledMessage} message={item.disabledMessage}>
          <div className="flex gap-6 py-1.5 px-3">
            <div
              className={cn('flex gap-2', item.disabled && 'text-th-text-disabled', itemClassName)}
            >
              {item.iconLeft}
              <Typography
                variant="bodybold"
                className={cn(
                  item.variant === 'danger' && 'text-th-red-warning',
                  item.disabled &&
                    (item.variant === 'danger'
                      ? 'text-th-red-warning text-opacity-50'
                      : 'text-th-text-secondary'),
                  'truncate max-w-[220px]',
                )}
              >
                {item.label}
              </Typography>
            </div>
            {item.iconRight}
          </div>
        </Tooltip>
      ),
      disabled: item.disabled,
      onClick: () => {
        item.onClick?.()
        if (item.closeOnClick) {
          onClose?.()
        }
      },
    }
  })

  return (
    <>
      <Dropdown
        open={open}
        onOpenChange={(o) => !o && onClose?.()}
        menu={{ items }}
        overlayClassName={overlayClassName ?? '!min-w-[200px]'}
        autoFocus={autoFocus}
        placement={placement}
        trigger={['click']}
      >
        <div className={wrapperClassName}>{children}</div>
      </Dropdown>
      <style>
        {`
      .ant-dropdown-menu {
        border-radius: 2px !important;
        border: 1px solid #D4D2D2 !important;
        box-shadow: 0px 2px 4px 2px rgba(221, 221, 221, 0.50) !important;
      }
      .ant-dropdown-menu-title-content {
        color: #353A4A;
      }
      .ant-dropdown-menu-item-divider {
        margin: 4px 12px !important;
        background-color: rgb(170 166 164 / 0.5) !important;
      }
    `}
      </style>
    </>
  )
}
