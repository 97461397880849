import File from 'compass-local/File'
import RadioOption from 'compass/input/RadioOption'
import { FileUtils } from 'components/misc/FileUtils'
import { Cb } from 'cb'
import { t } from 'content'
import useEstimateCoverPagePdfPreview from 'features/estimates/useEstimateCoverPagePdfPreview'
import { F } from 'msutils'
import useScreenSize from 'compass/theme/useScreenSize'
import { MSForm } from 'utils/form'
import { Mutator } from 'utils/form-input'
import { MSLayout } from 'utils/layout'
import useFormProps from 'utils/useFormProps'
import { Action } from 'utils/actions'
import { switchInline } from 'utils/misc'
import Divider from 'compass/data/Divider'
import InputLayout from 'compass/layout/InputLayout'
import { theme2 } from 'theme2'
import { TextInput } from 'compass/input/TextInput'
import TextArea from 'compass/input/TextArea'
import { EstimateInputUtils as Utils } from '../utils'
import { CoverPagePlaceholder } from './component-utils'

type Props = {
  state: F.Input<typeof Utils.schema>
  copy: Utils.CopySpec
  autosave: Mutator<any>
  saveDraft: Mutator<any>
  pageNumber: `${number} / ${number}`
}

export function CoverPagePage({ state, copy, autosave, saveDraft, pageNumber }: Props) {
  const { back, push } = Utils.pageManager.useContext()
  const { trigger, error } = F.useSubgroupValidation(Utils.schema, state, [
    'coverPage',
    'uploadedCoverPage',
  ])
  const sz = useScreenSize()
  const previewFormProps = useFormProps()
  const pdfPreview = useEstimateCoverPagePdfPreview({
    title: state.coverPage.title.value,
    body: state.coverPage.body.value,
    fileId: state.coverPage.photo.value.length === 1 ? state.coverPage.photo.value[0].fileId : null,
  })
  const uploadedFile = state.uploadedCoverPage.file.value.at(0) ?? null
  const client = state.client.value

  const saveDraftA = Action.mutation(copy.saveDraft, {
    qualify: () => (autosave.isLoading ? Utils.waitForAutoSaveMessage : {}),
    mutate: () => {
      if (
        (state.coverPageType.value === 'beam' && state.coverPage._controller.hasErrorWithin) ||
        (state.coverPageType.value === 'uploaded' &&
          state.uploadedCoverPage._controller.hasErrorWithin)
      ) {
        state.coverPage._controller.tap()
        state.uploadedCoverPage._controller.tap()
        throw new Error('')
      } else {
        return saveDraft.mutateAsync()
      }
    },
    theme: 'secondary',
  })

  return (
    <MSForm.Body
      title={copy.title}
      warnOnClose={state._controller.hasChanged}
      pageName={t('Cover page')}
      pageNumber={pageNumber}
      autosave={{ isSaved: !state._controller.hasChanged, isLoading: autosave.isLoading }}
      back={back}
      error={saveDraft.error ?? error}
      actions={[
        Action.more(
          [saveDraftA, Action.button(t('Preview'), { onClick: previewFormProps.setActive })],
          {
            qualify: () => sz === 'sm',
          },
        ),
        Action.hidden(saveDraftA, sz === 'sm'),
        Action.next(t('Review email'), {
          qualify: () =>
            !client
              ? t('You must select a client on page 1 in order to send an estimate')
              : { client },
          onClick: (ctx) => {
            if (
              state.coverPageType.value === 'beam' &&
              state.coverPage._controller.hasErrorWithin
            ) {
              return state.coverPage._controller.tap()
            }

            if (
              state.coverPageType.value === 'uploaded' &&
              state.uploadedCoverPage._controller.hasErrorWithin
            ) {
              return state.uploadedCoverPage._controller.tap()
            }

            return trigger(() => {
              push('review-email', { client: ctx.client })
            })
          },
        }),
      ]}
    >
      <MSForm.Panels
        rightContent={
          <MSForm.ComponentOrDrawer {...previewFormProps} title={t('Preview')}>
            <div className="md:p-5 h-full">
              {switchInline(state.coverPageType.value, {
                beam: <File file={pdfPreview} title={t('Estimate cover page')} />,
                uploaded: uploadedFile ? (
                  <FileUtils.Loader
                    value={uploadedFile}
                    download={Cb.downloadEstimateUploadedCoverPage}
                  />
                ) : (
                  <CoverPagePlaceholder text={t('No cover page has been uploaded')} />
                ),
                none: <CoverPagePlaceholder text={t('No cover page will be used')} />,
              })}
            </div>
          </MSForm.ComponentOrDrawer>
        }
      >
        <MSLayout.InputSection title={t('Add a cover page')}>
          <InputLayout>
            <RadioOption
              {...F.props(state.coverPageType)}
              theme={theme2.RadioSelectInlineTheme}
              isSelected={state.coverPageType.value === 'beam'}
              setSelected={() => state.coverPageType.update('beam')}
              title={t("Use Beam's template cover page")}
            />
            <RadioOption
              {...F.props(state.coverPageType)}
              theme={theme2.RadioSelectInlineTheme}
              isSelected={state.coverPageType.value === 'uploaded'}
              setSelected={() => state.coverPageType.update('uploaded')}
              title={t('Use your own cover page')}
            />
            <RadioOption
              {...F.props(state.coverPageType)}
              theme={theme2.RadioSelectInlineTheme}
              isSelected={state.coverPageType.value === 'none'}
              setSelected={() => state.coverPageType.update('none')}
              title={t('No cover page')}
            />
          </InputLayout>
          {state.coverPageType.value !== 'none' && <Divider />}
          {state.coverPageType.value === 'uploaded' && (
            <FileUtils.Input
              title={t('Upload your cover page')}
              fileTypes="pdf"
              {...F.props(state.uploadedCoverPage.file)}
              download={Cb.downloadEstimateUploadedCoverPage}
              required
              maxFiles={1}
            />
          )}
          {state.coverPageType.value === 'beam' && (
            <>
              <FileUtils.Input
                title={t('Upload your own photo')}
                {...F.props(state.coverPage.photo)}
                fileTypes="image"
                download={Cb.downloadEstimateCoverPagePhoto}
                required
                maxFiles={1}
              />
              <TextInput
                {...F.props(state.coverPage.title)}
                title={t('Title')}
                placeholder={t('E.g. About us')}
                maxLength={100}
              />
              <TextArea
                {...F.props(state.coverPage.body)}
                title={t('Message')}
                placeholder={t(
                  'E.g. We are a family owned and operated business with over 10 years of experience.',
                )}
                maxHeight={300}
                maxLength={1000}
              />
            </>
          )}
        </MSLayout.InputSection>
      </MSForm.Panels>
    </MSForm.Body>
  )
}
