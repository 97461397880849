import Divider from 'compass-local/Divider'
import Toggle from 'compass-local/Toggle'
import { t } from 'content'
import ScaleTierBanner from 'features/scale-tier/ScaleTierBanner'
import { F } from 'msutils'
import useScreenSize from 'compass/theme/useScreenSize'
import { MSForm } from 'utils/form'
import { Mutator } from 'utils/form-input'
import { MSLayout } from 'utils/layout'
import useFormProps from 'utils/useFormProps'
import { Action } from 'utils/actions'
import { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import { FileUtils } from 'components/misc/FileUtils'
import { Cb } from 'cb'
import { EstimateUtils } from 'features/estimates/utils'
import { useBusinessContext } from 'root/user'
import TextArea from 'compass/input/TextArea'
import { EstimateInputUtils as Utils } from '../utils'
import Preview from './Preview'

type Props = {
  state: F.Input<typeof Utils.schema>
  calculationContext: EstimateItemsInputUtils.EstimateCalculationContext
  copy: Utils.CopySpec
  saveDraft: Mutator<any>
  autosave: Mutator<any>
  showCoverPage: boolean
  pageNumber: `${number} / ${number}`
  capabilities: EstimateUtils.Capability[]
}

export function AdditionalDetailsPage({
  state,
  calculationContext,
  saveDraft,
  copy,
  autosave,
  showCoverPage,
  pageNumber,
  capabilities,
}: Props) {
  const { back, push } = Utils.pageManager.useContext()
  const { saasTier } = useBusinessContext()
  const { trigger, error } = F.useSubgroupValidation(Utils.schema, state, [
    'additionalInformation',
    'files',
  ])
  const sz = useScreenSize()
  const previewFormProps = useFormProps()

  const client = state.client.value

  const saveDraftA = Action.mutation(copy.saveDraft, {
    qualify: () => (autosave.isLoading ? Utils.waitForAutoSaveMessage : {}),
    mutate: () => saveDraft.mutateAsync(),
    theme: 'secondary',
  })

  return (
    <MSForm.Body
      title={copy.title}
      warnOnClose={state._controller.hasChanged}
      error={saveDraft.error ?? error}
      pageName={t('Additional details')}
      pageNumber={pageNumber}
      autosave={{ isSaved: !state._controller.hasChanged, isLoading: autosave.isLoading }}
      back={back}
      actions={[
        Action.more(
          [saveDraftA, Action.button(t('Preview'), { onClick: previewFormProps.setActive })],
          {
            qualify: () => sz === 'sm',
          },
        ),
        Action.hidden(saveDraftA, sz === 'sm'),
        Action.next(t('Cover page'), {
          qualify: () => showCoverPage,
          onClick: () => trigger(() => push('cover-page')),
        }),
        Action.next(t('Review email'), {
          qualify: () =>
            showCoverPage
              ? null
              : !client
              ? t('You must select a client on page 1 in order to send an estimate')
              : { client },
          onClick: (ctx) => trigger(() => push('review-email', { client: ctx.client })),
        }),
      ]}
    >
      <MSForm.Panels
        rightContent={
          <MSForm.ComponentOrDrawer {...previewFormProps} title={t('Preview')}>
            <Preview
              copy={copy}
              state={state}
              calculationContext={calculationContext}
              showFormatOptions={capabilities.includes('edit-structure')}
            />
          </MSForm.ComponentOrDrawer>
        }
      >
        <TextArea
          title={t('Additional information')}
          placeholder={t(
            'Enter your terms and conditions, payment terms, or any other additional information',
          )}
          optional
          {...F.props(state.additionalInformation)}
        />
        <FileUtils.Input
          {...F.props(state.files)}
          download={Cb.retrieveEstimateFileDownload}
          description={t('invoice PDFs, images of work, etc.')}
        />
        <Divider />
        <MSLayout.Section2 title={t('Collect e-signature')}>
          {saasTier === 'core' && (
            <ScaleTierBanner title={t('Collect e-signatures with Beam Plus.')} variant="thin" />
          )}
          <Toggle
            title={t("Collect client's e-signature upon approval")}
            {...F.props(state.requireApprovalSignature)}
            disabled={saasTier === 'core'}
          />
        </MSLayout.Section2>
      </MSForm.Panels>
    </MSForm.Body>
  )
}
