import { t } from 'content'
import { TextInput } from 'compass/input/TextInput'

type Props = {
  value: string
  update: (newValue: string) => void
  focus: () => void
  blur: () => void
  error: string | null
  title?: string
  placeholder?: string
}

export default function InvoiceNumberInput({ title, placeholder, ...props }: Props) {
  return (
    <div className="max-w-[200px]">
      <TextInput
        {...props}
        maxLength={21}
        title={title ?? t('Number')}
        placeholder={placeholder ?? '#'}
      />
    </div>
  )
}
