import { Fragment, ReactNode } from 'react'
import { cn } from 'msutils/classnames'
import { t } from 'content'
import { Collapsable, MSArray } from 'msutils/array'
import Divider from 'compass-local/Divider'
import PlayVideo from 'compass-local/legacy/icons/PlayVideo'
import { unreachable } from 'msutils/misc'
import Typography from 'compass/data/Typography'
import BaseCard from 'compass/layout/Card'
import BigNumber from 'bignumber.js'
import useScreenSize from 'compass/theme/useScreenSize'
import { Format } from 'msutils'
import Metric from 'compass-local/Metric'
import Metrics from 'compass-local/Metrics'
import { Action } from 'utils/actions'
import Alert from 'compass-local/Alert'
import { LinkEXPERIMENTAL } from 'components/misc/LinkEXPERIMENTAL'
import ComponentPropType from 'utils/types/ComponentPropTypes'
import Icon from 'compass/data/Icon'
import { BaseLayout } from 'compass/baseLayout'
import { MosaicHref } from 'modules/routes/types'
import BaseMetric from 'compass-local/BaseMetric'
import { useLayoutFlagContext } from 'compass/layout/LayoutFlagContext'
import { TChildren } from './utils'
import { SmartTextWrap } from './SmartTextWrap'

export function AdjacentInputs({ children }: TChildren) {
  return <div className="flex flex-col md:flex-row gap-5 md:gap-2">{children}</div>
}

function OptionalLabel({ children }: TChildren) {
  return (
    <div className="flex gap-2 items-baseline">
      {children}
      <Typography variant="label" className="text-th-text-secondary">
        {t('Optional')}
      </Typography>
    </div>
  )
}

export function InputSection({
  title,
  optional,
  children,
}: TChildren & { title: ReactNode; optional?: boolean }) {
  return (
    <div className="vflex gap-5">
      {optional ? (
        <OptionalLabel>
          <Typography variant="bodybold">{title}</Typography>
        </OptionalLabel>
      ) : (
        <Typography variant="bodybold">{title}</Typography>
      )}
      {children}
    </div>
  )
}

export function MetricsHeader({
  primaryMetric,
  secondaryMetrics = [],
}: {
  primaryMetric: { k: string; v: string }
  secondaryMetrics?: { k: string; v: string }[]
}) {
  const sz = useScreenSize()

  return (
    <div className={cn(sz === 'sm' ? 'vflex gap-5' : 'flex gap-8 justify-between', 'p-5')}>
      <Metric variant="large" k={primaryMetric.k} v={primaryMetric.v} />
      <div className={sz === 'sm' ? 'vflex gap-2' : 'flex gap-10'}>
        <Metrics variant="medium" align={sz === 'sm' ? 'left' : 'right'}>
          {secondaryMetrics.map((sm, i) => (
            <Metric key={`${sm.k}-${i}`} k={sm.k} v={sm.v} />
          ))}
        </Metrics>
      </div>
    </div>
  )
}

export function ResponsiveMetricsList({ children }: { children: ReactNode }) {
  const sz = useScreenSize()
  if (sz === 'sm') {
    return <div className="vflex gap-5">{children}</div>
  } else {
    return <div className="flex gap-10">{children}</div>
  }
}

export function SummaryMetricsBar({
  values,
}: {
  values: Collapsable<
    {
      k: string
      v: string
      bold?: boolean
    }[]
  >
}) {
  if (MSArray.collapse(values).length === 0) {
    return null
  } else {
    return (
      <Metrics align="right">
        <div className="justify-end gap-6 hidden md:flex">
          {MSArray.collapse(values).map((val, i) => (
            <Metric key={i} k={val.k} v={val.v} bold={val.bold ?? false} />
          ))}
        </div>
        <div className="vflex gap-2 md:hidden pb-1">
          {MSArray.collapse(values).map((val, i) => (
            <Metric key={i} k={val.k} v={val.v} bold={val.bold ?? false} layout="h" />
          ))}
        </div>
      </Metrics>
    )
  }
}

type GuestSectionProps = {
  title: string
  background: 'slate' | 'white'
  children: ReactNode
}
export function GuestSection({ title, background, children }: GuestSectionProps) {
  return (
    <div className="vflex gap-2">
      {title && (
        <div className="flex gap-5 justify-between items-center">
          <div className="flex gap-2 items-center">
            <Typography variant="subtitle" className="text-th-text px-5">
              {title}
            </Typography>
          </div>
        </div>
      )}
      <div
        className={cn(
          'p-5 vflex gap-5',
          background === 'slate'
            ? 'bg-th-bg-slate'
            : background === 'white'
            ? 'bg-th-bg-white'
            : unreachable(background),
        )}
      >
        {children}
      </div>
    </div>
  )
}

type SectionProps = {
  title: string
  icon?: ReactNode
  badge?: ReactNode
  banner?: ReactNode
  variant?: 'title' | 'subtitle'
  actions?: Collapsable<
    { onClick: () => void; name: string; icon?: ReactNode; disabled?: boolean }[]
  >
  actions2?: Action.Config[]
  children: ReactNode
}

export function Section2({
  title,
  icon,
  badge,
  banner,
  variant = 'subtitle',
  actions2,
  children,
}: SectionProps) {
  const { enableSectionPadding } = useLayoutFlagContext()
  return (
    <Typography className={cn('vflex gap-5', enableSectionPadding && 'p-5')}>
      {title && (
        <div className="flex gap-5 justify-between items-center">
          <div className="vflex gap-2">
            <div className="flex gap-2 items-center">
              {icon}
              <Typography variant={variant} className="text-th-text">
                {title}
              </Typography>
              {badge}
            </div>
            {banner}
          </div>
          <div className="flex gap-3 items-center">
            <Action.UIConfigProvider value={{ theme: 'orange-text' }}>
              {actions2?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
            </Action.UIConfigProvider>
          </div>
        </div>
      )}
      {children}
    </Typography>
  )
}

type JoinProps = {
  x: ReactNode
  children: ReactNode[]
}

export function Join({ x, children }: JoinProps) {
  return (
    <>
      {children.flatMap((y, i) => (i === 0 ? y : [<Fragment key={`join-${i}`}>{x}</Fragment>, y]))}
    </>
  )
}

export function UnpaddedSection({ children }: TChildren) {
  return <div className="-m-5 bg-th-bg-slate">{children}</div>
}

export function SegmentedList(...args: ReactNode[]) {
  return (
    <Join x={<Divider />}>
      {MSArray.collapse(args).map((x, i) => (
        <Fragment key={i}>{x}</Fragment>
      ))}
    </Join>
  )
}

type EmptyStateProps = {
  icon: JSX.Element
  title: string
  subtitle?: ReactNode
  caption?: string
  message?: ReactNode
  actions2?: Action.Config[]
  customAction?: ReactNode
  videoDetails?: {
    url: string
    length: number
  }
}

export function EmptyState(props: EmptyStateProps) {
  const { icon, title, subtitle, caption, message, actions2, customAction, videoDetails } = props

  return (
    <div className="py-8 px-5 md:py-10 flex justify-center">
      <div className="vflex gap-5 max-w-[480px] items-center">
        <div className="text-th-coolgrey-1">{icon}</div>
        <Typography variant="title" className="text-center">
          {title}
        </Typography>
        {subtitle && (
          <Typography className="text-center text-th-text-secondary">{subtitle}</Typography>
        )}
        {caption && (
          <Typography variant="caption" className="text-center text-th-text-secondary italic">
            {caption}
          </Typography>
        )}
        {message && (
          <Typography className="text-center text-th-text-secondary">{message}</Typography>
        )}
        {videoDetails && (
          <Typography
            variant="bodybold"
            className="flex items-center gap-2 text-th-brown-1"
            onClick={() => LinkEXPERIMENTAL.open(videoDetails.url)}
          >
            <PlayVideo />
            {t('Watch a {{ X }} minute video to learn more', { X: videoDetails.length })}
          </Typography>
        )}
        <div className="flex flex-col gap-4 items-center md:flex-row">
          {(actions2 ?? []).map((x, i) => (
            <Action.Mount key={`${x.name}-${i}`} {...x} />
          ))}
        </div>
        {customAction}
      </div>
    </div>
  )
}

type MinimalEmptyStateProps = {
  title: string
  actions?: Action.Config[]
}

export function MinimalEmptyState(props: MinimalEmptyStateProps) {
  const { title, actions = [] } = props

  return (
    <div className="flex vflex py-5 gap-5 text-center">
      <Typography className="text-th-coolgrey-1">{title}</Typography>
      <div className="flex flex-col gap-2 items-center md:flex-row justify-center">
        {actions.map((x, i) => (
          <div className="w-min">
            <Action.Mount key={`${x.name}-${i}`} {...x} />
          </div>
        ))}
      </div>
    </div>
  )
}

type InputSectionCardProps = {
  title: string
  subtitle?: string
  children: ReactNode
}

export function InputSectionCard({ title, subtitle, children }: InputSectionCardProps) {
  return (
    <BaseCard className="vflex gap-3">
      <div className="vflex gap-1">
        <Typography variant="label">{title}</Typography>
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      </div>
      {children}
    </BaseCard>
  )
}

type VStackProps = TChildren & {
  size?: 'small' | 'medium' | 'large'
}
export function VStack({ size = 'medium', children }: VStackProps) {
  return (
    <div
      className={cn(
        size === 'small'
          ? 'gap-2'
          : size === 'medium'
          ? 'gap-5'
          : size === 'large'
          ? 'gap-10'
          : unreachable(size),
        'vflex',
      )}
    >
      {children}
    </div>
  )
}

type HStackProps = TChildren & {
  size?: 'small' | 'medium' | 'large'
  separate?: boolean
  top?: boolean
}
export function HStack({ size = 'medium', separate = false, top = false, children }: HStackProps) {
  return (
    <div
      className={cn(
        size === 'small'
          ? 'gap-2'
          : size === 'medium'
          ? 'gap-5'
          : size === 'large'
          ? 'gap-10'
          : unreachable(size),
        separate && 'justify-between',
        !top && 'items-center',
        'flex',
      )}
    >
      {children}
    </div>
  )
}

type TabDescriptionProps = {
  total?: BigNumber
  caption?: string
}

export function TabDescription({ total, caption }: TabDescriptionProps) {
  const sz = useScreenSize()

  return (
    <div className="vflex gap-1">
      {total && (
        <Typography variant={sz === 'sm' ? 'label' : 'body'}>{Format.currency(total)}</Typography>
      )}
      {caption && (
        <Typography variant={total ? 'caption' : sz === 'sm' ? 'label' : 'body'}>
          {caption}
        </Typography>
      )}
    </div>
  )
}

type InlineFormProps = {
  title: string
  subtitle?: string
  errorMessage?: string | null
  children: ReactNode
  submit: Action.Config
  footer?: ReactNode
}

export function InlineForm({
  title,
  subtitle,
  errorMessage,
  children,
  submit,
  footer,
}: InlineFormProps) {
  return (
    <form className="p-6 sm:w-[420px] w-full vflex gap-4" onSubmit={(e) => e.preventDefault()}>
      <Typography variant="header">{title}</Typography>
      {subtitle && <Typography>{subtitle}</Typography>}
      <div className="vflex gap-4">
        {errorMessage && <Alert>{errorMessage}</Alert>}
        {children}
      </div>
      <div className="vflex gap-5">
        <Action.Mount {...submit} submit />
        {footer && <Typography className="text-center">{footer}</Typography>}
      </div>
    </form>
  )
}

type PrefixLabeledProps = TChildren & {
  label: string
}

export function PrefixLabel({ children, label }: PrefixLabeledProps) {
  return (
    <div className="flex gap-3 items-center">
      <Typography variant="label" className="text-th-text-secondary w-[42px]">
        {label}
      </Typography>
      {children}
    </div>
  )
}

type CardProps = {
  icon?: ReactNode
  title: string
  subtitle?: string
  badge?: ReactNode
  metrics?: { k: string; v: string }[]
  actions?: Action.Config[]
}

export function Card({ icon, title, subtitle, badge, actions, metrics }: CardProps) {
  return (
    <div className="flex p-5 justify-between items-center">
      <div className="flex gap-4 items-center">
        {icon}
        <div className="vflex gap-1">
          <div className="flex gap-2 items-center">
            <Typography variant="bodybold">{title}</Typography>
            {badge}
          </div>
          <Typography>{subtitle}</Typography>
          {metrics && (
            <div className="flex gap-10">
              {metrics.map((x) => (
                <Metric key={x.k} k={x.k} v={x.v} />
              ))}
            </div>
          )}
        </div>
      </div>
      {Action.visibleCount(actions) > 0 && (
        <div className="flex gap-4 items-center">
          {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
        </div>
      )}
    </div>
  )
}

export function List(...components: ReactNode[]) {
  return <div className="vflex">{SegmentedList(...components)}</div>
}

export function Panels({
  leftContent,
  rightContent,
}: {
  leftContent: ReactNode
  rightContent: ReactNode
}) {
  const sz = useScreenSize()

  return (
    <div className={cn(sz === 'sm' ? 'vflex gap-2 p-10' : 'flex gap-10 px-10', 'items-center')}>
      {leftContent}
      {rightContent}
    </div>
  )
}

type PaneProps = {
  children: ReactNode
}

export function Pane({ children }: PaneProps) {
  return <div className="bg-th-bg-white p-5 vflex gap-5">{children}</div>
}

type GuestDetailsMetricSectionProps = {
  metrics: { k: string; v: string }[]
}

export function GuestDetailsMetricSection({ metrics }: GuestDetailsMetricSectionProps) {
  return (
    <div className="vflex gap-5">
      <Typography variant="section" className="uppercase text-th-text-secondary">
        {t('Details')}
      </Typography>
      <Metrics layout="h">
        {metrics.map((x) => (
          <Metric key={x.k} k={x.k} v={x.v} />
        ))}
      </Metrics>
    </div>
  )
}

export function Gallery({ children }: { children: ReactNode }) {
  return <div className="flex flex-wrap gap-2">{children}</div>
}

type Card2Props = {
  icon: ComponentPropType<typeof Icon>['name']
  title: string
  badge?: ReactNode
  actions?: Action.Config[]
  metrics: { k: string; v: string }[]
}

export function Card2({ icon, title, badge, actions, metrics }: Card2Props) {
  const sz = useScreenSize()

  return (
    <BaseLayout.HStack gap={4} view={{ inset: 5 }}>
      <Icon name={icon} height={24} />
      <BaseLayout.VStack gap={2}>
        <BaseLayout.HStack justify="between" gap={4}>
          <BaseLayout.HStack gap={2}>
            <SmartTextWrap variant="bodybold">{title}</SmartTextWrap>
            <BaseLayout.HStack gap={2}>{badge}</BaseLayout.HStack>
          </BaseLayout.HStack>
          {Action.visibleCount(actions) > 0 && (
            <BaseLayout.HStack gap={4}>
              {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
            </BaseLayout.HStack>
          )}
        </BaseLayout.HStack>
        {sz === 'sm' ? (
          <BaseLayout.VStack gap={2}>
            {metrics.map((x) => (
              <Metric layout="h" key={x.k} k={x.k} v={x.v} />
            ))}
          </BaseLayout.VStack>
        ) : (
          <BaseLayout.HStack gap={3}>
            {metrics.map((x) => (
              <Metric key={x.k} k={x.k} v={x.v} />
            ))}
          </BaseLayout.HStack>
        )}
      </BaseLayout.VStack>
    </BaseLayout.HStack>
  )
}

type DetailTabProps = {
  metric: { k: string; v: ReactNode } | null
  hrefs?: { href: MosaicHref; label: string }[]
  paddedDONOTUSE?: boolean
  overview: ReactNode
  children: ReactNode
}

export function DetailTab({
  metric,
  hrefs = [],
  overview,
  paddedDONOTUSE,
  children,
}: DetailTabProps) {
  return (
    <div className={cn('vflex gap-5 bg-th-bg-white', paddedDONOTUSE && 'p-5')}>
      <div className="flex gap-4 justify-between items-center">
        {metric && <BaseMetric k={metric.k} v={metric.v} variant="large" />}
        {hrefs.length > 0 && (
          <div className="vflex gap-3 items-end">
            {hrefs.map((x) => (
              <LinkEXPERIMENTAL.Internal {...x} key={x.href} />
            ))}
          </div>
        )}
      </div>
      {overview}
      {children}
    </div>
  )
}

type EmptyState2Props = {
  titleMetric: { k: string; v: ReactNode }
  hrefs?: { label: string; href: MosaicHref }[]
  description: string
  action: Action.Config
}

export function EmptyState2({ titleMetric, hrefs = [], description, action }: EmptyState2Props) {
  return (
    <div className="vflex gap-5">
      <div className="flex gap-4 justify-between">
        <BaseMetric k={titleMetric.k} v={titleMetric.v} variant="large" />
        {hrefs?.length > 0 && (
          <Action.UIConfigProvider value={{ theme: 'text-small' }}>
            <div className="vflex gap-3 items-end">
              {hrefs?.map((x) => <LinkEXPERIMENTAL.Internal {...x} key={x.href} />)}
            </div>
          </Action.UIConfigProvider>
        )}
      </div>
      <div className="vflex gap-4 items-center py-10 px-5">
        <Typography className="text-th-text-secondary text-center">{description}</Typography>
        <div className="w-min">
          <Action.UIConfigProvider value={{ theme: 'secondary' }}>
            <Action.Mount {...action} />
          </Action.UIConfigProvider>
        </div>
      </div>
    </div>
  )
}

type DetailSectionProps = {
  metric: { k: string; v: string }
  qualifiedContent: string | null | ReactNode
  href: { label: string; href: MosaicHref }
}

export function DetailSection({ metric, qualifiedContent, href }: DetailSectionProps) {
  if (qualifiedContent === null) {
    return null
  } else if (typeof qualifiedContent === 'string') {
    return (
      <div className="flex gap-4 justify-between items-center">
        <Typography className="text-th-text-secondary">{qualifiedContent}</Typography>
        <LinkEXPERIMENTAL.Internal {...href} />
      </div>
    )
  } else {
    return (
      <div className="vflex gap-5">
        <Metric k={metric.k} v={metric.v} variant="medium" />
        {qualifiedContent}
        <div className="flex justify-end">
          <LinkEXPERIMENTAL.Internal {...href} />
        </div>
      </div>
    )
  }
}

type ContentContentDetailProps = {
  content1: ReactNode
  content2: ReactNode
  detail: ReactNode
}

export function ContentContentDetail({ content1, content2, detail }: ContentContentDetailProps) {
  const sz = useScreenSize()

  if (sz === 'sm') {
    return <div className="vflex gap-5 bg-th-bg-white">{detail}</div>
  } else if (sz === 'md') {
    return (
      <div className="vflex gap-5">
        {content1 && <div className="bg-th-bg-white p-5">{content1}</div>}
        {content2 && <div className="bg-th-bg-white p-5">{content2}</div>}
        <div className="bg-th-bg-white p-5">{detail}</div>
      </div>
    )
  } else {
    return (
      <div className="vflex gap-5">
        <div className="flex-force-equal gap-5">
          {content1 && <div className="bg-th-bg-white p-5 h-fit">{content1}</div>}
          {content2 && <div className="bg-th-bg-white p-5 h-fit">{content2}</div>}
        </div>
        <div className="bg-th-bg-white p-5">{detail}</div>
      </div>
    )
  }
}
