import { ReactNode, useMemo, useRef, useState } from 'react'
import { MSTimestamp, useOnChange, useOnMount } from 'msutils'
import { unreachable } from 'msutils/misc'
import { createPortal } from 'react-dom'
import { PortalRootId } from './_internal/constants'
import { getScrollableParents } from './dom-utils'
import { BaseLayout } from './baseLayout'

export namespace PortalLayout {
  type Coordinate = { x: number; y: number }
  type Size = { w: number; h: number }
  type Scrollable = Window | HTMLElement

  function usePortalRoot() {
    return useMemo(() => document.getElementById(PortalRootId) as HTMLDivElement, [])
  }

  function useOnClickAnywhere(onClickAnywhere: (target: HTMLElement) => void) {
    const handlerRef = useRef(onClickAnywhere)
    handlerRef.current = onClickAnywhere
    useOnMount(() => {
      const handle = (e: MouseEvent) =>
        e.target instanceof HTMLElement && handlerRef.current(e.target)
      document.addEventListener('click', handle)
      return () => {
        document.removeEventListener('click', handle)
      }
    })
  }

  function useScrollListeners(anchor: HTMLElement, onScrollAny: (targets: Scrollable[]) => void) {
    const onScrollAnyRef = useRef(onScrollAny)
    onScrollAnyRef.current = onScrollAny
    useOnMount(() => {
      const scrollableParents = getScrollableParents(anchor)
      const handleScroll = () => onScrollAnyRef.current(scrollableParents)

      scrollableParents.forEach((sp) => sp.addEventListener('scroll', handleScroll))
      return () => {
        scrollableParents.forEach((sp) => sp.removeEventListener('scroll', handleScroll))
      }
    })
  }

  function useResizeListener(onResize: () => void) {
    const onResizeRef = useRef(onResize)
    onResizeRef.current = onResize
    useOnMount(() => {
      const handleResize = () => onResizeRef.current()

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    })
  }

  function useOnWindowLoseFocus(callback: () => void) {
    const callbackRef = useRef(callback)
    callbackRef.current = callback
    useOnMount(() => {
      const handle = () => callbackRef.current()
      window.addEventListener('blur', handle)
      return () => {
        window.removeEventListener('blur', handle)
      }
    })
  }

  function useOnMutate(el: HTMLElement, callback: () => void) {
    const callbackRef = useRef(callback)
    callbackRef.current = callback
    useOnMount(() => {
      const mutationObserver = new MutationObserver(() => {
        setTimeout(() => callbackRef.current(), 10)
      })
      mutationObserver.observe(el, { childList: true, subtree: true, characterData: true })
      return () => {
        mutationObserver.disconnect()
      }
    })
  }

  function Portal({ children }: { children: ReactNode }) {
    const portalRoot = usePortalRoot()
    const portal = useMemo(() => createPortal(<>{children}</>, portalRoot), [children, portalRoot])

    return <>{portal}</>
  }

  function getAllowableBounds() {
    return document.body.getBoundingClientRect()
  }

  type Direction2 = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'none'

  function ContentLayout({
    anchor,
    contentRef: contentRef_,
    isActive,
    setInactive,
    direction: directionOverride,
    getAnchorOffset: getAnchorOffsetOverride,
    scrollBehavior,
    animationBehavior,
    animationDuration,
    margin,
    offset,
  }: {
    anchor: HTMLElement
    contentRef: HTMLDivElement
    isActive: boolean
    setInactive: () => void
    direction: Direction2 | undefined
    getAnchorOffset: (() => Coordinate) | undefined
    scrollBehavior: 'follow' | 'set-inactive'
    animationBehavior: 'drop' | 'scale' | 'none'
    animationDuration: number
    margin: number
    offset: number
  }) {
    const contentRef = contentRef_
    const [contentSize, setContentSize] = useState<Size>(() => {
      const contentRect = contentRef.getBoundingClientRect()
      return { w: contentRect.width, h: contentRect.height }
    })
    const getDirection = (anchorRect: DOMRect): Direction2 => {
      if (directionOverride) {
        return directionOverride
      } else {
        const allowableBounds = getAllowableBounds()
        const rightHasSpace = allowableBounds.right - anchorRect.right - margin > contentSize.w
        const leftHasSpace = anchorRect.left - margin > contentSize.w
        const bottomHasSpace =
          allowableBounds.bottom - anchorRect.bottom - margin - offset > contentSize.h
        const topHasSpace = anchorRect.top - margin - offset > contentSize.h
        // console.log(
        // `space - right: ${rightHasSpace}, left: ${leftHasSpace}, bottom: ${bottomHasSpace}, top: ${topHasSpace}`,
        // )
        // Note: anchor to the left if the right has space
        if (rightHasSpace) {
          if (bottomHasSpace) {
            return 'bottom-left'
          } else if (topHasSpace) {
            return 'top-left'
          } else {
            return 'bottom-left'
          }
        } else if (leftHasSpace) {
          if (bottomHasSpace) {
            return 'bottom-right'
          } else if (topHasSpace) {
            return 'top-right'
          } else {
            return 'bottom-right'
          }
        } else {
          if (bottomHasSpace) {
            return 'bottom-left'
          } else if (topHasSpace) {
            return 'top-left'
          } else {
            return 'bottom-left'
          }
        }
      }
    }

    const setPosition = (reason: string) => {
      if (!reason) throw new Error('Must have a reason')
      const anchorRect = anchor.getBoundingClientRect()
      const direction = getDirection(anchorRect)
      // console.log(`setting position for ${reason}`)
      const getDirectionOffset = (): Coordinate => {
        if (getAnchorOffsetOverride) {
          return getAnchorOffsetOverride()
        }
        switch (direction) {
          case 'top-left':
            return { x: 0, y: -contentSize.h - offset }
          case 'top-right':
            return { x: anchorRect.width - contentSize.w, y: -contentSize.h - offset }
          case 'bottom-left':
            return { x: 0, y: anchorRect.height + offset }
          case 'bottom-right':
            return { x: anchorRect.width - contentSize.w, y: anchorRect.height + offset }
          case 'none':
            return { x: 0, y: 0 }
          default:
            return unreachable(direction)
        }
      }
      const directionOffset = getDirectionOffset()
      contentRef.style.top = `${anchorRect.top + directionOffset.y}px`
      contentRef.style.left = `${anchorRect.left + directionOffset.x}px`
      contentRef.style.transformOrigin =
        direction === 'top-left'
          ? '0 100%'
          : direction === 'top-right'
          ? '80% 100%'
          : direction === 'bottom-left'
          ? '0 0'
          : direction === 'bottom-right'
          ? '80% 0'
          : direction === 'none'
          ? '0 0'
          : unreachable(direction)
    }

    useOnChange([contentSize], () => setPosition('content size change'))
    useResizeListener(() => setPosition('window resize'))
    useOnMutate(anchor, () => setPosition('dom mutation'))
    useOnMutate(contentRef, () => {
      const contectRect = contentRef.getBoundingClientRect()
      setContentSize({ w: contectRect.width, h: contectRect.height })
    })
    useScrollListeners(anchor, () => {
      setPosition('scroll')
      if (scrollBehavior === 'follow') {
        //
      } else if (scrollBehavior === 'set-inactive') {
        setInactive()
      } else {
        unreachable(scrollBehavior)
      }
    })
    useOnWindowLoseFocus(() => setInactive())

    useOnMount(() => {
      if (animationBehavior !== 'none') {
        contentRef.style.transition = 'ease-in-out opacity, ease-in-out transform'
        contentRef.style.transitionDuration = `${animationDuration}ms`
      }
    })

    useOnChange([isActive], () => {
      setPosition('changing active status')
      const anchorRect = anchor.getBoundingClientRect()
      const direction = getDirection(anchorRect)
      contentRef.style.opacity = isActive ? '1' : '0'

      if (isActive) {
        contentRef.style.transform = ''
        contentRef.style.pointerEvents = ''
      } else {
        contentRef.style.pointerEvents = 'none'
        if (animationBehavior === 'drop') {
          contentRef.style.transform = `translateY(${direction.startsWith('top') ? '' : '-'}10px)`
        } else if (animationBehavior === 'scale') {
          contentRef.style.transform = 'scale(0.1)'
        } else if (animationBehavior === 'none') {
          //
        } else {
          unreachable(animationBehavior)
        }
      }
    })

    return null
  }

  function ClickListener({ onClick }: { onClick: () => void }) {
    const mountedAt = useRef(MSTimestamp.now()).current
    useOnClickAnywhere((e) => {
      if (mountedAt.add(0.1, 'second').isPast()) {
        // Note: for now we ignore antd portal clicks
        if (document.getElementById('__next')?.contains(e)) {
          onClick()
        }
      }
    })
    return null
  }

  export function Dropdown({
    anchor,
    content,
    isActive,
    setInactive,
    direction,
    getAnchorOffset,
    scrollBehavior = 'set-inactive',
    animationBehavior = 'drop',
    animationDuration = 800,
    offset = 4,
    margin = 8,
  }: {
    anchor: HTMLElement
    content: ReactNode
    isActive: boolean
    setInactive: () => void
    direction?: Direction2
    getAnchorOffset?: () => Coordinate
    scrollBehavior?: 'follow' | 'set-inactive'
    closeBehavior?: 'on-blur' | 'manual'
    animationBehavior?: 'drop' | 'scale' | 'none'
    animationDuration?: number
    offset?: number
    margin?: number
  }) {
    const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null)

    return (
      <Portal>
        {contentRef && (
          <ContentLayout
            contentRef={contentRef}
            anchor={anchor}
            isActive={isActive}
            setInactive={setInactive}
            direction={direction}
            getAnchorOffset={getAnchorOffset}
            scrollBehavior={scrollBehavior}
            animationBehavior={animationBehavior}
            animationDuration={animationDuration}
            offset={offset}
            margin={margin}
          />
        )}
        {isActive && <ClickListener onClick={setInactive} />}
        <BaseLayout.NativeDiv ref={setContentRef} position="absolute" opacity="0">
          <BaseLayout.ClickPropagationBoundary>{content}</BaseLayout.ClickPropagationBoundary>
        </BaseLayout.NativeDiv>
      </Portal>
    )
  }
}
