export type ToCamel<T extends string> = T extends `${infer A}_${infer B}`
  ? `${A}${ToCamel<Capitalize<B>>}`
  : T

export type ToSnake<T extends string> = T extends `${infer A}${infer B}`
  ? `${A extends Capitalize<string> ? '_' : ''}${Uncapitalize<A>}${ToSnake<B>}`
  : T

export class MSString<T extends string> {
  private str: T

  private constructor(str: T) {
    this.str = str
  }

  private toCamel(): ToCamel<T> {
    return this.str.replace(/([-_][a-z])/gi, (_) =>
      _.toUpperCase().replace('-', '').replace('_', ''),
    ) as ToCamel<T>
  }

  private toSnake(): ToSnake<T> {
    return this.str.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`) as ToSnake<T>
  }

  static toCamel<T extends string>(str: T): ToCamel<T> {
    return new MSString(str).toCamel()
  }

  static toSnake<T extends string>(str: T): ToSnake<T> {
    return new MSString(str).toSnake()
  }

  static insertCharacters(str: string, insertChars: [string, number][]) {
    return str
      .split('')
      .map(
        (char, i) =>
          `${insertChars
            .filter(([, j]) => j === i)
            .map(([c]) => c)
            .join('')}${char}`,
      )
      .join('')
  }

  static stripNonNumeric(str: string) {
    return str
      .split('')
      .filter((x) => /\d/.test(x))
      .join('')
  }
}
