import { ReactNode } from 'react'
import { unreachable } from 'msutils/misc'
import AnimateHeight from 'react-animate-height'
import { t } from 'content'
import { BaseLayout } from '../../baseLayout'
import Typography from '../../data/Typography'
import Icon from '../../data/Icon'
import Tooltip from '../../layout/Tooltip'
import { InputContainerUtils } from './utils'

type Props = {
  title: string | undefined
  subtitle: string | undefined
  optional: boolean
  theme: InputContainerUtils.Theme
  error: string | null
  state: 'disabled' | 'focused' | 'idle'
  customAnnotation?: null | InputContainerUtils.CustomAnnotation
  endWidget?: ReactNode
  infoTooltip?: ReactNode
  children: ReactNode
}

export default function InputContainer({
  title,
  subtitle,
  optional,
  theme,
  error,
  state,
  customAnnotation,
  infoTooltip,
  endWidget,
  children,
}: Props) {
  const { titleConfig, annotationConfig } = InputContainerUtils.useContext()

  return (
    <BaseLayout.VStack gap={2}>
      {titleConfig !== 'always-hide' && (
        <BaseLayout.VStack gap={1}>
          {title && (
            <BaseLayout.HStack gap={2} align="baseline">
              <BaseLayout.HStack gap={2}>
                <Typography variant="label">{title}</Typography>
                {infoTooltip && <Tooltip.Info message={infoTooltip} />}
              </BaseLayout.HStack>
              {optional && (
                <Typography variant="label" className="text-th-text-secondary">
                  {t('Optional')}
                </Typography>
              )}
            </BaseLayout.HStack>
          )}
          {subtitle && (
            <Typography variant="caption" className="text-th-text-secondary">
              {subtitle}
            </Typography>
          )}
        </BaseLayout.VStack>
      )}
      <BaseLayout.VStack>
        <BaseLayout.View
          overridesDONOTUSE={{
            transition: 'all',
            transitionDuration: '50ms',
            boxShadow: state === 'focused' ? theme.focusedShadow : undefined,
            color: state === 'disabled' ? theme.disabledTextColor : theme.textColor,
            position: 'relative',
          }}
          fillHeight
          borderRadius={0.5}
          border={
            state === 'focused'
              ? theme.focusedBorder
              : error
              ? theme.errorBorder
              : state === 'disabled' || state === 'idle'
              ? theme.border
              : unreachable(state)
          }
          outlineOffset={
            state === 'focused'
              ? theme.focusedOutlineOffset
              : error
              ? theme.errorOutlineOffset
              : state === 'disabled' || state === 'idle'
              ? undefined
              : unreachable(state)
          }
          outline={
            state === 'focused'
              ? theme.focusedOutline
              : error
              ? theme.errorOutline
              : state === 'disabled' || state === 'idle'
              ? undefined
              : unreachable(state)
          }
          background={
            state === 'disabled'
              ? theme.disabledBackground
              : state === 'focused' || state === 'idle'
              ? theme.background
              : unreachable(state)
          }
        >
          {children}
          {endWidget && (
            <BaseLayout.VStack
              view={{
                overridesDONOTUSE: { position: 'absolute', top: 0, bottom: 0, right: 0 },
                inset: 2,
                fillWidth: false,
              }}
              justify="center"
            >
              {endWidget}
            </BaseLayout.VStack>
          )}
        </BaseLayout.View>
        <BaseLayout.NativeDiv>
          {annotationConfig !== 'border-only' && (
            <AnimateHeight height={error ? 'auto' : 0} animateOpacity duration={100}>
              <BaseLayout.NativeDiv height={8} />
              <BaseLayout.HStack gap={2}>
                <Icon name="exclamation-in-circle" rawColor={theme.errorColor} height={12} />
                <Typography rawColor={theme.errorColor}>{error}</Typography>
              </BaseLayout.HStack>
            </AnimateHeight>
          )}
        </BaseLayout.NativeDiv>
        <BaseLayout.NativeDiv>
          {annotationConfig !== 'border-only' && (
            <AnimateHeight height={customAnnotation ? 'auto' : 0} animateOpacity duration={100}>
              <BaseLayout.NativeDiv height={8} />
              <BaseLayout.HStack gap={2}>
                {customAnnotation?.icon && (
                  <Icon
                    name={customAnnotation.icon}
                    rawColor={customAnnotation.color}
                    height={12}
                  />
                )}
                <Typography rawColor={customAnnotation?.color}>
                  {customAnnotation?.name ?? ''}
                </Typography>
              </BaseLayout.HStack>
            </AnimateHeight>
          )}
        </BaseLayout.NativeDiv>
      </BaseLayout.VStack>
    </BaseLayout.VStack>
  )
}
