import { Cb } from 'cb'
import { t } from 'content'
import { BusinessUtils } from 'features/business'
import { MSArray } from 'msutils'
import { permissionRules, PermissionRules } from './config'

export function allowed(action: keyof PermissionRules, role: Cb.EmployeeRole) {
  return permissionRules[action].includes(role)
}

function disabledMessageForAction(action: keyof PermissionRules) {
  return t('This action is only available to {{ X }}.', {
    X: MSArray.print(
      permissionRules[action].map((x) => BusinessUtils.roleToPluralString(x).toLowerCase()),
      { maxNumItems: permissionRules[action].length },
    ),
  })
}

export function getActionProps(action: keyof PermissionRules, role: Cb.EmployeeRole) {
  if (allowed(action, role)) {
    return {}
  } else {
    return {
      disabled: true,
      disabledMessage: disabledMessageForAction(action),
    }
  }
}

export function getMenuOptionProps(action: keyof PermissionRules, role: Cb.EmployeeRole) {
  if (allowed(action, role)) {
    return {}
  } else {
    return {
      disabled: true,
      disabledMessage: disabledMessageForAction(action),
    }
  }
}
