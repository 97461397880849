import { useCallback, useEffect, useState } from 'react'
import { unreachable } from 'msutils/misc'
import { z } from 'zod'
import { MSArray, MSError2 } from 'msutils'
import { Cb } from 'cb'

const unauthedRoutes = [
  /\/sign-up/,
  /\/login/,
  /\/forgot-password/,
  /\/password_reset/,
  /\/employee-sign-up/,
]

export function isUnauthedRoute(route: string) {
  return unauthedRoutes.some((x) => x.test(route))
}

const redirectRoutes = [/\/redirect/, /\/r\/.*/]

export function isRedirectRoute(route: string) {
  return redirectRoutes.some((x) => x.test(route))
}

const authSchema = z.object({
  type: z.enum(['default', 'impersonator']).optional(),
  header: z.string(),
})
export type Auth = z.output<typeof authSchema>

export function getAuth(): (Auth & { storage: 'local' | 'session' }) | null {
  try {
    const sessionAuth = window.sessionStorage.getItem('__AUTH__')
    const localAuth = window.localStorage.getItem('__AUTH__')
    const impersonatorAuth = window.localStorage.getItem('__IMPERSONATOR_AUTH__')

    if ((sessionAuth || localAuth) && impersonatorAuth) {
      // eslint-disable-next-line
      console.error('Cannot have both impersonator auth and regular auth')
      return null
    } else if (sessionAuth) {
      const parsed = authSchema.parse(JSON.parse(sessionAuth))
      return {
        header: parsed.header,
        storage: 'session',
        type: parsed.type ?? 'default',
      }
    } else if (localAuth) {
      const parsed = authSchema.parse(JSON.parse(localAuth))
      return {
        header: parsed.header,
        storage: 'local',
        type: parsed.type ?? 'default',
      }
    } else {
      const parsed = authSchema.parse(JSON.parse(impersonatorAuth ?? ''))
      return {
        header: parsed.header,
        storage: 'local',
        type: parsed.type ?? 'default',
      }
    }
  } catch (e: any) {
    return null
  }
}

export function setAuth(newValue: Auth | null, mode: 'session' | 'local' = 'local') {
  if (newValue) {
    switch (mode) {
      case 'local':
        window.localStorage.setItem('__AUTH__', JSON.stringify(newValue))
        break
      case 'session':
        window.sessionStorage.setItem('__AUTH__', JSON.stringify(newValue))
        break
      default:
        unreachable(mode)
    }
  } else {
    window.localStorage.removeItem('__AUTH__')
    window.sessionStorage.removeItem('__AUTH__')
    window.localStorage.removeItem('__IMPERSONATOR_AUTH__')
  }
}

export function useAuth(): [Auth | null, (x: Auth | null, mode: 'session' | 'local') => void] {
  const [authState, setAuthState] = useState<Auth | null>(getAuth())
  const setAuthSync = useCallback((x: Auth | null, mode: 'session' | 'local') => {
    setAuthState(x)
    setAuth(x, mode)
  }, [])

  return [authState, setAuthSync]
}

const deepLinkSchema = z.string()
export function getDeepLink() {
  const raw = window.localStorage.getItem('__DEEP_LINK__')
  try {
    if (raw) {
      const parsed = deepLinkSchema.parse(raw)
      if (parsed.includes('[')) throw new MSError2('Invalid deep link')
      return parsed
    } else {
      return null
    }
  } catch (e: any) {
    MSError2.report(e.message)
    return null
  }
}

export function setDeepLink(newValue: string | null) {
  if (newValue === null) {
    window.localStorage.removeItem('__DEEP_LINK__')
  } else {
    window.localStorage.setItem('__DEEP_LINK__', newValue)
  }
}

export function useSmartEmailVerificationQuery() {
  const [hasVerified, setHasVerified] = useState(false)
  const emailVerificationsQ = Cb.useListEmailVerifications({
    refetchInterval: hasVerified ? undefined : 2000,
  })

  useEffect(() => {
    if (!hasVerified && emailVerificationsQ.data && MSArray.isNonEmpty(emailVerificationsQ.data)) {
      setHasVerified(true)
    }
  }, [hasVerified, emailVerificationsQ.data])

  return emailVerificationsQ
}
