import { useState } from 'react'
import { Cb, Q } from 'cb'
import Modal from 'compass-local/Modal'
import SearchInput from 'compass-local/SearchInput'
import { t } from 'content'
import { Action } from 'utils/actions'
import { isEmptyStringOrIncludes } from 'utils/string'
import withInjectedQueriesDONOTUSE, { AwaitedQueryInjectedProps } from 'utils/withInjectedQueries'
import { TableData } from 'msutils'
import BigNumber from 'bignumber.js'
import Table from 'compass/layout/Table'
import { theme2 } from 'theme2'

const col = TableData.colSpec<Cb.CostLibraryItem>()
const columns = [
  col(t('Description'), { accessor: ({ row }) => row.description, maximizeWidth: () => true }),
  col.currency(t('Unit cost'), {
    accessor: ({ row }) => (row.unit_cost ? BigNumber(row.unit_cost) : null),
  }),
]

function useQueries() {
  return Q.group({
    costLibraryItems: Cb.useListCostLibraryItems(),
  })
}

type Props = {
  onSuccess: (result: Cb.CostLibraryItem[]) => void
  _queryset: AwaitedQueryInjectedProps<typeof useQueries>
  setInactive?: () => void
}

const ImportFromCostLibrary = Modal.asModal(({ onSuccess, setInactive, _queryset }: Props) => {
  const { costLibraryItems } = _queryset
  const [search, setSearch] = useState('')
  const selections = TableData.useRowState()

  const tableSpec = TableData.build({
    rows: costLibraryItems.filter(
      (x) => search.length < 2 || isEmptyStringOrIncludes(x.description ?? '', search),
    ),
    columns,
    id: (x) => x.id,
  })

  const numItems = costLibraryItems.filter((x) => selections.isActive(x.id)).length

  return (
    <Modal.Body2
      title={t('Import from cost library')}
      actions={[
        Action.button(
          numItems === 0 || numItems === 1 ? t('Add') : t('Add {{ X }} items', { X: numItems }),
          {
            qualify: () => (numItems === 0 ? '' : true),
            onClick: () => {
              onSuccess(costLibraryItems.filter((x) => selections.isActive(x.id)))
              setInactive?.()
            },
          },
        ),
      ]}
    >
      <SearchInput value={search} update={setSearch} autofocus />
      <div className="h-[340px] overflow-y-auto px-5">
        <Table tableData={tableSpec} selection={{ state: selections }} theme={theme2.TableTheme} />
      </div>
    </Modal.Body2>
  )
})

export default withInjectedQueriesDONOTUSE(
  useQueries,
  ImportFromCostLibrary,
  () => null,
  () => null,
)
