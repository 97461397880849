import { cn } from 'msutils/classnames'
import { BaseLayout } from 'compass/baseLayout'
import Typography from 'compass/data/Typography'
import useScreenSize from 'compass/theme/useScreenSize'
import { TabGroupUtils } from './utils'

type BaseOptionUIProps<T extends string> = {
  spec: TabGroupUtils.Option<T>
  onClick: () => void
  isSelected: boolean
  capitalize?: boolean
}

export function BaseOptionUI<T extends string>({
  spec,
  onClick,
  isSelected,
  capitalize = false,
}: BaseOptionUIProps<T>) {
  const sz = useScreenSize()
  return (
    <Typography
      // TODO: need to get rid of this tab variant (invoice detail and bill detail)
      variant={capitalize ? 'section' : undefined}
      className={cn(
        'flex gap-3 items-center py-2 cursor-pointer transition-all duration-100 border-b-2',
        capitalize && 'uppercase',
        sz === 'sm' && 'px-5',
        isSelected
          ? 'text-th-text-focus border-b-th-brown-1'
          : 'text-th-text-secondary hover:text-th-text border-b-transparent',
      )}
      onClick={onClick}
    >
      {spec.icon}
      {spec.name}
      {spec.badge}
    </Typography>
  )
}

type CardOptionUIProps<T extends string> = BaseOptionUIProps<T> & {
  metric: string
}

export function CardOptionUI<T extends string>({
  spec,
  isSelected,
  onClick,
  metric,
}: CardOptionUIProps<T>) {
  return (
    <div className="flex grow max-w-[426px]" onClick={onClick}>
      <div
        className={cn(
          'flex grow gap-3 md:gap-4 items-center transition-all duration-100 p-3 md:p-5 border-t-[3px] cursor-pointer',
          isSelected
            ? 'border-th-brown-1 bg-th-bg-white'
            : 'border-transparent bg-th-coolgrey-3 text-th-text-secondary hover:bg-th-coolgrey-dark3',
        )}
      >
        {spec.icon}
        <div className="vflex gap-1 md:gap-2">
          <Typography variant="label">{spec.name}</Typography>
          {metric && <Typography variant="drawerheader">{metric}</Typography>}
        </div>
      </div>
      {spec.badge}
    </div>
  )
}

type TableOptionUIProps<T extends string> = BaseOptionUIProps<T> & {
  description: string
}

export function TableOptionUI<T extends string>({
  spec,
  isSelected,
  onClick,
  description,
}: TableOptionUIProps<T>) {
  return (
    <div className="flex grow max-w-[426px]" onClick={onClick}>
      <div
        className={cn(
          'flex grow gap-3 md:gap-4 items-center transition-all duration-100 p-3 md:p-5 border-t-[3px] cursor-pointer',
          isSelected
            ? 'border-th-brown-1 bg-th-bg-white'
            : 'border-transparent bg-th-coolgrey-3 text-th-text-secondary hover:bg-th-coolgrey-dark3',
        )}
      >
        {spec.icon}
        <BaseLayout.VStack gap={1}>
          <Typography variant="bodybold">{spec.name}</Typography>
          {description && (
            <Typography
              variant="caption"
              className={cn(
                'transition-all duration-100',
                isSelected ? 'text-th-text-secondary' : 'text-th-coolgrey-1',
              )}
            >
              {description}
            </Typography>
          )}
        </BaseLayout.VStack>
      </div>
      {spec.badge}
    </div>
  )
}
