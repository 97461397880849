import { useEffect, useRef, useState } from 'react'
import { FileUtils } from 'components/misc/FileUtils'
import { Cb } from 'cb'
import { MultiEmailInputUtils } from 'compass-local/MultiEmailInput'
import { F, MSAssert, Zero, MSDate } from 'msutils'
import { unreachable } from 'msutils/misc'
import { Mutator } from 'utils/form-input'
import { definePageSpec, pageContext } from 'utils/form-input/multi-page-component'
import { switchInline } from 'utils/misc'
import { t } from 'content'
import { RichAddressInputUtils } from '../RichAddressInput'
import { EstimateItemsInputUtils } from '../EstimateItemsInput'
import { ContactEmail } from '../ContactEmailSelect/Component'

export namespace EstimateInputUtils {
  type GetDefaultEmailBodyProps = {
    client: Cb.PayerContact
    addressLine1: string
    isUpdate: boolean
    type: Cb.EstimateType
    renofiAd: Cb.RenofiAd | null
  }
  export function getDefaultEmailBody({
    client,
    addressLine1,
    isUpdate,
    type,
  }: GetDefaultEmailBodyProps) {
    const typeName = switchInline(type, {
      project: 'estimate',
      change_order: 'change order',
    })

    return isUpdate
      ? `Hi ${client.name},\n\nHere is the updated ${typeName} for ${addressLine1}. Please follow the link below to approve or reject it.`
      : `Hi ${client.name},\n\nHere is the ${typeName} for ${addressLine1}. Please follow the link below to approve or reject it.`
  }

  export type Context = {
    generalProjectConfig: Cb.GeneralProjectConfig
  }

  export const schema = F.Group({
    validate: (subvals) => {
      const lineItems = subvals.lineItems
      if (!lineItems) {
        throw new Error('Invalid line items')
      }

      if (subvals.coverPageType === 'beam') {
        const coverPage = MSAssert.expectReturn2(subvals.coverPage)(t('Cover page: invalid input'))
        return { ...subvals, coverPageType: 'beam' as const, coverPage }
      } else if (subvals.coverPageType === 'uploaded') {
        const uploadedCoverPage = MSAssert.expectReturn2(subvals.uploadedCoverPage)(
          t('Cover page: invalid input'),
        )
        return { ...subvals, coverPageType: 'uploaded' as const, uploadedCoverPage }
      } else {
        return { ...subvals, coverPageType: 'none' as const }
      }
    },
    spec: {
      number: F.Text({ required: true }),
      client: F.Choice<Cb.PayerContact>(),
      newClientEmail: F.Text(),
      recipientContact: F.Choice<ContactEmail>(),
      address: RichAddressInputUtils.schema,
      displayName: F.Text(),
      title: F.Text(),
      ccEmails: MultiEmailInputUtils.schema,
      additionalInformation: F.Text(),
      files: FileUtils.schema,
      expiration: F.Choice<MSDate>(),
      emailBody: F.Text(),
      lineItems: F.Group({ spec: EstimateItemsInputUtils.schema.spec }),
      showMarkup: F.Toggle(),
      showSubSectionPricing: F.Toggle(),
      showUnitCosts: F.Toggle(),
      showQuantity: F.Toggle(),
      showZeroAmountItems: F.Toggle(),
      hideEstimateTotal: F.Toggle(),
      requireApprovalSignature: F.Toggle(),
      changeOrderAccountingCode: F.Choice<Cb.CostCode>(),
      enableRenofi: F.Toggle(),
      coverPageType: F.DefaultChoice<'beam' | 'uploaded' | 'none'>('none'),
      coverPage: F.Group({
        optional: true,
        spec: {
          title: F.Text({ required: true }),
          body: F.Text({ required: true }),
          photo: FileUtils.schema,
        },
      }),
      uploadedCoverPage: F.Group({
        optional: true,
        spec: {
          file: FileUtils.input({ required: true }),
        },
      }),
    },
    hooks: ({ recipientContact, showMarkup, showSubSectionPricing }) => ({
      onChangeClient: (newValue) => {
        if (newValue !== null && newValue.email) {
          recipientContact.update({ id: newValue.id, name: '', email: newValue.email })
        } else {
          recipientContact.update(null)
        }
      },
      onChangeShowUnitCosts: (newValue) => {
        if (!newValue) {
          showMarkup.update(false)
          showSubSectionPricing.update(false)
        }
      },
    }),
  })

  export function getClientEmailResults(
    validData: F.OutputShape<typeof EstimateInputUtils.schema>,
  ): { overrideEmail?: string; replacementEmail?: string } {
    if (validData.client) {
      if (validData.client.is_guest) {
        if (validData.newClientEmail && validData.newClientEmail !== '') {
          return { replacementEmail: validData.newClientEmail }
        } else if (validData.recipientContact) {
          return { overrideEmail: validData.recipientContact.email }
        } else {
          // Bit implicit, but this covers the case where we're entering an email address for the client and the case where we're selecting a contact
          throw new Error('Must specify a recipient')
        }
      } else {
        return {}
      }
    } else {
      return {}
    }
  }

  export function toApi(
    validData: F.OutputShape<typeof EstimateInputUtils.schema>,
  ): Omit<Cb.EstimateCreate, 'payee_id' | 'type' | 'contract_id'> {
    // Have to cast because of some weirdness with the type being recursive
    const calculationContext = EstimateItemsInputUtils.getPostValidationCalculationContext(
      validData.lineItems as F.OutputShape<typeof EstimateItemsInputUtils.schema>,
    )
    const {
      lineItems,
      number,
      client,
      displayName,
      address,
      expiration,
      additionalInformation,
      files,
      showUnitCosts,
      showMarkup,
      showSubSectionPricing,
      showQuantity,
      showZeroAmountItems,
      requireApprovalSignature,
      title,
      changeOrderAccountingCode,
      coverPageType,
      coverPage,
      uploadedCoverPage,
    } = validData
    const commonArgs = {
      number,
      payer_id: client?.payer_id ?? null,
      display_name: displayName || title,
      address: RichAddressInputUtils.toApi(address),
      expiration_date: expiration?.iso() ?? null,
      additional_information: additionalInformation,
      files: FileUtils.toApi(files),
      show_markup: showMarkup,
      show_sub_section_pricing: showSubSectionPricing,
      show_unit_costs: showUnitCosts,
      show_quantity: showQuantity,
      show_zero_amount_items: showZeroAmountItems,
      hide_total: validData.hideEstimateTotal,
      change_order_accounting_code_id: changeOrderAccountingCode?.id ?? null,
      approval_signature_required: requireApprovalSignature,
      cover_page:
        coverPageType === 'beam'
          ? {
              title: coverPage.title,
              body: coverPage.body,
              cover_photo:
                coverPage.photo.length === 1 && coverPage.photo[0].fileId
                  ? { file_id: coverPage.photo[0].fileId }
                  : null,
            }
          : null,
      uploaded_cover_page:
        coverPageType === 'uploaded' &&
        uploadedCoverPage.file.length === 1 &&
        uploadedCoverPage.file[0].fileId
          ? { file_id: uploadedCoverPage.file[0].fileId }
          : null,
    }

    const getItems = (
      row: typeof lineItems,
    ): Cb.EstimateCreateLineItemNodesItem | Cb.EstimateCreateLineItemNodesItemChildrenItem => {
      if (row.type === 'group') {
        return {
          is_inactive: row.isInactive,
          amount_details: null,
          group_details: {
            cost_code_id: row.costCode?.id ?? null,
            description: row.description,
            hide_line_items: row.hideInnerItems,
            is_section: row.isSection,
          },
          children: row.children.map((x) =>
            getItems({ ...x, children: (x as any).children ?? [] }),
          ),
        }
      } else if (row.type === 'fixed-priced') {
        return {
          is_inactive: row.isInactive,
          group_details: null,
          children: [],
          amount_details: {
            cost_code_id: row.costCode?.id ?? null,
            cost_type_id: row.costType?.id ?? null,
            description: row.description,
            markup_multiplier: row.markup.toFixed(4),
            fee_multiplier: null,
            is_post_subtotal: row.postSubtotal,
            unit_cost: row.unitCost.toFixed(5),
            quantity: row.quantity.toFixed(3),
            unit_type: row.unit ?? null,
          },
        }
      } else if (row.type === 'percent-priced') {
        return {
          is_inactive: row.isInactive,
          group_details: null,
          children: [],
          amount_details: {
            cost_code_id: row.costCode?.id ?? null,
            cost_type_id: row.costType?.id ?? null,
            description: row.description,
            markup_multiplier: '0',
            fee_multiplier: (row.fee || Zero).toFixed(4),
            is_post_subtotal: row.postSubtotal,
            unit_cost: calculationContext.getLeafNodeAmount(row.listId).toFixed(2),
            quantity: '1',
            unit_type: null,
          },
        }
      } else if (row.type === 'buffered-discount') {
        return {
          is_inactive: row.isInactive,
          group_details: null,
          amount_details: null,
          children: [],
          buffered_discount_details: {
            cost_code_id: row.costCode?.id ?? null,
            cost_type_id: row.costType?.id ?? null,
            description: row.description,
            buffer_reverse_multiplier: row.bufferReverseMultiplier.toFixed(2),
            discount_multiplier: row.discountMultiplier.toFixed(2),
            total_buffer_amount: calculationContext.getTotalBufferAmount(row.listId).toFixed(2),
            discount_amount: calculationContext.getDiscountAmount(row.listId).toFixed(2),
            is_commission: row.isCommission,
          },
        }
      } else {
        return unreachable(row.type)
      }
    }

    return {
      ...commonArgs,
      line_item_nodes: (getItems(lineItems).children as any) ?? [],
    }
  }

  export const pageSpec = {
    details: pageContext(),
    amounts: pageContext(),
    'additional-details': pageContext(),
    'cover-page': pageContext(),
    'review-email': pageContext<{ client: Cb.PayerContact }>(),
  }
  export const pageManager = definePageSpec(pageSpec, { id: 'details' })

  export function usePeriodicAutosave(props: {
    autosave: Mutator<any>
    state: F.Input<typeof schema>
  }) {
    const [active, setActive] = useState(false)
    const { autosave, state } = props
    const autosaveWrapper = useRef(autosave)
    autosaveWrapper.current = autosave
    const stateWrapper = useRef(state)
    stateWrapper.current = state
    useEffect(() => {
      if (active) {
        const timeout = setInterval(() => {
          if (stateWrapper.current._controller.hasChanged && F.isValid(stateWrapper.current)) {
            autosaveWrapper.current.mutateAsync(undefined, {
              onSuccess: () => stateWrapper.current._controller.checkpoint(),
            })
          }
        }, 4000)

        return () => {
          clearTimeout(timeout)
        }
      } else {
        return () => undefined
      }
    }, [autosaveWrapper, stateWrapper, active])

    return { active, setActive }
  }

  export type CopySpec = {
    title: string
    submit: string
    saveDraft: string
    typeName: string
  }

  export const waitForAutoSaveMessage = t('Please wait while the estimate is being auto-saved...')

  type GetDefaultCoverPageBodyProps = {
    business: Cb.Business
    employee: Cb.Employee
  }
  export function getDefaultCoverPageBody({ business, employee }: GetDefaultCoverPageBodyProps) {
    return `Thank you for considering ${business.name} as your potential contractor.\n\nLed by experienced professionals, ${business.name} takes pride in the work we do and the services we provide. We are a results-driven company determined to uphold the highest quality of workmanship. We understand the value and importance of being accountable, reliable, and responsive to your needs. We are confident you will enjoy working with us and our dedicated team.\n\nAttached, you will find an itemized construction proposal. We hope this information helps you engage with us and allows us to build your dream project. If you have any questions, please don't hesitate to call or email us anytime. References are available upon request.\n\nThank you again for considering ${business.name}. We look forward to the opportunity to work with you.\n\nSincerely, \n${employee.full_name}`
  }
}
