import { ReactNode, useState } from 'react'
import Typography from 'compass/data/Typography'
import Spinner from 'compass-local/Spinner'
import Breadcrumb, { BreadcrumbSegment } from 'compass-local/Breadcrumb'
import { Collapsable, MSArray } from 'msutils/array'
import useScreenSize from 'compass/theme/useScreenSize'
import SectionBoundary from 'components/boundaries/SectionBoundary'
import WidgetBoundary from 'components/boundaries/WidgetBoundary'
import { Action } from 'utils/actions'
import { SmartTextWrap } from './SmartTextWrap'

type Props = {
  title: string
  subtitle?: string
  banner?: ReactNode
  isLoading?: boolean
  badge?: ReactNode
  breadcrumbs?: Collapsable<BreadcrumbSegment[]>
  actions?: Action.Config[]
  footer?: ReactNode
  mainContent: ReactNode
  leftContent1?: ReactNode
  leftContent2?: ReactNode
  rightContent?: ReactNode
  mount?: ReactNode
}

export function Module({
  banner,
  title,
  isLoading,
  badge,
  breadcrumbs: breadcrumbsUncollapsed,
  actions,
  footer,
  mainContent,
  leftContent1,
  leftContent2,
  rightContent,
  mount,
}: Props) {
  const sz = useScreenSize()
  const breadcrumbs = MSArray.collapse(breadcrumbsUncollapsed ?? [])

  const [actionsContainer, setActionsContainer] = useState<HTMLDivElement | null>(null)
  const actionsContainerHeight = actionsContainer?.clientHeight ?? 0

  return (
    <div className="vflex h-full w-full">
      <div className="vflex gap-5 h-full w-full" style={{ paddingBottom: actionsContainerHeight }}>
        {mount}
        {banner}
        {sz === 'sm' ? (
          <div className="vflex gap-5 px-5 isolate">
            {MSArray.isNonEmpty(breadcrumbs) && <Breadcrumb segments={breadcrumbs} />}
            <div className="flex gap-3 items-center">
              <div className="vflex gap-2">
                <Typography variant="header">{title}</Typography>
                {badge}
              </div>
              {isLoading && <Spinner w="w-5" h="h-5" color="text-th-coolgrey-1" />}
            </div>
          </div>
        ) : (
          <div className="flex gap-5 justify-between isolate items-start">
            <div className="vflex gap-3 px-5">
              {MSArray.isNonEmpty(breadcrumbs) && <Breadcrumb segments={breadcrumbs} />}
              <div className="flex gap-3 items-center">
                {sz === 'md' ? (
                  <div className="vflex gap-2">
                    <Typography variant="header">{title}</Typography>
                    {badge}
                  </div>
                ) : (
                  <>
                    <SmartTextWrap variant="header">{title}</SmartTextWrap>
                    {badge}
                  </>
                )}
                {isLoading && <Spinner w="w-5" h="h-5" color="text-th-coolgrey-1" />}
              </div>
            </div>
            <div className="flex gap-3 items-center">
              {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
            </div>
          </div>
        )}
        <div className={sz === 'sm' || sz === 'md' ? 'vflex gap-5 w-full' : 'flex gap-5'}>
          {!!(leftContent1 || leftContent2) && (
            <div className="vflex lg:w-fit lg:max-w-[320px] lg:min-w-[320px] gap-5">
              <SectionBoundary>
                <div className="vflex gap-5 p-5 bg-th-bg-white">{leftContent1}</div>
              </SectionBoundary>
              {leftContent2 && sz !== 'sm' && sz !== 'md' && (
                <WidgetBoundary>
                  <div className="vflex gap-10 p-5 bg-th-bg-white">{leftContent2}</div>
                </WidgetBoundary>
              )}
            </div>
          )}
          <div className="w-full">
            <SectionBoundary>
              <div className="vflex gap-5 p-5 bg-th-bg-white">{mainContent}</div>
            </SectionBoundary>
          </div>
          {!!rightContent && sz !== 'sm' && sz !== 'md' && (
            <div className="vflex lg:w-fit xl:min-w-[420px] xl:max-w-[420px] lg:max-w-[320px] lg:min-w-[320px]">
              <SectionBoundary>
                <div className="vflex gap-5">{rightContent}</div>
              </SectionBoundary>
            </div>
          )}
        </div>
        {(sz === 'sm' || sz === 'md') && rightContent && (
          <SectionBoundary>
            <div className="vflex gap-5 p-5">{rightContent}</div>
          </SectionBoundary>
        )}
        {(sz === 'sm' || sz === 'md') && leftContent2 && (
          <WidgetBoundary>
            <div className="vflex gap-10 p-5 bg-th-bg-white">{leftContent2}</div>
          </WidgetBoundary>
        )}
      </div>
      {sz === 'sm' && (MSArray.isNonEmpty(actions ?? []) || footer) && (
        <>
          <div className="footer-actions shadow-reverse" ref={setActionsContainer}>
            {footer}
            {MSArray.isNonEmpty(actions ?? []) && (
              <div className="vflex gap-3">
                {actions
                  ?.slice()
                  .reverse()
                  .map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
