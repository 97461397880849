import { useState } from 'react'
import { cn } from 'msutils/classnames'
import { InputBaseContext } from 'compass-local/InputBase'
import Typography from 'compass/data/Typography'
import EllipsisMenu from 'components/misc/EllipsisMenu'
import { MSArray } from 'msutils'
import { unreachable } from 'msutils/misc'
import { useDrag, useDrop } from 'react-dnd'
import { t } from 'content'
import Tooltip from 'compass/layout/Tooltip'
import {
  Cell,
  DragControl,
  ExpandControl,
  getAlignment,
  getBorderForStyle,
  getPaddingForStyle,
  getPropFromMeta,
  getVerticalAlignmentForStyle,
  getWhitespace,
  HrefIndicator,
  Row,
  SelectControl,
  Style,
  TD,
  TR,
} from '../../internal-utils'
import { TableUtils as Utils } from '../../utils'

type Props<TData, TCtx> = {
  spec: ReturnType<typeof Utils.useSpec<TData, TCtx>>
  ctx: TCtx
  style: Style
  row: Row<TData>
  index: number
}

export default function TableRow<TData, TCtx>({
  spec,
  ctx,
  row,
  style,
  index,
}: Props<TData, TCtx>) {
  const { data, depth, parent, hasChildren } = row
  const {
    rowActions: getRowActions,
    getId,
    href: getHref,
    state,
    getSelectDisabledReason,
    hidden: getHidden,
    allowSelect,
    disableCollapse,
    dragId: getDragId,
    dropIds: getDropIds,
    handleMove,
    rows,
    rowFooter: getRowFooter,
    rowHeader: getRowHeader,
    isInactive: getIsInactive,
    setActive,
  } = spec
  const columns = spec.columns.filter(
    (c) => !c.dynamic?.omit?.(ctx) && !c.dynamic?.mobileOnly?.(ctx),
  )
  const cellMeta = { row: data, depth }
  const isLastInSection = !!(
    rows.at(index + 1)?.depth === depth - 1 ||
    (depth > 0 && index === rows.length - 1)
  )
  const showBorder =
    style === 'item-list'
      ? index !== rows.length - 1
      : style === 'estimate'
      ? index !== rows.length - 1 && ((!hasChildren && depth === 0) || isLastInSection)
      : style === 'preview'
      ? true
      : style === 'input-table'
      ? false
      : unreachable(style)
  const rowId = getId(data, index)
  const rowMeta = {
    ctx,
    depth,
    index,
    id: rowId,
    parent: parent?.data ?? null,
  }
  const href = getHref?.(data, rowMeta)
  // TODO: in theory, this needs to be recursive (like getMetaForParent(row, rows))
  // but it should be fine for now
  const isInactive = getIsInactive?.(data, rowMeta)
  const parentInactive =
    parent &&
    getIsInactive?.(parent?.data, {
      ctx,
      depth: depth - 1,
      index: parent.index,
      parent: null,
      id: getId(parent.data, parent.index),
    })
  const rowActions = MSArray.collapse(getRowActions?.(data, rowMeta) ?? [])
  const expandChildren = state.expanded.isActive(rowId)
  const expanded = !parent || state.expanded.isActive(getId(parent.data, parent.index))
  const selected = state.selected.isActive(rowId)
  const border = style === 'input-table' ? { color: 'grey' as const, width: 'sm' as const } : null

  const dragId = getDragId?.(data, rowMeta) ?? '<none>'
  const dropIds = getDropIds?.(data, rowMeta) ?? []

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: dragId,
      item: { id: rowId },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    }),
    [dragId, rowId],
  )

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: dropIds,
      collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
      drop: (item: { id: string }) => {
        if (item.id !== rowId) {
          const fromRowIndex = rows.findIndex((x, j) => item.id === getId(x.data, j))
          if (fromRowIndex >= 0) {
            const fromRow = rows[fromRowIndex]
            handleMove?.(
              {
                row: fromRow.data,
                index: fromRowIndex,
                depth: fromRow.depth,
                id: item.id,
                parent: fromRow.parent?.data ?? null,
              },
              { row: row.data, id: rowId, index, depth: row.depth, parent: parent?.data ?? null },
            )
          } else {
            // console.log('Could not find row source to move', item.id)
          }
        }
      },
    }),
    [dropIds, rowId, handleMove, rows, row, index, rowId, parent],
  )

  const dndRef = (el: any) => {
    drop(el)
    dragPreview(el)
  }
  const [hovered, setHovered] = useState(false)

  if (!expanded) return null
  if (getHidden && getHidden(row.data, rowMeta)) return null

  const rowHeader = getRowHeader?.(row.data, rowMeta)

  return (
    <>
      {rowHeader?.component && (!(isInactive || parentInactive) || rowHeader.showWhenInactive) && (
        <TR className="bg-th-bg-slate" key={`header__${rowId}`}>
          <TD border={null} />
          <TD colSpan={columns.length} border={null}>
            {rowHeader.component}
          </TD>
          <TD border={null} />
        </TR>
      )}
      {!isInactive && !parentInactive ? (
        <TR
          href={href}
          rowRef={dndRef}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={cn(
            showBorder && getBorderForStyle(style),
            href && 'cursor-pointer hover:bg-th-bg-slate',
            isOver && canDrop && 'opacity-50',
            getVerticalAlignmentForStyle(style),
          )}
        >
          <TD border={border} minimize>
            {(allowSelect || (hasChildren && !disableCollapse) || dragId !== '<none>') && (
              <div className={cn('flex gap-1 items-center', style === 'input-table' && 'pr-2')}>
                {dragId !== '<none>' && (
                  <DragControl dragRef={drag} isDragging={isDragging} visible={hovered} />
                )}
                {allowSelect && (
                  <SelectControl
                    selected={selected}
                    setSelected={(newValue) => state.selected.set(rowId, newValue)}
                    disabledMessage={getSelectDisabledReason?.(cellMeta, ctx) ?? null}
                  />
                )}
                {hasChildren && !disableCollapse && (
                  <ExpandControl
                    expanded={expandChildren}
                    setExpanded={(newValue) => state.expanded.set(rowId, newValue)}
                  />
                )}
              </div>
            )}
          </TD>
          {columns.map((c, j) => {
            const tooltip = getPropFromMeta(c.tooltip, { ctx, cellMeta })
            const borderStyle = getPropFromMeta(c.border, { ctx, cellMeta })
            const indent = j === columns.findIndex((c2) => !c2.dynamic?.omit?.(ctx)) ? depth : 0

            if (getPropFromMeta(c.void, { ctx, cellMeta })) return null
            const nextNonVoidIndex = columns.findIndex(
              (x, k) => k > j && !getPropFromMeta(x.void, { ctx, cellMeta }),
            )
            const colSpan =
              nextNonVoidIndex < 0
                ? 1
                : // don't include colspan for omitted columns
                  nextNonVoidIndex -
                  j -
                  columns.filter((x, k) => k > j && k < nextNonVoidIndex && x.dynamic?.omit?.(ctx))
                    .length

            return (
              <TD
                colSpan={colSpan}
                key={j}
                border={borderStyle ?? border}
                maximize={getPropFromMeta(c.size?.maximize, { ctx, cellMeta })}
                minimize={getPropFromMeta(c.size?.minimize, { ctx, cellMeta })}
              >
                <InputBaseContext align={c.align}>
                  <Tooltip
                    message={tooltip}
                    inactive={!tooltip || getPropFromMeta(c.hidden, { ctx, cellMeta })}
                  >
                    <div
                      style={{ paddingLeft: indent ? `${indent * 28}px` : undefined }}
                      className={cn(
                        getPropFromMeta(c.size?.maxWidth, { ctx, cellMeta }),
                        'overflow-hidden',
                      )}
                    >
                      <Typography
                        variant={getPropFromMeta(c.bold, { ctx, cellMeta }) ? 'bodybold' : 'body'}
                        className={cn(
                          '!block text-th-text',
                          getPaddingForStyle(style),
                          getAlignment(c.align),
                          getPropFromMeta(c.size?.minWidth, { ctx, cellMeta }),
                          getWhitespace(getPropFromMeta(c.whitespace, { ctx, cellMeta })) ??
                            'whitespace-pre-wrap',
                        )}
                      >
                        <Cell column={c} props={{ ctx, cellMeta }} />
                      </Typography>
                    </div>
                  </Tooltip>
                </InputBaseContext>
              </TD>
            )
          })}
          <TD border={border} minimize>
            {!!(href || MSArray.isNonEmpty(rowActions.filter((x) => x.type !== 'divider'))) && (
              <div className={cn('flex gap-1 items-center', style === 'input-table' && 'pl-2.5')}>
                {!!href && <HrefIndicator />}
                {MSArray.isNonEmpty(rowActions) && (
                  <EllipsisMenu sizeDONOTUSE="small" options={rowActions} />
                )}
              </div>
            )}
          </TD>
        </TR>
      ) : !parentInactive ? (
        <tr key={`inactive__row__${rowId}`}>
          <td colSpan={columns.length + 2} className="m-0 p-0">
            <Tooltip message={t('Show row')}>
              <div
                className="bg-th-orange-light2 h-1 cursor-pointer"
                onClick={() => setActive?.(data, rowMeta)}
              />
            </Tooltip>
          </td>
        </tr>
      ) : null}
      {(!expandChildren || !row.hasChildren) &&
        row.path.map((parentIndex) => {
          const parentRow = rows[parentIndex]
          const parentId = getId(parentRow.data, parentIndex)
          const nextAtDepth = rows.findIndex(
            (x, j) => j > parentIndex && x.depth === parentRow.depth,
          )
          // show my own footer, show footer if there is no next at depth, show a footer if this is the row before going back to some specific depth
          const isDescendentOfLastInSection =
            parentIndex === index ||
            (nextAtDepth < 0 && index === rows.length - 1) ||
            nextAtDepth === index + 1
          const footer = isDescendentOfLastInSection
            ? getRowFooter?.(parentRow.data, {
                parent: parentRow.parent?.data ?? null,
                index: parentIndex,
                depth: parentRow.depth,
                id: parentId,
                ctx,
              })
            : null

          if (
            footer?.component &&
            (parentIndex !== index || expandChildren) &&
            (!parentInactive || footer.showWhenInactive)
          ) {
            return (
              <TR className="bg-th-bg-slate" key={`footer__${parentId}`}>
                <TD border={null} />
                <TD colSpan={columns.length} border={null}>
                  <div style={{ paddingLeft: `${(parentRow.depth + 1) * 28}px` }}>
                    {footer.component}
                  </div>
                </TD>
                <TD border={null} />
              </TR>
            )
          } else {
            return null
          }
        })}
    </>
  )
}
