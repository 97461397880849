import { createContext, ReactNode, useContext, useId, useState } from 'react'
import { download as downloadFile } from 'utils/file'
import { F, MSArray, uploadFile } from 'msutils'
import Icon from 'compass/data/Icon'
import Typography from 'compass/data/Typography'
import { t, Trans } from 'content'
import { useDropzone } from 'react-dropzone'
import { Action } from 'utils/actions'
import { useQuery } from '@tanstack/react-query'
import Spinner from 'compass/data/Spinner'
import PDF from 'compass-local/legacy/PDF'
import Image from 'compass-local/legacy/Image'
import { unreachable } from 'msutils/misc'
import Modal from 'compass-local/Modal'
import useFormProps from 'utils/useFormProps'
import { MSLayout } from 'utils/layout'
import Button from 'compass/data/Button'
import { theme2 } from 'theme2'
import FilePreview from 'compass-local/FilePreview'
import { cn } from 'msutils/classnames'
import { base } from 'utils/actions/base'
import { Qualified, ThemeProps } from 'utils/actions/types'
import { noOpMount } from 'utils/actions/internal-utils'

export namespace FileUtils {
  type TFileInputContext = {
    uploadFile: (file: File) => Promise<string>
  }

  const FileInputContext = createContext<TFileInputContext | undefined>(undefined)

  export function useFileInputContext() {
    const ctx = useContext(FileInputContext)
    if (ctx === undefined)
      throw new Error('File Input Context must be used within a File Input Provider')
    return ctx
  }

  export function FileInputProvider({
    ctx,
    children,
  }: {
    ctx: TFileInputContext
    children: ReactNode
  }) {
    return <FileInputContext.Provider value={ctx}>{children}</FileInputContext.Provider>
  }

  type FileValue = { type: 'ref'; name: string; downloadId: string } | { type: 'file'; file: File }

  type DownloadFn = (fileId: string) => Promise<Blob | [Blob, string]>

  type FileQueryProps = {
    value: FileValue
    download: DownloadFn
  }
  export function useFileQuery({ value, download }: FileQueryProps) {
    const id = useId()
    return useQuery(
      value.type === 'ref'
        ? {
            queryKey: ['STABLE-FILE', value.downloadId],
            queryFn: async (): Promise<[Blob, string]> => {
              const data = await download(value.downloadId)
              return Array.isArray(data) ? data : [data, value.name]
            },
          }
        : {
            queryKey: ['UNQUERIED-FILE', id],
            queryFn: (): Promise<[Blob, string]> => Promise.resolve([value.file, value.file.name]),
          },
    )
  }

  type DownloadButtonProps = {
    value: FileValue
    download: DownloadFn
    thickness?: number
  }
  export function DownloadButton({ value, download, thickness }: DownloadButtonProps) {
    const q = useFileQuery({ value, download })

    return (
      <Icon
        name="download"
        color="text-th-brown-2"
        cursor="pointer"
        thickness={thickness}
        onClick={() =>
          q.data ? downloadFile(q.data[0] as File, q.data[1].split('.')[0]) : undefined
        }
      />
    )
  }

  // ----- Loader stuff -----
  type LoaderProps = {
    value: FileValue
    download: DownloadFn
    style?: 'thumbnail' | 'preview'
  }
  export function Loader({ value, download, style = 'preview' }: LoaderProps) {
    const q = useFileQuery({ value, download })
    if (q.status === 'success') {
      const [file, name] = q.data
      if (file.type === 'application/pdf') {
        return (
          <PDF
            noShadow={style === 'thumbnail'}
            noBorder={style === 'thumbnail'}
            noPages={style === 'thumbnail'}
            file={file as File}
          />
        )
      } else if (file.type === 'image/png' || file.type === 'image/jpeg') {
        return (
          <Image
            noBorder={style === 'thumbnail'}
            noShadow={style === 'thumbnail'}
            file={file as File}
            title={name}
          />
        )
      } else {
        return (
          <div className="w-full h-full flex items-center justify-center bg-white text-th-coolgrey-1">
            <Icon name="document" height={40} />
          </div>
        )
      }
    } else {
      return (
        <div className="w-full h-full flex items-center justify-center bg-white text-th-coolgrey-1">
          {q.status === 'error' ? (
            <Icon name="exclamation-in-triangle" />
          ) : q.status === 'loading' ? (
            <Spinner size="medium" />
          ) : (
            unreachable(q)
          )}
        </div>
      )
    }
  }

  type PopupViewProps = {
    value: FileValue
    download: DownloadFn
    setInactive: () => void
  }
  export const PopupView = Modal.asModal(({ value, download, setInactive }: PopupViewProps) => {
    const name = value.type === 'ref' ? value.name : value.file.name

    return (
      <Modal.Body title={name} setInactive={setInactive}>
        <Loader value={value} download={download} />
      </Modal.Body>
    )
  })

  type WithPreviewProps = {
    value: FileValue
    download: DownloadFn
    children: ReactNode
  }

  export function WithPreview({ value, download, children }: WithPreviewProps) {
    const previewProps = useFormProps()
    return (
      <>
        <PopupView value={value} download={download} {...previewProps} />
        <div onClick={previewProps.setActive} className="cursor-pointer">
          {children}
        </div>
      </>
    )
  }

  type GalleryItemProps = {
    value: FileValue
    download: DownloadFn
    hideDownloadINTERNAL?: boolean
    removeINTERNAL?: () => void
    disablePreviews?: boolean
  }
  function GalleryItem({
    value,
    download,
    disablePreviews,
    hideDownloadINTERNAL,
    removeINTERNAL,
  }: GalleryItemProps) {
    const modalFormProps = useFormProps()

    return (
      <div className="w-[120px] p-2 rounded-2 vflex gap-2 bg-th-bg-slate">
        <div className="flex items-center justify-between gap-1">
          <Typography variant="label" className="truncate">
            {value.type === 'file' ? value.file.name : value.name}
          </Typography>
          {!hideDownloadINTERNAL && <DownloadButton value={value} download={download} />}
          {!!removeINTERNAL && (
            <Icon
              name="x"
              height={12}
              thickness={2.5}
              color="text-th-brown-2"
              cursor="pointer"
              onClick={removeINTERNAL}
            />
          )}
        </div>
        <PopupView {...modalFormProps} value={value} download={download} />
        <div
          className="w-full pt-[100%] relative cursor-pointer"
          onClick={modalFormProps.setActive}
        >
          <div className="absolute inset-0 overflow-hidden rounded-4 bg-white">
            {!disablePreviews ? (
              <Loader value={value} download={download} style="thumbnail" />
            ) : (
              <div className="h-full flex items-center justify-center text-th-coolgrey-1">
                <Icon name="document" height={40} />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  // ----- Gallery stuff -----
  type GalleryProps = {
    files: FileValue[]
    download: DownloadFn
    hideDownloadINTERNAL?: boolean
    removeINTERNAL?: (index: number) => void
    hideCollapseINTERNAL?: boolean
  }

  const CollapseCount = 4
  const MaxPreviews = 20

  export function Gallery({
    files,
    download,
    hideDownloadINTERNAL,
    removeINTERNAL,
    hideCollapseINTERNAL,
  }: GalleryProps) {
    const [collapsed, setCollapsed] = useState(!hideCollapseINTERNAL)
    const collapsedFiles = collapsed ? files.slice(0, CollapseCount) : files
    return (
      <div className="vflex gap-2">
        <div className="flex gap-2 flex-wrap">
          {collapsedFiles.map((f, i) => (
            <GalleryItem
              key={f.type === 'ref' ? f.downloadId : `${i}-${f.file.name}`}
              value={f}
              download={download}
              hideDownloadINTERNAL={hideDownloadINTERNAL}
              removeINTERNAL={removeINTERNAL ? () => removeINTERNAL(i) : undefined}
              disablePreviews={files.length > MaxPreviews}
            />
          ))}
        </div>
        {files.length <= CollapseCount || hideCollapseINTERNAL ? null : (
          <div className="w-min">
            <Button theme={theme2.ButtonThemeTextOrange} onClick={() => setCollapsed((x) => !x)}>
              {collapsed ? t('Show more') : t('Show less')}
            </Button>
          </div>
        )}
      </div>
    )
  }

  type ListItemProps = {
    value: FileValue
    download: DownloadFn
  }

  export function ListItem({ value, download }: ListItemProps) {
    const previewProps = useFormProps()
    return (
      <div className="flex gap-4 justify-between items-center truncate">
        <PopupView value={value} download={download} {...previewProps} />
        <div className="flex gap-2 items-center truncate">
          <Icon name="paperclip" color="text-th-brown-2" />
          <Typography
            variant="bodybold"
            className="text-th-brown-1 truncate cursor-pointer"
            onClick={previewProps.setActive}
          >
            {value.type === 'ref' ? value.name : value.file.name}
          </Typography>
        </div>
        <DownloadButton value={value} download={download} thickness={3} />
      </div>
    )
  }

  type ListProps = {
    files: FileValue[]
    download: DownloadFn
  }

  export function List({ files, download }: ListProps) {
    return (
      <div className="vflex gap-3">
        {files.map((f, i) => (
          <ListItem key={i} value={f} download={download} />
        ))}
      </div>
    )
  }

  type AttachmentsSectionProps = {
    files: FileValue[]
    download: DownloadFn
    zip?: () => Promise<Blob | [Blob, string]>
  }

  export function AttachmentsSection({ files, download, zip }: AttachmentsSectionProps) {
    return (
      <MSLayout.Section2
        title={t('Attachments')}
        actions2={[
          Action.download(t('ZIP'), {
            qualify: () => (files.length === 0 ? null : !zip ? null : zip),
            getFile: async (z) => {
              const downloadedZip = await z()
              const [filename, file] = Array.isArray(downloadedZip)
                ? downloadedZip
                : ['download', downloadedZip]
              return [filename, file as File] as [string, File]
            },
          }),
        ]}
      >
        {files.length > 0 ? (
          <FileUtils.Gallery files={files} download={download} />
        ) : (
          <Typography className="text-th-text-disabled text-center">{t('No items.')}</Typography>
        )}
      </MSLayout.Section2>
    )
  }

  // ----- Input stuff -----
  export type InputValue =
    | { type: 'file'; file: File; isLoading: true; fileId: null }
    | { type: 'file'; file: File; isLoading: false; fileId: string }
    | {
        type: 'ref'
        file: null
        fileId: string
        downloadId: string
        isLoading: false
        name: string
      }

  export function input(props?: { required?: boolean }) {
    return F.Scalar<InputValue[], (InputValue & { fileId: string })[]>({
      initValue: [],
      validate: (val) => {
        if (val.some((x) => x.isLoading)) {
          throw new Error('File is loading. Please try again.')
        }
        const valids = val.flatMap((x) => (x.fileId ? x : []))
        if (props?.required && valids.length === 0) {
          throw new Error('Required')
        }
        return valids
      },
    })
  }

  export const schema = input()

  const FileTypeSets = {
    image: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
    },
    csv: { 'text/csv': ['.csv'] },
    pdf: { 'application/pdf': ['.pdf'] },
  }

  function getAllowedExtensionsForFileTypes(fileTypes: keyof typeof FileTypeSets) {
    return Object.values(FileTypeSets[fileTypes]).flat()
  }

  const MaxFileSize = 10 * 1024 * 1024 // 10 MB

  async function handleFileUpload({
    update,
    oldValue,
    newFiles,
    store,
  }: {
    update: (newValues: InputValue[]) => void
    oldValue: InputValue[]
    newFiles: File[]
    store: (file: File) => Promise<string>
  }) {
    update(
      oldValue.concat(
        newFiles.map((x) => ({ type: 'file', file: x, isLoading: true, fileId: null })),
      ),
    )
    const uploadedFiles = await Promise.all(
      newFiles.map((f) => store(f).then((u) => ({ file: f, fileId: u }))),
    )
    update(
      oldValue.concat(
        uploadedFiles.map((x) => ({
          type: 'file',
          file: x.file,
          fileId: x.fileId,
          isLoading: false,
        })),
      ),
    )
  }

  type DropzoneUIProps = {
    description?: string
  }

  export function DropzoneUI({ description }: DropzoneUIProps) {
    return (
      <div className="relative p-5 border border-dashed border-th-warmgrey-1 rounded-8 inline-block w-full h-full flex justify-center items-center">
        <div className="vflex gap-3 items-center text-center">
          <Icon name="document-with-plus" color="text-th-orange-dark1" height={20} />
          <div className="vflex gap-1">
            <Typography variant="bodybold" className="text-th-brown-2">
              <Trans
                tKey="Drag and drop, or <X>click to upload</X>"
                X={
                  <Typography variant="bodybold" className="text-th-orange-dark1 cursor-pointer" />
                }
              />
            </Typography>
            {description && <Typography className="text-th-brown-2">{description}</Typography>}
          </div>
        </div>
      </div>
    )
  }

  type DropzoneProps = {
    fileTypes?: keyof typeof FileTypeSets
    value: InputValue[]
    update: (newValues: InputValue[]) => void
    maxFiles?: number
    description?: string
    error?: string | null
    children?: ReactNode
  }

  export function Dropzone({
    fileTypes = 'image',
    value,
    update,
    maxFiles = 100,
    description,
    error: externalError,
    children,
  }: DropzoneProps) {
    const ctx = useFileInputContext()
    const [error, setError] = useState<string | null>(null)

    const { getRootProps, getInputProps } = useDropzone({
      onDropAccepted: (newFiles: File[]) => {
        setError(null)
        handleFileUpload({ oldValue: value, newFiles, store: ctx.uploadFile, update })
      },
      onDropRejected: (files) => {
        if (files.length > maxFiles)
          setError(t('Please upload at most {{ X }} files', { X: maxFiles }))
        else if (files.some((f) => f.file.size > MaxFileSize))
          setError(t('Files must be no larger than 10 MB in size'))
        else {
          setError(
            t('File type is not supported. Please use one of these types: {{ X }}', {
              X: getAllowedExtensionsForFileTypes(fileTypes).join(', '),
            }),
          )
        }
      },
      maxFiles,
      disabled: maxFiles === 0,
      maxSize: MaxFileSize,
      accept: FileTypeSets[fileTypes],
    })

    return (
      <div className="vflex gap-4 h-full">
        <div {...getRootProps()} className="h-full">
          <input {...getInputProps()} />
          {children ?? <DropzoneUI description={description} />}
        </div>
        {!externalError && error && (
          <Typography className="text-th-red-warning">{error}</Typography>
        )}
        {externalError && <Typography className="text-th-red-warning">{externalError}</Typography>}
      </div>
    )
  }

  type Props = {
    fileTypes?: keyof typeof FileTypeSets
    title?: string
    required?: boolean
    description?: string
    value: InputValue[]
    update: (newValues: InputValue[]) => void
    download: DownloadFn
    maxFiles?: number
    error?: string | null
  }

  export function Input({
    title = t('Add attachments'),
    required,
    fileTypes = 'image',
    description,
    value,
    update,
    download,
    error,
    maxFiles = 100,
  }: Props) {
    const ctx = useFileInputContext()

    return (
      <div className="vflex gap-2">
        <div className="flex gap-4 justify-between items-center">
          <div className="flex gap-2 items-baseline">
            <Typography variant="label">{title}</Typography>
            {!required && (
              <Typography variant="label" className="text-th-text-secondary">
                {t('Optional')}
              </Typography>
            )}
          </div>
          {value.length > 0 && (
            <div className="w-fit">
              <Action.Mount
                {...Action.button(t('Add'), {
                  icon: 'plus',
                  theme: 'orange-text',
                  qualify: () =>
                    value.length >= maxFiles
                      ? t('You may upload at most {{ X }} files', { X: maxFiles })
                      : true,
                  onClick: async () => {
                    const files = await uploadFile({ single: false })
                    if (files) {
                      handleFileUpload({
                        oldValue: value,
                        newFiles: files,
                        store: ctx.uploadFile,
                        update,
                      })
                    }
                  },
                })}
              />
            </div>
          )}
        </div>
        {value.length > 0 ? (
          <div className="vflex gap-2">
            <Gallery
              files={value}
              download={download}
              hideDownloadINTERNAL
              hideCollapseINTERNAL
              removeINTERNAL={(i) => update?.(MSArray.removeAt(value, i))}
            />
          </div>
        ) : (
          <Dropzone
            fileTypes={fileTypes}
            value={value}
            update={update}
            maxFiles={maxFiles - value.length}
            error={error}
          >
            <DropzoneUI description={description} />
          </Dropzone>
        )}
      </div>
    )
  }

  type SingleInputProps = {
    fileTypes?: keyof typeof FileTypeSets
    title?: string | null
    optional?: boolean
    description?: string
    fullHeight?: boolean
    value: InputValue[]
    update: (newValues: InputValue[]) => void
    download: DownloadFn
  }

  export function SingleInput({
    title,
    optional,
    fileTypes = 'image',
    fullHeight,
    description,
    value,
    update,
    download,
  }: SingleInputProps) {
    const title_ = title === undefined ? t('Add attachments') : title
    return (
      <div className={cn('vflex gap-2', fullHeight && 'h-full')}>
        {title_ && (
          <div className="flex gap-4 justify-between items-center">
            <div className="flex gap-2 items-baseline">
              <Typography variant="label">{title_}</Typography>
              {optional && (
                <Typography variant="label" className="text-th-text-secondary">
                  {t('Optional')}
                </Typography>
              )}
            </div>
          </div>
        )}
        {value.length > 0 ? (
          <div className="vflex gap-2">
            <div className="flex gap-5 justify-between">
              <FilePreview
                leftIcon="paperclip"
                name={value[0].type === 'ref' ? value[0].name : value[0].file.name}
              />
              <Icon name="x" color="text-th-brown-2" height={14} onClick={() => update([])} />
            </div>
            <Loader value={value[0]} download={download} />
          </div>
        ) : (
          <Dropzone fileTypes={fileTypes} value={value} update={update} maxFiles={1 - value.length}>
            <DropzoneUI description={description} />
          </Dropzone>
        )}
      </div>
    )
  }

  export function fromApi(
    list: { id: string; file_id: string; original_file_name: string }[],
  ): InputValue[] {
    return list.map((f) => ({
      type: 'ref',
      fileId: f.file_id,
      downloadId: f.id,
      name: f.original_file_name,
      isLoading: false,
      file: null,
    }))
  }

  export function toApi(valids: F.OutputShape<typeof schema>): { file_id: string }[] {
    return valids.map((x) => ({ file_id: x.fileId }))
  }

  function useFileActionProps() {
    const ctx = useFileInputContext()
    return { store: ctx.uploadFile }
  }

  type FileProps = ThemeProps & {
    maxFiles?: number
    qualify?: () => Qualified<true>
    onUpload: (files: InputValue[]) => void
  }

  export function action(name: string, props: FileProps) {
    const maxFiles = props.maxFiles ?? 1
    return base(name, {
      ...props,
      qualify: props.qualify ?? (() => true),
      useMountCtx: useFileActionProps,
      getMountProps: () => ({}),
      Mount: noOpMount,
      onClick: async ({ mountCtx }) => {
        const files = await uploadFile({ single: maxFiles <= 1 })
        if (files) {
          const filesArray = Array.isArray(files) ? files : [files]
          handleFileUpload({
            update: props.onUpload,
            oldValue: [],
            newFiles: filesArray,
            store: mountCtx.store,
          })
        }
      },
    })
  }

  export function downloadAction({ value, download }: { value: FileValue; download: DownloadFn }) {
    const name = value.type === 'ref' ? value.name : value.file.name
    return Action.download(name, {
      getFile: async () => {
        if (value.type === 'file') {
          return [name, value.file]
        } else {
          const downloadResult = await download(value.downloadId)
          if (Array.isArray(downloadResult)) {
            return [downloadResult[1], downloadResult[0]]
          } else {
            return [name, downloadResult]
          }
        }
      },
    })
  }
}
