import { ReactNode, useState } from 'react'
import { useSizeOfText } from 'msutils/dom-utils'
import { BaseInputUtils } from './baseUtils'
import InputContainer from './InputContainer'
import { InputContainerUtils } from './InputContainer/utils'

type Props = BaseInputUtils.Props<string> & {
  title?: string
  subtitle?: string
  disableTrackTextHeight?: boolean
  minHeight?: number | null
  maxHeight?: number | null
  theme?: InputContainerUtils.Theme
  optional?: boolean
  maxLength?: number
  placeholder?: string
  customAnnotation?: InputContainerUtils.CustomAnnotation | null
  endWidget?: ReactNode
  infoTooltip?: ReactNode
}

export default function TextArea({
  value,
  update,
  focus,
  blur,
  disabled,
  hidden,
  error,
  title,
  subtitle,
  disableTrackTextHeight,
  minHeight,
  maxHeight,
  theme: theme_,
  optional,
  maxLength,
  placeholder,
  customAnnotation,
  endWidget,
  infoTooltip,
}: Props) {
  const { defaultTheme } = InputContainerUtils.useContext()
  const theme = theme_ ?? defaultTheme

  const [inputRef, setInputRef] = useState<HTMLTextAreaElement | null>(null)
  const sizeOfText = useSizeOfText(value || placeholder || '', {
    classNames: BaseInputUtils.ClassNames,
    fixedWidth: inputRef?.clientWidth,
    inset: [theme.inset[0] * 4, theme.inset[1] * 4],
  })
  const isFocused = document.activeElement === inputRef

  const height = !disableTrackTextHeight
    ? Math.max(
        Math.min(maxHeight === null ? Infinity : maxHeight ?? 320, sizeOfText.height),
        minHeight ?? 100,
      )
    : undefined

  if (hidden) return null
  return (
    <InputContainer
      title={title}
      subtitle={subtitle}
      state={disabled ? 'disabled' : isFocused ? 'focused' : 'idle'}
      error={error ?? null}
      theme={theme}
      optional={optional ?? false}
      customAnnotation={customAnnotation}
      infoTooltip={infoTooltip}
      endWidget={endWidget}
    >
      {/* textareas get incorrectly sized by their parents for some reason, so we set the size here instead */}
      <div style={{ height: height ?? undefined }}>
        <textarea
          value={value}
          disabled={disabled}
          onChange={(e) => update?.(e.target.value)}
          onFocus={focus}
          onBlur={blur}
          onKeyDown={(e) => {
            if (e.code === 'Escape') {
              inputRef?.blur()
              e.stopPropagation()
            }
          }}
          ref={setInputRef}
          placeholder={placeholder}
          maxLength={maxLength}
          style={{
            paddingTop: theme.inset[0] * 4,
            paddingBottom: theme.inset[0] * 4,
            paddingRight: theme.inset[1] * 4,
            paddingLeft: theme.inset[1] * 4,
            border: 0,
            margin: 0,
            height: '100%',
            width: '100%',
            background: 'transparent',
            cursor: disabled ? 'not-allowed' : undefined,
          }}
          className={BaseInputUtils.ClassNames}
        />
      </div>
    </InputContainer>
  )
}
