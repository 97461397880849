import { Cb } from 'cb'
import { cn } from 'msutils/classnames'
import Divider from 'compass-local/Divider'
import MultiEmailInput from 'compass-local/MultiEmailInput'
import Toggle from 'compass-local/Toggle'
import { t } from 'content'
import RenofiAdCard from 'features/renofi/RenofiAdCard'
import { F, Zero } from 'msutils'
import useScreenSize from 'compass/theme/useScreenSize'
import { MSForm } from 'utils/form'
import { Mutator } from 'utils/form-input'
import { MSLayout } from 'utils/layout'
import useFormProps from 'utils/useFormProps'
import File from 'compass-local/File'
import Metric from 'compass-local/Metric'
import { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import { Action } from 'utils/actions'
import ContactEmailSelect from 'components/inputs/ContactEmailSelect'
import { LinkEXPERIMENTAL } from 'components/misc/LinkEXPERIMENTAL'
import Tooltip from 'compass/layout/Tooltip'
import { EmailInput } from 'compass/input/TextInput'
import TextArea from 'compass/input/TextArea'
import { EstimateInputUtils as Utils } from '../utils'

type Props = {
  state: F.Input<typeof Utils.schema>
  calculationContext: EstimateItemsInputUtils.EstimateCalculationContext
  copy: Utils.CopySpec
  submit: Mutator<any>
  client: Cb.PayerContact
  saveDraft: Mutator<any>
  autosave: Mutator<any>
  disableRenofiAd: boolean
  context: Utils.Context
  estimateId: string | null
  pageNumber: `${number} / ${number}`
}

function EstimatePdfPreview({ estimateId }: { estimateId: string }) {
  const generatedPdfQ = Cb.useEstimatePdf(estimateId, { select: (x) => x as File })
  return (
    <div className="md:p-5">
      <File file={generatedPdfQ.data} title={t('Estimate PDF')} />
    </div>
  )
}

export function ReviewEmailPage({
  state,
  calculationContext,
  submit,
  saveDraft,
  copy,
  client,
  autosave,
  disableRenofiAd,
  estimateId,
  pageNumber,
}: Props) {
  const { back } = Utils.pageManager.useContext()
  const sz = useScreenSize()
  const previewFormProps = useFormProps()

  const estimateTotal = calculationContext.getL4Amount(state.lineItems)
  const renofiAdQ = Cb.useGetRenofiAd({
    params: {
      payer_id: state.client.value?.payer_id ?? '',
      estimate_amount: (estimateTotal ?? Zero).toFixed(2),
      us_state: state.address.state.value ?? undefined,
    },
  }).data

  const saveDraftA = Action.mutation(copy.saveDraft, {
    qualify: () => (autosave.isLoading ? Utils.waitForAutoSaveMessage : {}),
    mutate: () => saveDraft.mutateAsync(),
    theme: 'secondary',
  })

  return (
    <MSForm.Body
      title={copy.title}
      warnOnClose={state._controller.hasChanged}
      pageName={t('Review email')}
      pageNumber={pageNumber}
      autosave={{ isSaved: !state._controller.hasChanged, isLoading: autosave.isLoading }}
      error={submit.error ?? saveDraft.error}
      back={back}
      actions={[
        Action.more(
          [saveDraftA, Action.button(t('Preview'), { onClick: previewFormProps.setActive })],
          {
            qualify: () => sz === 'sm',
          },
        ),
        Action.hidden(saveDraftA, sz === 'sm'),
        Action.mutation(copy.submit, {
          qualify: () => (autosave.isLoading ? Utils.waitForAutoSaveMessage : {}),
          mutate: () => submit.mutateAsync(),
        }),
      ]}
    >
      <MSForm.Panels
        rightContent={
          <MSForm.ComponentOrDrawer {...previewFormProps} title={t('Preview')}>
            {estimateId && <EstimatePdfPreview estimateId={estimateId} />}
          </MSForm.ComponentOrDrawer>
        }
      >
        {client.is_guest ? (
          client.email ? (
            <ContactEmailSelect client={client} {...F.props(state.recipientContact)} />
          ) : (
            <EmailInput title={t('To')} {...F.props(state.newClientEmail)} />
          )
        ) : (
          <Metric k={t('To')} v={client.email} />
        )}
        <MultiEmailInput title={t('Cc emails')} optional {...F.props(state.ccEmails)} />
        <TextArea maxHeight={240} title={t('Email body')} optional {...F.props(state.emailBody)} />
        {!disableRenofiAd &&
          // fully hide if state isn't supported
          (renofiAdQ?.no_offer_reason === 'state_not_supported' ? null : (
            <>
              <Divider />
              <MSLayout.Section2
                title={t('Home renovation financing')}
                badge={
                  <Tooltip.Info
                    message={t('Only valid for renovations of owner-occupied single family homes.')}
                  />
                }
              >
                <div className="vflex gap-3">
                  <Toggle
                    subtitle={
                      <LinkEXPERIMENTAL.External href="https://www.trybeam.com/partners/renofi">
                        {t('Learn more about RenoFi')}
                      </LinkEXPERIMENTAL.External>
                    }
                    title={t('Show a personalized RenoFi loan offer')}
                    {...F.props(state.enableRenofi)}
                    disabled={!renofiAdQ?.offer}
                    disabledMessage={
                      renofiAdQ?.no_offer_reason === 'not_homeowner'
                        ? t('Client must be a homeowner')
                        : renofiAdQ?.no_offer_reason === 'amount_too_low'
                        ? t('Estimate amount is too low.')
                        : undefined
                    }
                  />
                  {!disableRenofiAd && renofiAdQ?.offer && (
                    <div
                      className={cn(
                        'pl-10 transition-all duration-100',
                        !state.enableRenofi.value && 'opacity-50',
                      )}
                    >
                      <RenofiAdCard
                        offer={renofiAdQ.offer}
                        amount={estimateTotal ?? Zero}
                        perspective="offerer"
                      />
                    </div>
                  )}
                </div>
              </MSLayout.Section2>
            </>
          ))}
      </MSForm.Panels>
    </MSForm.Body>
  )
}
