/* eslint-disable mosaic-js/no-useless-div */
import { ReactNode, useImperativeHandle } from 'react'
import { cn } from 'msutils/classnames'
import Typography from 'compass/data/Typography'
import ScrollOnMount from 'compass-local/utils/ScrollOnMount'
import Spinner from 'compass-local/Spinner'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import Alert from 'compass-local/Alert'
import { Arrow, X } from 'compass-local/legacy/icons'
import { t } from 'content'
import { MSArray } from 'msutils'
import { unreachable } from 'msutils/misc'
import { LayoutSwitcher } from 'utils/components'
import Divider from 'compass-local/Divider'
import { Action } from 'utils/actions'
import { LayoutFlagContextProvider } from 'compass/layout/LayoutFlagContext'
import { FormSize, useFormContext } from './openerContext'

type ConfigurationProps = { warnOnClose?: boolean; size?: FormSize }
function useConfigureForm({ warnOnClose, size }: ConfigurationProps) {
  const { handle } = useFormContext()
  useImperativeHandle(handle, () => ({ size, warnOnClose }), [warnOnClose, size])
}

type FormBodyProps = {
  title: ReactNode
  back?: () => void
  error?: Error | string | null
  pageName?: ReactNode
  pageNumber?: `${number} / ${number}`
  inlineFooter?: ReactNode
  summary?: ReactNode
  // TODO: not supported for drawer
  footer?: ReactNode
  // TODO: not supported for drawer
  customOverlay?: ReactNode
  reverseFooterDONOTUSE?: boolean
  actions?: Action.Config[]
  warnOnClose?: boolean
  // TODO: not supported for drawer
  autosave?: {
    isLoading: boolean
    isSaved: boolean
  }
  size?: FormSize
  inlineTitle?: boolean
  children: ReactNode
}

function DrawerBody({
  title,
  back,
  error,
  pageName,
  pageNumber,
  warnOnClose,
  customOverlay,
  inlineFooter,
  summary,
  reverseFooterDONOTUSE,
  actions,
  size,
  inlineTitle,
  children,
}: FormBodyProps) {
  useConfigureForm({ size, warnOnClose })
  const { warnOrSetInactive } = useFormContext()

  return (
    <div
      className={cn(
        'px-5 md:px-9 relative h-full bg-th-bg-white vflex gap-5',
        !!customOverlay && 'overflow-hidden',
      )}
    >
      {customOverlay && (
        <div className="absolute inset-0 bg-[rgba(255,255,255,0.9)] vflex justify-center items-center z-[10000]">
          <div className="max-w-[420px] px-5">{customOverlay}</div>
        </div>
      )}
      <div className="sticky top-0 flex justify-between pb-3 pt-4 md:pt-7 bg-[rgba(256,256,256,0.9)] relative z-[11]">
        {back ? (
          <LinkButtonDONOTUSE
            variant="dark"
            icon={<Arrow className="-rotate-90 text-th-brown-2" />}
            onClick={back}
          >
            {t('Back')}
          </LinkButtonDONOTUSE>
        ) : (
          !inlineTitle && <div />
        )}
        {inlineTitle && <Typography variant="drawerheader">{title}</Typography>}
        <div
          className="p-4 -m-4 cursor-pointer rounded-full bg-[rgba(256,256,256,0.5)]"
          onClick={(e) => {
            warnOrSetInactive()
            e.stopPropagation()
          }}
        >
          <X height={16} thickness={2.4} className="text-th-brown-2" />
        </div>
      </div>
      {!inlineTitle && (
        <Typography variant="drawerheader" className="relative z-[10]">
          {title}
        </Typography>
      )}
      {error && !(error instanceof Error && !error.message) && (
        <ScrollOnMount position="end">
          <Alert>{error instanceof Error ? error.message : error}</Alert>
        </ScrollOnMount>
      )}
      <div className="grow vflex gap-5">
        {(pageName || pageNumber) && (
          <div className="flex gap-2 items-baseline">
            {pageNumber && (
              <Typography variant="bodybold" className="text-th-coolgrey-1">
                {pageNumber}
              </Typography>
            )}
            <Typography variant="title" className="text-th-grey-2">
              {pageName}
            </Typography>
          </div>
        )}
        {children}
      </div>
      <LayoutSwitcher
        sm={
          (MSArray.isNonEmpty(actions ?? []) || summary || inlineFooter) && (
            <div className="sticky bottom-0 py-4 border-t border-th-warmgrey-1 bg-[rgba(256,256,256,0.9)] flex flex-col-reverse gap-2 items-center [&>*]:w-full">
              {reverseFooterDONOTUSE && (inlineFooter ?? summary)}
              <Action.UIConfigProvider value={{ theme: 'primary' }}>
                {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
              </Action.UIConfigProvider>
              {!reverseFooterDONOTUSE && (inlineFooter ?? summary)}
            </div>
          )
        }
        lg={
          (MSArray.isNonEmpty(actions ?? []) || summary) && (
            <div className="sticky bottom-0 px-5 -mx-5 bg-[rgba(256,256,256,0.9)]">
              <Divider />
              <div className="vflex gap-3 py-4">
                {summary}
                <div className="flex items-center gap-5 pb-0 justify-between">
                  <div>{inlineFooter}</div>
                  {MSArray.isNonEmpty(actions ?? []) && (
                    <Action.UIConfigProvider value={{ theme: 'primary' }}>
                      <div className="w-min flex gap-4 justify-end">
                        {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
                      </div>
                    </Action.UIConfigProvider>
                  )}
                </div>
              </div>
            </div>
          )
        }
      />
    </div>
  )
}

function ModalBody({
  title,
  pageNumber,
  pageName,
  error,
  back,
  autosave,
  summary,
  actions,
  customOverlay,
  footer,
  warnOnClose,
  children,
}: FormBodyProps) {
  useConfigureForm({ warnOnClose })
  const { warnOrSetInactive } = useFormContext()

  return (
    <div className="vflex h-full max-h-full isolate">
      {customOverlay && (
        <div className="absolute inset-0 bg-[rgba(255,255,255,0.9)] opacity-90 vflex justify-center items-center z-[10000]">
          <div className="max-w-[420px] px-5">{customOverlay}</div>
        </div>
      )}
      <div className="flex justify-between px-8 py-5 gap-10 border-b border-th-warmgrey-2 items-center">
        <div className="flex gap-5 items-center">
          <Typography variant="title">{title}</Typography>
          <div className="bg-th-warmgrey-2 w-px h-4" />
          <div className="flex gap-2 items-baseline">
            {pageNumber && (
              <Typography variant="bodybold" className="text-th-coolgrey-1">
                {pageNumber}
              </Typography>
            )}
            <Typography variant="bodybold">{pageName}</Typography>
          </div>
        </div>
        <div className="flex gap-5 items-center">
          {(autosave?.isSaved || autosave?.isLoading) && (
            <Typography variant="label" className="text-th-coolgrey-1">
              {autosave.isLoading ? <Spinner /> : t('Draft auto-saved')}
            </Typography>
          )}
          <X
            height={12}
            thickness={2.4}
            onClick={warnOrSetInactive}
            className="cursor-pointer text-th-coolgrey-1"
          />
        </div>
      </div>
      {error && !(error instanceof Error && !error.message) && (
        <div className="p-3">
          <Alert>{error instanceof Error ? error.message : error}</Alert>
        </div>
      )}
      {children}
      <div className="vflex gap-2 px-8 py-3 border-t border-th-warmgrey-2">
        {summary}
        {footer}
        <div className="flex justify-between gap-8 items-center">
          {back ? (
            <LinkButtonDONOTUSE
              variant="dark"
              icon={<Arrow className="-rotate-90 text-th-brown-2" />}
              onClick={back}
            >
              {t('Back')}
            </LinkButtonDONOTUSE>
          ) : (
            <div />
          )}
          <div className="flex gap-4 items-center">
            <Action.UIConfigProvider value={{ theme: 'primary' }}>
              {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
            </Action.UIConfigProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export function FormBody(props: FormBodyProps) {
  const { ui } = useFormContext()
  switch (ui) {
    case 'modal':
      return <ModalBody {...props} />
    case 'drawer':
      return (
        <LayoutFlagContextProvider disabledTableInset>
          <DrawerBody {...props} />
        </LayoutFlagContextProvider>
      )
    default:
      return unreachable(ui)
  }
}
