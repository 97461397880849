import { ReactNode } from 'react'
import Typography from 'compass/data/Typography'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import { t, Trans } from 'content'
import { useSupportContext } from 'root/global'
import { getAuth, setAuth } from 'root/utils'

type Props = {
  title?: string
  message?: ReactNode
  error?: string
}

export default function ErrorState({
  title = t('An unexpected error has occurred'),
  message,
  error = t('Failed to load'),
}: Props) {
  const isLoggedIn = !!getAuth()
  const { setChatOpen } = useSupportContext()

  const defaultMessage = isLoggedIn ? (
    <Trans
      tKey="Try refreshing the page or going back. Still need help? Please <X1>contact support</X1> or <X2>log out</X2> and log back in."
      X1={<LinkButtonDONOTUSE onClick={() => setChatOpen(true)} />}
      X2={
        <LinkButtonDONOTUSE
          onClick={() => {
            setAuth(null)
            setTimeout(() => window.location.reload(), 100)
          }}
        />
      }
    />
  ) : (
    <Trans
      tKey="Try refreshing the page or going back. Still need help? Please <X1>contact support</X1>."
      X1={<LinkButtonDONOTUSE onClick={() => setChatOpen(true)} />}
    />
  )

  return (
    <div className="flex justify-center p-4">
      <div className="vflex max-w-[400px] gap-4">
        <Typography variant="drawerheader">{title}</Typography>
        <Typography>{message ?? defaultMessage}</Typography>
        <Typography className="text-th-coolgrey-1">{error}</Typography>
      </div>
    </div>
  )
}
