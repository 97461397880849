import { Cb } from 'cb'
import EstimateItemsInput, { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import { F, Format, MSArray, random, Zero } from 'msutils'
import EllipsisMenu from 'components/misc/EllipsisMenu'
import { t } from 'content'
import { MSLayout } from 'utils/layout'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import { Plus } from 'compass-local/legacy/icons'
import useScreenSize from 'compass/theme/useScreenSize'
import Metric from 'compass-local/Metric'
import Typography from 'compass/data/Typography'
import { EstimateUtils } from 'features/estimates/utils'
import { TextInput } from 'compass/input/TextInput'

type SectionInputProps = {
  hideTotal: boolean
  state: EstimateItemsInputUtils.ItemInput
  calculationContext: EstimateItemsInputUtils.EstimateCalculationContext
  generalProjectConfig: Cb.GeneralProjectConfig
  didAddReversedDiscount: () => void
  capabilities: EstimateUtils.Capability[]
}

export function SectionInput({
  hideTotal,
  state,
  calculationContext,
  generalProjectConfig,
  didAddReversedDiscount,
  capabilities,
}: SectionInputProps) {
  const sz = useScreenSize()
  const cost = calculationContext.getCost(state)
  const subtotal = calculationContext.getL2Amount(state)
  const total = calculationContext.getL4Amount(state)
  const commission = calculationContext.getCommission(state)
  const hasCommission = MSArray.isNonEmpty(
    EstimateItemsInputUtils.getAllItems(state).filter((x) => x.isCommission.value),
  )
  const totalsMetrics = [
    capabilities.includes('view-markup') && { k: t('Cost'), v: Format.currency(cost) },
    hasCommission && { k: t('Commission'), v: Format.currency(commission) },
    capabilities.includes('view-markup') && {
      k: t('Profit margin'),
      v: Format.currencyRatio(total.minus((cost ?? Zero).plus(commission ?? Zero)), total),
    },
    { k: t('Estimate total'), v: Format.currency(total), bold: true },
  ]

  return (
    <div className="vflex gap-8 grow">
      {state.children.map((section, i) =>
        section.isSection.value ? (
          <div className="vflex gap-5" key={section.listId.value}>
            <div className="flex items-center justify-between">
              <div className="max-w-[70%] grow">
                <TextInput {...F.props(section.description)} />
              </div>
              {capabilities.includes('edit-structure') && MSArray.isNonEmpty(state.children) && (
                <EllipsisMenu
                  options={[
                    {
                      label: t('Delete'),
                      variant: 'danger',
                      onClick: () => {
                        const sectionChildren = state.children.at(i)?.children
                        state.children._controller.remove(i)
                        if (state.children.length === 1) {
                          sectionChildren?.forEach((x) =>
                            state.children._controller.append(
                              EstimateItemsInputUtils.getRowInitFromInput(x),
                            ),
                          )
                        }
                      },
                    },
                  ]}
                />
              )}
            </div>
            <EstimateItemsInput
              state={section}
              calculationContext={calculationContext}
              generalProjectConfig={generalProjectConfig}
              didAddReversedDiscount={didAddReversedDiscount}
              capabilities={capabilities}
            />
          </div>
        ) : null,
      )}
      {sz !== 'sm' && (
        <>
          <div className="grow" />
          <div className="sticky bottom-0 -my-5 -mx-8 border-t vflex bg-th-bg-white">
            {state.children.some((x) => !x.isSection.value) && (
              <>
                <div className="flex bg-th-bg-slate justify-end items-center py-2 px-5">
                  <Metric k={t('Subtotal')} v={Format.currency(subtotal)} />
                </div>
                <div className="px-8">
                  <EstimateItemsInput
                    state={state}
                    calculationContext={calculationContext}
                    generalProjectConfig={generalProjectConfig}
                    noFooters
                    noHeader
                    filter={(x) => !x.isSection.value}
                    capabilities={capabilities}
                    disablePostSubtotalConfiguration
                  />
                </div>
              </>
            )}
            <div className="flex bg-th-bg-slate justify-between items-center py-2 px-5">
              {capabilities.includes('edit-structure') && (
                <div className="flex gap-3">
                  <LinkButtonDONOTUSE
                    onClick={() =>
                      state.children._controller.append({
                        type: 'percent-priced',
                        listId: random(),
                        postSubtotal: true,
                        children: [],
                      })
                    }
                    {...(hideTotal && {
                      disabled: true,
                      disabledMessage: t(
                        'Cannot add post-subtotal items when the estimate total is hidden',
                      ),
                    })}
                    icon={<Plus height={16} />}
                  >
                    {t('Add % fee')}
                  </LinkButtonDONOTUSE>
                  <LinkButtonDONOTUSE
                    onClick={() =>
                      state.children._controller.append({
                        type: 'fixed-priced',
                        listId: random(),
                        postSubtotal: true,
                        children: [],
                      })
                    }
                    icon={<Plus height={16} />}
                    {...(hideTotal && {
                      disabled: true,
                      disabledMessage: t(
                        'Cannot add post-subtotal items when the estimate total is hidden',
                      ),
                    })}
                  >
                    {t('Add fixed fee')}
                  </LinkButtonDONOTUSE>
                </div>
              )}
              <MSLayout.SummaryMetricsBar values={totalsMetrics} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export function CoverPagePlaceholder({ text }: { text: string }) {
  return (
    <div className="flex justify-center items-center border-dashed w-full h-full border-th-warmgrey-1 border-2 rounded-8">
      <Typography variant="label" className="text-th-text-disabled">
        {text}
      </Typography>
    </div>
  )
}
