/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListInvoiceLineItemsOrdering =
  (typeof ListInvoiceLineItemsOrdering)[keyof typeof ListInvoiceLineItemsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListInvoiceLineItemsOrdering = {
  description: 'description',
  '-description': '-description',
  work_completed: 'work_completed',
  '-work_completed': '-work_completed',
  retainage_billed: 'retainage_billed',
  '-retainage_billed': '-retainage_billed',
  retainage_held: 'retainage_held',
  '-retainage_held': '-retainage_held',
  payment_amount: 'payment_amount',
  '-payment_amount': '-payment_amount',
  number: 'number',
  '-number': '-number',
  change_order_number: 'change_order_number',
  '-change_order_number': '-change_order_number',
  change_order_description: 'change_order_description',
  '-change_order_description': '-change_order_description',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
