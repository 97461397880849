/* eslint-disable mosaic-js/no-raw-text-jsx */
import { Cb, Q } from 'cb'
import Typography from 'compass/data/Typography'
import Image from 'compass-local/legacy/Image'
import AutoScale from 'compass-local/utils/AutoScale'
import Divider from 'compass-local/Divider'
import { RichAddressInputUtils } from 'components/inputs/RichAddressInput'
import LoadingPage from 'components/misc/LoadingPage'
import { F, Format, Zero, MSDate } from 'msutils'
import { useBusinessContext } from 'root/user'
import withInjectedQueriesDONOTUSE, { AwaitedQueryInjectedProps } from 'utils/withInjectedQueries'
import { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import Metric from 'compass-local/Metric'
import Metrics from 'compass-local/Metrics'
import { EstimateInputUtils as Utils } from '../utils'
import SectionPreview from '../SectionPreview'
import PreviewOptions from './PreviewOptions'

function useQueries() {
  return Q.group({
    externalCommunicationConfig: Cb.useRetrieveSingletonExternalCommunicationConfig(),
  })
}

type Props = {
  copy: Utils.CopySpec
  state: F.Input<typeof Utils.schema>
  calculationContext: EstimateItemsInputUtils.EstimateCalculationContext
  showFormatOptions: boolean
  _queryset: AwaitedQueryInjectedProps<typeof useQueries>
}

function Preview({ copy, state, calculationContext, showFormatOptions, _queryset }: Props) {
  const { business } = useBusinessContext()
  const { externalCommunicationConfig } = _queryset

  const businessLogoConfigQ = Cb.useListBusinessLogoEmbeddedFiles({ select: Q.opt })
  const logo = Cb.useBusinessLogoEmbeddedFileDownload(businessLogoConfigQ.data?.id as string, {
    enabled: !!businessLogoConfigQ.data?.id,
    select: (x) => x as File,
  }).data

  const businessAddress = RichAddressInputUtils.formatApi(business.address, { multiline: true })
  const businessPhone = externalCommunicationConfig.phone
  const businessLicenseNumber = externalCommunicationConfig.license_number

  const estimateTotal = calculationContext.getL4Amount(state.lineItems)
  const estimateSubtotal = calculationContext.getL3Amount(state.lineItems)

  const totalColumnEnabled = state.showUnitCosts.value
  const unitCostColumnEnabled = state.showQuantity.value && state.showUnitCosts.value
  const quantityColumnEnabled = state.showQuantity.value
  const markupColumnEnabled = state.showMarkup.value

  return (
    <div className="vflex gap-6 min-h-full md:p-5">
      {showFormatOptions && <PreviewOptions state={state} />}
      <AutoScale classNames={{ container: () => 'shadow-md border border-th-wgrey-2' }}>
        <div className="min-w-[800px] min-h-[800px] bg-white p-8">
          <div className="flex items-start justify-between">
            <div className="vflex gap-1 pb-1">
              <Typography variant="header">{`${copy.typeName} for ${
                (state.displayName.value || state.title.value) ?? '--'
              }`}</Typography>
              <Typography variant="bodybold">{business.name}</Typography>
              {businessLicenseNumber && <Typography>{businessLicenseNumber}</Typography>}
              {businessAddress && (
                <Typography className="whitespace-pre-wrap">{businessAddress}</Typography>
              )}
              {businessPhone && <Typography>{Format.phone(businessPhone)}</Typography>}
              {externalCommunicationConfig.display_business_email && business.email && (
                <Typography>{business.email}</Typography>
              )}
            </div>
            {logo ? (
              <div className="h-[60px]">
                <Image file={logo} title="" noShadow noBorder sizeWithHeight className="h-full" />
              </div>
            ) : (
              <Metric
                align="right"
                k={`${copy.typeName} total`}
                v={Format.currency(estimateTotal)}
                variant="medium"
              />
            )}
          </div>
          <div className="h-4" />
          <div className="vflex gap-5">
            <Divider color="black" height="h-[2px]" />
            <div className="flex gap-6">
              <div className="basis-[1/3] grow vflex gap-1 items-start">
                <div className="vflex gap-3">
                  <Typography variant="bodybold">Prepared for</Typography>
                  <Typography variant="preview/title">
                    {state.client.value?.name ?? '--'}
                  </Typography>
                </div>
                <Typography className="max-w-[240px] truncate whitespace-pre-wrap">
                  {state.client.value?.address
                    ? RichAddressInputUtils.formatApi(state.client.value?.address, {
                        multiline: true,
                      })
                    : state.client.value?.email ?? '--'}
                </Typography>
              </div>
              <div className="basis-[1/3] grow vflex gap-1 items-start">
                <div className="vflex gap-3">
                  <Typography variant="bodybold">Address</Typography>
                  <Typography variant="preview/title">
                    {RichAddressInputUtils.format(state.address).split(', ')[0]}
                  </Typography>
                </div>
                <Typography className="max-w-[240px] truncate">
                  {RichAddressInputUtils.format(state.address).split(', ').slice(1).join(', ')}
                </Typography>
              </div>
              <div className="basis-[1/6] grow vflex gap-3">
                <Typography variant="bodybold">Sent on</Typography>
                <Typography variant="preview/title">{MSDate.today().format('numeric')}</Typography>
              </div>
              {state.expiration.value && (
                <div className="basis-[1/6] grow vflex gap-3">
                  <Typography variant="bodybold">Expires on</Typography>
                  <Typography variant="preview/title">
                    {state.expiration.value.format('numeric')}
                  </Typography>
                </div>
              )}
            </div>
            <Divider color="black" height="h-[2px]" />
          </div>
          <div className="h-8" />
          {state.lineItems.children.at(0)?.isSection.value ? (
            <div className="vflex gap-[50px]">
              {state.lineItems.children
                .filter((x) => x.isSection.value)
                .map((x, i) => (
                  <SectionPreview
                    key={i}
                    state={x}
                    calculationContext={calculationContext}
                    showAsSection
                    totalColumnEnabled={totalColumnEnabled}
                    unitCostColumnEnabled={unitCostColumnEnabled && markupColumnEnabled}
                    unitPriceColumnEnabled={unitCostColumnEnabled && !markupColumnEnabled}
                    quantityColumnEnabled={quantityColumnEnabled}
                    markupColumnEnabled={markupColumnEnabled}
                    showSubSectionPricing={state.showSubSectionPricing.value}
                    showZeroAmountItems={state.showZeroAmountItems.value}
                    additionalInformation={state.additionalInformation.value}
                    typeName={copy.typeName}
                    requireApprovalSignature={state.requireApprovalSignature.value}
                  />
                ))}
            </div>
          ) : (
            <SectionPreview
              state={state.lineItems}
              calculationContext={calculationContext}
              showAsSection={false}
              totalColumnEnabled={totalColumnEnabled}
              unitCostColumnEnabled={unitCostColumnEnabled && markupColumnEnabled}
              unitPriceColumnEnabled={unitCostColumnEnabled && !markupColumnEnabled}
              quantityColumnEnabled={quantityColumnEnabled}
              markupColumnEnabled={markupColumnEnabled}
              showSubSectionPricing={state.showSubSectionPricing.value}
              showZeroAmountItems={state.showZeroAmountItems.value}
              additionalInformation={state.additionalInformation.value}
              typeName={copy.typeName}
              requireApprovalSignature={state.requireApprovalSignature.value}
            />
          )}
          {state.lineItems.children.at(0)?.isSection.value && (
            <>
              <div className="h-8" />
              {!state.hideEstimateTotal.value && (
                <div className="flex gap-8 justify-end">
                  <div className="vflex gap-2.5 w-[320px] p-5 bg-th-bg-slate">
                    {!estimateSubtotal.eq(estimateTotal) &&
                      state.lineItems.children.some(
                        (x) => x.postSubtotal.value && !x.isInactive.value,
                      ) && (
                        <>
                          <Metrics layout="h" variant="preview/medium">
                            <Metric k="Subtotal" v={Format.currency(estimateSubtotal)} />
                            {state.lineItems.children
                              .filter((x) => x.postSubtotal.value && !x.isInactive.value)
                              .map((item, i) =>
                                item.type.value === 'buffered-discount' ? (
                                  calculationContext.getLeafNodeAmount(item) < Zero && (
                                    <Metric
                                      key={i}
                                      k={`${item.description.value} (${Format.percent(
                                        item.discountMultiplier.value,
                                      )})`}
                                      v={Format.currency(
                                        calculationContext.getLeafNodeAmount(item),
                                      )}
                                    />
                                  )
                                ) : (
                                  <Metric
                                    key={i}
                                    k={
                                      item.type.value === 'percent-priced'
                                        ? `${item.description.value} (${Format.percent(
                                            item.fee.value || null,
                                          )})`
                                        : item.description.value
                                    }
                                    v={Format.currency(calculationContext.getLeafNodeAmount(item))}
                                  />
                                ),
                              )}
                          </Metrics>
                          <Divider height="h-[2px]" color="black" />
                        </>
                      )}
                    <Metric
                      k={`${copy.typeName} total`}
                      v={Format.currency(estimateTotal)}
                      layout="h"
                      variant="preview/large"
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {state.requireApprovalSignature.value && (
            <>
              <div className="h-8" />
              <div className="flex gap-8 justify-end">
                <div className="vflex gap-2.5 w-[320px] p-5 bg-th-bg-slate">
                  <Typography variant="bodybold">Approved and signed electronically by</Typography>
                  <div className="h-12" />
                  <Divider color="black" />
                  <div className="vflex gap-1">
                    <Typography variant="caption">Name:</Typography>
                    <Typography variant="caption">Date:</Typography>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </AutoScale>
    </div>
  )
}

export default withInjectedQueriesDONOTUSE(useQueries, Preview, () => <LoadingPage />)
