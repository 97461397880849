/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListLienWaiverPrefillsOrdering =
  (typeof ListLienWaiverPrefillsOrdering)[keyof typeof ListLienWaiverPrefillsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListLienWaiverPrefillsOrdering = {
  is_conditional: 'is_conditional',
  '-is_conditional': '-is_conditional',
  through_date: 'through_date',
  '-through_date': '-through_date',
  is_final: 'is_final',
  '-is_final': '-is_final',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
