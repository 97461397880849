import { FC } from 'react'
import { unreachable } from 'msutils/misc'
import { Q } from 'cb'
import { MSError2 } from 'msutils'
import { t } from 'content'
import LoadingPage from 'components/misc/LoadingPage'

type InjectedProps<T extends object> = T
type ClientProps<T extends object> = {
  _isRefetching?: boolean
  _queryset: T
}

type AwaitedQueryPrivateProps = '_queryset' | '_isRefetching'

export type AwaitedQueryInjectedProps<UseQuery> = UseQuery extends (_: any) => Q.Queryset<infer Q1>
  ? InjectedProps<Q1>
  : never

function QueryLoader<T extends object, S extends ClientProps<T>>({
  useQueries,
  props,
  Page,
  Loading,
}: {
  useQueries: (props: Omit<S, AwaitedQueryPrivateProps>) => Q.Queryset<T>
  props: S
  Page: FC<S>
  Loading: FC<Omit<S, AwaitedQueryPrivateProps>>
}) {
  const q = useQueries(props)

  switch (q.status) {
    case 'error':
      throw new MSError2(t('Failed to load'), {
        reportMessage: JSON.stringify(
          Object.fromEntries(Object.entries(q._queries).map(([k, v]) => [k, !!(v as any).error])),
        ),
      })
    case 'loading':
      return <Loading {...props} />
    case 'success':
      return <Page {...props} _queryset={q.queries} />
    default:
      return unreachable(q)
  }
}

// eslint-disable-next-line mosaic-js/unnamed-args
export default function withInjectedQueriesDONOTUSE<T extends object, S extends ClientProps<T>>(
  useQueries: (props: Omit<S, AwaitedQueryPrivateProps>) => Q.Queryset<T>,
  Page: FC<S>,
  Loading?: FC<Omit<S, AwaitedQueryPrivateProps>>,
  Error?: FC<{ error?: MSError2 }>,
) {
  type Props = Omit<S, AwaitedQueryPrivateProps>

  return function Inner(props: Props) {
    return (
      <MSError2.Boundary CustomUi={Error}>
        <QueryLoader
          useQueries={useQueries}
          props={props as any}
          Page={Page}
          Loading={Loading ?? LoadingPage}
        />
      </MSError2.Boundary>
    )
  }
}
