/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListFrontEndBannersOrdering =
  (typeof ListFrontEndBannersOrdering)[keyof typeof ListFrontEndBannersOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListFrontEndBannersOrdering = {
  content: 'content',
  '-content': '-content',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
