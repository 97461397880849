import { HTMLAttributes, ReactNode, useMemo, useState } from 'react'
import { useOnChange } from 'msutils'
import { useSizeOfText } from 'msutils/dom-utils'
import { BaseInputUtils } from '../baseUtils'
import InputContainer from '../InputContainer'
import { InputContainerUtils } from '../InputContainer/utils'
import { BaseTextInputUtils } from './utils'

const NoOpSerialization: BaseTextInputUtils.SerializationConfig<string> = {
  toDisplay: (x) => x,
  fromDisplay: (x) => x,
  isAcceptableAsExternalValue: () => true,
  isAcceptableAsIntermediateValue: () => true,
  eq: (a, b) => a === b,
}

function buildChangeHandler<T>({
  externalValue,
  updateExternalValue,
  updateDisplayValue,
  serializationConfig,
}: {
  externalValue: T
  updateExternalValue: (newValue: T) => void
  updateDisplayValue: (newValue: string) => void
  serializationConfig: BaseTextInputUtils.SerializationConfig<T>
}): (newValue: string) => void {
  return (inputValue) => {
    if (!serializationConfig.isAcceptableAsIntermediateValue(inputValue)) {
      // this shouldn't have happened because the onBeforeInput should've stopped it
      return
    }
    const newValue = serializationConfig.mapInputValue?.(inputValue) ?? inputValue
    updateDisplayValue(newValue)

    if (
      serializationConfig.isAcceptableAsExternalValue(newValue) &&
      !serializationConfig.eq(externalValue, serializationConfig.fromDisplay(newValue))
    ) {
      updateExternalValue(serializationConfig.fromDisplay(newValue))
    }
  }
}

function getLikelyNextValue(e: any) {
  return (
    e.target.value.substring(0, e.target.selectionStart) +
    (e.data ?? '') +
    e.target.value.substring(e.target.selectionEnd)
  )
}

function clipSelectionIndex({
  target,
  selectionBounds,
}: {
  target: HTMLInputElement
  selectionBounds: [number, number]
}) {
  setTimeout(() => {
    const selectionStart = Math.max(selectionBounds[0], target.selectionStart ?? 0)
    const selectionEnd = Math.min(selectionBounds[1], target.selectionEnd ?? 0)
    target.setSelectionRange(selectionStart, selectionEnd)
  }, 20)
}

export type Props<T> = BaseInputUtils.Props<T> & {
  title?: string
  subtitle?: string
  theme?: InputContainerUtils.Theme
  optional?: boolean
  maxLength?: number
  placeholder?: string
  align?: 'right' | 'left'
  fitContent?: boolean
  customAnnotation?: InputContainerUtils.CustomAnnotation | null
  endWidget?: ReactNode
  infoTooltip?: ReactNode
  enableAutocomplete?: boolean
  autofocus?: boolean
  typeProps?: {
    serializationConfig?: BaseTextInputUtils.SerializationConfig<T>
    disableKeyboard?: boolean
    autoselectOnFocus?: boolean
    getSelectionRangeBounds?: (displayValue: string) => [number, number]
    inputMode?: HTMLAttributes<HTMLInputElement>['inputMode']
    inputType?: 'text' | 'password'
  }
}

export default function BaseTextInput<T = string>({
  value,
  update,
  focus,
  blur,
  disabled,
  hidden,
  error,
  title,
  subtitle,
  align: align_,
  theme: theme_,
  fitContent: fitContent_,
  customAnnotation,
  endWidget,
  optional,
  maxLength,
  placeholder,
  infoTooltip,
  enableAutocomplete,
  autofocus,
  typeProps = {},
}: Props<T>) {
  const {
    serializationConfig = NoOpSerialization as unknown as BaseTextInputUtils.SerializationConfig<T>,
    disableKeyboard,
    autoselectOnFocus,
    inputMode,
    inputType = 'text',
  } = typeProps
  const { defaultTheme, alignOverride, fitContentOverride } = InputContainerUtils.useContext()
  const align = alignOverride ?? align_
  const fitContent = fitContentOverride ?? fitContent_
  const theme = theme_ ?? defaultTheme

  const [displayValue, setDisplayValue] = useState(serializationConfig.toDisplay(value))
  useOnChange([value], () => {
    if (!serializationConfig.eq(value, serializationConfig.fromDisplay(displayValue))) {
      setDisplayValue(serializationConfig.toDisplay(value))
    }
  })
  const sizeOfText = useSizeOfText(displayValue, {
    classNames: BaseInputUtils.ClassNames,
    inset: [theme.inset[0] * 4, theme.inset[1] * 4],
  })

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null)
  const handleUpdate = useMemo(
    () =>
      buildChangeHandler({
        externalValue: value,
        updateExternalValue: (x) => update?.(x),
        updateDisplayValue: setDisplayValue,
        serializationConfig,
      }),
    [update, serializationConfig, value],
  )
  const isFocused = document.activeElement === inputRef

  if (hidden) return null
  return (
    <InputContainer
      title={title}
      subtitle={subtitle}
      state={disabled ? 'disabled' : isFocused ? 'focused' : 'idle'}
      error={error ?? null}
      theme={theme}
      optional={optional ?? false}
      customAnnotation={customAnnotation}
      infoTooltip={infoTooltip}
      endWidget={endWidget}
    >
      <input
        type={inputType}
        value={displayValue}
        ref={setInputRef}
        disabled={disabled}
        readOnly={disableKeyboard}
        maxLength={maxLength}
        inputMode={inputMode}
        placeholder={placeholder}
        autoComplete={enableAutocomplete ? 'on' : 'off'}
        autoFocus={autofocus}
        onBeforeInput={(e) => {
          const likelyNextValue = getLikelyNextValue(e)
          if (!serializationConfig.isAcceptableAsIntermediateValue(likelyNextValue)) {
            e.preventDefault()
          }
        }}
        onChange={(e) => {
          handleUpdate(e.target.value)
          const selectionBounds = typeProps.getSelectionRangeBounds?.(e.target.value)
          if (selectionBounds) clipSelectionIndex({ target: e.target, selectionBounds })
        }}
        onPaste={(e) => {
          const pastedValue = e.clipboardData.getData('Text')
          if (!serializationConfig.isAcceptableAsIntermediateValue(pastedValue)) {
            const mappedValue = serializationConfig.mapPastedData?.(pastedValue) ?? null
            if (mappedValue) handleUpdate(mappedValue)
          }
        }}
        onKeyDown={(e) => {
          if (e.code === 'Escape' || e.code === 'Enter') {
            inputRef?.blur()
            e.stopPropagation()
          }
        }}
        onFocus={(e) => {
          focus?.()
          if (autoselectOnFocus) e.target.setSelectionRange(0, e.target.value.length)
        }}
        onBlur={() => {
          blur?.()
          if (serializationConfig.isAcceptableAsExternalValue(displayValue)) {
            setDisplayValue(
              serializationConfig.toDisplay(serializationConfig.fromDisplay(displayValue)),
            )
          } else {
            setDisplayValue(serializationConfig.toDisplay(value))
          }
        }}
        // TODO: rm tailwind
        className={BaseInputUtils.ClassNames}
        // TODO: rm manual layout
        style={{
          paddingTop: theme.inset[0] * 4,
          paddingBottom: theme.inset[0] * 4,
          paddingRight: theme.inset[1] * 4,
          paddingLeft: theme.inset[1] * 4,
          minWidth: fitContent ? sizeOfText.width : undefined,
          border: 0,
          width: '100%',
          textAlign: align === 'right' ? 'right' : undefined,
          background: 'transparent',
          cursor: disabled ? 'not-allowed' : undefined,
        }}
      />
    </InputContainer>
  )
}
