import { ReactNode } from 'react'
import { cn } from 'msutils/classnames'
import { switchInline } from 'utils/misc'
import Typography from 'compass/data/Typography'
import Spinner from 'compass-local/Spinner'
import Tooltip from 'compass/layout/Tooltip'

type Variant = 'light' | 'dark' | 'red' | 'unstyled'

type Props = {
  onClick: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  disabled?: boolean
  disabledMessage?: string
  isLoading?: boolean
  isSubmit?: boolean
  align?: 'right' | 'left'
  fit?: boolean
  icon?: ReactNode
  endIcon?: ReactNode
  variant?: Variant
  bordered?: boolean
  children?: ReactNode
}

export default function LinkButtonDONOTUSE({
  onClick,
  onMouseEnter,
  onMouseLeave,
  disabled = false,
  disabledMessage,
  isLoading = false,
  isSubmit = false,
  fit,
  align,
  icon,
  endIcon,
  variant = 'light',
  bordered = false,
  children,
}: Props) {
  return (
    <Tooltip inactive={!disabled || !disabledMessage} message={disabledMessage ?? null}>
      <button
        type={isSubmit ? 'submit' : 'button'}
        onClick={disabled ? undefined : onClick}
        onMouseEnter={disabled ? undefined : onMouseEnter}
        onMouseLeave={disabled ? undefined : onMouseLeave}
        className={cn(
          'relative p-2 -m-2 hover:bg-transparent whitespace-nowrap rounded-4',
          switchInline(variant, {
            light: 'text-th-orange-dark1 border-th-orange-dark1',
            dark: 'text-th-brown-1 border-th-brown-1',
            red: 'text-th-red-warning border-th-red-warning',
            unstyled: 'border-th-text-focus',
          }),
          bordered ? 'border-2' : 'border-0',
          disabled && 'cursor-not-allowed opacity-50',
          fit && 'w-fit',
        )}
      >
        <div className={cn(isLoading && 'invisible')}>
          <Typography
            variant={switchInline(variant, {
              light: 'bodybold',
              dark: 'subtitle',
              red: 'bodybold',
              unstyled: undefined,
            })}
            className={cn(
              'flex items-center gap-2',
              align === 'right' && 'justify-end',
              align === 'left' && 'justify-start',
              align === undefined && 'justify-center',
            )}
          >
            {icon}
            {children}
            {endIcon}
          </Typography>
        </div>
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner size="normal" thickness={220} />
          </div>
        )}
      </button>
    </Tooltip>
  )
}
