/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */
import { customAxios } from '../utils/generator-utils'
import type {
  PublicLogoViewOutput,
  ListAdditionalContacts200,
  ListAdditionalContactsParams,
  AdditionalContact,
  AdditionalContactCreate,
  AdditionalContactPatch,
  ListBusiness200,
  ListBusinessParams,
  Business,
  BusinessCreate,
  BusinessPatch,
  ListEmployeeCommunicationConfigs200,
  ListEmployeeCommunicationConfigsParams,
  EmployeeCommunicationConfig,
  EmployeeCommunicationConfigPatch,
  ListEmployees200,
  ListEmployeesParams,
  Employee,
  EmployeeCreate,
  EmployeePatch,
  ListGCEmails200,
  ListGCEmailsParams,
  GCEmail,
  GCEmailCreate,
  ListOutboundInvites200,
  ListOutboundInvitesParams,
  OutboundInvite,
  OutboundInviteCreate,
  ListPayeeContacts200,
  ListPayeeContactsParams,
  PayeeContact,
  PayeeContactCreate,
  PayeeContactPatch,
  GetArchiveRestrictionsOutput,
  GetPayeeContactArchiveRestrictionsList200,
  GetPayeeContactArchiveRestrictionsListParams,
  ListPayerContacts200,
  ListPayerContactsParams,
  PayerContact,
  PayerContactCreate,
  PayerContactPatch,
  GetPayerContactArchiveRestrictionsList200,
  GetPayerContactArchiveRestrictionsListParams,
  ListExternalCommunicationConfigs200,
  ListExternalCommunicationConfigsParams,
  ExternalCommunicationConfig,
  ExternalCommunicationConfigCreate,
  ExternalCommunicationConfigPatch,
  ListFrontEndBanners200,
  ListFrontEndBannersParams,
  FrontEndBanner,
  ListStripeConnectedAccountLinks200,
  ListStripeConnectedAccountLinksParams,
  StripeConnectedAccountLink,
  ListBusinessLogoEmbeddedFiles200,
  ListBusinessLogoEmbeddedFilesParams,
  BusinessLogoEmbeddedFile,
  BusinessLogoEmbeddedFileCreate,
  BusinessLogoEmbeddedFilePatch,
  ListChangeOrderEmbeddedFiles200,
  ListChangeOrderEmbeddedFilesParams,
  EmbeddedFile,
  EmbeddedFileCreate,
  ListChangeOrders200,
  ListChangeOrdersParams,
  ChangeOrder,
  ChangeOrderCreate,
  ChangeOrderPatch,
  ListClients200,
  ListClientsParams,
  Client,
  ListProjectPermissions200,
  ListProjectPermissionsParams,
  ProjectPermission,
  ListProjects200,
  ListProjectsParams,
  Project,
  ProjectCreate,
  ProjectPatch,
  ListRemovalChangeOrderEmbeddedFiles200,
  ListRemovalChangeOrderEmbeddedFilesParams,
  ListRemovalChangeOrders200,
  ListRemovalChangeOrdersParams,
  RemovalChangeOrder,
  RemovalChangeOrderPatch,
  ContractItemEditRestrictionViewSetOutput,
  ContractItemEditRestrictionViewSetParams,
  ListChangeOrderFiles200,
  ListChangeOrderFilesParams,
  ChangeOrderFile,
  ChangeOrderFileCreate,
  ChangeOrderFilePatch,
  ListContractEmbeddedFile2s200,
  ListContractEmbeddedFile2sParams,
  ContractEmbeddedFile2,
  ContractEmbeddedFile2Create,
  ContractEmbeddedFile2Patch,
  ListContractItems200,
  ListContractItemsParams,
  ContractItem,
  ContractItemCreate,
  ContractItemPatch,
  ArchiveRestriction,
  ListContractPrimaryFiles200,
  ListContractPrimaryFilesParams,
  ContractPrimaryFile,
  ContractPrimaryFileCreate,
  ContractPrimaryFilePatch,
  ListContracts200,
  ListContractsParams,
  Contract,
  ContractCreate,
  ContractPatch,
  ListCostCodes200,
  ListCostCodesParams,
  CostCode,
  CostCodeCreate,
  CostCodePatch,
  ListCostTypes200,
  ListCostTypesParams,
  CostType,
  CostTypeCreate,
  CostTypePatch,
  ListGeneralProjectConfigs200,
  ListGeneralProjectConfigsParams,
  GeneralProjectConfig,
  GeneralProjectConfigCreate,
  GeneralProjectConfigPatch,
  ListPayerGeneralProjectConfigs200,
  ListPayerGeneralProjectConfigsParams,
  PayerGeneralProjectConfig,
  ListRetainageReleases200,
  ListRetainageReleasesParams,
  RetainageRelease,
  RetainageReleaseCreate,
  ListNoteFileLinks200,
  ListNoteFileLinksParams,
  NoteFileLink,
  NoteFileLinkCreate,
  NoteFileLinkPatch,
  ListNoteMentionLinks200,
  ListNoteMentionLinksParams,
  NoteMentionLink,
  NoteMentionLinkCreate,
  ListNotes200,
  ListNotesParams,
  Note,
  NoteCreate,
  ListBills200,
  ListBillsParams,
  Bill,
  BillCreate,
  BillPatch,
  ListContractProgressSummarys200,
  ListContractProgressSummarysParams,
  ContractProgressSummary,
  ListExternalPaymentSources200,
  ListExternalPaymentSourcesParams,
  ExternalPaymentSource,
  ListInvoiceActionRequests200,
  ListInvoiceActionRequestsParams,
  InvoiceActionRequest,
  InvoiceActionRequestCreate,
  ListInvoiceApprovalActions200,
  ListInvoiceApprovalActionsParams,
  InvoiceApprovalAction,
  InvoiceApprovalActionCreate,
  ListInvoiceConfigs200,
  ListInvoiceConfigsParams,
  InvoiceConfig,
  ListInvoicePaymentGroups200,
  ListInvoicePaymentGroupsParams,
  InvoicePaymentGroup,
  InvoicePaymentGroupCreate,
  InvoicePaymentGroupPatch,
  ListInvoicePayments200,
  ListInvoicePaymentsParams,
  InvoicePayment,
  InvoicePaymentCreate,
  InvoicePaymentPatch,
  GetInvoicePaymentArchiveRestrictionsList200,
  GetInvoicePaymentArchiveRestrictionsListParams,
  ListInvoicePermissions200,
  ListInvoicePermissionsParams,
  InvoicePermission,
  ListInvoices200,
  ListInvoicesParams,
  Invoice,
  InvoiceCreate,
  InvoicePatch,
  ListLienWaiverClaimantSignatures200,
  ListLienWaiverClaimantSignaturesParams,
  LienWaiverClaimantSignature,
  ListLienWaiverEmbeddedFiles200,
  ListLienWaiverEmbeddedFilesParams,
  ListLienWaiverEmbeddedPrimaryFiles200,
  ListLienWaiverEmbeddedPrimaryFilesParams,
  ListLienWaivers200,
  ListLienWaiversParams,
  LienWaiver,
  LienWaiverCreate,
  LienWaiverPatch,
  ListPrimeContractInvoiceEmails200,
  ListPrimeContractInvoiceEmailsParams,
  PrimeContractInvoiceEmail,
  ListPrimeContractInvoiceEmailViews200,
  ListPrimeContractInvoiceEmailViewsParams,
  PrimeContractInvoiceEmailView,
  ListPrimeContractInvoicePaymentPageVisits200,
  ListPrimeContractInvoicePaymentPageVisitsParams,
  PrimeContractInvoicePaymentPageVisit,
  PrimeContractInvoicePaymentPageVisitCreate,
  ListProjectExpensePermissions200,
  ListProjectExpensePermissionsParams,
  ProjectExpensePermission,
  ListProjectExpenseReceiptEmbeddedFiles200,
  ListProjectExpenseReceiptEmbeddedFilesParams,
  ListProjectExpenses200,
  ListProjectExpensesParams,
  ProjectExpense,
  ProjectExpenseCreate,
  ProjectExpensePatch,
  ListProjectProgressSummaryV2s200,
  ListProjectProgressSummaryV2sParams,
  ProjectProgressSummaryV2,
  ListScheduleOfValuesItemProgressSummarys200,
  ListScheduleOfValuesItemProgressSummarysParams,
  ScheduleOfValuesItemProgressSummary,
  GetContractSummaryByItemViewOutput,
  GetContractSummaryByItemParams,
  GetExpenseMetricsOutput,
  GetInvoiceApprovalWorkflowRequirementsViewOutput,
  GetInvoiceApprovalWorkflowRequirementsParams,
  GetInvoiceCardFeeOutput,
  GetInvoiceCardFeeParams,
  GetInvoiceFileParams,
  GetInvoicePaymentStartDateViewOutput,
  GetInvoicePaymentStartDateParams,
  GetInvoiceSnapshotValuesViewOutput,
  GetInvoiceSnapshotValuesParams,
  GetLienWaiverPaymentBlockersOutput,
  GetLienWaiverPaymentBlockersParams,
  GetLienWaiverPdfPreviewV2Params,
  GetNextInvoiceNumberOutput,
  GetRetainageValuesViewOutput,
  GetRetainageValuesParams,
  PredictUncategorizedExpenseDetailsOutput,
  PredictUncategorizedExpenseDetailsParams,
  ListExpenseEmbeddedFiles200,
  ListExpenseEmbeddedFilesParams,
  ExpenseEmbeddedFile,
  ExpenseEmbeddedFileCreate,
  ExpenseEmbeddedFilePatch,
  ListInvoiceApprovalRules200,
  ListInvoiceApprovalRulesParams,
  InvoiceApprovalRule,
  InvoiceApprovalRuleCreate,
  InvoiceApprovalRulePatch,
  ListInvoiceFiles200,
  ListInvoiceFilesParams,
  InvoiceFile,
  InvoiceFileCreate,
  InvoiceFilePatch,
  ListInvoiceIntentEmbeddedFiles200,
  ListInvoiceIntentEmbeddedFilesParams,
  InvoiceIntentEmbeddedFile,
  InvoiceIntentEmbeddedFileCreate,
  InvoiceIntentEmbeddedFilePatch,
  ListInvoiceIntents200,
  ListInvoiceIntentsParams,
  InvoiceIntent,
  InvoiceIntentCreate,
  InvoiceIntentPatch,
  ListInvoiceLineItemMarkupLinks200,
  ListInvoiceLineItemMarkupLinksParams,
  InvoiceLineItemMarkupLink,
  InvoiceLineItemMarkupLinkCreate,
  InvoiceLineItemMarkupLinkPatch,
  ListInvoiceLineItems200,
  ListInvoiceLineItemsParams,
  InvoiceLineItem,
  InvoiceLineItemCreate,
  InvoiceLineItemPatch,
  ListInvoicePrefillLineItems200,
  ListInvoicePrefillLineItemsParams,
  InvoicePrefillLineItem,
  ListInvoicePrefills200,
  ListInvoicePrefillsParams,
  InvoicePrefill,
  ListInvoicePrimaryFiles200,
  ListInvoicePrimaryFilesParams,
  InvoicePrimaryFile,
  InvoicePrimaryFileCreate,
  InvoicePrimaryFilePatch,
  ListLienWaiverFiles200,
  ListLienWaiverFilesParams,
  LienWaiverFile,
  LienWaiverFileCreate,
  LienWaiverFilePatch,
  ListLienWaiverPrefills200,
  ListLienWaiverPrefillsParams,
  LienWaiverPrefill,
  LienWaiverPrefillCreate,
  LienWaiverPrefillPatch,
  ListLienWaiverPrimaryFiles200,
  ListLienWaiverPrimaryFilesParams,
  LienWaiverPrimaryFile,
  LienWaiverPrimaryFileCreate,
  LienWaiverPrimaryFilePatch,
  ListPaymentSources200,
  ListPaymentSourcesParams,
  PaymentSource,
  PaymentSourceCreate,
  PaymentSourcePatch,
  ListTimeEntrys200,
  ListTimeEntrysParams,
  TimeEntry,
  TimeEntryCreate,
  TimeEntryPatch,
  ListVendorBillingConfigs200,
  ListVendorBillingConfigsParams,
  VendorBillingConfig,
  VendorBillingConfigCreate,
  VendorBillingConfigPatch,
  ListWorkCompletedSources200,
  ListWorkCompletedSourcesParams,
  WorkCompletedSource,
  WorkCompletedSourceCreate,
  WorkCompletedSourcePatch,
  ListDummys200,
  ListDummysParams,
  ListEmailVerificationAttempts200,
  ListEmailVerificationAttemptsParams,
  EmailVerificationAttempt,
  EmailVerificationAttemptCreate,
  ListEmailVerifications200,
  ListEmailVerificationsParams,
  EmailVerification,
  EmailVerificationCreate,
  ListUsers200,
  ListUsersParams,
  User,
  UserCreate,
  UserPatch,
  ListCards200,
  ListCardsParams,
  Card,
  ListMicrodepositRequirements200,
  ListMicrodepositRequirementsParams,
  MicrodepositRequirement,
  ListTransactionAccounts200,
  ListTransactionAccountsParams,
  TransactionAccount,
  GetBankNameViewOutput,
  GetBankNameParams,
  GetStatementPDFParams,
  ListBankAccounts200,
  ListBankAccountsParams,
  BankAccount,
  BankAccountCreate,
  BankAccountPatch,
  ListControlledPayeeBankAccounts200,
  ListControlledPayeeBankAccountsParams,
  ControlledPayeeBankAccount,
  ControlledPayeeBankAccountCreate,
  ControlledPayeeBankAccountPatch,
  ListPayeeBankAccounts200,
  ListPayeeBankAccountsParams,
  PayeeBankAccount,
  ListCashBackPayments200,
  ListCashBackPaymentsParams,
  CashBackPayment,
  ListSaasTiers200,
  ListSaasTiersParams,
  SaasTier,
  ListYieldPayments200,
  ListYieldPaymentsParams,
  YieldPayment,
  GetProjectBudgetCostViewOutput,
  GetProjectBudgetCostParams,
  ListBudgetConfigs200,
  ListBudgetConfigsParams,
  BudgetConfig,
  BudgetConfigCreate,
  BudgetConfigPatch,
  ListProjectBudgetItems200,
  ListProjectBudgetItemsParams,
  ProjectBudgetItem,
  ProjectBudgetItemCreate,
  ProjectBudgetItemPatch,
  ListProjectBudgets200,
  ListProjectBudgetsParams,
  ProjectBudget,
  ProjectBudgetCreate,
  ProjectBudgetPatch,
  ListTasks200,
  ListTasksParams,
  Task,
  GetStripeIdentityVerificationSessionUrlOutput,
  GetStripeIdentityVerificationSessionUrlParams,
  WhoAmIViewOutput,
  ListOnboardingSurveys200,
  ListOnboardingSurveysParams,
  OnboardingSurvey,
  OnboardingSurveyCreate,
  OnboardingSurveyPatch,
  ListStripeIdentityVerificationSessions200,
  ListStripeIdentityVerificationSessionsParams,
  StripeIdentityVerificationSession,
  ListComplianceDocumentEmbeddedFiles200,
  ListComplianceDocumentEmbeddedFilesParams,
  ListComplianceDocuments200,
  ListComplianceDocumentsParams,
  ComplianceDocument,
  ComplianceDocumentCreate,
  ListComplianceSettingsLists200,
  ListComplianceSettingsListsParams,
  ComplianceSettingsList,
  ComplianceSettingsListPatch,
  ListComplianceStatusSummarys200,
  ListComplianceStatusSummarysParams,
  ComplianceStatusSummary,
  ListPayeeComplianceRequirements200,
  ListPayeeComplianceRequirementsParams,
  PayeeComplianceRequirement,
  PayeeComplianceRequirementPatch,
  ListRequirementLists200,
  ListRequirementListsParams,
  RequirementList,
  GetCombinedComplianceSettingsListAsPayeeViewOutput,
  GetCombinedComplianceSettingsListAsPayeeParams,
  GetComplianceSettingsListForPayeeViewOutput,
  GetComplianceSettingsListForPayeeParams,
  ListComplianceSettingsListPayeeOverrides200,
  ListComplianceSettingsListPayeeOverridesParams,
  ComplianceSettingsListPayeeOverride,
  ComplianceSettingsListPayeeOverrideCreate,
  ComplianceSettingsListPayeeOverridePatch,
  ListEstimateApprovalSignatures200,
  ListEstimateApprovalSignaturesParams,
  EstimateApprovalSignature,
  ListEstimateEmails200,
  ListEstimateEmailsParams,
  EstimateEmail,
  ListEstimateEmailViews200,
  ListEstimateEmailViewsParams,
  EstimateEmailView,
  ListEstimateEmbeddedFiles200,
  ListEstimateEmbeddedFilesParams,
  ListEstimatePageVisits200,
  ListEstimatePageVisitsParams,
  EstimatePaymentPageVisit,
  EstimatePaymentPageVisitCreate,
  ListEstimates200,
  ListEstimatesParams,
  Estimate,
  EstimateCreate,
  EstimatePatch,
  GetEstimateCoverPagePdfPreviewParams,
  GetEstimateInputFromFileViewOutput,
  GetEstimateInputFromFileParams,
  GetNextEstimateNumberOutput,
  GetNextEstimateNumberParams,
  ListCostLibraryItems200,
  ListCostLibraryItemsParams,
  CostLibraryItem,
  CostLibraryItemCreate,
  CostLibraryItemPatch,
  ListEstimateConfigs200,
  ListEstimateConfigsParams,
  EstimateConfig,
  EstimateConfigCreate,
  EstimateConfigPatch,
  ListEstimateCoverPagePhotos200,
  ListEstimateCoverPagePhotosParams,
  EstimateCoverPagePhoto,
  EstimateCoverPagePhotoCreate,
  EstimateCoverPagePhotoPatch,
  ListEstimateCoverPages200,
  ListEstimateCoverPagesParams,
  EstimateCoverPage,
  EstimateCoverPageCreate,
  EstimateCoverPagePatch,
  ListEstimateUploadedCoverPages200,
  ListEstimateUploadedCoverPagesParams,
  EstimateUploadedCoverPage,
  EstimateUploadedCoverPageCreate,
  EstimateUploadedCoverPagePatch,
  ListEstimatesAsPayeePageSummarys200,
  ListEstimatesAsPayeePageSummarysParams,
  EstimatesAsPayeePageSummary,
  ListAccounts200,
  ListAccountsParams,
  Account,
  AccountCreate,
  AccountPatch,
  ListInvoiceOutboundSyncV2s200,
  ListInvoiceOutboundSyncV2sParams,
  InvoiceOutboundSyncV2,
  GetLinkTokenOutput,
  GetUpdatedTokenStatusOutput,
  ListProjectImportRequests200,
  ListProjectImportRequestsParams,
  ProjectImportRequest,
  ProjectImportRequestCreate,
  ListQBProjects200,
  ListQBProjectsParams,
  QBProject,
  ListQBPullRequests200,
  ListQBPullRequestsParams,
  QBPullRequest,
  ListQBPushRequests200,
  ListQBPushRequestsParams,
  QBPushRequest,
  ListQboLinks200,
  ListQboLinksParams,
  QboLink,
  ListRawQboLinks200,
  ListRawQboLinksParams,
  RawQboLink,
  ListBillListRows200,
  ListBillListRowsParams,
  BillListRow,
  ListCardTransactionListRows200,
  ListCardTransactionListRowsParams,
  CardTransactionListRow,
  ListClientListRows200,
  ListClientListRowsParams,
  ClientListRow,
  ListExpenseListRows200,
  ListExpenseListRowsParams,
  ExpenseListRow,
  ListInvoiceListRows200,
  ListInvoiceListRowsParams,
  InvoiceListRow,
  ListInvoicePaymentFeeListRows200,
  ListInvoicePaymentFeeListRowsParams,
  InvoicePaymentFeeListRow,
  ListProjectListRows200,
  ListProjectListRowsParams,
  ProjectListRow,
  ListVendorListRows200,
  ListVendorListRowsParams,
  VendorListRow,
  ListBillsPageSummarys200,
  ListBillsPageSummarysParams,
  BillsPageSummary,
  ListEstimateAsPayeeListRows200,
  ListEstimateAsPayeeListRowsParams,
  EstimateAsPayeeListRow,
  ListEstimatesPageSummarys200,
  ListEstimatesPageSummarysParams,
  EstimatesPageSummary,
  ListInvoicesPageSummarys200,
  ListInvoicesPageSummarysParams,
  InvoicesPageSummary,
  ListProjectsPageSummarys200,
  ListProjectsPageSummarysParams,
  ProjectsPageSummary,
  APIActivityViewOutput,
  APIActivityParams,
  AggregatedPaymentListRowViewOutput,
  AggregatedPaymentListRowParams,
  CustomerSummaryViewOutput,
  CustomerSummaryParams,
  GetReadOnlyAuthCommandViewOutput,
  GetReadOnlyAuthCommandParams,
  GetAllowedQBBucketSearchesViewOutput,
  GetAllowedQBBucketSearchesParams,
  ListAdminQBPullRequests200,
  ListAdminQBPullRequestsParams,
  AdminQBPullRequest,
  ListAdminQBPushRequests200,
  ListAdminQBPushRequestsParams,
  AdminQBPushRequest,
  ListAdminTransactions200,
  ListAdminTransactionsParams,
  AdminTransaction,
  ListAgaveAccounts200,
  ListAgaveAccountsParams,
  AgaveAccount,
  ListBusinessNotes200,
  ListBusinessNotesParams,
  BusinessNote,
  BusinessNoteCreate,
  BusinessNotePatch,
  EmailPixelViewOutput,
  ListBeamBalanceTransactions200,
  ListBeamBalanceTransactionsParams,
  BeamBalanceTransaction,
  ListTransfers200,
  ListTransfersParams,
  Transfer,
  TransferCreate,
  GetHubspotVisitorTokenViewOutput,
  ListCardTransactions200,
  ListCardTransactionsParams,
  CardTransaction,
  ListExpenseCards200,
  ListExpenseCardsParams,
  ExpenseCard,
  ListCardPrograms200,
  ListCardProgramsParams,
  CardProgram,
  GetAccountLoginURLViewOutput,
  GetInvoiceCtaViewOutput,
  GetInvoiceCtaParams,
  GetInvoiceListCtaViewOutput,
  GetInvoiceListCtaParams,
  ListTutorials200,
  ListTutorialsParams,
  Tutorial,
  TutorialPatch,
  ListExpenseCodes200,
  ListExpenseCodesParams,
  ExpenseCode,
  ListIncomeCodes200,
  ListIncomeCodesParams,
  IncomeCode,
  ListItems200,
  ListItemsParams,
  Item,
  ListLedgerAccounts200,
  ListLedgerAccountsParams,
  LedgerAccount,
  ListBillOcrRawResultLineItems200,
  ListBillOcrRawResultLineItemsParams,
  BillOcrRawResultLineItem,
  ListBillOcrRawResults200,
  ListBillOcrRawResultsParams,
  BillOcrRawResult,
  ListMfaRequirements200,
  ListMfaRequirementsParams,
  MfaRequirement,
  GetAuthRequestLinkViewOutput,
  GetAuthRequestLinkParams,
  ListCompanyCamAccounts200,
  ListCompanyCamAccountsParams,
  CompanyCamAccount,
  CompanyCamAccountCreate,
  ListProcoreAccounts200,
  ListProcoreAccountsParams,
  ProcoreAccount,
  ProcoreAccountCreate,
  ListQboAccounts200,
  ListQboAccountsParams,
  QboAccount,
  QboAccountCreate,
  ListCompanyCamLinks200,
  ListCompanyCamLinksParams,
  CompanyCamLink,
  ListDocuments200,
  ListDocumentsParams,
  Document,
  ListPhotos200,
  ListPhotosParams,
  Photo,
  ListRenofiAds200,
  ListRenofiAdsParams,
  RenofiAd,
  RenofiAdCreate,
  RenofiAdPatch,
  GetRenofiAdViewOutput,
  GetRenofiAdParams,
  ListRenofiAdClicks200,
  ListRenofiAdClicksParams,
  RenofiAdClick,
  ListDeals200,
  ListDealsParams,
  Deal,
  DealCreate,
  DealPatch,
  ListDealSummarys200,
  ListDealSummarysParams,
  DealSummary,
  SetBusinessStatusOutput,
  SetBusinessStatusInput,
  BumpInvoiceVersionOutput,
  BumpInvoiceVersionInput,
  CreateLienWaiverFromInvoicePdfOutput,
  CreateLienWaiverFromInvoicePdfInput,
  SendInvoiceEmailOutput,
  SendInvoiceEmailInput,
  SendLienWaiverEmailOutput,
  SendLienWaiverEmailInput,
  UpdateContractTypeOutput,
  UpdateContractTypeInput,
  Login,
  LoginCreate,
  LogoutV2,
  LogoutV2Create,
  AuthenticateMagicLinkViewOutput,
  AuthenticateMagicLinkViewInput,
  GenerateMagicLinkTokenViewOutput,
  GenerateMagicLinkTokenViewInput,
  PasswordResetCompletionViewOutput,
  PasswordResetCompletionViewInput,
  PasswordResetInitializationViewOutput,
  PasswordResetInitializationViewInput,
  VerifyEmailViewOutput,
  VerifyEmailViewInput,
  MicrodepositAttempt,
  MicrodepositAttemptCreate,
  SetupIntent,
  SetupIntentCreate,
  SetInboundTransfersEnabledViewOutput,
  SetInboundTransfersEnabledViewInput,
  SetMfaRequirementOutput,
  SetMfaRequirementInput,
  File,
  FileCreate,
  FileUpload,
  FileUploadCreate,
  AdminCreateStripeIdentityVerificationSessionOutput,
  AdminCreateStripeIdentityVerificationSessionInput,
  GenerateStripeOnboardingClientSecretViewOutput,
  GenerateStripeOnboardingClientSecretViewInput,
  ConvertEstimateToContractOutput,
  ConvertEstimateToContractInput,
  PublishEstimateOutput,
  PublishEstimateInput,
  SendEstimateReminderOutput,
  SendEstimateReminderInput,
  UploadEstimateTemplateOutput,
  UploadEstimateTemplateInput,
  ArchiveBankAccountViewOutput,
  ArchiveBankAccountViewInput,
  GetQBBucketsViewOutput,
  GetQBBucketsViewInput,
  TrackEmailLinkClickedViewOutput,
  TrackEmailLinkClickedViewInput,
  CategorizeTransactionOutput,
  CategorizeTransactionInput,
  CreateSampleEstimateOutput,
  CreateSampleEstimateInput,
  InitiateBillOcrOutput,
  InitiateBillOcrInput,
  MfaAttempt,
  MfaAttemptCreate,
  ESignature,
  ESignatureCreate,
  UpdateAuthTokenViewOutput,
  UpdateAuthTokenViewInput,
  ClickLinkViewOutput,
  ClickLinkViewInput,
  RefreshDealsViewOutput,
  RefreshDealsViewInput,
  OnboardBusinessToStripeOutput,
  OnboardBusinessToStripeInput,
  UpdateMoneyMovementViewOutput,
  UpdateMoneyMovementViewInput,
  SetupIntentPatch,
} from './models'

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never

export const publicLogo = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<PublicLogoViewOutput>(
    { url: `/businesses/logos/${id}/image`, method: 'get' },
    options,
  )
}

export const listAdditionalContacts = (
  params?: ListAdditionalContactsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListAdditionalContacts200>(
    { url: `/businesses/additional_contacts/`, method: 'get', params },
    options,
  )
}

export const createAdditionalContact = (
  additionalContactCreate: AdditionalContactCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<AdditionalContact>(
    {
      url: `/businesses/additional_contacts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: additionalContactCreate,
    },
    options,
  )
}

export const retrieveAdditionalContact = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<AdditionalContact>(
    { url: `/businesses/additional_contacts/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateAdditionalContact = (
  id: string,
  additionalContactPatch: Partial<AdditionalContactPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<AdditionalContact>(
    {
      url: `/businesses/additional_contacts/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: additionalContactPatch,
    },
    options,
  )
}

export const destroyAdditionalContact = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/businesses/additional_contacts/${id}/`, method: 'delete' },
    options,
  )
}

export const listBusiness = (
  params?: ListBusinessParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListBusiness200>({ url: `/businesses/`, method: 'get', params }, options)
}

export const createBusiness = (
  businessCreate: BusinessCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Business>(
    {
      url: `/businesses/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: businessCreate,
    },
    options,
  )
}

export const retrieveBusiness = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Business>({ url: `/businesses/${id}/`, method: 'get' }, options)
}

export const partialUpdateBusiness = (
  id: string,
  businessPatch: Partial<BusinessPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Business>(
    {
      url: `/businesses/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: businessPatch,
    },
    options,
  )
}

export const listEmployeeCommunicationConfigs = (
  params?: ListEmployeeCommunicationConfigsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEmployeeCommunicationConfigs200>(
    { url: `/businesses/employee_communication_configs/`, method: 'get', params },
    options,
  )
}

export const retrieveSingletonEmployeeCommunicationConfig = (
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmployeeCommunicationConfig>(
    { url: `/businesses/employee_communication_configs/singleton/`, method: 'get' },
    options,
  )
}

export const retrieveEmployeeCommunicationConfig = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmployeeCommunicationConfig>(
    { url: `/businesses/employee_communication_configs/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateEmployeeCommunicationConfig = (
  id: string,
  employeeCommunicationConfigPatch: Partial<EmployeeCommunicationConfigPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmployeeCommunicationConfig>(
    {
      url: `/businesses/employee_communication_configs/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: employeeCommunicationConfigPatch,
    },
    options,
  )
}

export const listEmployees = (
  params?: ListEmployeesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEmployees200>(
    { url: `/businesses/employees/`, method: 'get', params },
    options,
  )
}

export const createEmployee = (
  employeeCreate: EmployeeCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Employee>(
    {
      url: `/businesses/employees/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: employeeCreate,
    },
    options,
  )
}

export const retrieveEmployee = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Employee>({ url: `/businesses/employees/${id}/`, method: 'get' }, options)
}

export const partialUpdateEmployee = (
  id: string,
  employeePatch: Partial<EmployeePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Employee>(
    {
      url: `/businesses/employees/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: employeePatch,
    },
    options,
  )
}

export const destroyEmployee = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/businesses/employees/${id}/`, method: 'delete' }, options)
}

export const listGCEmails = (
  params?: ListGCEmailsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListGCEmails200>(
    { url: `/businesses/gc_emails/`, method: 'get', params },
    options,
  )
}

export const createGCEmail = (
  gCEmailCreate: GCEmailCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GCEmail>(
    {
      url: `/businesses/gc_emails/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: gCEmailCreate,
    },
    options,
  )
}

export const retrieveGCEmail = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<GCEmail>({ url: `/businesses/gc_emails/${id}/`, method: 'get' }, options)
}

export const listOutboundInvites = (
  params?: ListOutboundInvitesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListOutboundInvites200>(
    { url: `/businesses/outbound_invites/`, method: 'get', params },
    options,
  )
}

export const createOutboundInvite = (
  outboundInviteCreate: OutboundInviteCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<OutboundInvite>(
    {
      url: `/businesses/outbound_invites/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: outboundInviteCreate,
    },
    options,
  )
}

export const retrieveOutboundInvite = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<OutboundInvite>(
    { url: `/businesses/outbound_invites/${id}/`, method: 'get' },
    options,
  )
}

export const listPayeeContacts = (
  params?: ListPayeeContactsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListPayeeContacts200>(
    { url: `/businesses/payee_contacts/`, method: 'get', params },
    options,
  )
}

export const createPayeeContact = (
  payeeContactCreate: PayeeContactCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PayeeContact>(
    {
      url: `/businesses/payee_contacts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: payeeContactCreate,
    },
    options,
  )
}

export const retrievePayeeContact = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<PayeeContact>(
    { url: `/businesses/payee_contacts/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdatePayeeContact = (
  id: string,
  payeeContactPatch: Partial<PayeeContactPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PayeeContact>(
    {
      url: `/businesses/payee_contacts/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: payeeContactPatch,
    },
    options,
  )
}

export const getPayeeContactArchiveRestrictions = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetArchiveRestrictionsOutput>(
    { url: `/businesses/payee_contacts/${id}/archive_restrictions/`, method: 'get' },
    options,
  )
}

export const getPayeeContactArchiveRestrictionsList = (
  params?: GetPayeeContactArchiveRestrictionsListParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetPayeeContactArchiveRestrictionsList200>(
    { url: `/businesses/payee_contacts/archive_restrictions/`, method: 'get', params },
    options,
  )
}

export const listPayerContacts = (
  params?: ListPayerContactsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListPayerContacts200>(
    { url: `/businesses/payer_contacts/`, method: 'get', params },
    options,
  )
}

export const createPayerContact = (
  payerContactCreate: PayerContactCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PayerContact>(
    {
      url: `/businesses/payer_contacts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: payerContactCreate,
    },
    options,
  )
}

export const retrievePayerContact = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<PayerContact>(
    { url: `/businesses/payer_contacts/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdatePayerContact = (
  id: string,
  payerContactPatch: Partial<PayerContactPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PayerContact>(
    {
      url: `/businesses/payer_contacts/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: payerContactPatch,
    },
    options,
  )
}

export const getPayerContactArchiveRestrictions = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetArchiveRestrictionsOutput>(
    { url: `/businesses/payer_contacts/${id}/archive_restrictions/`, method: 'get' },
    options,
  )
}

export const getPayerContactArchiveRestrictionsList = (
  params?: GetPayerContactArchiveRestrictionsListParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetPayerContactArchiveRestrictionsList200>(
    { url: `/businesses/payer_contacts/archive_restrictions/`, method: 'get', params },
    options,
  )
}

export const listExternalCommunicationConfigs = (
  params?: ListExternalCommunicationConfigsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListExternalCommunicationConfigs200>(
    { url: `/businesses/external_communication_configs/`, method: 'get', params },
    options,
  )
}

export const createExternalCommunicationConfig = (
  externalCommunicationConfigCreate: ExternalCommunicationConfigCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ExternalCommunicationConfig>(
    {
      url: `/businesses/external_communication_configs/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: externalCommunicationConfigCreate,
    },
    options,
  )
}

export const retrieveExternalCommunicationConfig = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ExternalCommunicationConfig>(
    { url: `/businesses/external_communication_configs/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateExternalCommunicationConfig = (
  id: string,
  externalCommunicationConfigPatch: Partial<ExternalCommunicationConfigPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ExternalCommunicationConfig>(
    {
      url: `/businesses/external_communication_configs/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: externalCommunicationConfigPatch,
    },
    options,
  )
}

export const retrieveSingletonExternalCommunicationConfig = (
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ExternalCommunicationConfig>(
    { url: `/businesses/external_communication_configs/singleton/`, method: 'get' },
    options,
  )
}

export const listFrontEndBanners = (
  params?: ListFrontEndBannersParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListFrontEndBanners200>(
    { url: `/businesses/front_end_banners/`, method: 'get', params },
    options,
  )
}

export const retrieveFrontEndBanner = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<FrontEndBanner>(
    { url: `/businesses/front_end_banners/${id}/`, method: 'get' },
    options,
  )
}

export const retrieveSingletonFrontEndBanner = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<FrontEndBanner>(
    { url: `/businesses/front_end_banners/singleton/`, method: 'get' },
    options,
  )
}

export const listStripeConnectedAccountLinks = (
  params?: ListStripeConnectedAccountLinksParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListStripeConnectedAccountLinks200>(
    { url: `/businesses/stripe_connected_account_links/`, method: 'get', params },
    options,
  )
}

export const retrieveStripeConnectedAccountLink = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<StripeConnectedAccountLink>(
    { url: `/businesses/stripe_connected_account_links/${id}/`, method: 'get' },
    options,
  )
}

export const listBusinessLogoEmbeddedFiles = (
  params?: ListBusinessLogoEmbeddedFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListBusinessLogoEmbeddedFiles200>(
    { url: `/projects/logos/`, method: 'get', params },
    options,
  )
}

export const createBusinessLogoEmbeddedFile = (
  businessLogoEmbeddedFileCreate: BusinessLogoEmbeddedFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BusinessLogoEmbeddedFile>(
    {
      url: `/projects/logos/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: businessLogoEmbeddedFileCreate,
    },
    options,
  )
}

export const retrieveBusinessLogoEmbeddedFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BusinessLogoEmbeddedFile>(
    { url: `/projects/logos/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateBusinessLogoEmbeddedFile = (
  id: string,
  businessLogoEmbeddedFilePatch: Partial<BusinessLogoEmbeddedFilePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BusinessLogoEmbeddedFile>(
    {
      url: `/projects/logos/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: businessLogoEmbeddedFilePatch,
    },
    options,
  )
}

export const destroyBusinessLogoEmbeddedFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>({ url: `/projects/logos/${id}/`, method: 'delete' }, options)
}

export const retrieveBusinessLogoEmbeddedFileDownload = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/projects/logos/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listChangeOrderEmbeddedFiles = (
  changeOrderId: string,
  params?: ListChangeOrderEmbeddedFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListChangeOrderEmbeddedFiles200>(
    { url: `/projects/change_orders/${changeOrderId}/files/`, method: 'get', params },
    options,
  )
}

export const createChangeOrderEmbeddedFile = (
  changeOrderId: string,
  embeddedFileCreate: EmbeddedFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    {
      url: `/projects/change_orders/${changeOrderId}/files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: embeddedFileCreate,
    },
    options,
  )
}

export const retrieveChangeOrderEmbeddedFile = (
  changeOrderId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    { url: `/projects/change_orders/${changeOrderId}/files/${id}/`, method: 'get' },
    options,
  )
}

export const retrieveChangeOrderEmbeddedFileDownload = (
  changeOrderId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/projects/change_orders/${changeOrderId}/files/${id}/download/`,
      method: 'get',
      responseType: 'blob',
    },
    options,
  )
}

export const listChangeOrders = (
  params?: ListChangeOrdersParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListChangeOrders200>(
    { url: `/projects/change_orders/`, method: 'get', params },
    options,
  )
}

export const createChangeOrder = (
  changeOrderCreate: ChangeOrderCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ChangeOrder>(
    {
      url: `/projects/change_orders/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: changeOrderCreate,
    },
    options,
  )
}

export const retrieveChangeOrder = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<ChangeOrder>({ url: `/projects/change_orders/${id}/`, method: 'get' }, options)
}

export const partialUpdateChangeOrder = (
  id: string,
  changeOrderPatch: Partial<ChangeOrderPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ChangeOrder>(
    {
      url: `/projects/change_orders/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: changeOrderPatch,
    },
    options,
  )
}

export const destroyChangeOrder = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/projects/change_orders/${id}/`, method: 'delete' }, options)
}

export const listClients = (
  params?: ListClientsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListClients200>({ url: `/projects/clients/`, method: 'get', params }, options)
}

export const retrieveClient = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Client>({ url: `/projects/clients/${id}/`, method: 'get' }, options)
}

export const listProjectPermissions = (
  params?: ListProjectPermissionsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProjectPermissions200>(
    { url: `/projects/project_permissions/`, method: 'get', params },
    options,
  )
}

export const retrieveProjectPermission = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectPermission>(
    { url: `/projects/project_permissions/${id}/`, method: 'get' },
    options,
  )
}

export const listProjects = (
  params?: ListProjectsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProjects200>({ url: `/projects/`, method: 'get', params }, options)
}

export const createProject = (
  projectCreate: ProjectCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Project>(
    {
      url: `/projects/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: projectCreate,
    },
    options,
  )
}

export const retrieveProject = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Project>({ url: `/projects/${id}/`, method: 'get' }, options)
}

export const partialUpdateProject = (
  id: string,
  projectPatch: Partial<ProjectPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Project>(
    {
      url: `/projects/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: projectPatch,
    },
    options,
  )
}

export const destroyProject = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/projects/${id}/`, method: 'delete' }, options)
}

export const listRemovalChangeOrderEmbeddedFiles = (
  removalChangeOrderId: string,
  params?: ListRemovalChangeOrderEmbeddedFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListRemovalChangeOrderEmbeddedFiles200>(
    {
      url: `/projects/removal_change_orders/${removalChangeOrderId}/files/`,
      method: 'get',
      params,
    },
    options,
  )
}

export const createRemovalChangeOrderEmbeddedFile = (
  removalChangeOrderId: string,
  embeddedFileCreate: EmbeddedFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    {
      url: `/projects/removal_change_orders/${removalChangeOrderId}/files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: embeddedFileCreate,
    },
    options,
  )
}

export const retrieveRemovalChangeOrderEmbeddedFile = (
  removalChangeOrderId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    { url: `/projects/removal_change_orders/${removalChangeOrderId}/files/${id}/`, method: 'get' },
    options,
  )
}

export const retrieveRemovalChangeOrderEmbeddedFileDownload = (
  removalChangeOrderId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/projects/removal_change_orders/${removalChangeOrderId}/files/${id}/download/`,
      method: 'get',
      responseType: 'blob',
    },
    options,
  )
}

export const listRemovalChangeOrders = (
  params?: ListRemovalChangeOrdersParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListRemovalChangeOrders200>(
    { url: `/projects/removal_change_orders/`, method: 'get', params },
    options,
  )
}

export const retrieveRemovalChangeOrder = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<RemovalChangeOrder>(
    { url: `/projects/removal_change_orders/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateRemovalChangeOrder = (
  id: string,
  removalChangeOrderPatch: Partial<RemovalChangeOrderPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<RemovalChangeOrder>(
    {
      url: `/projects/removal_change_orders/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: removalChangeOrderPatch,
    },
    options,
  )
}

export const destroyRemovalChangeOrder = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/projects/removal_change_orders/${id}/`, method: 'delete' },
    options,
  )
}

export const contractItemEditRestrictionViewSet = (
  params: ContractItemEditRestrictionViewSetParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ContractItemEditRestrictionViewSetOutput>(
    { url: `/projects/get_contract_item_edit_restrictions/`, method: 'get', params },
    options,
  )
}

export const listChangeOrderFiles = (
  params?: ListChangeOrderFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListChangeOrderFiles200>(
    { url: `/projects/change_order_files/`, method: 'get', params },
    options,
  )
}

export const createChangeOrderFile = (
  changeOrderFileCreate: ChangeOrderFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ChangeOrderFile>(
    {
      url: `/projects/change_order_files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: changeOrderFileCreate,
    },
    options,
  )
}

export const retrieveChangeOrderFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ChangeOrderFile>(
    { url: `/projects/change_order_files/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateChangeOrderFile = (
  id: string,
  changeOrderFilePatch: Partial<ChangeOrderFilePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ChangeOrderFile>(
    {
      url: `/projects/change_order_files/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: changeOrderFilePatch,
    },
    options,
  )
}

export const destroyChangeOrderFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/projects/change_order_files/${id}/`, method: 'delete' },
    options,
  )
}

export const downloadChangeOrderFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/projects/change_order_files/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listContractEmbeddedFile2s = (
  params?: ListContractEmbeddedFile2sParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListContractEmbeddedFile2s200>(
    { url: `/projects/contract_files/`, method: 'get', params },
    options,
  )
}

export const createContractEmbeddedFile2 = (
  contractEmbeddedFile2Create: ContractEmbeddedFile2Create,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ContractEmbeddedFile2>(
    {
      url: `/projects/contract_files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: contractEmbeddedFile2Create,
    },
    options,
  )
}

export const retrieveContractEmbeddedFile2 = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ContractEmbeddedFile2>(
    { url: `/projects/contract_files/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateContractEmbeddedFile2 = (
  id: string,
  contractEmbeddedFile2Patch: Partial<ContractEmbeddedFile2Patch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ContractEmbeddedFile2>(
    {
      url: `/projects/contract_files/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: contractEmbeddedFile2Patch,
    },
    options,
  )
}

export const destroyContractEmbeddedFile2 = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>({ url: `/projects/contract_files/${id}/`, method: 'delete' }, options)
}

export const downloadContractEmbeddedFile2 = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/projects/contract_files/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listContractItems = (
  params?: ListContractItemsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListContractItems200>(
    { url: `/projects/contract_items/`, method: 'get', params },
    options,
  )
}

export const createContractItem = (
  contractItemCreate: ContractItemCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ContractItem>(
    {
      url: `/projects/contract_items/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: contractItemCreate,
    },
    options,
  )
}

export const retrieveContractItem = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<ContractItem>(
    { url: `/projects/contract_items/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateContractItem = (
  id: string,
  contractItemPatch: Partial<ContractItemPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ContractItem>(
    {
      url: `/projects/contract_items/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: contractItemPatch,
    },
    options,
  )
}

export const destroyContractItem = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/projects/contract_items/${id}/`, method: 'delete' }, options)
}

export const getContractItemArchiveRestrictions = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ArchiveRestriction>(
    { url: `/projects/contract_items/${id}/archive_restrictions/`, method: 'get' },
    options,
  )
}

export const listContractPrimaryFiles = (
  params?: ListContractPrimaryFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListContractPrimaryFiles200>(
    { url: `/projects/contract_primary_files/`, method: 'get', params },
    options,
  )
}

export const createContractPrimaryFile = (
  contractPrimaryFileCreate: ContractPrimaryFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ContractPrimaryFile>(
    {
      url: `/projects/contract_primary_files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: contractPrimaryFileCreate,
    },
    options,
  )
}

export const retrieveContractPrimaryFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ContractPrimaryFile>(
    { url: `/projects/contract_primary_files/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateContractPrimaryFile = (
  id: string,
  contractPrimaryFilePatch: Partial<ContractPrimaryFilePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ContractPrimaryFile>(
    {
      url: `/projects/contract_primary_files/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: contractPrimaryFilePatch,
    },
    options,
  )
}

export const destroyContractPrimaryFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/projects/contract_primary_files/${id}/`, method: 'delete' },
    options,
  )
}

export const downloadContractPrimaryFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/projects/contract_primary_files/${id}/download/`,
      method: 'get',
      responseType: 'blob',
    },
    options,
  )
}

export const listContracts = (
  params?: ListContractsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListContracts200>(
    { url: `/projects/contracts/`, method: 'get', params },
    options,
  )
}

export const createContract = (
  contractCreate: ContractCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Contract>(
    {
      url: `/projects/contracts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: contractCreate,
    },
    options,
  )
}

export const retrieveContract = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Contract>({ url: `/projects/contracts/${id}/`, method: 'get' }, options)
}

export const partialUpdateContract = (
  id: string,
  contractPatch: Partial<ContractPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Contract>(
    {
      url: `/projects/contracts/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: contractPatch,
    },
    options,
  )
}

export const getContractArchiveRestrictions = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ArchiveRestriction>(
    { url: `/projects/contracts/${id}/archive_restrictions/`, method: 'get' },
    options,
  )
}

export const listCostCodes = (
  params?: ListCostCodesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListCostCodes200>(
    { url: `/projects/cost_codes/`, method: 'get', params },
    options,
  )
}

export const createCostCode = (
  costCodeCreate: CostCodeCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CostCode>(
    {
      url: `/projects/cost_codes/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: costCodeCreate,
    },
    options,
  )
}

export const retrieveCostCode = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<CostCode>({ url: `/projects/cost_codes/${id}/`, method: 'get' }, options)
}

export const partialUpdateCostCode = (
  id: string,
  costCodePatch: Partial<CostCodePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CostCode>(
    {
      url: `/projects/cost_codes/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: costCodePatch,
    },
    options,
  )
}

export const listCostTypes = (
  params?: ListCostTypesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListCostTypes200>(
    { url: `/projects/cost_types/`, method: 'get', params },
    options,
  )
}

export const createCostType = (
  costTypeCreate: CostTypeCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CostType>(
    {
      url: `/projects/cost_types/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: costTypeCreate,
    },
    options,
  )
}

export const retrieveCostType = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<CostType>({ url: `/projects/cost_types/${id}/`, method: 'get' }, options)
}

export const partialUpdateCostType = (
  id: string,
  costTypePatch: Partial<CostTypePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CostType>(
    {
      url: `/projects/cost_types/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: costTypePatch,
    },
    options,
  )
}

export const getCostTypeArchiveRestrictions = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ArchiveRestriction>(
    { url: `/projects/cost_types/${id}/archive_restrictions/`, method: 'get' },
    options,
  )
}

export const listGeneralProjectConfigs = (
  params?: ListGeneralProjectConfigsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListGeneralProjectConfigs200>(
    { url: `/projects/general_project_configs/`, method: 'get', params },
    options,
  )
}

export const createGeneralProjectConfig = (
  generalProjectConfigCreate: GeneralProjectConfigCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GeneralProjectConfig>(
    {
      url: `/projects/general_project_configs/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: generalProjectConfigCreate,
    },
    options,
  )
}

export const retrieveGeneralProjectConfig = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GeneralProjectConfig>(
    { url: `/projects/general_project_configs/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateGeneralProjectConfig = (
  id: string,
  generalProjectConfigPatch: Partial<GeneralProjectConfigPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GeneralProjectConfig>(
    {
      url: `/projects/general_project_configs/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: generalProjectConfigPatch,
    },
    options,
  )
}

export const retrieveSingletonGeneralProjectConfig = (
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GeneralProjectConfig>(
    { url: `/projects/general_project_configs/singleton/`, method: 'get' },
    options,
  )
}

export const listPayerGeneralProjectConfigs = (
  params?: ListPayerGeneralProjectConfigsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListPayerGeneralProjectConfigs200>(
    { url: `/projects/payer_general_project_configs/`, method: 'get', params },
    options,
  )
}

export const retrievePayerGeneralProjectConfig = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PayerGeneralProjectConfig>(
    { url: `/projects/payer_general_project_configs/${id}/`, method: 'get' },
    options,
  )
}

export const listRetainageReleases = (
  params?: ListRetainageReleasesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListRetainageReleases200>(
    { url: `/projects/retainage_releases/`, method: 'get', params },
    options,
  )
}

export const createRetainageRelease = (
  retainageReleaseCreate: RetainageReleaseCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<RetainageRelease>(
    {
      url: `/projects/retainage_releases/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: retainageReleaseCreate,
    },
    options,
  )
}

export const retrieveRetainageRelease = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<RetainageRelease>(
    { url: `/projects/retainage_releases/${id}/`, method: 'get' },
    options,
  )
}

export const listNoteFileLinks = (
  params?: ListNoteFileLinksParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListNoteFileLinks200>(
    { url: `/attachments/note_file_links/`, method: 'get', params },
    options,
  )
}

export const createNoteFileLink = (
  noteFileLinkCreate: NoteFileLinkCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<NoteFileLink>(
    {
      url: `/attachments/note_file_links/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: noteFileLinkCreate,
    },
    options,
  )
}

export const retrieveNoteFileLink = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<NoteFileLink>(
    { url: `/attachments/note_file_links/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateNoteFileLink = (
  id: string,
  noteFileLinkPatch: Partial<NoteFileLinkPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<NoteFileLink>(
    {
      url: `/attachments/note_file_links/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: noteFileLinkPatch,
    },
    options,
  )
}

export const destroyNoteFileLink = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>(
    { url: `/attachments/note_file_links/${id}/`, method: 'delete' },
    options,
  )
}

export const downloadNoteFileLink = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Blob>(
    { url: `/attachments/note_file_links/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listNoteMentionLinks = (
  params?: ListNoteMentionLinksParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListNoteMentionLinks200>(
    { url: `/attachments/note_mention_links/`, method: 'get', params },
    options,
  )
}

export const createNoteMentionLink = (
  noteMentionLinkCreate: NoteMentionLinkCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<NoteMentionLink>(
    {
      url: `/attachments/note_mention_links/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: noteMentionLinkCreate,
    },
    options,
  )
}

export const retrieveNoteMentionLink = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<NoteMentionLink>(
    { url: `/attachments/note_mention_links/${id}/`, method: 'get' },
    options,
  )
}

export const listNotes = (
  params?: ListNotesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListNotes200>({ url: `/attachments/notes/`, method: 'get', params }, options)
}

export const createNote = (
  noteCreate: NoteCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Note>(
    {
      url: `/attachments/notes/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: noteCreate,
    },
    options,
  )
}

export const retrieveNote = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Note>({ url: `/attachments/notes/${id}/`, method: 'get' }, options)
}

export const destroyNote = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/attachments/notes/${id}/`, method: 'delete' }, options)
}

export const listBills = (
  params?: ListBillsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListBills200>({ url: `/billing/bills/`, method: 'get', params }, options)
}

export const createBill = (
  billCreate: BillCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Bill>(
    {
      url: `/billing/bills/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: billCreate,
    },
    options,
  )
}

export const retrieveBill = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Bill>({ url: `/billing/bills/${id}/`, method: 'get' }, options)
}

export const partialUpdateBill = (
  id: string,
  billPatch: Partial<BillPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Bill>(
    {
      url: `/billing/bills/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: billPatch,
    },
    options,
  )
}

export const destroyBill = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/billing/bills/${id}/`, method: 'delete' }, options)
}

export const retrieveBillPdf = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Blob>(
    { url: `/billing/bills/${id}/pdf/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const retrieveBillZip = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Blob>(
    { url: `/billing/bills/${id}/zip/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listContractProgressSummarys = (
  params?: ListContractProgressSummarysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListContractProgressSummarys200>(
    { url: `/billing/contract_progress_summarys/`, method: 'get', params },
    options,
  )
}

export const retrieveContractProgressSummary = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ContractProgressSummary>(
    { url: `/billing/contract_progress_summarys/${id}/`, method: 'get' },
    options,
  )
}

export const listExternalPaymentSources = (
  params?: ListExternalPaymentSourcesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListExternalPaymentSources200>(
    { url: `/billing/external_payment_sources/`, method: 'get', params },
    options,
  )
}

export const retrieveExternalPaymentSource = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ExternalPaymentSource>(
    { url: `/billing/external_payment_sources/${id}/`, method: 'get' },
    options,
  )
}

export const listInvoiceActionRequests = (
  params?: ListInvoiceActionRequestsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoiceActionRequests200>(
    { url: `/billing/invoice_action_requests/`, method: 'get', params },
    options,
  )
}

export const createInvoiceActionRequest = (
  invoiceActionRequestCreate: InvoiceActionRequestCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceActionRequest>(
    {
      url: `/billing/invoice_action_requests/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceActionRequestCreate,
    },
    options,
  )
}

export const retrieveInvoiceActionRequest = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceActionRequest>(
    { url: `/billing/invoice_action_requests/${id}/`, method: 'get' },
    options,
  )
}

export const listInvoiceApprovalActions = (
  params?: ListInvoiceApprovalActionsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoiceApprovalActions200>(
    { url: `/billing/invoice_approval_actions/`, method: 'get', params },
    options,
  )
}

export const createInvoiceApprovalAction = (
  invoiceApprovalActionCreate: InvoiceApprovalActionCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceApprovalAction>(
    {
      url: `/billing/invoice_approval_actions/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceApprovalActionCreate,
    },
    options,
  )
}

export const retrieveInvoiceApprovalAction = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceApprovalAction>(
    { url: `/billing/invoice_approval_actions/${id}/`, method: 'get' },
    options,
  )
}

export const listInvoiceConfigs = (
  params?: ListInvoiceConfigsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoiceConfigs200>(
    { url: `/billing/invoice_configs/`, method: 'get', params },
    options,
  )
}

export const retrieveSingletonInvoiceConfig = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<InvoiceConfig>(
    { url: `/billing/invoice_configs/singleton/`, method: 'get' },
    options,
  )
}

export const retrieveInvoiceConfig = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceConfig>(
    { url: `/billing/invoice_configs/${id}/`, method: 'get' },
    options,
  )
}

export const listInvoicePaymentGroups = (
  params?: ListInvoicePaymentGroupsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoicePaymentGroups200>(
    { url: `/billing/invoice_payment_groups/`, method: 'get', params },
    options,
  )
}

export const createInvoicePaymentGroup = (
  invoicePaymentGroupCreate: InvoicePaymentGroupCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePaymentGroup>(
    {
      url: `/billing/invoice_payment_groups/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoicePaymentGroupCreate,
    },
    options,
  )
}

export const retrieveInvoicePaymentGroup = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePaymentGroup>(
    { url: `/billing/invoice_payment_groups/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateInvoicePaymentGroup = (
  id: string,
  invoicePaymentGroupPatch: Partial<InvoicePaymentGroupPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePaymentGroup>(
    {
      url: `/billing/invoice_payment_groups/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: invoicePaymentGroupPatch,
    },
    options,
  )
}

export const listInvoicePayments = (
  params?: ListInvoicePaymentsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoicePayments200>(
    { url: `/billing/invoice_payments/`, method: 'get', params },
    options,
  )
}

export const createInvoicePayment = (
  invoicePaymentCreate: InvoicePaymentCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePayment>(
    {
      url: `/billing/invoice_payments/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoicePaymentCreate,
    },
    options,
  )
}

export const retrieveInvoicePayment = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePayment>(
    { url: `/billing/invoice_payments/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateInvoicePayment = (
  id: string,
  invoicePaymentPatch: Partial<InvoicePaymentPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePayment>(
    {
      url: `/billing/invoice_payments/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: invoicePaymentPatch,
    },
    options,
  )
}

export const destroyInvoicePayment = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>({ url: `/billing/invoice_payments/${id}/`, method: 'delete' }, options)
}

export const getInvoicePaymentArchiveRestrictions = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetArchiveRestrictionsOutput>(
    { url: `/billing/invoice_payments/${id}/archive_restrictions/`, method: 'get' },
    options,
  )
}

export const getInvoicePaymentArchiveRestrictionsList = (
  params?: GetInvoicePaymentArchiveRestrictionsListParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetInvoicePaymentArchiveRestrictionsList200>(
    { url: `/billing/invoice_payments/archive_restrictions/`, method: 'get', params },
    options,
  )
}

export const listInvoicePermissions = (
  params?: ListInvoicePermissionsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoicePermissions200>(
    { url: `/billing/invoice_permissions/`, method: 'get', params },
    options,
  )
}

export const retrieveInvoicePermission = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePermission>(
    { url: `/billing/invoice_permissions/${id}/`, method: 'get' },
    options,
  )
}

export const listInvoices = (
  params?: ListInvoicesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoices200>({ url: `/billing/invoices/`, method: 'get', params }, options)
}

export const createInvoice = (
  invoiceCreate: InvoiceCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Invoice>(
    {
      url: `/billing/invoices/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceCreate,
    },
    options,
  )
}

export const retrieveInvoice = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Invoice>({ url: `/billing/invoices/${id}/`, method: 'get' }, options)
}

export const partialUpdateInvoice = (
  id: string,
  invoicePatch: Partial<InvoicePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Invoice>(
    {
      url: `/billing/invoices/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: invoicePatch,
    },
    options,
  )
}

export const destroyInvoice = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/billing/invoices/${id}/`, method: 'delete' }, options)
}

export const retrieveInvoicePdf = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Blob>(
    { url: `/billing/invoices/${id}/pdf/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const retrieveInvoiceZip = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Blob>(
    { url: `/billing/invoices/${id}/zip/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listLienWaiverClaimantSignatures = (
  lienWaiverId: string,
  params?: ListLienWaiverClaimantSignaturesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListLienWaiverClaimantSignatures200>(
    { url: `/billing/lien_waivers/${lienWaiverId}/claimant_signature/`, method: 'get', params },
    options,
  )
}

export const retrieveLienWaiverClaimantSignature = (
  lienWaiverId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiverClaimantSignature>(
    { url: `/billing/lien_waivers/${lienWaiverId}/claimant_signature/${id}/`, method: 'get' },
    options,
  )
}

export const listLienWaiverEmbeddedFiles = (
  lienWaiverId: string,
  params?: ListLienWaiverEmbeddedFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListLienWaiverEmbeddedFiles200>(
    { url: `/billing/lien_waivers/${lienWaiverId}/files/`, method: 'get', params },
    options,
  )
}

export const createLienWaiverEmbeddedFile = (
  lienWaiverId: string,
  embeddedFileCreate: EmbeddedFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    {
      url: `/billing/lien_waivers/${lienWaiverId}/files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: embeddedFileCreate,
    },
    options,
  )
}

export const retrieveLienWaiverEmbeddedFile = (
  lienWaiverId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    { url: `/billing/lien_waivers/${lienWaiverId}/files/${id}/`, method: 'get' },
    options,
  )
}

export const destroyLienWaiverEmbeddedFile = (
  lienWaiverId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/billing/lien_waivers/${lienWaiverId}/files/${id}/`, method: 'delete' },
    options,
  )
}

export const retrieveLienWaiverEmbeddedFileDownload = (
  lienWaiverId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/billing/lien_waivers/${lienWaiverId}/files/${id}/download/`,
      method: 'get',
      responseType: 'blob',
    },
    options,
  )
}

export const listLienWaiverEmbeddedPrimaryFiles = (
  lienWaiverId: string,
  params?: ListLienWaiverEmbeddedPrimaryFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListLienWaiverEmbeddedPrimaryFiles200>(
    { url: `/billing/lien_waivers/${lienWaiverId}/primary_files/`, method: 'get', params },
    options,
  )
}

export const createLienWaiverEmbeddedPrimaryFile = (
  lienWaiverId: string,
  embeddedFileCreate: EmbeddedFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    {
      url: `/billing/lien_waivers/${lienWaiverId}/primary_files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: embeddedFileCreate,
    },
    options,
  )
}

export const retrieveLienWaiverEmbeddedPrimaryFile = (
  lienWaiverId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    { url: `/billing/lien_waivers/${lienWaiverId}/primary_files/${id}/`, method: 'get' },
    options,
  )
}

export const destroyLienWaiverEmbeddedPrimaryFile = (
  lienWaiverId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/billing/lien_waivers/${lienWaiverId}/primary_files/${id}/`, method: 'delete' },
    options,
  )
}

export const retrieveLienWaiverEmbeddedPrimaryFileDownload = (
  lienWaiverId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/billing/lien_waivers/${lienWaiverId}/primary_files/${id}/download/`,
      method: 'get',
      responseType: 'blob',
    },
    options,
  )
}

export const listLienWaivers = (
  params?: ListLienWaiversParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListLienWaivers200>(
    { url: `/billing/lien_waivers/`, method: 'get', params },
    options,
  )
}

export const createLienWaiver = (
  lienWaiverCreate: LienWaiverCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiver>(
    {
      url: `/billing/lien_waivers/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: lienWaiverCreate,
    },
    options,
  )
}

export const retrieveLienWaiver = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<LienWaiver>({ url: `/billing/lien_waivers/${id}/`, method: 'get' }, options)
}

export const partialUpdateLienWaiver = (
  id: string,
  lienWaiverPatch: Partial<LienWaiverPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiver>(
    {
      url: `/billing/lien_waivers/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: lienWaiverPatch,
    },
    options,
  )
}

export const destroyLienWaiver = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/billing/lien_waivers/${id}/`, method: 'delete' }, options)
}

export const retrieveLienWaiverPdf = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/billing/lien_waivers/${id}/pdf/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listPrimeContractInvoiceEmails = (
  params?: ListPrimeContractInvoiceEmailsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListPrimeContractInvoiceEmails200>(
    { url: `/billing/prime_contract_invoice_emails/`, method: 'get', params },
    options,
  )
}

export const retrievePrimeContractInvoiceEmail = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PrimeContractInvoiceEmail>(
    { url: `/billing/prime_contract_invoice_emails/${id}/`, method: 'get' },
    options,
  )
}

export const listPrimeContractInvoiceEmailViews = (
  params?: ListPrimeContractInvoiceEmailViewsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListPrimeContractInvoiceEmailViews200>(
    { url: `/billing/prime_contract_invoice_email_views/`, method: 'get', params },
    options,
  )
}

export const retrievePrimeContractInvoiceEmailView = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PrimeContractInvoiceEmailView>(
    { url: `/billing/prime_contract_invoice_email_views/${id}/`, method: 'get' },
    options,
  )
}

export const listPrimeContractInvoicePaymentPageVisits = (
  params?: ListPrimeContractInvoicePaymentPageVisitsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListPrimeContractInvoicePaymentPageVisits200>(
    { url: `/billing/prime_contract_invoice_payment_page_visits/`, method: 'get', params },
    options,
  )
}

export const createPrimeContractInvoicePaymentPageVisit = (
  primeContractInvoicePaymentPageVisitCreate: PrimeContractInvoicePaymentPageVisitCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PrimeContractInvoicePaymentPageVisit>(
    {
      url: `/billing/prime_contract_invoice_payment_page_visits/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: primeContractInvoicePaymentPageVisitCreate,
    },
    options,
  )
}

export const retrievePrimeContractInvoicePaymentPageVisit = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PrimeContractInvoicePaymentPageVisit>(
    { url: `/billing/prime_contract_invoice_payment_page_visits/${id}/`, method: 'get' },
    options,
  )
}

export const listProjectExpensePermissions = (
  params?: ListProjectExpensePermissionsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProjectExpensePermissions200>(
    { url: `/billing/project_expense_permissions/`, method: 'get', params },
    options,
  )
}

export const retrieveProjectExpensePermission = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectExpensePermission>(
    { url: `/billing/project_expense_permissions/${id}/`, method: 'get' },
    options,
  )
}

export const listProjectExpenseReceiptEmbeddedFiles = (
  expenseId: string,
  params?: ListProjectExpenseReceiptEmbeddedFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProjectExpenseReceiptEmbeddedFiles200>(
    { url: `/billing/project_expenses/${expenseId}/receipts/`, method: 'get', params },
    options,
  )
}

export const createProjectExpenseReceiptEmbeddedFile = (
  expenseId: string,
  embeddedFileCreate: EmbeddedFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    {
      url: `/billing/project_expenses/${expenseId}/receipts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: embeddedFileCreate,
    },
    options,
  )
}

export const retrieveProjectExpenseReceiptEmbeddedFile = (
  expenseId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    { url: `/billing/project_expenses/${expenseId}/receipts/${id}/`, method: 'get' },
    options,
  )
}

export const destroyProjectExpenseReceiptEmbeddedFile = (
  expenseId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/billing/project_expenses/${expenseId}/receipts/${id}/`, method: 'delete' },
    options,
  )
}

export const retrieveProjectExpenseReceiptEmbeddedFileDownload = (
  expenseId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/billing/project_expenses/${expenseId}/receipts/${id}/download/`,
      method: 'get',
      responseType: 'blob',
    },
    options,
  )
}

export const listProjectExpenses = (
  params?: ListProjectExpensesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProjectExpenses200>(
    { url: `/billing/project_expenses/`, method: 'get', params },
    options,
  )
}

export const createProjectExpense = (
  projectExpenseCreate: ProjectExpenseCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectExpense>(
    {
      url: `/billing/project_expenses/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: projectExpenseCreate,
    },
    options,
  )
}

export const retrieveProjectExpense = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectExpense>(
    { url: `/billing/project_expenses/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateProjectExpense = (
  id: string,
  projectExpensePatch: Partial<ProjectExpensePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectExpense>(
    {
      url: `/billing/project_expenses/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: projectExpensePatch,
    },
    options,
  )
}

export const listProjectProgressSummaryV2s = (
  params?: ListProjectProgressSummaryV2sParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProjectProgressSummaryV2s200>(
    { url: `/billing/project_progress_summary_v2s/`, method: 'get', params },
    options,
  )
}

export const retrieveProjectProgressSummaryV2 = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectProgressSummaryV2>(
    { url: `/billing/project_progress_summary_v2s/${id}/`, method: 'get' },
    options,
  )
}

export const listScheduleOfValuesItemProgressSummarys = (
  params?: ListScheduleOfValuesItemProgressSummarysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListScheduleOfValuesItemProgressSummarys200>(
    { url: `/billing/schedule_of_values_item_progress_summaries/`, method: 'get', params },
    options,
  )
}

export const retrieveScheduleOfValuesItemProgressSummary = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ScheduleOfValuesItemProgressSummary>(
    { url: `/billing/schedule_of_values_item_progress_summaries/${id}/`, method: 'get' },
    options,
  )
}

export const getContractSummaryByItem = (
  params?: GetContractSummaryByItemParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetContractSummaryByItemViewOutput>(
    { url: `/billing/get_contract_summary_by_item/`, method: 'get', params },
    options,
  )
}

export const getExpenseMetrics = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<GetExpenseMetricsOutput>(
    { url: `/billing/get_expense_metrics/`, method: 'get' },
    options,
  )
}

export const getInvoiceApprovalWorkflowRequirements = (
  params: GetInvoiceApprovalWorkflowRequirementsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetInvoiceApprovalWorkflowRequirementsViewOutput>(
    { url: `/billing/get_invoice_approval_workflow_requirements/`, method: 'get', params },
    options,
  )
}

export const getInvoiceCardFee = (
  params: GetInvoiceCardFeeParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetInvoiceCardFeeOutput>(
    { url: `/billing/get_invoice_card_fee/`, method: 'get', params },
    options,
  )
}

export const getInvoiceFile = (
  params: GetInvoiceFileParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/billing/get_invoice_file/`, method: 'get', params, responseType: 'blob' },
    options,
  )
}

export const getInvoicePaymentStartDate = (
  params?: GetInvoicePaymentStartDateParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetInvoicePaymentStartDateViewOutput>(
    { url: `/billing/get_invoice_payment_start_date/`, method: 'get', params },
    options,
  )
}

export const getInvoiceSnapshotValues = (
  params: GetInvoiceSnapshotValuesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetInvoiceSnapshotValuesViewOutput>(
    { url: `/billing/get_invoice_snapshot_values/`, method: 'get', params },
    options,
  )
}

export const getLienWaiverPaymentBlockers = (
  params: GetLienWaiverPaymentBlockersParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetLienWaiverPaymentBlockersOutput>(
    { url: `/billing/get_lien_waiver_payment_blockers/`, method: 'get', params },
    options,
  )
}

export const getLienWaiverPdfPreviewV2 = (
  params: GetLienWaiverPdfPreviewV2Params,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/billing/get_lien_waiver_pdf_preview_v2/`,
      method: 'get',
      params,
      responseType: 'blob',
    },
    options,
  )
}

export const getNextInvoiceNumber = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<GetNextInvoiceNumberOutput>(
    { url: `/billing/get_next_invoice_number/`, method: 'get' },
    options,
  )
}

export const getRetainageValues = (
  params: GetRetainageValuesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetRetainageValuesViewOutput>(
    { url: `/billing/get_retainage_values/`, method: 'get', params },
    options,
  )
}

export const predictUncategorizedExpenseDetails = (
  params: PredictUncategorizedExpenseDetailsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PredictUncategorizedExpenseDetailsOutput>(
    { url: `/billing/predict_uncategorized_expense_details/`, method: 'get', params },
    options,
  )
}

export const listExpenseEmbeddedFiles = (
  params?: ListExpenseEmbeddedFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListExpenseEmbeddedFiles200>(
    { url: `/billing/expense_files/`, method: 'get', params },
    options,
  )
}

export const createExpenseEmbeddedFile = (
  expenseEmbeddedFileCreate: ExpenseEmbeddedFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ExpenseEmbeddedFile>(
    {
      url: `/billing/expense_files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: expenseEmbeddedFileCreate,
    },
    options,
  )
}

export const retrieveExpenseEmbeddedFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ExpenseEmbeddedFile>(
    { url: `/billing/expense_files/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateExpenseEmbeddedFile = (
  id: string,
  expenseEmbeddedFilePatch: Partial<ExpenseEmbeddedFilePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ExpenseEmbeddedFile>(
    {
      url: `/billing/expense_files/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: expenseEmbeddedFilePatch,
    },
    options,
  )
}

export const destroyExpenseEmbeddedFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>({ url: `/billing/expense_files/${id}/`, method: 'delete' }, options)
}

export const downloadExpenseEmbeddedFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/billing/expense_files/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listInvoiceApprovalRules = (
  params?: ListInvoiceApprovalRulesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoiceApprovalRules200>(
    { url: `/billing/invoice_approval_rules/`, method: 'get', params },
    options,
  )
}

export const createInvoiceApprovalRule = (
  invoiceApprovalRuleCreate: InvoiceApprovalRuleCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceApprovalRule>(
    {
      url: `/billing/invoice_approval_rules/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceApprovalRuleCreate,
    },
    options,
  )
}

export const retrieveInvoiceApprovalRule = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceApprovalRule>(
    { url: `/billing/invoice_approval_rules/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateInvoiceApprovalRule = (
  id: string,
  invoiceApprovalRulePatch: Partial<InvoiceApprovalRulePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceApprovalRule>(
    {
      url: `/billing/invoice_approval_rules/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceApprovalRulePatch,
    },
    options,
  )
}

export const destroyInvoiceApprovalRule = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/billing/invoice_approval_rules/${id}/`, method: 'delete' },
    options,
  )
}

export const listInvoiceFiles = (
  params?: ListInvoiceFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoiceFiles200>(
    { url: `/billing/invoice_files/`, method: 'get', params },
    options,
  )
}

export const createInvoiceFile = (
  invoiceFileCreate: InvoiceFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceFile>(
    {
      url: `/billing/invoice_files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceFileCreate,
    },
    options,
  )
}

export const retrieveInvoiceFile = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<InvoiceFile>({ url: `/billing/invoice_files/${id}/`, method: 'get' }, options)
}

export const partialUpdateInvoiceFile = (
  id: string,
  invoiceFilePatch: Partial<InvoiceFilePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceFile>(
    {
      url: `/billing/invoice_files/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceFilePatch,
    },
    options,
  )
}

export const destroyInvoiceFile = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/billing/invoice_files/${id}/`, method: 'delete' }, options)
}

export const downloadInvoiceFile = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Blob>(
    { url: `/billing/invoice_files/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listInvoiceIntentEmbeddedFiles = (
  params?: ListInvoiceIntentEmbeddedFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoiceIntentEmbeddedFiles200>(
    { url: `/billing/invoice_intent_files/`, method: 'get', params },
    options,
  )
}

export const createInvoiceIntentEmbeddedFile = (
  invoiceIntentEmbeddedFileCreate: InvoiceIntentEmbeddedFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceIntentEmbeddedFile>(
    {
      url: `/billing/invoice_intent_files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceIntentEmbeddedFileCreate,
    },
    options,
  )
}

export const retrieveInvoiceIntentEmbeddedFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceIntentEmbeddedFile>(
    { url: `/billing/invoice_intent_files/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateInvoiceIntentEmbeddedFile = (
  id: string,
  invoiceIntentEmbeddedFilePatch: Partial<InvoiceIntentEmbeddedFilePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceIntentEmbeddedFile>(
    {
      url: `/billing/invoice_intent_files/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceIntentEmbeddedFilePatch,
    },
    options,
  )
}

export const destroyInvoiceIntentEmbeddedFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/billing/invoice_intent_files/${id}/`, method: 'delete' },
    options,
  )
}

export const downloadInvoiceIntentEmbeddedFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/billing/invoice_intent_files/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listInvoiceIntents = (
  params?: ListInvoiceIntentsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoiceIntents200>(
    { url: `/billing/invoice_intents/`, method: 'get', params },
    options,
  )
}

export const createInvoiceIntent = (
  invoiceIntentCreate: InvoiceIntentCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceIntent>(
    {
      url: `/billing/invoice_intents/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceIntentCreate,
    },
    options,
  )
}

export const retrieveInvoiceIntent = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceIntent>(
    { url: `/billing/invoice_intents/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateInvoiceIntent = (
  id: string,
  invoiceIntentPatch: Partial<InvoiceIntentPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceIntent>(
    {
      url: `/billing/invoice_intents/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceIntentPatch,
    },
    options,
  )
}

export const destroyInvoiceIntent = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/billing/invoice_intents/${id}/`, method: 'delete' }, options)
}

export const listInvoiceLineItemMarkupLinks = (
  params?: ListInvoiceLineItemMarkupLinksParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoiceLineItemMarkupLinks200>(
    { url: `/billing/invoice_line_item_markup_links/`, method: 'get', params },
    options,
  )
}

export const createInvoiceLineItemMarkupLink = (
  invoiceLineItemMarkupLinkCreate: InvoiceLineItemMarkupLinkCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceLineItemMarkupLink>(
    {
      url: `/billing/invoice_line_item_markup_links/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceLineItemMarkupLinkCreate,
    },
    options,
  )
}

export const retrieveInvoiceLineItemMarkupLink = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceLineItemMarkupLink>(
    { url: `/billing/invoice_line_item_markup_links/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateInvoiceLineItemMarkupLink = (
  id: string,
  invoiceLineItemMarkupLinkPatch: Partial<InvoiceLineItemMarkupLinkPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceLineItemMarkupLink>(
    {
      url: `/billing/invoice_line_item_markup_links/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceLineItemMarkupLinkPatch,
    },
    options,
  )
}

export const destroyInvoiceLineItemMarkupLink = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/billing/invoice_line_item_markup_links/${id}/`, method: 'delete' },
    options,
  )
}

export const listInvoiceLineItems = (
  params?: ListInvoiceLineItemsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoiceLineItems200>(
    { url: `/billing/invoice_line_items/`, method: 'get', params },
    options,
  )
}

export const createInvoiceLineItem = (
  invoiceLineItemCreate: InvoiceLineItemCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceLineItem>(
    {
      url: `/billing/invoice_line_items/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceLineItemCreate,
    },
    options,
  )
}

export const retrieveInvoiceLineItem = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceLineItem>(
    { url: `/billing/invoice_line_items/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateInvoiceLineItem = (
  id: string,
  invoiceLineItemPatch: Partial<InvoiceLineItemPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceLineItem>(
    {
      url: `/billing/invoice_line_items/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceLineItemPatch,
    },
    options,
  )
}

export const destroyInvoiceLineItem = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>({ url: `/billing/invoice_line_items/${id}/`, method: 'delete' }, options)
}

export const listInvoicePrefillLineItems = (
  params?: ListInvoicePrefillLineItemsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoicePrefillLineItems200>(
    { url: `/billing/invoice_prefill_line_items/`, method: 'get', params },
    options,
  )
}

export const retrieveInvoicePrefillLineItem = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePrefillLineItem>(
    { url: `/billing/invoice_prefill_line_items/${id}/`, method: 'get' },
    options,
  )
}

export const listInvoicePrefills = (
  params?: ListInvoicePrefillsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoicePrefills200>(
    { url: `/billing/invoice_prefills/`, method: 'get', params },
    options,
  )
}

export const retrieveInvoicePrefill = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePrefill>(
    { url: `/billing/invoice_prefills/${id}/`, method: 'get' },
    options,
  )
}

export const listInvoicePrimaryFiles = (
  params?: ListInvoicePrimaryFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoicePrimaryFiles200>(
    { url: `/billing/invoice_primary_files/`, method: 'get', params },
    options,
  )
}

export const createInvoicePrimaryFile = (
  invoicePrimaryFileCreate: InvoicePrimaryFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePrimaryFile>(
    {
      url: `/billing/invoice_primary_files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoicePrimaryFileCreate,
    },
    options,
  )
}

export const retrieveInvoicePrimaryFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePrimaryFile>(
    { url: `/billing/invoice_primary_files/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateInvoicePrimaryFile = (
  id: string,
  invoicePrimaryFilePatch: Partial<InvoicePrimaryFilePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePrimaryFile>(
    {
      url: `/billing/invoice_primary_files/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: invoicePrimaryFilePatch,
    },
    options,
  )
}

export const destroyInvoicePrimaryFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/billing/invoice_primary_files/${id}/`, method: 'delete' },
    options,
  )
}

export const downloadInvoicePrimaryFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/billing/invoice_primary_files/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listLienWaiverFiles = (
  params?: ListLienWaiverFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListLienWaiverFiles200>(
    { url: `/billing/lien_waiver_files/`, method: 'get', params },
    options,
  )
}

export const createLienWaiverFile = (
  lienWaiverFileCreate: LienWaiverFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiverFile>(
    {
      url: `/billing/lien_waiver_files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: lienWaiverFileCreate,
    },
    options,
  )
}

export const retrieveLienWaiverFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiverFile>(
    { url: `/billing/lien_waiver_files/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateLienWaiverFile = (
  id: string,
  lienWaiverFilePatch: Partial<LienWaiverFilePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiverFile>(
    {
      url: `/billing/lien_waiver_files/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: lienWaiverFilePatch,
    },
    options,
  )
}

export const destroyLienWaiverFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>({ url: `/billing/lien_waiver_files/${id}/`, method: 'delete' }, options)
}

export const downloadLienWaiverFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/billing/lien_waiver_files/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listLienWaiverPrefills = (
  params?: ListLienWaiverPrefillsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListLienWaiverPrefills200>(
    { url: `/billing/lien_waiver_prefills/`, method: 'get', params },
    options,
  )
}

export const createLienWaiverPrefill = (
  lienWaiverPrefillCreate: LienWaiverPrefillCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiverPrefill>(
    {
      url: `/billing/lien_waiver_prefills/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: lienWaiverPrefillCreate,
    },
    options,
  )
}

export const retrieveLienWaiverPrefill = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiverPrefill>(
    { url: `/billing/lien_waiver_prefills/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateLienWaiverPrefill = (
  id: string,
  lienWaiverPrefillPatch: Partial<LienWaiverPrefillPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiverPrefill>(
    {
      url: `/billing/lien_waiver_prefills/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: lienWaiverPrefillPatch,
    },
    options,
  )
}

export const listLienWaiverPrimaryFiles = (
  params?: ListLienWaiverPrimaryFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListLienWaiverPrimaryFiles200>(
    { url: `/billing/lien_waiver_primary_files/`, method: 'get', params },
    options,
  )
}

export const createLienWaiverPrimaryFile = (
  lienWaiverPrimaryFileCreate: LienWaiverPrimaryFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiverPrimaryFile>(
    {
      url: `/billing/lien_waiver_primary_files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: lienWaiverPrimaryFileCreate,
    },
    options,
  )
}

export const retrieveLienWaiverPrimaryFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiverPrimaryFile>(
    { url: `/billing/lien_waiver_primary_files/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateLienWaiverPrimaryFile = (
  id: string,
  lienWaiverPrimaryFilePatch: Partial<LienWaiverPrimaryFilePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LienWaiverPrimaryFile>(
    {
      url: `/billing/lien_waiver_primary_files/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: lienWaiverPrimaryFilePatch,
    },
    options,
  )
}

export const destroyLienWaiverPrimaryFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/billing/lien_waiver_primary_files/${id}/`, method: 'delete' },
    options,
  )
}

export const downloadLienWaiverPrimaryFile = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/billing/lien_waiver_primary_files/${id}/download/`,
      method: 'get',
      responseType: 'blob',
    },
    options,
  )
}

export const listPaymentSources = (
  params?: ListPaymentSourcesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListPaymentSources200>(
    { url: `/billing/payment_sources/`, method: 'get', params },
    options,
  )
}

export const createPaymentSource = (
  paymentSourceCreate: PaymentSourceCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PaymentSource>(
    {
      url: `/billing/payment_sources/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: paymentSourceCreate,
    },
    options,
  )
}

export const retrievePaymentSource = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PaymentSource>(
    { url: `/billing/payment_sources/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdatePaymentSource = (
  id: string,
  paymentSourcePatch: Partial<PaymentSourcePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PaymentSource>(
    {
      url: `/billing/payment_sources/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: paymentSourcePatch,
    },
    options,
  )
}

export const listTimeEntrys = (
  params?: ListTimeEntrysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListTimeEntrys200>(
    { url: `/billing/time_entries/`, method: 'get', params },
    options,
  )
}

export const createTimeEntry = (
  timeEntryCreate: TimeEntryCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<TimeEntry>(
    {
      url: `/billing/time_entries/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: timeEntryCreate,
    },
    options,
  )
}

export const retrieveTimeEntry = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<TimeEntry>({ url: `/billing/time_entries/${id}/`, method: 'get' }, options)
}

export const partialUpdateTimeEntry = (
  id: string,
  timeEntryPatch: Partial<TimeEntryPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<TimeEntry>(
    {
      url: `/billing/time_entries/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: timeEntryPatch,
    },
    options,
  )
}

export const listVendorBillingConfigs = (
  params?: ListVendorBillingConfigsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListVendorBillingConfigs200>(
    { url: `/billing/vendor_billing_configs/`, method: 'get', params },
    options,
  )
}

export const createVendorBillingConfig = (
  vendorBillingConfigCreate: VendorBillingConfigCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<VendorBillingConfig>(
    {
      url: `/billing/vendor_billing_configs/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: vendorBillingConfigCreate,
    },
    options,
  )
}

export const retrieveVendorBillingConfig = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<VendorBillingConfig>(
    { url: `/billing/vendor_billing_configs/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateVendorBillingConfig = (
  id: string,
  vendorBillingConfigPatch: Partial<VendorBillingConfigPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<VendorBillingConfig>(
    {
      url: `/billing/vendor_billing_configs/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: vendorBillingConfigPatch,
    },
    options,
  )
}

export const listWorkCompletedSources = (
  params?: ListWorkCompletedSourcesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListWorkCompletedSources200>(
    { url: `/billing/work_completed_sources/`, method: 'get', params },
    options,
  )
}

export const createWorkCompletedSource = (
  workCompletedSourceCreate: WorkCompletedSourceCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<WorkCompletedSource>(
    {
      url: `/billing/work_completed_sources/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: workCompletedSourceCreate,
    },
    options,
  )
}

export const retrieveWorkCompletedSource = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<WorkCompletedSource>(
    { url: `/billing/work_completed_sources/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateWorkCompletedSource = (
  id: string,
  workCompletedSourcePatch: Partial<WorkCompletedSourcePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<WorkCompletedSource>(
    {
      url: `/billing/work_completed_sources/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: workCompletedSourcePatch,
    },
    options,
  )
}

export const destroyWorkCompletedSource = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/billing/work_completed_sources/${id}/`, method: 'delete' },
    options,
  )
}

export const listDummys = (
  params?: ListDummysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListDummys200>({ url: `/users/dummy/`, method: 'get', params }, options)
}

export const listEmailVerificationAttempts = (
  params?: ListEmailVerificationAttemptsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEmailVerificationAttempts200>(
    { url: `/users/email_verification_attempts/`, method: 'get', params },
    options,
  )
}

export const createEmailVerificationAttempt = (
  emailVerificationAttemptCreate: EmailVerificationAttemptCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmailVerificationAttempt>(
    {
      url: `/users/email_verification_attempts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: emailVerificationAttemptCreate,
    },
    options,
  )
}

export const retrieveEmailVerificationAttempt = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmailVerificationAttempt>(
    { url: `/users/email_verification_attempts/${id}/`, method: 'get' },
    options,
  )
}

export const listEmailVerifications = (
  params?: ListEmailVerificationsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEmailVerifications200>(
    { url: `/users/email_verifications/`, method: 'get', params },
    options,
  )
}

export const createEmailVerification = (
  emailVerificationCreate: EmailVerificationCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmailVerification>(
    {
      url: `/users/email_verifications/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: emailVerificationCreate,
    },
    options,
  )
}

export const retrieveEmailVerification = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmailVerification>(
    { url: `/users/email_verifications/${id}/`, method: 'get' },
    options,
  )
}

export const listUsers = (
  params?: ListUsersParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListUsers200>({ url: `/users/`, method: 'get', params }, options)
}

export const createUser = (
  userCreate: UserCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<User>(
    {
      url: `/users/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: userCreate,
    },
    options,
  )
}

export const retrieveUser = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<User>({ url: `/users/${id}/`, method: 'get' }, options)
}

export const partialUpdateUser = (
  id: string,
  userPatch: Partial<UserPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<User>(
    {
      url: `/users/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: userPatch,
    },
    options,
  )
}

export const listCards = (
  params?: ListCardsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListCards200>({ url: `/banking/cards/`, method: 'get', params }, options)
}

export const retrieveCard = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Card>({ url: `/banking/cards/${id}/`, method: 'get' }, options)
}

export const listMicrodepositRequirements = (
  params?: ListMicrodepositRequirementsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListMicrodepositRequirements200>(
    { url: `/banking/microdeposit_requirements/`, method: 'get', params },
    options,
  )
}

export const retrieveMicrodepositRequirement = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<MicrodepositRequirement>(
    { url: `/banking/microdeposit_requirements/${id}/`, method: 'get' },
    options,
  )
}

export const listTransactionAccounts = (
  params?: ListTransactionAccountsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListTransactionAccounts200>(
    { url: `/banking/transaction_accounts/`, method: 'get', params },
    options,
  )
}

export const retrieveTransactionAccount = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<TransactionAccount>(
    { url: `/banking/transaction_accounts/${id}/`, method: 'get' },
    options,
  )
}

export const getBankName = (
  params: GetBankNameParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetBankNameViewOutput>(
    { url: `/banking/get_bank_name/`, method: 'get', params },
    options,
  )
}

export const getStatementPDF = (
  params: GetStatementPDFParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/banking/get_statement_pdf/`, method: 'get', params, responseType: 'blob' },
    options,
  )
}

export const listBankAccounts = (
  params?: ListBankAccountsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListBankAccounts200>(
    { url: `/banking/bank_accounts/`, method: 'get', params },
    options,
  )
}

export const createBankAccount = (
  bankAccountCreate: BankAccountCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BankAccount>(
    {
      url: `/banking/bank_accounts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: bankAccountCreate,
    },
    options,
  )
}

export const retrieveBankAccount = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<BankAccount>({ url: `/banking/bank_accounts/${id}/`, method: 'get' }, options)
}

export const partialUpdateBankAccount = (
  id: string,
  bankAccountPatch: Partial<BankAccountPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BankAccount>(
    {
      url: `/banking/bank_accounts/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: bankAccountPatch,
    },
    options,
  )
}

export const destroyBankAccount = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/banking/bank_accounts/${id}/`, method: 'delete' }, options)
}

export const getBankAccountArchiveRestrictions = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ArchiveRestriction>(
    { url: `/banking/bank_accounts/${id}/archive_restrictions/`, method: 'get' },
    options,
  )
}

export const listControlledPayeeBankAccounts = (
  params?: ListControlledPayeeBankAccountsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListControlledPayeeBankAccounts200>(
    { url: `/banking/controlled_payee_bank_accounts/`, method: 'get', params },
    options,
  )
}

export const createControlledPayeeBankAccount = (
  controlledPayeeBankAccountCreate: ControlledPayeeBankAccountCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ControlledPayeeBankAccount>(
    {
      url: `/banking/controlled_payee_bank_accounts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: controlledPayeeBankAccountCreate,
    },
    options,
  )
}

export const retrieveControlledPayeeBankAccount = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ControlledPayeeBankAccount>(
    { url: `/banking/controlled_payee_bank_accounts/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateControlledPayeeBankAccount = (
  id: string,
  controlledPayeeBankAccountPatch: Partial<ControlledPayeeBankAccountPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ControlledPayeeBankAccount>(
    {
      url: `/banking/controlled_payee_bank_accounts/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: controlledPayeeBankAccountPatch,
    },
    options,
  )
}

export const getControlledPayeeBankAccountArchiveRestrictions = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ArchiveRestriction>(
    { url: `/banking/controlled_payee_bank_accounts/${id}/archive_restrictions/`, method: 'get' },
    options,
  )
}

export const listPayeeBankAccounts = (
  params?: ListPayeeBankAccountsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListPayeeBankAccounts200>(
    { url: `/banking/payee_bank_accounts/`, method: 'get', params },
    options,
  )
}

export const retrievePayeeBankAccount = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PayeeBankAccount>(
    { url: `/banking/payee_bank_accounts/${id}/`, method: 'get' },
    options,
  )
}

export const getPayeeBankAccountArchiveRestrictions = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ArchiveRestriction>(
    { url: `/banking/payee_bank_accounts/${id}/archive_restrictions/`, method: 'get' },
    options,
  )
}

export const listCashBackPayments = (
  params?: ListCashBackPaymentsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListCashBackPayments200>(
    { url: `/fees/cash_back_payments/`, method: 'get', params },
    options,
  )
}

export const retrieveCashBackPayment = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CashBackPayment>(
    { url: `/fees/cash_back_payments/${id}/`, method: 'get' },
    options,
  )
}

export const listSaasTiers = (
  params?: ListSaasTiersParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListSaasTiers200>({ url: `/fees/saas_tiers/`, method: 'get', params }, options)
}

export const retrieveSaasTier = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<SaasTier>({ url: `/fees/saas_tiers/${id}/`, method: 'get' }, options)
}

export const listYieldPayments = (
  params?: ListYieldPaymentsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListYieldPayments200>(
    { url: `/fees/yield_payments/`, method: 'get', params },
    options,
  )
}

export const retrieveYieldPayment = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<YieldPayment>({ url: `/fees/yield_payments/${id}/`, method: 'get' }, options)
}

export const getProjectBudgetCost = (
  params: GetProjectBudgetCostParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetProjectBudgetCostViewOutput>(
    { url: `/budgeting/get_project_budget_cost/`, method: 'get', params },
    options,
  )
}

export const listBudgetConfigs = (
  params?: ListBudgetConfigsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListBudgetConfigs200>(
    { url: `/budgeting/budget_configs/`, method: 'get', params },
    options,
  )
}

export const createBudgetConfig = (
  budgetConfigCreate: BudgetConfigCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BudgetConfig>(
    {
      url: `/budgeting/budget_configs/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: budgetConfigCreate,
    },
    options,
  )
}

export const retrieveBudgetConfig = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<BudgetConfig>(
    { url: `/budgeting/budget_configs/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateBudgetConfig = (
  id: string,
  budgetConfigPatch: Partial<BudgetConfigPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BudgetConfig>(
    {
      url: `/budgeting/budget_configs/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: budgetConfigPatch,
    },
    options,
  )
}

export const retrieveSingletonBudgetConfig = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<BudgetConfig>(
    { url: `/budgeting/budget_configs/singleton/`, method: 'get' },
    options,
  )
}

export const listProjectBudgetItems = (
  params?: ListProjectBudgetItemsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProjectBudgetItems200>(
    { url: `/budgeting/project_budget_items/`, method: 'get', params },
    options,
  )
}

export const createProjectBudgetItem = (
  projectBudgetItemCreate: ProjectBudgetItemCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectBudgetItem>(
    {
      url: `/budgeting/project_budget_items/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: projectBudgetItemCreate,
    },
    options,
  )
}

export const retrieveProjectBudgetItem = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectBudgetItem>(
    { url: `/budgeting/project_budget_items/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateProjectBudgetItem = (
  id: string,
  projectBudgetItemPatch: Partial<ProjectBudgetItemPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectBudgetItem>(
    {
      url: `/budgeting/project_budget_items/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: projectBudgetItemPatch,
    },
    options,
  )
}

export const destroyProjectBudgetItem = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/budgeting/project_budget_items/${id}/`, method: 'delete' },
    options,
  )
}

export const listProjectBudgets = (
  params?: ListProjectBudgetsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProjectBudgets200>(
    { url: `/budgeting/project_budgets/`, method: 'get', params },
    options,
  )
}

export const createProjectBudget = (
  projectBudgetCreate: ProjectBudgetCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectBudget>(
    {
      url: `/budgeting/project_budgets/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: projectBudgetCreate,
    },
    options,
  )
}

export const retrieveProjectBudget = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectBudget>(
    { url: `/budgeting/project_budgets/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateProjectBudget = (
  id: string,
  projectBudgetPatch: Partial<ProjectBudgetPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectBudget>(
    {
      url: `/budgeting/project_budgets/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: projectBudgetPatch,
    },
    options,
  )
}

export const listTasks = (
  params?: ListTasksParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListTasks200>({ url: `/tasks/`, method: 'get', params }, options)
}

export const retrieveTask = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Task>({ url: `/tasks/${id}/`, method: 'get' }, options)
}

export const retrieveFileDownload = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Blob>(
    { url: `/files/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const getStripeIdentityVerificationSessionUrl = (
  params: GetStripeIdentityVerificationSessionUrlParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetStripeIdentityVerificationSessionUrlOutput>(
    { url: `/onboarding/get_stripe_identity_verification_session_url/`, method: 'get', params },
    options,
  )
}

export const whoAmI = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<WhoAmIViewOutput>({ url: `/onboarding/whoami/`, method: 'get' }, options)
}

export const listOnboardingSurveys = (
  params?: ListOnboardingSurveysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListOnboardingSurveys200>(
    { url: `/onboarding/onboarding_surveys/`, method: 'get', params },
    options,
  )
}

export const createOnboardingSurvey = (
  onboardingSurveyCreate: OnboardingSurveyCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<OnboardingSurvey>(
    {
      url: `/onboarding/onboarding_surveys/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: onboardingSurveyCreate,
    },
    options,
  )
}

export const retrieveOnboardingSurvey = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<OnboardingSurvey>(
    { url: `/onboarding/onboarding_surveys/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateOnboardingSurvey = (
  id: string,
  onboardingSurveyPatch: Partial<OnboardingSurveyPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<OnboardingSurvey>(
    {
      url: `/onboarding/onboarding_surveys/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: onboardingSurveyPatch,
    },
    options,
  )
}

export const listStripeIdentityVerificationSessions = (
  params?: ListStripeIdentityVerificationSessionsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListStripeIdentityVerificationSessions200>(
    { url: `/onboarding/stripe_identity_verification_sessions/`, method: 'get', params },
    options,
  )
}

export const retrieveStripeIdentityVerificationSession = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<StripeIdentityVerificationSession>(
    { url: `/onboarding/stripe_identity_verification_sessions/${id}/`, method: 'get' },
    options,
  )
}

export const listComplianceDocumentEmbeddedFiles = (
  complianceDocumentId: string,
  params?: ListComplianceDocumentEmbeddedFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListComplianceDocumentEmbeddedFiles200>(
    {
      url: `/compliance/compliance_documents/${complianceDocumentId}/files/`,
      method: 'get',
      params,
    },
    options,
  )
}

export const retrieveComplianceDocumentEmbeddedFile = (
  complianceDocumentId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    { url: `/compliance/compliance_documents/${complianceDocumentId}/files/${id}/`, method: 'get' },
    options,
  )
}

export const retrieveComplianceDocumentEmbeddedFileDownload = (
  complianceDocumentId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/compliance/compliance_documents/${complianceDocumentId}/files/${id}/download/`,
      method: 'get',
      responseType: 'blob',
    },
    options,
  )
}

export const listComplianceDocuments = (
  params?: ListComplianceDocumentsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListComplianceDocuments200>(
    { url: `/compliance/compliance_documents/`, method: 'get', params },
    options,
  )
}

export const createComplianceDocument = (
  complianceDocumentCreate: ComplianceDocumentCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ComplianceDocument>(
    {
      url: `/compliance/compliance_documents/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: complianceDocumentCreate,
    },
    options,
  )
}

export const retrieveComplianceDocument = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ComplianceDocument>(
    { url: `/compliance/compliance_documents/${id}/`, method: 'get' },
    options,
  )
}

export const destroyComplianceDocument = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/compliance/compliance_documents/${id}/`, method: 'delete' },
    options,
  )
}

export const listComplianceSettingsLists = (
  params?: ListComplianceSettingsListsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListComplianceSettingsLists200>(
    { url: `/compliance/compliance_settings_lists/`, method: 'get', params },
    options,
  )
}

export const retrieveSingletonComplianceSettingsList = (
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ComplianceSettingsList>(
    { url: `/compliance/compliance_settings_lists/singleton/`, method: 'get' },
    options,
  )
}

export const retrieveComplianceSettingsList = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ComplianceSettingsList>(
    { url: `/compliance/compliance_settings_lists/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateComplianceSettingsList = (
  id: string,
  complianceSettingsListPatch: Partial<ComplianceSettingsListPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ComplianceSettingsList>(
    {
      url: `/compliance/compliance_settings_lists/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: complianceSettingsListPatch,
    },
    options,
  )
}

export const listComplianceStatusSummarys = (
  params?: ListComplianceStatusSummarysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListComplianceStatusSummarys200>(
    { url: `/compliance/compliance_status_summarys/`, method: 'get', params },
    options,
  )
}

export const retrieveComplianceStatusSummary = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ComplianceStatusSummary>(
    { url: `/compliance/compliance_status_summarys/${id}/`, method: 'get' },
    options,
  )
}

export const listPayeeComplianceRequirements = (
  params?: ListPayeeComplianceRequirementsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListPayeeComplianceRequirements200>(
    { url: `/compliance/payee_compliance_requirements/`, method: 'get', params },
    options,
  )
}

export const retrievePayeeComplianceRequirement = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PayeeComplianceRequirement>(
    { url: `/compliance/payee_compliance_requirements/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdatePayeeComplianceRequirement = (
  id: string,
  payeeComplianceRequirementPatch: Partial<PayeeComplianceRequirementPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PayeeComplianceRequirement>(
    {
      url: `/compliance/payee_compliance_requirements/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: payeeComplianceRequirementPatch,
    },
    options,
  )
}

export const listRequirementLists = (
  params?: ListRequirementListsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListRequirementLists200>(
    { url: `/compliance/requirement_lists/`, method: 'get', params },
    options,
  )
}

export const retrieveRequirementList = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<RequirementList>(
    { url: `/compliance/requirement_lists/${id}/`, method: 'get' },
    options,
  )
}

export const getCombinedComplianceSettingsListAsPayee = (
  params: GetCombinedComplianceSettingsListAsPayeeParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetCombinedComplianceSettingsListAsPayeeViewOutput>(
    { url: `/compliance/get_combined_compliance_settings_list_as_payee/`, method: 'get', params },
    options,
  )
}

export const getComplianceSettingsListForPayee = (
  params: GetComplianceSettingsListForPayeeParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetComplianceSettingsListForPayeeViewOutput>(
    { url: `/compliance/get_compliance_settings_list_for_payee/`, method: 'get', params },
    options,
  )
}

export const listComplianceSettingsListPayeeOverrides = (
  params?: ListComplianceSettingsListPayeeOverridesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListComplianceSettingsListPayeeOverrides200>(
    { url: `/compliance/compliance_settings_list_payee_overrides/`, method: 'get', params },
    options,
  )
}

export const createComplianceSettingsListPayeeOverride = (
  complianceSettingsListPayeeOverrideCreate: ComplianceSettingsListPayeeOverrideCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ComplianceSettingsListPayeeOverride>(
    {
      url: `/compliance/compliance_settings_list_payee_overrides/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: complianceSettingsListPayeeOverrideCreate,
    },
    options,
  )
}

export const retrieveComplianceSettingsListPayeeOverride = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ComplianceSettingsListPayeeOverride>(
    { url: `/compliance/compliance_settings_list_payee_overrides/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateComplianceSettingsListPayeeOverride = (
  id: string,
  complianceSettingsListPayeeOverridePatch: Partial<ComplianceSettingsListPayeeOverridePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ComplianceSettingsListPayeeOverride>(
    {
      url: `/compliance/compliance_settings_list_payee_overrides/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: complianceSettingsListPayeeOverridePatch,
    },
    options,
  )
}

export const destroyComplianceSettingsListPayeeOverride = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/compliance/compliance_settings_list_payee_overrides/${id}/`, method: 'delete' },
    options,
  )
}

export const listEstimateApprovalSignatures = (
  estimateId: string,
  params?: ListEstimateApprovalSignaturesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimateApprovalSignatures200>(
    { url: `/estimates/${estimateId}/approval_signature/`, method: 'get', params },
    options,
  )
}

export const retrieveEstimateApprovalSignature = (
  estimateId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateApprovalSignature>(
    { url: `/estimates/${estimateId}/approval_signature/${id}/`, method: 'get' },
    options,
  )
}

export const listEstimateEmails = (
  params?: ListEstimateEmailsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimateEmails200>(
    { url: `/estimates/estimate_emails/`, method: 'get', params },
    options,
  )
}

export const retrieveEstimateEmail = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateEmail>(
    { url: `/estimates/estimate_emails/${id}/`, method: 'get' },
    options,
  )
}

export const listEstimateEmailViews = (
  params?: ListEstimateEmailViewsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimateEmailViews200>(
    { url: `/estimates/estimate_email_views/`, method: 'get', params },
    options,
  )
}

export const retrieveEstimateEmailView = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateEmailView>(
    { url: `/estimates/estimate_email_views/${id}/`, method: 'get' },
    options,
  )
}

export const listEstimateEmbeddedFiles = (
  estimateId: string,
  params?: ListEstimateEmbeddedFilesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimateEmbeddedFiles200>(
    { url: `/estimates/${estimateId}/files/`, method: 'get', params },
    options,
  )
}

export const createEstimateEmbeddedFile = (
  estimateId: string,
  embeddedFileCreate: EmbeddedFileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    {
      url: `/estimates/${estimateId}/files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: embeddedFileCreate,
    },
    options,
  )
}

export const retrieveEstimateEmbeddedFile = (
  estimateId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EmbeddedFile>(
    { url: `/estimates/${estimateId}/files/${id}/`, method: 'get' },
    options,
  )
}

export const retrieveEstimateEmbeddedFileDownload = (
  estimateId: string,
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/estimates/${estimateId}/files/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const retrieveEstimateFileDownload = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/estimates/estimate_files/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listEstimatePageVisits = (
  params?: ListEstimatePageVisitsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimatePageVisits200>(
    { url: `/estimates/estimate_page_visits/`, method: 'get', params },
    options,
  )
}

export const createEstimatePageVisit = (
  estimatePaymentPageVisitCreate: EstimatePaymentPageVisitCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimatePaymentPageVisit>(
    {
      url: `/estimates/estimate_page_visits/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: estimatePaymentPageVisitCreate,
    },
    options,
  )
}

export const retrieveEstimatePageVisit = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimatePaymentPageVisit>(
    { url: `/estimates/estimate_page_visits/${id}/`, method: 'get' },
    options,
  )
}

export const listEstimates = (
  params?: ListEstimatesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimates200>({ url: `/estimates/`, method: 'get', params }, options)
}

export const createEstimate = (
  estimateCreate: EstimateCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Estimate>(
    {
      url: `/estimates/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: estimateCreate,
    },
    options,
  )
}

export const retrieveEstimate = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Estimate>({ url: `/estimates/${id}/`, method: 'get' }, options)
}

export const partialUpdateEstimate = (
  id: string,
  estimatePatch: Partial<EstimatePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Estimate>(
    {
      url: `/estimates/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: estimatePatch,
    },
    options,
  )
}

export const destroyEstimate = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/estimates/${id}/`, method: 'delete' }, options)
}

export const retrieveEstimatePdf = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Blob>(
    { url: `/estimates/${id}/pdf/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const getEstimateCoverPagePdfPreview = (
  params: GetEstimateCoverPagePdfPreviewParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/estimates/get_estimate_cover_page_pdf_preview/`,
      method: 'get',
      params,
      responseType: 'blob',
    },
    options,
  )
}

export const getEstimateInputFromFile = (
  params: GetEstimateInputFromFileParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetEstimateInputFromFileViewOutput>(
    { url: `/estimates/get_estimate_input_from_file/`, method: 'get', params },
    options,
  )
}

export const getNextEstimateNumber = (
  params: GetNextEstimateNumberParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetNextEstimateNumberOutput>(
    { url: `/estimates/get_next_estimate_number/`, method: 'get', params },
    options,
  )
}

export const listCostLibraryItems = (
  params?: ListCostLibraryItemsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListCostLibraryItems200>(
    { url: `/estimates/cost_library_items/`, method: 'get', params },
    options,
  )
}

export const createCostLibraryItem = (
  costLibraryItemCreate: CostLibraryItemCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CostLibraryItem>(
    {
      url: `/estimates/cost_library_items/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: costLibraryItemCreate,
    },
    options,
  )
}

export const retrieveCostLibraryItem = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CostLibraryItem>(
    { url: `/estimates/cost_library_items/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateCostLibraryItem = (
  id: string,
  costLibraryItemPatch: Partial<CostLibraryItemPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CostLibraryItem>(
    {
      url: `/estimates/cost_library_items/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: costLibraryItemPatch,
    },
    options,
  )
}

export const listEstimateConfigs = (
  params?: ListEstimateConfigsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimateConfigs200>(
    { url: `/estimates/estimate_configs/`, method: 'get', params },
    options,
  )
}

export const createEstimateConfig = (
  estimateConfigCreate: EstimateConfigCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateConfig>(
    {
      url: `/estimates/estimate_configs/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: estimateConfigCreate,
    },
    options,
  )
}

export const retrieveEstimateConfig = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateConfig>(
    { url: `/estimates/estimate_configs/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateEstimateConfig = (
  id: string,
  estimateConfigPatch: Partial<EstimateConfigPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateConfig>(
    {
      url: `/estimates/estimate_configs/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: estimateConfigPatch,
    },
    options,
  )
}

export const retrieveSingletonEstimateConfig = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<EstimateConfig>(
    { url: `/estimates/estimate_configs/singleton/`, method: 'get' },
    options,
  )
}

export const listEstimateCoverPagePhotos = (
  params?: ListEstimateCoverPagePhotosParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimateCoverPagePhotos200>(
    { url: `/estimates/estimate_cover_page_photos/`, method: 'get', params },
    options,
  )
}

export const createEstimateCoverPagePhoto = (
  estimateCoverPagePhotoCreate: EstimateCoverPagePhotoCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateCoverPagePhoto>(
    {
      url: `/estimates/estimate_cover_page_photos/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: estimateCoverPagePhotoCreate,
    },
    options,
  )
}

export const retrieveEstimateCoverPagePhoto = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateCoverPagePhoto>(
    { url: `/estimates/estimate_cover_page_photos/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateEstimateCoverPagePhoto = (
  id: string,
  estimateCoverPagePhotoPatch: Partial<EstimateCoverPagePhotoPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateCoverPagePhoto>(
    {
      url: `/estimates/estimate_cover_page_photos/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: estimateCoverPagePhotoPatch,
    },
    options,
  )
}

export const destroyEstimateCoverPagePhoto = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/estimates/estimate_cover_page_photos/${id}/`, method: 'delete' },
    options,
  )
}

export const downloadEstimateCoverPagePhoto = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/estimates/estimate_cover_page_photos/${id}/download/`,
      method: 'get',
      responseType: 'blob',
    },
    options,
  )
}

export const listEstimateCoverPages = (
  params?: ListEstimateCoverPagesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimateCoverPages200>(
    { url: `/estimates/estimate_cover_pages/`, method: 'get', params },
    options,
  )
}

export const createEstimateCoverPage = (
  estimateCoverPageCreate: EstimateCoverPageCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateCoverPage>(
    {
      url: `/estimates/estimate_cover_pages/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: estimateCoverPageCreate,
    },
    options,
  )
}

export const retrieveEstimateCoverPage = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateCoverPage>(
    { url: `/estimates/estimate_cover_pages/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateEstimateCoverPage = (
  id: string,
  estimateCoverPagePatch: Partial<EstimateCoverPagePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateCoverPage>(
    {
      url: `/estimates/estimate_cover_pages/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: estimateCoverPagePatch,
    },
    options,
  )
}

export const destroyEstimateCoverPage = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/estimates/estimate_cover_pages/${id}/`, method: 'delete' },
    options,
  )
}

export const downloadEstimateCoverPage = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    { url: `/estimates/estimate_cover_pages/${id}/download/`, method: 'get', responseType: 'blob' },
    options,
  )
}

export const listEstimateUploadedCoverPages = (
  params?: ListEstimateUploadedCoverPagesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimateUploadedCoverPages200>(
    { url: `/estimates/estimate_uploaded_cover_pages/`, method: 'get', params },
    options,
  )
}

export const createEstimateUploadedCoverPage = (
  estimateUploadedCoverPageCreate: EstimateUploadedCoverPageCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateUploadedCoverPage>(
    {
      url: `/estimates/estimate_uploaded_cover_pages/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: estimateUploadedCoverPageCreate,
    },
    options,
  )
}

export const retrieveEstimateUploadedCoverPage = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateUploadedCoverPage>(
    { url: `/estimates/estimate_uploaded_cover_pages/${id}/`, method: 'get' },
    options,
  )
}

export const partialUpdateEstimateUploadedCoverPage = (
  id: string,
  estimateUploadedCoverPagePatch: Partial<EstimateUploadedCoverPagePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateUploadedCoverPage>(
    {
      url: `/estimates/estimate_uploaded_cover_pages/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: estimateUploadedCoverPagePatch,
    },
    options,
  )
}

export const destroyEstimateUploadedCoverPage = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<void>(
    { url: `/estimates/estimate_uploaded_cover_pages/${id}/`, method: 'delete' },
    options,
  )
}

export const downloadEstimateUploadedCoverPage = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Blob>(
    {
      url: `/estimates/estimate_uploaded_cover_pages/${id}/download/`,
      method: 'get',
      responseType: 'blob',
    },
    options,
  )
}

export const listEstimatesAsPayeePageSummarys = (
  params?: ListEstimatesAsPayeePageSummarysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimatesAsPayeePageSummarys200>(
    { url: `/estimates/estimates_as_payee_page_summarys/`, method: 'get', params },
    options,
  )
}

export const retrieveEstimatesAsPayeePageSummary = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimatesAsPayeePageSummary>(
    { url: `/estimates/estimates_as_payee_page_summarys/${id}/`, method: 'get' },
    options,
  )
}

export const listAccounts = (
  params?: ListAccountsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListAccounts200>({ url: `/agave/accounts/`, method: 'get', params }, options)
}

export const createAccount = (
  accountCreate: AccountCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Account>(
    {
      url: `/agave/accounts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: accountCreate,
    },
    options,
  )
}

export const retrieveAccount = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Account>({ url: `/agave/accounts/${id}/`, method: 'get' }, options)
}

export const partialUpdateAccount = (
  id: string,
  accountPatch: Partial<AccountPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Account>(
    {
      url: `/agave/accounts/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: accountPatch,
    },
    options,
  )
}

export const listInvoiceOutboundSyncV2s = (
  params?: ListInvoiceOutboundSyncV2sParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoiceOutboundSyncV2s200>(
    { url: `/agave/invoice_outbound_syncs_v2/`, method: 'get', params },
    options,
  )
}

export const retrieveInvoiceOutboundSyncV2 = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceOutboundSyncV2>(
    { url: `/agave/invoice_outbound_syncs_v2/${id}/`, method: 'get' },
    options,
  )
}

export const getLinkToken = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<GetLinkTokenOutput>({ url: `/agave/get_link_token/`, method: 'get' }, options)
}

export const getUpdatedTokenStatus = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<GetUpdatedTokenStatusOutput>(
    { url: `/agave/get_updated_token_status/`, method: 'get' },
    options,
  )
}

export const listProjectImportRequests = (
  params?: ListProjectImportRequestsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProjectImportRequests200>(
    { url: `/agave/project_import_requests/`, method: 'get', params },
    options,
  )
}

export const createProjectImportRequest = (
  projectImportRequestCreate: ProjectImportRequestCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectImportRequest>(
    {
      url: `/agave/project_import_requests/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: projectImportRequestCreate,
    },
    options,
  )
}

export const retrieveProjectImportRequest = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectImportRequest>(
    { url: `/agave/project_import_requests/${id}/`, method: 'get' },
    options,
  )
}

export const listQBProjects = (
  params?: ListQBProjectsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListQBProjects200>(
    { url: `/agave/qb_projects/`, method: 'get', params },
    options,
  )
}

export const retrieveQBProject = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<QBProject>({ url: `/agave/qb_projects/${id}/`, method: 'get' }, options)
}

export const listQBPullRequests = (
  params?: ListQBPullRequestsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListQBPullRequests200>(
    { url: `/agave/qb_pull_requests/`, method: 'get', params },
    options,
  )
}

export const retrieveQBPullRequest = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<QBPullRequest>(
    { url: `/agave/qb_pull_requests/${id}/`, method: 'get' },
    options,
  )
}

export const listQBPushRequests = (
  params?: ListQBPushRequestsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListQBPushRequests200>(
    { url: `/agave/qb_push_requests/`, method: 'get', params },
    options,
  )
}

export const retrieveQBPushRequest = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<QBPushRequest>(
    { url: `/agave/qb_push_requests/${id}/`, method: 'get' },
    options,
  )
}

export const listQboLinks = (
  params?: ListQboLinksParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListQboLinks200>({ url: `/agave/qbo_links/`, method: 'get', params }, options)
}

export const retrieveQboLink = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<QboLink>({ url: `/agave/qbo_links/${id}/`, method: 'get' }, options)
}

export const listRawQboLinks = (
  params?: ListRawQboLinksParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListRawQboLinks200>(
    { url: `/agave/raw_qbo_links/`, method: 'get', params },
    options,
  )
}

export const retrieveRawQboLink = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<RawQboLink>({ url: `/agave/raw_qbo_links/${id}/`, method: 'get' }, options)
}

export const listBillListRows = (
  params?: ListBillListRowsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListBillListRows200>(
    { url: `/page_resources/bill_list_rows/`, method: 'get', params },
    options,
  )
}

export const retrieveBillListRow = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<BillListRow>(
    { url: `/page_resources/bill_list_rows/${id}/`, method: 'get' },
    options,
  )
}

export const listCardTransactionListRows = (
  params?: ListCardTransactionListRowsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListCardTransactionListRows200>(
    { url: `/page_resources/card_transaction_list_rows/`, method: 'get', params },
    options,
  )
}

export const retrieveCardTransactionListRow = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CardTransactionListRow>(
    { url: `/page_resources/card_transaction_list_rows/${id}/`, method: 'get' },
    options,
  )
}

export const listClientListRows = (
  params?: ListClientListRowsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListClientListRows200>(
    { url: `/page_resources/client_list_rows/`, method: 'get', params },
    options,
  )
}

export const retrieveClientListRow = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ClientListRow>(
    { url: `/page_resources/client_list_rows/${id}/`, method: 'get' },
    options,
  )
}

export const listExpenseListRows = (
  params?: ListExpenseListRowsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListExpenseListRows200>(
    { url: `/page_resources/expense_list_rows/`, method: 'get', params },
    options,
  )
}

export const retrieveExpenseListRow = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ExpenseListRow>(
    { url: `/page_resources/expense_list_rows/${id}/`, method: 'get' },
    options,
  )
}

export const listInvoiceListRows = (
  params?: ListInvoiceListRowsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoiceListRows200>(
    { url: `/page_resources/invoice_list_rows/`, method: 'get', params },
    options,
  )
}

export const retrieveInvoiceListRow = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoiceListRow>(
    { url: `/page_resources/invoice_list_rows/${id}/`, method: 'get' },
    options,
  )
}

export const listInvoicePaymentFeeListRows = (
  params?: ListInvoicePaymentFeeListRowsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoicePaymentFeeListRows200>(
    { url: `/page_resources/invoice_payment_fee_list_rows/`, method: 'get', params },
    options,
  )
}

export const retrieveInvoicePaymentFeeListRow = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePaymentFeeListRow>(
    { url: `/page_resources/invoice_payment_fee_list_rows/${id}/`, method: 'get' },
    options,
  )
}

export const listProjectListRows = (
  params?: ListProjectListRowsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProjectListRows200>(
    { url: `/page_resources/project_list_rows/`, method: 'get', params },
    options,
  )
}

export const retrieveProjectListRow = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectListRow>(
    { url: `/page_resources/project_list_rows/${id}/`, method: 'get' },
    options,
  )
}

export const listVendorListRows = (
  params?: ListVendorListRowsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListVendorListRows200>(
    { url: `/page_resources/vendor_list_rows/`, method: 'get', params },
    options,
  )
}

export const retrieveVendorListRow = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<VendorListRow>(
    { url: `/page_resources/vendor_list_rows/${id}/`, method: 'get' },
    options,
  )
}

export const listBillsPageSummarys = (
  params?: ListBillsPageSummarysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListBillsPageSummarys200>(
    { url: `/page_resources/bills_page_summarys/`, method: 'get', params },
    options,
  )
}

export const retrieveBillsPageSummary = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BillsPageSummary>(
    { url: `/page_resources/bills_page_summarys/${id}/`, method: 'get' },
    options,
  )
}

export const listEstimateAsPayeeListRows = (
  params?: ListEstimateAsPayeeListRowsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimateAsPayeeListRows200>(
    { url: `/page_resources/estimate_as_payee_list_rows/`, method: 'get', params },
    options,
  )
}

export const retrieveEstimateAsPayeeListRow = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimateAsPayeeListRow>(
    { url: `/page_resources/estimate_as_payee_list_rows/${id}/`, method: 'get' },
    options,
  )
}

export const listEstimatesPageSummarys = (
  params?: ListEstimatesPageSummarysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListEstimatesPageSummarys200>(
    { url: `/page_resources/estimates_page_summarys/`, method: 'get', params },
    options,
  )
}

export const retrieveEstimatesPageSummary = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<EstimatesPageSummary>(
    { url: `/page_resources/estimates_page_summarys/${id}/`, method: 'get' },
    options,
  )
}

export const listInvoicesPageSummarys = (
  params?: ListInvoicesPageSummarysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListInvoicesPageSummarys200>(
    { url: `/page_resources/invoices_page_summarys/`, method: 'get', params },
    options,
  )
}

export const retrieveInvoicesPageSummary = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicesPageSummary>(
    { url: `/page_resources/invoices_page_summarys/${id}/`, method: 'get' },
    options,
  )
}

export const listProjectsPageSummarys = (
  params?: ListProjectsPageSummarysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProjectsPageSummarys200>(
    { url: `/page_resources/projects_page_summary/`, method: 'get', params },
    options,
  )
}

export const retrieveProjectsPageSummary = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProjectsPageSummary>(
    { url: `/page_resources/projects_page_summary/${id}/`, method: 'get' },
    options,
  )
}

export const aPIActivity = (
  params?: APIActivityParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<APIActivityViewOutput>(
    { url: `/admin_resources/api_activities/`, method: 'get', params },
    options,
  )
}

export const aggregatedPaymentListRow = (
  params?: AggregatedPaymentListRowParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<AggregatedPaymentListRowViewOutput>(
    { url: `/admin_resources/aggregated_payment_list_rows/`, method: 'get', params },
    options,
  )
}

export const customerSummary = (
  params?: CustomerSummaryParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CustomerSummaryViewOutput>(
    { url: `/admin_resources/customer_summaries/`, method: 'get', params },
    options,
  )
}

export const getReadOnlyAuthCommand = (
  params: GetReadOnlyAuthCommandParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetReadOnlyAuthCommandViewOutput>(
    { url: `/admin_resources/get_read_only_auth_command/`, method: 'get', params },
    options,
  )
}

export const getAllowedQBBucketSearches = (
  params: GetAllowedQBBucketSearchesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetAllowedQBBucketSearchesViewOutput>(
    { url: `/admin/get_allowed_qb_bucket_searches/`, method: 'get', params },
    options,
  )
}

export const listAdminQBPullRequests = (
  params?: ListAdminQBPullRequestsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListAdminQBPullRequests200>(
    { url: `/admin/qb_pull_requests/`, method: 'get', params },
    options,
  )
}

export const retrieveAdminQBPullRequest = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<AdminQBPullRequest>(
    { url: `/admin/qb_pull_requests/${id}/`, method: 'get' },
    options,
  )
}

export const listAdminQBPushRequests = (
  params?: ListAdminQBPushRequestsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListAdminQBPushRequests200>(
    { url: `/admin/qb_push_requests/`, method: 'get', params },
    options,
  )
}

export const retrieveAdminQBPushRequest = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<AdminQBPushRequest>(
    { url: `/admin/qb_push_requests/${id}/`, method: 'get' },
    options,
  )
}

export const listAdminTransactions = (
  params?: ListAdminTransactionsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListAdminTransactions200>(
    { url: `/admin/transactions/`, method: 'get', params },
    options,
  )
}

export const retrieveAdminTransaction = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<AdminTransaction>(
    { url: `/admin/transactions/${id}/`, method: 'get' },
    options,
  )
}

export const listAgaveAccounts = (
  params?: ListAgaveAccountsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListAgaveAccounts200>(
    { url: `/admin/accounts/`, method: 'get', params },
    options,
  )
}

export const retrieveAgaveAccount = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<AgaveAccount>({ url: `/admin/accounts/${id}/`, method: 'get' }, options)
}

export const listBusinessNotes = (
  params?: ListBusinessNotesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListBusinessNotes200>(
    { url: `/admin/business_notes/`, method: 'get', params },
    options,
  )
}

export const createBusinessNote = (
  businessNoteCreate: BusinessNoteCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BusinessNote>(
    {
      url: `/admin/business_notes/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: businessNoteCreate,
    },
    options,
  )
}

export const retrieveBusinessNote = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<BusinessNote>({ url: `/admin/business_notes/${id}/`, method: 'get' }, options)
}

export const partialUpdateBusinessNote = (
  id: string,
  businessNotePatch: Partial<BusinessNotePatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BusinessNote>(
    {
      url: `/admin/business_notes/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: businessNotePatch,
    },
    options,
  )
}

export const destroyBusinessNote = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<void>({ url: `/admin/business_notes/${id}/`, method: 'delete' }, options)
}

export const emailPixel = (trackingId: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<EmailPixelViewOutput>(
    { url: `/email/trackers/${trackingId}/pixel.gif`, method: 'get' },
    options,
  )
}

export const listBeamBalanceTransactions = (
  params?: ListBeamBalanceTransactionsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListBeamBalanceTransactions200>(
    { url: `/transfers/beam_balance_transactions/`, method: 'get', params },
    options,
  )
}

export const retrieveBeamBalanceTransaction = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BeamBalanceTransaction>(
    { url: `/transfers/beam_balance_transactions/${id}/`, method: 'get' },
    options,
  )
}

export const listTransfers = (
  params?: ListTransfersParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListTransfers200>(
    { url: `/transfers/transfers/`, method: 'get', params },
    options,
  )
}

export const createTransfer = (
  transferCreate: TransferCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Transfer>(
    {
      url: `/transfers/transfers/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: transferCreate,
    },
    options,
  )
}

export const retrieveTransfer = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Transfer>({ url: `/transfers/transfers/${id}/`, method: 'get' }, options)
}

export const getHubspotVisitorToken = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<GetHubspotVisitorTokenViewOutput>(
    { url: `/chat/get_hubspot_visitor_token/`, method: 'get' },
    options,
  )
}

export const listCardTransactions = (
  params?: ListCardTransactionsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListCardTransactions200>(
    { url: `/expense_card/card_transactions/`, method: 'get', params },
    options,
  )
}

export const retrieveCardTransaction = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CardTransaction>(
    { url: `/expense_card/card_transactions/${id}/`, method: 'get' },
    options,
  )
}

export const listExpenseCards = (
  params?: ListExpenseCardsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListExpenseCards200>(
    { url: `/expense_card/expense_cards/`, method: 'get', params },
    options,
  )
}

export const retrieveExpenseCard = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<ExpenseCard>(
    { url: `/expense_card/expense_cards/${id}/`, method: 'get' },
    options,
  )
}

export const listCardPrograms = (
  params?: ListCardProgramsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListCardPrograms200>(
    { url: `/expense_card/card_programs/`, method: 'get', params },
    options,
  )
}

export const retrieveCardProgram = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<CardProgram>(
    { url: `/expense_card/card_programs/${id}/`, method: 'get' },
    options,
  )
}

export const getAccountLoginURL = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<GetAccountLoginURLViewOutput>(
    { url: `/capitalos/get_account_login_url/`, method: 'get' },
    options,
  )
}

export const getInvoiceCta = (
  params: GetInvoiceCtaParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetInvoiceCtaViewOutput>(
    { url: `/gtm/get_invoice_cta/`, method: 'get', params },
    options,
  )
}

export const getInvoiceListCta = (
  params: GetInvoiceListCtaParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetInvoiceListCtaViewOutput>(
    { url: `/gtm/get_invoice_list_cta/`, method: 'get', params },
    options,
  )
}

export const listTutorials = (
  params?: ListTutorialsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListTutorials200>({ url: `/gtm/tutorials/`, method: 'get', params }, options)
}

export const retrieveTutorial = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Tutorial>({ url: `/gtm/tutorials/${id}/`, method: 'get' }, options)
}

export const partialUpdateTutorial = (
  id: string,
  tutorialPatch: Partial<TutorialPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Tutorial>(
    {
      url: `/gtm/tutorials/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: tutorialPatch,
    },
    options,
  )
}

export const listExpenseCodes = (
  params?: ListExpenseCodesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListExpenseCodes200>(
    { url: `/accounting/expense_codes/`, method: 'get', params },
    options,
  )
}

export const retrieveExpenseCode = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<ExpenseCode>(
    { url: `/accounting/expense_codes/${id}/`, method: 'get' },
    options,
  )
}

export const listIncomeCodes = (
  params?: ListIncomeCodesParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListIncomeCodes200>(
    { url: `/accounting/income_codes/`, method: 'get', params },
    options,
  )
}

export const retrieveIncomeCode = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<IncomeCode>({ url: `/accounting/income_codes/${id}/`, method: 'get' }, options)
}

export const listItems = (
  params?: ListItemsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListItems200>({ url: `/accounting/items/`, method: 'get', params }, options)
}

export const retrieveItem = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Item>({ url: `/accounting/items/${id}/`, method: 'get' }, options)
}

export const listLedgerAccounts = (
  params?: ListLedgerAccountsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListLedgerAccounts200>(
    { url: `/accounting/ledger_accounts/`, method: 'get', params },
    options,
  )
}

export const retrieveLedgerAccount = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LedgerAccount>(
    { url: `/accounting/ledger_accounts/${id}/`, method: 'get' },
    options,
  )
}

export const listBillOcrRawResultLineItems = (
  params?: ListBillOcrRawResultLineItemsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListBillOcrRawResultLineItems200>(
    { url: `/ocr/bill_ocr_raw_result_line_items/`, method: 'get', params },
    options,
  )
}

export const retrieveBillOcrRawResultLineItem = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BillOcrRawResultLineItem>(
    { url: `/ocr/bill_ocr_raw_result_line_items/${id}/`, method: 'get' },
    options,
  )
}

export const listBillOcrRawResults = (
  params?: ListBillOcrRawResultsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListBillOcrRawResults200>(
    { url: `/ocr/bill_ocr_raw_results/`, method: 'get', params },
    options,
  )
}

export const retrieveBillOcrRawResult = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BillOcrRawResult>(
    { url: `/ocr/bill_ocr_raw_results/${id}/`, method: 'get' },
    options,
  )
}

export const listMfaRequirements = (
  params?: ListMfaRequirementsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListMfaRequirements200>(
    { url: `/mfa/mfa_requirements/`, method: 'get', params },
    options,
  )
}

export const retrieveMfaRequirement = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<MfaRequirement>(
    { url: `/mfa/mfa_requirements/${id}/`, method: 'get' },
    options,
  )
}

export const getAuthRequestLink = (
  params: GetAuthRequestLinkParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetAuthRequestLinkViewOutput>(
    { url: `/oauth/get_auth_request_link/`, method: 'get', params },
    options,
  )
}

export const listCompanyCamAccounts = (
  params?: ListCompanyCamAccountsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListCompanyCamAccounts200>(
    { url: `/oauth/companycam_accounts/`, method: 'get', params },
    options,
  )
}

export const createCompanyCamAccount = (
  companyCamAccountCreate: CompanyCamAccountCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CompanyCamAccount>(
    {
      url: `/oauth/companycam_accounts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: companyCamAccountCreate,
    },
    options,
  )
}

export const retrieveCompanyCamAccount = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CompanyCamAccount>(
    { url: `/oauth/companycam_accounts/${id}/`, method: 'get' },
    options,
  )
}

export const retrieveSingletonCompanyCamAccount = (
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CompanyCamAccount>(
    { url: `/oauth/companycam_accounts/singleton/`, method: 'get' },
    options,
  )
}

export const listProcoreAccounts = (
  params?: ListProcoreAccountsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListProcoreAccounts200>(
    { url: `/oauth/procore_accounts/`, method: 'get', params },
    options,
  )
}

export const createProcoreAccount = (
  procoreAccountCreate: ProcoreAccountCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProcoreAccount>(
    {
      url: `/oauth/procore_accounts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: procoreAccountCreate,
    },
    options,
  )
}

export const retrieveProcoreAccount = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ProcoreAccount>(
    { url: `/oauth/procore_accounts/${id}/`, method: 'get' },
    options,
  )
}

export const retrieveSingletonProcoreAccount = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<ProcoreAccount>(
    { url: `/oauth/procore_accounts/singleton/`, method: 'get' },
    options,
  )
}

export const listQboAccounts = (
  params?: ListQboAccountsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListQboAccounts200>(
    { url: `/oauth/qbo_accounts/`, method: 'get', params },
    options,
  )
}

export const createQboAccount = (
  qboAccountCreate: QboAccountCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<QboAccount>(
    {
      url: `/oauth/qbo_accounts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: qboAccountCreate,
    },
    options,
  )
}

export const retrieveQboAccount = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<QboAccount>({ url: `/oauth/qbo_accounts/${id}/`, method: 'get' }, options)
}

export const retrieveSingletonQboAccount = (options?: SecondParameter<typeof customAxios>) => {
  return customAxios<QboAccount>({ url: `/oauth/qbo_accounts/singleton/`, method: 'get' }, options)
}

export const listCompanyCamLinks = (
  params?: ListCompanyCamLinksParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListCompanyCamLinks200>(
    { url: `/companycam/companycam_links/`, method: 'get', params },
    options,
  )
}

export const retrieveCompanyCamLink = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CompanyCamLink>(
    { url: `/companycam/companycam_links/${id}/`, method: 'get' },
    options,
  )
}

export const listDocuments = (
  params?: ListDocumentsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListDocuments200>(
    { url: `/companycam/documents/`, method: 'get', params },
    options,
  )
}

export const retrieveDocument = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Document>({ url: `/companycam/documents/${id}/`, method: 'get' }, options)
}

export const listPhotos = (
  params?: ListPhotosParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListPhotos200>({ url: `/companycam/photos/`, method: 'get', params }, options)
}

export const retrievePhoto = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Photo>({ url: `/companycam/photos/${id}/`, method: 'get' }, options)
}

export const listRenofiAds = (
  params?: ListRenofiAdsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListRenofiAds200>(
    { url: `/partnerships/renofi_ads/`, method: 'get', params },
    options,
  )
}

export const createRenofiAd = (
  renofiAdCreate: RenofiAdCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<RenofiAd>(
    {
      url: `/partnerships/renofi_ads/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: renofiAdCreate,
    },
    options,
  )
}

export const retrieveRenofiAd = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<RenofiAd>({ url: `/partnerships/renofi_ads/${id}/`, method: 'get' }, options)
}

export const partialUpdateRenofiAd = (
  id: string,
  renofiAdPatch: Partial<RenofiAdPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<RenofiAd>(
    {
      url: `/partnerships/renofi_ads/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: renofiAdPatch,
    },
    options,
  )
}

export const getRenofiAd = (
  params: GetRenofiAdParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetRenofiAdViewOutput>(
    { url: `/partnerships/get_renofi_ad/`, method: 'get', params },
    options,
  )
}

export const listRenofiAdClicks = (
  params?: ListRenofiAdClicksParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListRenofiAdClicks200>(
    { url: `/partnerships/renofi_ad_clicks/`, method: 'get', params },
    options,
  )
}

export const retrieveRenofiAdClick = (
  id: string,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<RenofiAdClick>(
    { url: `/partnerships/renofi_ad_clicks/${id}/`, method: 'get' },
    options,
  )
}

export const listDeals = (
  params?: ListDealsParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListDeals200>({ url: `/crm/deals/`, method: 'get', params }, options)
}

export const createDeal = (
  dealCreate: DealCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Deal>(
    {
      url: `/crm/deals/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: dealCreate,
    },
    options,
  )
}

export const retrieveDeal = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<Deal>({ url: `/crm/deals/${id}/`, method: 'get' }, options)
}

export const partialUpdateDeal = (
  id: string,
  dealPatch: Partial<DealPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Deal>(
    {
      url: `/crm/deals/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: dealPatch,
    },
    options,
  )
}

export const listDealSummarys = (
  params?: ListDealSummarysParams,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ListDealSummarys200>(
    { url: `/crm/deal_summaries/`, method: 'get', params },
    options,
  )
}

export const retrieveDealSummary = (id: string, options?: SecondParameter<typeof customAxios>) => {
  return customAxios<DealSummary>({ url: `/crm/deal_summaries/${id}/`, method: 'get' }, options)
}

export const setBusinessStatus = (
  setBusinessStatusInput: SetBusinessStatusInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<SetBusinessStatusOutput>(
    {
      url: `/businesses/set_business_status/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: setBusinessStatusInput,
    },
    options,
  )
}

export const bulkCreateInvoicePayment = (
  invoicePaymentCreate: InvoicePaymentCreate[],
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InvoicePayment[]>(
    {
      url: `/billing/invoice_payments/bulk/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: invoicePaymentCreate,
    },
    options,
  )
}

export const bumpInvoiceVersion = (
  bumpInvoiceVersionInput: BumpInvoiceVersionInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<BumpInvoiceVersionOutput>(
    {
      url: `/billing/bump_invoice_version/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: bumpInvoiceVersionInput,
    },
    options,
  )
}

export const createLienWaiverFromInvoicePdf = (
  createLienWaiverFromInvoicePdfInput: CreateLienWaiverFromInvoicePdfInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CreateLienWaiverFromInvoicePdfOutput>(
    {
      url: `/billing/create_lien_waiver_from_invoice_pdf/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createLienWaiverFromInvoicePdfInput,
    },
    options,
  )
}

export const sendInvoiceEmail = (
  sendInvoiceEmailInput: SendInvoiceEmailInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<SendInvoiceEmailOutput>(
    {
      url: `/billing/send_invoice_email/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sendInvoiceEmailInput,
    },
    options,
  )
}

export const sendLienWaiverEmail = (
  sendLienWaiverEmailInput: SendLienWaiverEmailInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<SendLienWaiverEmailOutput>(
    {
      url: `/billing/send_sign_lien_waiver_email/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sendLienWaiverEmailInput,
    },
    options,
  )
}

export const updateContractType = (
  updateContractTypeInput: UpdateContractTypeInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<UpdateContractTypeOutput>(
    {
      url: `/billing/update_contract_type/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateContractTypeInput,
    },
    options,
  )
}

export const createLogin = (
  loginCreate: LoginCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<Login>(
    {
      url: `/users/login/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: loginCreate,
    },
    options,
  )
}

export const createLogoutV2 = (
  logoutV2Create: LogoutV2Create,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<LogoutV2>(
    {
      url: `/users/logout/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: logoutV2Create,
    },
    options,
  )
}

export const authenticateMagicLink = (
  authenticateMagicLinkViewInput: AuthenticateMagicLinkViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<AuthenticateMagicLinkViewOutput>(
    {
      url: `/users/authenticate_magic_link/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: authenticateMagicLinkViewInput,
    },
    options,
  )
}

export const generateMagicLinkToken = (
  generateMagicLinkTokenViewInput: GenerateMagicLinkTokenViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GenerateMagicLinkTokenViewOutput>(
    {
      url: `/users/generate_magic_link_token/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: generateMagicLinkTokenViewInput,
    },
    options,
  )
}

export const passwordResetCompletion = (
  passwordResetCompletionViewInput: PasswordResetCompletionViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PasswordResetCompletionViewOutput>(
    {
      url: `/users/password_reset_completions/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: passwordResetCompletionViewInput,
    },
    options,
  )
}

export const passwordResetInitialization = (
  passwordResetInitializationViewInput: PasswordResetInitializationViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PasswordResetInitializationViewOutput>(
    {
      url: `/users/password_reset_initializations/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: passwordResetInitializationViewInput,
    },
    options,
  )
}

export const verifyEmail = (
  verifyEmailViewInput: VerifyEmailViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<VerifyEmailViewOutput>(
    {
      url: `/users/verify_email/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: verifyEmailViewInput,
    },
    options,
  )
}

export const createMicrodepositAttempt = (
  microdepositAttemptCreate: MicrodepositAttemptCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<MicrodepositAttempt>(
    {
      url: `/banking/microdeposit_attempts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: microdepositAttemptCreate,
    },
    options,
  )
}

export const createSetupIntent = (
  setupIntentCreate: SetupIntentCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<SetupIntent>(
    {
      url: `/banking/setup_intents/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: setupIntentCreate,
    },
    options,
  )
}

export const setInboundTransfersEnabled = (
  setInboundTransfersEnabledViewInput: SetInboundTransfersEnabledViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<SetInboundTransfersEnabledViewOutput>(
    {
      url: `/banking/set_inbound_transfers_enabled/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: setInboundTransfersEnabledViewInput,
    },
    options,
  )
}

export const setMfaRequirement = (
  setMfaRequirementInput: SetMfaRequirementInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<SetMfaRequirementOutput>(
    {
      url: `/banking/set_mfa_requirement/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: setMfaRequirementInput,
    },
    options,
  )
}

export const createFile = (
  fileCreate: FileCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<File>(
    {
      url: `/files/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fileCreate,
    },
    options,
  )
}

export const createFileUpload = (
  fileUploadCreate: FileUploadCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<FileUpload>(
    {
      url: `/files/uploads/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fileUploadCreate,
    },
    options,
  )
}

export const adminCreateStripeIdentityVerificationSession = (
  adminCreateStripeIdentityVerificationSessionInput: AdminCreateStripeIdentityVerificationSessionInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<AdminCreateStripeIdentityVerificationSessionOutput>(
    {
      url: `/onboarding/create_stripe_identity_verification_session/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: adminCreateStripeIdentityVerificationSessionInput,
    },
    options,
  )
}

export const generateStripeOnboardingClientSecret = (
  generateStripeOnboardingClientSecretViewInput: GenerateStripeOnboardingClientSecretViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GenerateStripeOnboardingClientSecretViewOutput>(
    {
      url: `/onboarding/generate_stripe_onboarding_client_secret/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: generateStripeOnboardingClientSecretViewInput,
    },
    options,
  )
}

export const convertEstimateToContract = (
  convertEstimateToContractInput: ConvertEstimateToContractInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ConvertEstimateToContractOutput>(
    {
      url: `/estimates/convert_estimate_to_contract/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: convertEstimateToContractInput,
    },
    options,
  )
}

export const publishEstimate = (
  publishEstimateInput: PublishEstimateInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<PublishEstimateOutput>(
    {
      url: `/estimates/publish_estimate/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: publishEstimateInput,
    },
    options,
  )
}

export const sendEstimateReminder = (
  sendEstimateReminderInput: SendEstimateReminderInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<SendEstimateReminderOutput>(
    {
      url: `/estimates/send_estimate_reminder/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sendEstimateReminderInput,
    },
    options,
  )
}

export const uploadEstimateTemplate = (
  uploadEstimateTemplateInput: UploadEstimateTemplateInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<UploadEstimateTemplateOutput>(
    {
      url: `/admin_resources/upload_estimate_template/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: uploadEstimateTemplateInput,
    },
    options,
  )
}

export const archiveBankAccount = (
  archiveBankAccountViewInput: ArchiveBankAccountViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ArchiveBankAccountViewOutput>(
    {
      url: `/admin/archive_bank_account/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: archiveBankAccountViewInput,
    },
    options,
  )
}

export const getQBBuckets = (
  getQBBucketsViewInput: GetQBBucketsViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<GetQBBucketsViewOutput>(
    {
      url: `/admin/get_qb_buckets/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: getQBBucketsViewInput,
    },
    options,
  )
}

export const trackEmailLinkClicked = (
  trackEmailLinkClickedViewInput: TrackEmailLinkClickedViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<TrackEmailLinkClickedViewOutput>(
    {
      url: `/email/track_email_link_clicked/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: trackEmailLinkClickedViewInput,
    },
    options,
  )
}

export const categorizeTransaction = (
  categorizeTransactionInput: CategorizeTransactionInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CategorizeTransactionOutput>(
    {
      url: `/transfers/categorize_transaction/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: categorizeTransactionInput,
    },
    options,
  )
}

export const createSampleEstimate = (
  createSampleEstimateInput: CreateSampleEstimateInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<CreateSampleEstimateOutput>(
    {
      url: `/gtm/create_sample_estimate/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createSampleEstimateInput,
    },
    options,
  )
}

export const initiateBillOcr = (
  initiateBillOcrInput: InitiateBillOcrInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<InitiateBillOcrOutput>(
    {
      url: `/ocr/initiate_bill_ocr/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: initiateBillOcrInput,
    },
    options,
  )
}

export const createMfaAttempt = (
  mfaAttemptCreate: MfaAttemptCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<MfaAttempt>(
    {
      url: `/mfa/mfa_attempts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: mfaAttemptCreate,
    },
    options,
  )
}

export const createESignature = (
  eSignatureCreate: ESignatureCreate,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ESignature>(
    {
      url: `/e_signatures/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: eSignatureCreate,
    },
    options,
  )
}

export const updateAuthToken = (
  updateAuthTokenViewInput: UpdateAuthTokenViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<UpdateAuthTokenViewOutput>(
    {
      url: `/oauth/update_auth_token/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateAuthTokenViewInput,
    },
    options,
  )
}

export const clickLink = (
  clickLinkViewInput: ClickLinkViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<ClickLinkViewOutput>(
    {
      url: `/analytics/click_link/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: clickLinkViewInput,
    },
    options,
  )
}

export const refreshDeals = (
  refreshDealsViewInput: RefreshDealsViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<RefreshDealsViewOutput>(
    {
      url: `/crm/refresh_deals/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: refreshDealsViewInput,
    },
    options,
  )
}

export const onboardBusinessToStripe = (
  onboardBusinessToStripeInput: OnboardBusinessToStripeInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<OnboardBusinessToStripeOutput>(
    {
      url: `/dev/onboard_business_to_stripe/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: onboardBusinessToStripeInput,
    },
    options,
  )
}

export const updateMoneyMovement = (
  updateMoneyMovementViewInput: UpdateMoneyMovementViewInput,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<UpdateMoneyMovementViewOutput>(
    {
      url: `/dev/update_money_movement/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateMoneyMovementViewInput,
    },
    options,
  )
}

export const partialUpdateSetupIntent = (
  id: string,
  setupIntentPatch: Partial<SetupIntentPatch>,
  options?: SecondParameter<typeof customAxios>,
) => {
  return customAxios<SetupIntent>(
    {
      url: `/banking/setup_intents/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: setupIntentPatch,
    },
    options,
  )
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export type PublicLogoResult = NonNullable<Awaited<ReturnType<typeof publicLogo>>>
export type ListAdditionalContactsResult = NonNullable<
  Awaited<ReturnType<typeof listAdditionalContacts>>
>
export type CreateAdditionalContactResult = NonNullable<
  Awaited<ReturnType<typeof createAdditionalContact>>
>
export type RetrieveAdditionalContactResult = NonNullable<
  Awaited<ReturnType<typeof retrieveAdditionalContact>>
>
export type PartialUpdateAdditionalContactResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateAdditionalContact>>
>
export type DestroyAdditionalContactResult = NonNullable<
  Awaited<ReturnType<typeof destroyAdditionalContact>>
>
export type ListBusinessResult = NonNullable<Awaited<ReturnType<typeof listBusiness>>>
export type CreateBusinessResult = NonNullable<Awaited<ReturnType<typeof createBusiness>>>
export type RetrieveBusinessResult = NonNullable<Awaited<ReturnType<typeof retrieveBusiness>>>
export type PartialUpdateBusinessResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateBusiness>>
>
export type ListEmployeeCommunicationConfigsResult = NonNullable<
  Awaited<ReturnType<typeof listEmployeeCommunicationConfigs>>
>
export type RetrieveSingletonEmployeeCommunicationConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveSingletonEmployeeCommunicationConfig>>
>
export type RetrieveEmployeeCommunicationConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEmployeeCommunicationConfig>>
>
export type PartialUpdateEmployeeCommunicationConfigResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateEmployeeCommunicationConfig>>
>
export type ListEmployeesResult = NonNullable<Awaited<ReturnType<typeof listEmployees>>>
export type CreateEmployeeResult = NonNullable<Awaited<ReturnType<typeof createEmployee>>>
export type RetrieveEmployeeResult = NonNullable<Awaited<ReturnType<typeof retrieveEmployee>>>
export type PartialUpdateEmployeeResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateEmployee>>
>
export type DestroyEmployeeResult = NonNullable<Awaited<ReturnType<typeof destroyEmployee>>>
export type ListGCEmailsResult = NonNullable<Awaited<ReturnType<typeof listGCEmails>>>
export type CreateGCEmailResult = NonNullable<Awaited<ReturnType<typeof createGCEmail>>>
export type RetrieveGCEmailResult = NonNullable<Awaited<ReturnType<typeof retrieveGCEmail>>>
export type ListOutboundInvitesResult = NonNullable<Awaited<ReturnType<typeof listOutboundInvites>>>
export type CreateOutboundInviteResult = NonNullable<
  Awaited<ReturnType<typeof createOutboundInvite>>
>
export type RetrieveOutboundInviteResult = NonNullable<
  Awaited<ReturnType<typeof retrieveOutboundInvite>>
>
export type ListPayeeContactsResult = NonNullable<Awaited<ReturnType<typeof listPayeeContacts>>>
export type CreatePayeeContactResult = NonNullable<Awaited<ReturnType<typeof createPayeeContact>>>
export type RetrievePayeeContactResult = NonNullable<
  Awaited<ReturnType<typeof retrievePayeeContact>>
>
export type PartialUpdatePayeeContactResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdatePayeeContact>>
>
export type GetPayeeContactArchiveRestrictionsResult = NonNullable<
  Awaited<ReturnType<typeof getPayeeContactArchiveRestrictions>>
>
export type GetPayeeContactArchiveRestrictionsListResult = NonNullable<
  Awaited<ReturnType<typeof getPayeeContactArchiveRestrictionsList>>
>
export type ListPayerContactsResult = NonNullable<Awaited<ReturnType<typeof listPayerContacts>>>
export type CreatePayerContactResult = NonNullable<Awaited<ReturnType<typeof createPayerContact>>>
export type RetrievePayerContactResult = NonNullable<
  Awaited<ReturnType<typeof retrievePayerContact>>
>
export type PartialUpdatePayerContactResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdatePayerContact>>
>
export type GetPayerContactArchiveRestrictionsResult = NonNullable<
  Awaited<ReturnType<typeof getPayerContactArchiveRestrictions>>
>
export type GetPayerContactArchiveRestrictionsListResult = NonNullable<
  Awaited<ReturnType<typeof getPayerContactArchiveRestrictionsList>>
>
export type ListExternalCommunicationConfigsResult = NonNullable<
  Awaited<ReturnType<typeof listExternalCommunicationConfigs>>
>
export type CreateExternalCommunicationConfigResult = NonNullable<
  Awaited<ReturnType<typeof createExternalCommunicationConfig>>
>
export type RetrieveExternalCommunicationConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveExternalCommunicationConfig>>
>
export type PartialUpdateExternalCommunicationConfigResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateExternalCommunicationConfig>>
>
export type RetrieveSingletonExternalCommunicationConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveSingletonExternalCommunicationConfig>>
>
export type ListFrontEndBannersResult = NonNullable<Awaited<ReturnType<typeof listFrontEndBanners>>>
export type RetrieveFrontEndBannerResult = NonNullable<
  Awaited<ReturnType<typeof retrieveFrontEndBanner>>
>
export type RetrieveSingletonFrontEndBannerResult = NonNullable<
  Awaited<ReturnType<typeof retrieveSingletonFrontEndBanner>>
>
export type ListStripeConnectedAccountLinksResult = NonNullable<
  Awaited<ReturnType<typeof listStripeConnectedAccountLinks>>
>
export type RetrieveStripeConnectedAccountLinkResult = NonNullable<
  Awaited<ReturnType<typeof retrieveStripeConnectedAccountLink>>
>
export type ListBusinessLogoEmbeddedFilesResult = NonNullable<
  Awaited<ReturnType<typeof listBusinessLogoEmbeddedFiles>>
>
export type CreateBusinessLogoEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof createBusinessLogoEmbeddedFile>>
>
export type RetrieveBusinessLogoEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveBusinessLogoEmbeddedFile>>
>
export type PartialUpdateBusinessLogoEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateBusinessLogoEmbeddedFile>>
>
export type DestroyBusinessLogoEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof destroyBusinessLogoEmbeddedFile>>
>
export type RetrieveBusinessLogoEmbeddedFileDownloadResult = NonNullable<
  Awaited<ReturnType<typeof retrieveBusinessLogoEmbeddedFileDownload>>
>
export type ListChangeOrderEmbeddedFilesResult = NonNullable<
  Awaited<ReturnType<typeof listChangeOrderEmbeddedFiles>>
>
export type CreateChangeOrderEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof createChangeOrderEmbeddedFile>>
>
export type RetrieveChangeOrderEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveChangeOrderEmbeddedFile>>
>
export type RetrieveChangeOrderEmbeddedFileDownloadResult = NonNullable<
  Awaited<ReturnType<typeof retrieveChangeOrderEmbeddedFileDownload>>
>
export type ListChangeOrdersResult = NonNullable<Awaited<ReturnType<typeof listChangeOrders>>>
export type CreateChangeOrderResult = NonNullable<Awaited<ReturnType<typeof createChangeOrder>>>
export type RetrieveChangeOrderResult = NonNullable<Awaited<ReturnType<typeof retrieveChangeOrder>>>
export type PartialUpdateChangeOrderResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateChangeOrder>>
>
export type DestroyChangeOrderResult = NonNullable<Awaited<ReturnType<typeof destroyChangeOrder>>>
export type ListClientsResult = NonNullable<Awaited<ReturnType<typeof listClients>>>
export type RetrieveClientResult = NonNullable<Awaited<ReturnType<typeof retrieveClient>>>
export type ListProjectPermissionsResult = NonNullable<
  Awaited<ReturnType<typeof listProjectPermissions>>
>
export type RetrieveProjectPermissionResult = NonNullable<
  Awaited<ReturnType<typeof retrieveProjectPermission>>
>
export type ListProjectsResult = NonNullable<Awaited<ReturnType<typeof listProjects>>>
export type CreateProjectResult = NonNullable<Awaited<ReturnType<typeof createProject>>>
export type RetrieveProjectResult = NonNullable<Awaited<ReturnType<typeof retrieveProject>>>
export type PartialUpdateProjectResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateProject>>
>
export type DestroyProjectResult = NonNullable<Awaited<ReturnType<typeof destroyProject>>>
export type ListRemovalChangeOrderEmbeddedFilesResult = NonNullable<
  Awaited<ReturnType<typeof listRemovalChangeOrderEmbeddedFiles>>
>
export type CreateRemovalChangeOrderEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof createRemovalChangeOrderEmbeddedFile>>
>
export type RetrieveRemovalChangeOrderEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveRemovalChangeOrderEmbeddedFile>>
>
export type RetrieveRemovalChangeOrderEmbeddedFileDownloadResult = NonNullable<
  Awaited<ReturnType<typeof retrieveRemovalChangeOrderEmbeddedFileDownload>>
>
export type ListRemovalChangeOrdersResult = NonNullable<
  Awaited<ReturnType<typeof listRemovalChangeOrders>>
>
export type RetrieveRemovalChangeOrderResult = NonNullable<
  Awaited<ReturnType<typeof retrieveRemovalChangeOrder>>
>
export type PartialUpdateRemovalChangeOrderResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateRemovalChangeOrder>>
>
export type DestroyRemovalChangeOrderResult = NonNullable<
  Awaited<ReturnType<typeof destroyRemovalChangeOrder>>
>
export type ContractItemEditRestrictionViewSetResult = NonNullable<
  Awaited<ReturnType<typeof contractItemEditRestrictionViewSet>>
>
export type ListChangeOrderFilesResult = NonNullable<
  Awaited<ReturnType<typeof listChangeOrderFiles>>
>
export type CreateChangeOrderFileResult = NonNullable<
  Awaited<ReturnType<typeof createChangeOrderFile>>
>
export type RetrieveChangeOrderFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveChangeOrderFile>>
>
export type PartialUpdateChangeOrderFileResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateChangeOrderFile>>
>
export type DestroyChangeOrderFileResult = NonNullable<
  Awaited<ReturnType<typeof destroyChangeOrderFile>>
>
export type DownloadChangeOrderFileResult = NonNullable<
  Awaited<ReturnType<typeof downloadChangeOrderFile>>
>
export type ListContractEmbeddedFile2sResult = NonNullable<
  Awaited<ReturnType<typeof listContractEmbeddedFile2s>>
>
export type CreateContractEmbeddedFile2Result = NonNullable<
  Awaited<ReturnType<typeof createContractEmbeddedFile2>>
>
export type RetrieveContractEmbeddedFile2Result = NonNullable<
  Awaited<ReturnType<typeof retrieveContractEmbeddedFile2>>
>
export type PartialUpdateContractEmbeddedFile2Result = NonNullable<
  Awaited<ReturnType<typeof partialUpdateContractEmbeddedFile2>>
>
export type DestroyContractEmbeddedFile2Result = NonNullable<
  Awaited<ReturnType<typeof destroyContractEmbeddedFile2>>
>
export type DownloadContractEmbeddedFile2Result = NonNullable<
  Awaited<ReturnType<typeof downloadContractEmbeddedFile2>>
>
export type ListContractItemsResult = NonNullable<Awaited<ReturnType<typeof listContractItems>>>
export type CreateContractItemResult = NonNullable<Awaited<ReturnType<typeof createContractItem>>>
export type RetrieveContractItemResult = NonNullable<
  Awaited<ReturnType<typeof retrieveContractItem>>
>
export type PartialUpdateContractItemResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateContractItem>>
>
export type DestroyContractItemResult = NonNullable<Awaited<ReturnType<typeof destroyContractItem>>>
export type GetContractItemArchiveRestrictionsResult = NonNullable<
  Awaited<ReturnType<typeof getContractItemArchiveRestrictions>>
>
export type ListContractPrimaryFilesResult = NonNullable<
  Awaited<ReturnType<typeof listContractPrimaryFiles>>
>
export type CreateContractPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof createContractPrimaryFile>>
>
export type RetrieveContractPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveContractPrimaryFile>>
>
export type PartialUpdateContractPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateContractPrimaryFile>>
>
export type DestroyContractPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof destroyContractPrimaryFile>>
>
export type DownloadContractPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof downloadContractPrimaryFile>>
>
export type ListContractsResult = NonNullable<Awaited<ReturnType<typeof listContracts>>>
export type CreateContractResult = NonNullable<Awaited<ReturnType<typeof createContract>>>
export type RetrieveContractResult = NonNullable<Awaited<ReturnType<typeof retrieveContract>>>
export type PartialUpdateContractResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateContract>>
>
export type GetContractArchiveRestrictionsResult = NonNullable<
  Awaited<ReturnType<typeof getContractArchiveRestrictions>>
>
export type ListCostCodesResult = NonNullable<Awaited<ReturnType<typeof listCostCodes>>>
export type CreateCostCodeResult = NonNullable<Awaited<ReturnType<typeof createCostCode>>>
export type RetrieveCostCodeResult = NonNullable<Awaited<ReturnType<typeof retrieveCostCode>>>
export type PartialUpdateCostCodeResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateCostCode>>
>
export type ListCostTypesResult = NonNullable<Awaited<ReturnType<typeof listCostTypes>>>
export type CreateCostTypeResult = NonNullable<Awaited<ReturnType<typeof createCostType>>>
export type RetrieveCostTypeResult = NonNullable<Awaited<ReturnType<typeof retrieveCostType>>>
export type PartialUpdateCostTypeResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateCostType>>
>
export type GetCostTypeArchiveRestrictionsResult = NonNullable<
  Awaited<ReturnType<typeof getCostTypeArchiveRestrictions>>
>
export type ListGeneralProjectConfigsResult = NonNullable<
  Awaited<ReturnType<typeof listGeneralProjectConfigs>>
>
export type CreateGeneralProjectConfigResult = NonNullable<
  Awaited<ReturnType<typeof createGeneralProjectConfig>>
>
export type RetrieveGeneralProjectConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveGeneralProjectConfig>>
>
export type PartialUpdateGeneralProjectConfigResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateGeneralProjectConfig>>
>
export type RetrieveSingletonGeneralProjectConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveSingletonGeneralProjectConfig>>
>
export type ListPayerGeneralProjectConfigsResult = NonNullable<
  Awaited<ReturnType<typeof listPayerGeneralProjectConfigs>>
>
export type RetrievePayerGeneralProjectConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrievePayerGeneralProjectConfig>>
>
export type ListRetainageReleasesResult = NonNullable<
  Awaited<ReturnType<typeof listRetainageReleases>>
>
export type CreateRetainageReleaseResult = NonNullable<
  Awaited<ReturnType<typeof createRetainageRelease>>
>
export type RetrieveRetainageReleaseResult = NonNullable<
  Awaited<ReturnType<typeof retrieveRetainageRelease>>
>
export type ListNoteFileLinksResult = NonNullable<Awaited<ReturnType<typeof listNoteFileLinks>>>
export type CreateNoteFileLinkResult = NonNullable<Awaited<ReturnType<typeof createNoteFileLink>>>
export type RetrieveNoteFileLinkResult = NonNullable<
  Awaited<ReturnType<typeof retrieveNoteFileLink>>
>
export type PartialUpdateNoteFileLinkResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateNoteFileLink>>
>
export type DestroyNoteFileLinkResult = NonNullable<Awaited<ReturnType<typeof destroyNoteFileLink>>>
export type DownloadNoteFileLinkResult = NonNullable<
  Awaited<ReturnType<typeof downloadNoteFileLink>>
>
export type ListNoteMentionLinksResult = NonNullable<
  Awaited<ReturnType<typeof listNoteMentionLinks>>
>
export type CreateNoteMentionLinkResult = NonNullable<
  Awaited<ReturnType<typeof createNoteMentionLink>>
>
export type RetrieveNoteMentionLinkResult = NonNullable<
  Awaited<ReturnType<typeof retrieveNoteMentionLink>>
>
export type ListNotesResult = NonNullable<Awaited<ReturnType<typeof listNotes>>>
export type CreateNoteResult = NonNullable<Awaited<ReturnType<typeof createNote>>>
export type RetrieveNoteResult = NonNullable<Awaited<ReturnType<typeof retrieveNote>>>
export type DestroyNoteResult = NonNullable<Awaited<ReturnType<typeof destroyNote>>>
export type ListBillsResult = NonNullable<Awaited<ReturnType<typeof listBills>>>
export type CreateBillResult = NonNullable<Awaited<ReturnType<typeof createBill>>>
export type RetrieveBillResult = NonNullable<Awaited<ReturnType<typeof retrieveBill>>>
export type PartialUpdateBillResult = NonNullable<Awaited<ReturnType<typeof partialUpdateBill>>>
export type DestroyBillResult = NonNullable<Awaited<ReturnType<typeof destroyBill>>>
export type RetrieveBillPdfResult = NonNullable<Awaited<ReturnType<typeof retrieveBillPdf>>>
export type RetrieveBillZipResult = NonNullable<Awaited<ReturnType<typeof retrieveBillZip>>>
export type ListContractProgressSummarysResult = NonNullable<
  Awaited<ReturnType<typeof listContractProgressSummarys>>
>
export type RetrieveContractProgressSummaryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveContractProgressSummary>>
>
export type ListExternalPaymentSourcesResult = NonNullable<
  Awaited<ReturnType<typeof listExternalPaymentSources>>
>
export type RetrieveExternalPaymentSourceResult = NonNullable<
  Awaited<ReturnType<typeof retrieveExternalPaymentSource>>
>
export type ListInvoiceActionRequestsResult = NonNullable<
  Awaited<ReturnType<typeof listInvoiceActionRequests>>
>
export type CreateInvoiceActionRequestResult = NonNullable<
  Awaited<ReturnType<typeof createInvoiceActionRequest>>
>
export type RetrieveInvoiceActionRequestResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoiceActionRequest>>
>
export type ListInvoiceApprovalActionsResult = NonNullable<
  Awaited<ReturnType<typeof listInvoiceApprovalActions>>
>
export type CreateInvoiceApprovalActionResult = NonNullable<
  Awaited<ReturnType<typeof createInvoiceApprovalAction>>
>
export type RetrieveInvoiceApprovalActionResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoiceApprovalAction>>
>
export type ListInvoiceConfigsResult = NonNullable<Awaited<ReturnType<typeof listInvoiceConfigs>>>
export type RetrieveSingletonInvoiceConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveSingletonInvoiceConfig>>
>
export type RetrieveInvoiceConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoiceConfig>>
>
export type ListInvoicePaymentGroupsResult = NonNullable<
  Awaited<ReturnType<typeof listInvoicePaymentGroups>>
>
export type CreateInvoicePaymentGroupResult = NonNullable<
  Awaited<ReturnType<typeof createInvoicePaymentGroup>>
>
export type RetrieveInvoicePaymentGroupResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoicePaymentGroup>>
>
export type PartialUpdateInvoicePaymentGroupResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateInvoicePaymentGroup>>
>
export type ListInvoicePaymentsResult = NonNullable<Awaited<ReturnType<typeof listInvoicePayments>>>
export type CreateInvoicePaymentResult = NonNullable<
  Awaited<ReturnType<typeof createInvoicePayment>>
>
export type RetrieveInvoicePaymentResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoicePayment>>
>
export type PartialUpdateInvoicePaymentResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateInvoicePayment>>
>
export type DestroyInvoicePaymentResult = NonNullable<
  Awaited<ReturnType<typeof destroyInvoicePayment>>
>
export type GetInvoicePaymentArchiveRestrictionsResult = NonNullable<
  Awaited<ReturnType<typeof getInvoicePaymentArchiveRestrictions>>
>
export type GetInvoicePaymentArchiveRestrictionsListResult = NonNullable<
  Awaited<ReturnType<typeof getInvoicePaymentArchiveRestrictionsList>>
>
export type ListInvoicePermissionsResult = NonNullable<
  Awaited<ReturnType<typeof listInvoicePermissions>>
>
export type RetrieveInvoicePermissionResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoicePermission>>
>
export type ListInvoicesResult = NonNullable<Awaited<ReturnType<typeof listInvoices>>>
export type CreateInvoiceResult = NonNullable<Awaited<ReturnType<typeof createInvoice>>>
export type RetrieveInvoiceResult = NonNullable<Awaited<ReturnType<typeof retrieveInvoice>>>
export type PartialUpdateInvoiceResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateInvoice>>
>
export type DestroyInvoiceResult = NonNullable<Awaited<ReturnType<typeof destroyInvoice>>>
export type RetrieveInvoicePdfResult = NonNullable<Awaited<ReturnType<typeof retrieveInvoicePdf>>>
export type RetrieveInvoiceZipResult = NonNullable<Awaited<ReturnType<typeof retrieveInvoiceZip>>>
export type ListLienWaiverClaimantSignaturesResult = NonNullable<
  Awaited<ReturnType<typeof listLienWaiverClaimantSignatures>>
>
export type RetrieveLienWaiverClaimantSignatureResult = NonNullable<
  Awaited<ReturnType<typeof retrieveLienWaiverClaimantSignature>>
>
export type ListLienWaiverEmbeddedFilesResult = NonNullable<
  Awaited<ReturnType<typeof listLienWaiverEmbeddedFiles>>
>
export type CreateLienWaiverEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof createLienWaiverEmbeddedFile>>
>
export type RetrieveLienWaiverEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveLienWaiverEmbeddedFile>>
>
export type DestroyLienWaiverEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof destroyLienWaiverEmbeddedFile>>
>
export type RetrieveLienWaiverEmbeddedFileDownloadResult = NonNullable<
  Awaited<ReturnType<typeof retrieveLienWaiverEmbeddedFileDownload>>
>
export type ListLienWaiverEmbeddedPrimaryFilesResult = NonNullable<
  Awaited<ReturnType<typeof listLienWaiverEmbeddedPrimaryFiles>>
>
export type CreateLienWaiverEmbeddedPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof createLienWaiverEmbeddedPrimaryFile>>
>
export type RetrieveLienWaiverEmbeddedPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveLienWaiverEmbeddedPrimaryFile>>
>
export type DestroyLienWaiverEmbeddedPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof destroyLienWaiverEmbeddedPrimaryFile>>
>
export type RetrieveLienWaiverEmbeddedPrimaryFileDownloadResult = NonNullable<
  Awaited<ReturnType<typeof retrieveLienWaiverEmbeddedPrimaryFileDownload>>
>
export type ListLienWaiversResult = NonNullable<Awaited<ReturnType<typeof listLienWaivers>>>
export type CreateLienWaiverResult = NonNullable<Awaited<ReturnType<typeof createLienWaiver>>>
export type RetrieveLienWaiverResult = NonNullable<Awaited<ReturnType<typeof retrieveLienWaiver>>>
export type PartialUpdateLienWaiverResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateLienWaiver>>
>
export type DestroyLienWaiverResult = NonNullable<Awaited<ReturnType<typeof destroyLienWaiver>>>
export type RetrieveLienWaiverPdfResult = NonNullable<
  Awaited<ReturnType<typeof retrieveLienWaiverPdf>>
>
export type ListPrimeContractInvoiceEmailsResult = NonNullable<
  Awaited<ReturnType<typeof listPrimeContractInvoiceEmails>>
>
export type RetrievePrimeContractInvoiceEmailResult = NonNullable<
  Awaited<ReturnType<typeof retrievePrimeContractInvoiceEmail>>
>
export type ListPrimeContractInvoiceEmailViewsResult = NonNullable<
  Awaited<ReturnType<typeof listPrimeContractInvoiceEmailViews>>
>
export type RetrievePrimeContractInvoiceEmailViewResult = NonNullable<
  Awaited<ReturnType<typeof retrievePrimeContractInvoiceEmailView>>
>
export type ListPrimeContractInvoicePaymentPageVisitsResult = NonNullable<
  Awaited<ReturnType<typeof listPrimeContractInvoicePaymentPageVisits>>
>
export type CreatePrimeContractInvoicePaymentPageVisitResult = NonNullable<
  Awaited<ReturnType<typeof createPrimeContractInvoicePaymentPageVisit>>
>
export type RetrievePrimeContractInvoicePaymentPageVisitResult = NonNullable<
  Awaited<ReturnType<typeof retrievePrimeContractInvoicePaymentPageVisit>>
>
export type ListProjectExpensePermissionsResult = NonNullable<
  Awaited<ReturnType<typeof listProjectExpensePermissions>>
>
export type RetrieveProjectExpensePermissionResult = NonNullable<
  Awaited<ReturnType<typeof retrieveProjectExpensePermission>>
>
export type ListProjectExpenseReceiptEmbeddedFilesResult = NonNullable<
  Awaited<ReturnType<typeof listProjectExpenseReceiptEmbeddedFiles>>
>
export type CreateProjectExpenseReceiptEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof createProjectExpenseReceiptEmbeddedFile>>
>
export type RetrieveProjectExpenseReceiptEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveProjectExpenseReceiptEmbeddedFile>>
>
export type DestroyProjectExpenseReceiptEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof destroyProjectExpenseReceiptEmbeddedFile>>
>
export type RetrieveProjectExpenseReceiptEmbeddedFileDownloadResult = NonNullable<
  Awaited<ReturnType<typeof retrieveProjectExpenseReceiptEmbeddedFileDownload>>
>
export type ListProjectExpensesResult = NonNullable<Awaited<ReturnType<typeof listProjectExpenses>>>
export type CreateProjectExpenseResult = NonNullable<
  Awaited<ReturnType<typeof createProjectExpense>>
>
export type RetrieveProjectExpenseResult = NonNullable<
  Awaited<ReturnType<typeof retrieveProjectExpense>>
>
export type PartialUpdateProjectExpenseResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateProjectExpense>>
>
export type ListProjectProgressSummaryV2sResult = NonNullable<
  Awaited<ReturnType<typeof listProjectProgressSummaryV2s>>
>
export type RetrieveProjectProgressSummaryV2Result = NonNullable<
  Awaited<ReturnType<typeof retrieveProjectProgressSummaryV2>>
>
export type ListScheduleOfValuesItemProgressSummarysResult = NonNullable<
  Awaited<ReturnType<typeof listScheduleOfValuesItemProgressSummarys>>
>
export type RetrieveScheduleOfValuesItemProgressSummaryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveScheduleOfValuesItemProgressSummary>>
>
export type GetContractSummaryByItemResult = NonNullable<
  Awaited<ReturnType<typeof getContractSummaryByItem>>
>
export type GetExpenseMetricsResult = NonNullable<Awaited<ReturnType<typeof getExpenseMetrics>>>
export type GetInvoiceApprovalWorkflowRequirementsResult = NonNullable<
  Awaited<ReturnType<typeof getInvoiceApprovalWorkflowRequirements>>
>
export type GetInvoiceCardFeeResult = NonNullable<Awaited<ReturnType<typeof getInvoiceCardFee>>>
export type GetInvoiceFileResult = NonNullable<Awaited<ReturnType<typeof getInvoiceFile>>>
export type GetInvoicePaymentStartDateResult = NonNullable<
  Awaited<ReturnType<typeof getInvoicePaymentStartDate>>
>
export type GetInvoiceSnapshotValuesResult = NonNullable<
  Awaited<ReturnType<typeof getInvoiceSnapshotValues>>
>
export type GetLienWaiverPaymentBlockersResult = NonNullable<
  Awaited<ReturnType<typeof getLienWaiverPaymentBlockers>>
>
export type GetLienWaiverPdfPreviewV2Result = NonNullable<
  Awaited<ReturnType<typeof getLienWaiverPdfPreviewV2>>
>
export type GetNextInvoiceNumberResult = NonNullable<
  Awaited<ReturnType<typeof getNextInvoiceNumber>>
>
export type GetRetainageValuesResult = NonNullable<Awaited<ReturnType<typeof getRetainageValues>>>
export type PredictUncategorizedExpenseDetailsResult = NonNullable<
  Awaited<ReturnType<typeof predictUncategorizedExpenseDetails>>
>
export type ListExpenseEmbeddedFilesResult = NonNullable<
  Awaited<ReturnType<typeof listExpenseEmbeddedFiles>>
>
export type CreateExpenseEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof createExpenseEmbeddedFile>>
>
export type RetrieveExpenseEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveExpenseEmbeddedFile>>
>
export type PartialUpdateExpenseEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateExpenseEmbeddedFile>>
>
export type DestroyExpenseEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof destroyExpenseEmbeddedFile>>
>
export type DownloadExpenseEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof downloadExpenseEmbeddedFile>>
>
export type ListInvoiceApprovalRulesResult = NonNullable<
  Awaited<ReturnType<typeof listInvoiceApprovalRules>>
>
export type CreateInvoiceApprovalRuleResult = NonNullable<
  Awaited<ReturnType<typeof createInvoiceApprovalRule>>
>
export type RetrieveInvoiceApprovalRuleResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoiceApprovalRule>>
>
export type PartialUpdateInvoiceApprovalRuleResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateInvoiceApprovalRule>>
>
export type DestroyInvoiceApprovalRuleResult = NonNullable<
  Awaited<ReturnType<typeof destroyInvoiceApprovalRule>>
>
export type ListInvoiceFilesResult = NonNullable<Awaited<ReturnType<typeof listInvoiceFiles>>>
export type CreateInvoiceFileResult = NonNullable<Awaited<ReturnType<typeof createInvoiceFile>>>
export type RetrieveInvoiceFileResult = NonNullable<Awaited<ReturnType<typeof retrieveInvoiceFile>>>
export type PartialUpdateInvoiceFileResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateInvoiceFile>>
>
export type DestroyInvoiceFileResult = NonNullable<Awaited<ReturnType<typeof destroyInvoiceFile>>>
export type DownloadInvoiceFileResult = NonNullable<Awaited<ReturnType<typeof downloadInvoiceFile>>>
export type ListInvoiceIntentEmbeddedFilesResult = NonNullable<
  Awaited<ReturnType<typeof listInvoiceIntentEmbeddedFiles>>
>
export type CreateInvoiceIntentEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof createInvoiceIntentEmbeddedFile>>
>
export type RetrieveInvoiceIntentEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoiceIntentEmbeddedFile>>
>
export type PartialUpdateInvoiceIntentEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateInvoiceIntentEmbeddedFile>>
>
export type DestroyInvoiceIntentEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof destroyInvoiceIntentEmbeddedFile>>
>
export type DownloadInvoiceIntentEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof downloadInvoiceIntentEmbeddedFile>>
>
export type ListInvoiceIntentsResult = NonNullable<Awaited<ReturnType<typeof listInvoiceIntents>>>
export type CreateInvoiceIntentResult = NonNullable<Awaited<ReturnType<typeof createInvoiceIntent>>>
export type RetrieveInvoiceIntentResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoiceIntent>>
>
export type PartialUpdateInvoiceIntentResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateInvoiceIntent>>
>
export type DestroyInvoiceIntentResult = NonNullable<
  Awaited<ReturnType<typeof destroyInvoiceIntent>>
>
export type ListInvoiceLineItemMarkupLinksResult = NonNullable<
  Awaited<ReturnType<typeof listInvoiceLineItemMarkupLinks>>
>
export type CreateInvoiceLineItemMarkupLinkResult = NonNullable<
  Awaited<ReturnType<typeof createInvoiceLineItemMarkupLink>>
>
export type RetrieveInvoiceLineItemMarkupLinkResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoiceLineItemMarkupLink>>
>
export type PartialUpdateInvoiceLineItemMarkupLinkResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateInvoiceLineItemMarkupLink>>
>
export type DestroyInvoiceLineItemMarkupLinkResult = NonNullable<
  Awaited<ReturnType<typeof destroyInvoiceLineItemMarkupLink>>
>
export type ListInvoiceLineItemsResult = NonNullable<
  Awaited<ReturnType<typeof listInvoiceLineItems>>
>
export type CreateInvoiceLineItemResult = NonNullable<
  Awaited<ReturnType<typeof createInvoiceLineItem>>
>
export type RetrieveInvoiceLineItemResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoiceLineItem>>
>
export type PartialUpdateInvoiceLineItemResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateInvoiceLineItem>>
>
export type DestroyInvoiceLineItemResult = NonNullable<
  Awaited<ReturnType<typeof destroyInvoiceLineItem>>
>
export type ListInvoicePrefillLineItemsResult = NonNullable<
  Awaited<ReturnType<typeof listInvoicePrefillLineItems>>
>
export type RetrieveInvoicePrefillLineItemResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoicePrefillLineItem>>
>
export type ListInvoicePrefillsResult = NonNullable<Awaited<ReturnType<typeof listInvoicePrefills>>>
export type RetrieveInvoicePrefillResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoicePrefill>>
>
export type ListInvoicePrimaryFilesResult = NonNullable<
  Awaited<ReturnType<typeof listInvoicePrimaryFiles>>
>
export type CreateInvoicePrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof createInvoicePrimaryFile>>
>
export type RetrieveInvoicePrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoicePrimaryFile>>
>
export type PartialUpdateInvoicePrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateInvoicePrimaryFile>>
>
export type DestroyInvoicePrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof destroyInvoicePrimaryFile>>
>
export type DownloadInvoicePrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof downloadInvoicePrimaryFile>>
>
export type ListLienWaiverFilesResult = NonNullable<Awaited<ReturnType<typeof listLienWaiverFiles>>>
export type CreateLienWaiverFileResult = NonNullable<
  Awaited<ReturnType<typeof createLienWaiverFile>>
>
export type RetrieveLienWaiverFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveLienWaiverFile>>
>
export type PartialUpdateLienWaiverFileResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateLienWaiverFile>>
>
export type DestroyLienWaiverFileResult = NonNullable<
  Awaited<ReturnType<typeof destroyLienWaiverFile>>
>
export type DownloadLienWaiverFileResult = NonNullable<
  Awaited<ReturnType<typeof downloadLienWaiverFile>>
>
export type ListLienWaiverPrefillsResult = NonNullable<
  Awaited<ReturnType<typeof listLienWaiverPrefills>>
>
export type CreateLienWaiverPrefillResult = NonNullable<
  Awaited<ReturnType<typeof createLienWaiverPrefill>>
>
export type RetrieveLienWaiverPrefillResult = NonNullable<
  Awaited<ReturnType<typeof retrieveLienWaiverPrefill>>
>
export type PartialUpdateLienWaiverPrefillResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateLienWaiverPrefill>>
>
export type ListLienWaiverPrimaryFilesResult = NonNullable<
  Awaited<ReturnType<typeof listLienWaiverPrimaryFiles>>
>
export type CreateLienWaiverPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof createLienWaiverPrimaryFile>>
>
export type RetrieveLienWaiverPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveLienWaiverPrimaryFile>>
>
export type PartialUpdateLienWaiverPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateLienWaiverPrimaryFile>>
>
export type DestroyLienWaiverPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof destroyLienWaiverPrimaryFile>>
>
export type DownloadLienWaiverPrimaryFileResult = NonNullable<
  Awaited<ReturnType<typeof downloadLienWaiverPrimaryFile>>
>
export type ListPaymentSourcesResult = NonNullable<Awaited<ReturnType<typeof listPaymentSources>>>
export type CreatePaymentSourceResult = NonNullable<Awaited<ReturnType<typeof createPaymentSource>>>
export type RetrievePaymentSourceResult = NonNullable<
  Awaited<ReturnType<typeof retrievePaymentSource>>
>
export type PartialUpdatePaymentSourceResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdatePaymentSource>>
>
export type ListTimeEntrysResult = NonNullable<Awaited<ReturnType<typeof listTimeEntrys>>>
export type CreateTimeEntryResult = NonNullable<Awaited<ReturnType<typeof createTimeEntry>>>
export type RetrieveTimeEntryResult = NonNullable<Awaited<ReturnType<typeof retrieveTimeEntry>>>
export type PartialUpdateTimeEntryResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateTimeEntry>>
>
export type ListVendorBillingConfigsResult = NonNullable<
  Awaited<ReturnType<typeof listVendorBillingConfigs>>
>
export type CreateVendorBillingConfigResult = NonNullable<
  Awaited<ReturnType<typeof createVendorBillingConfig>>
>
export type RetrieveVendorBillingConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveVendorBillingConfig>>
>
export type PartialUpdateVendorBillingConfigResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateVendorBillingConfig>>
>
export type ListWorkCompletedSourcesResult = NonNullable<
  Awaited<ReturnType<typeof listWorkCompletedSources>>
>
export type CreateWorkCompletedSourceResult = NonNullable<
  Awaited<ReturnType<typeof createWorkCompletedSource>>
>
export type RetrieveWorkCompletedSourceResult = NonNullable<
  Awaited<ReturnType<typeof retrieveWorkCompletedSource>>
>
export type PartialUpdateWorkCompletedSourceResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateWorkCompletedSource>>
>
export type DestroyWorkCompletedSourceResult = NonNullable<
  Awaited<ReturnType<typeof destroyWorkCompletedSource>>
>
export type ListDummysResult = NonNullable<Awaited<ReturnType<typeof listDummys>>>
export type ListEmailVerificationAttemptsResult = NonNullable<
  Awaited<ReturnType<typeof listEmailVerificationAttempts>>
>
export type CreateEmailVerificationAttemptResult = NonNullable<
  Awaited<ReturnType<typeof createEmailVerificationAttempt>>
>
export type RetrieveEmailVerificationAttemptResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEmailVerificationAttempt>>
>
export type ListEmailVerificationsResult = NonNullable<
  Awaited<ReturnType<typeof listEmailVerifications>>
>
export type CreateEmailVerificationResult = NonNullable<
  Awaited<ReturnType<typeof createEmailVerification>>
>
export type RetrieveEmailVerificationResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEmailVerification>>
>
export type ListUsersResult = NonNullable<Awaited<ReturnType<typeof listUsers>>>
export type CreateUserResult = NonNullable<Awaited<ReturnType<typeof createUser>>>
export type RetrieveUserResult = NonNullable<Awaited<ReturnType<typeof retrieveUser>>>
export type PartialUpdateUserResult = NonNullable<Awaited<ReturnType<typeof partialUpdateUser>>>
export type ListCardsResult = NonNullable<Awaited<ReturnType<typeof listCards>>>
export type RetrieveCardResult = NonNullable<Awaited<ReturnType<typeof retrieveCard>>>
export type ListMicrodepositRequirementsResult = NonNullable<
  Awaited<ReturnType<typeof listMicrodepositRequirements>>
>
export type RetrieveMicrodepositRequirementResult = NonNullable<
  Awaited<ReturnType<typeof retrieveMicrodepositRequirement>>
>
export type ListTransactionAccountsResult = NonNullable<
  Awaited<ReturnType<typeof listTransactionAccounts>>
>
export type RetrieveTransactionAccountResult = NonNullable<
  Awaited<ReturnType<typeof retrieveTransactionAccount>>
>
export type GetBankNameResult = NonNullable<Awaited<ReturnType<typeof getBankName>>>
export type GetStatementPDFResult = NonNullable<Awaited<ReturnType<typeof getStatementPDF>>>
export type ListBankAccountsResult = NonNullable<Awaited<ReturnType<typeof listBankAccounts>>>
export type CreateBankAccountResult = NonNullable<Awaited<ReturnType<typeof createBankAccount>>>
export type RetrieveBankAccountResult = NonNullable<Awaited<ReturnType<typeof retrieveBankAccount>>>
export type PartialUpdateBankAccountResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateBankAccount>>
>
export type DestroyBankAccountResult = NonNullable<Awaited<ReturnType<typeof destroyBankAccount>>>
export type GetBankAccountArchiveRestrictionsResult = NonNullable<
  Awaited<ReturnType<typeof getBankAccountArchiveRestrictions>>
>
export type ListControlledPayeeBankAccountsResult = NonNullable<
  Awaited<ReturnType<typeof listControlledPayeeBankAccounts>>
>
export type CreateControlledPayeeBankAccountResult = NonNullable<
  Awaited<ReturnType<typeof createControlledPayeeBankAccount>>
>
export type RetrieveControlledPayeeBankAccountResult = NonNullable<
  Awaited<ReturnType<typeof retrieveControlledPayeeBankAccount>>
>
export type PartialUpdateControlledPayeeBankAccountResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateControlledPayeeBankAccount>>
>
export type GetControlledPayeeBankAccountArchiveRestrictionsResult = NonNullable<
  Awaited<ReturnType<typeof getControlledPayeeBankAccountArchiveRestrictions>>
>
export type ListPayeeBankAccountsResult = NonNullable<
  Awaited<ReturnType<typeof listPayeeBankAccounts>>
>
export type RetrievePayeeBankAccountResult = NonNullable<
  Awaited<ReturnType<typeof retrievePayeeBankAccount>>
>
export type GetPayeeBankAccountArchiveRestrictionsResult = NonNullable<
  Awaited<ReturnType<typeof getPayeeBankAccountArchiveRestrictions>>
>
export type ListCashBackPaymentsResult = NonNullable<
  Awaited<ReturnType<typeof listCashBackPayments>>
>
export type RetrieveCashBackPaymentResult = NonNullable<
  Awaited<ReturnType<typeof retrieveCashBackPayment>>
>
export type ListSaasTiersResult = NonNullable<Awaited<ReturnType<typeof listSaasTiers>>>
export type RetrieveSaasTierResult = NonNullable<Awaited<ReturnType<typeof retrieveSaasTier>>>
export type ListYieldPaymentsResult = NonNullable<Awaited<ReturnType<typeof listYieldPayments>>>
export type RetrieveYieldPaymentResult = NonNullable<
  Awaited<ReturnType<typeof retrieveYieldPayment>>
>
export type GetProjectBudgetCostResult = NonNullable<
  Awaited<ReturnType<typeof getProjectBudgetCost>>
>
export type ListBudgetConfigsResult = NonNullable<Awaited<ReturnType<typeof listBudgetConfigs>>>
export type CreateBudgetConfigResult = NonNullable<Awaited<ReturnType<typeof createBudgetConfig>>>
export type RetrieveBudgetConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveBudgetConfig>>
>
export type PartialUpdateBudgetConfigResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateBudgetConfig>>
>
export type RetrieveSingletonBudgetConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveSingletonBudgetConfig>>
>
export type ListProjectBudgetItemsResult = NonNullable<
  Awaited<ReturnType<typeof listProjectBudgetItems>>
>
export type CreateProjectBudgetItemResult = NonNullable<
  Awaited<ReturnType<typeof createProjectBudgetItem>>
>
export type RetrieveProjectBudgetItemResult = NonNullable<
  Awaited<ReturnType<typeof retrieveProjectBudgetItem>>
>
export type PartialUpdateProjectBudgetItemResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateProjectBudgetItem>>
>
export type DestroyProjectBudgetItemResult = NonNullable<
  Awaited<ReturnType<typeof destroyProjectBudgetItem>>
>
export type ListProjectBudgetsResult = NonNullable<Awaited<ReturnType<typeof listProjectBudgets>>>
export type CreateProjectBudgetResult = NonNullable<Awaited<ReturnType<typeof createProjectBudget>>>
export type RetrieveProjectBudgetResult = NonNullable<
  Awaited<ReturnType<typeof retrieveProjectBudget>>
>
export type PartialUpdateProjectBudgetResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateProjectBudget>>
>
export type ListTasksResult = NonNullable<Awaited<ReturnType<typeof listTasks>>>
export type RetrieveTaskResult = NonNullable<Awaited<ReturnType<typeof retrieveTask>>>
export type RetrieveFileDownloadResult = NonNullable<
  Awaited<ReturnType<typeof retrieveFileDownload>>
>
export type GetStripeIdentityVerificationSessionUrlResult = NonNullable<
  Awaited<ReturnType<typeof getStripeIdentityVerificationSessionUrl>>
>
export type WhoAmIResult = NonNullable<Awaited<ReturnType<typeof whoAmI>>>
export type ListOnboardingSurveysResult = NonNullable<
  Awaited<ReturnType<typeof listOnboardingSurveys>>
>
export type CreateOnboardingSurveyResult = NonNullable<
  Awaited<ReturnType<typeof createOnboardingSurvey>>
>
export type RetrieveOnboardingSurveyResult = NonNullable<
  Awaited<ReturnType<typeof retrieveOnboardingSurvey>>
>
export type PartialUpdateOnboardingSurveyResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateOnboardingSurvey>>
>
export type ListStripeIdentityVerificationSessionsResult = NonNullable<
  Awaited<ReturnType<typeof listStripeIdentityVerificationSessions>>
>
export type RetrieveStripeIdentityVerificationSessionResult = NonNullable<
  Awaited<ReturnType<typeof retrieveStripeIdentityVerificationSession>>
>
export type ListComplianceDocumentEmbeddedFilesResult = NonNullable<
  Awaited<ReturnType<typeof listComplianceDocumentEmbeddedFiles>>
>
export type RetrieveComplianceDocumentEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveComplianceDocumentEmbeddedFile>>
>
export type RetrieveComplianceDocumentEmbeddedFileDownloadResult = NonNullable<
  Awaited<ReturnType<typeof retrieveComplianceDocumentEmbeddedFileDownload>>
>
export type ListComplianceDocumentsResult = NonNullable<
  Awaited<ReturnType<typeof listComplianceDocuments>>
>
export type CreateComplianceDocumentResult = NonNullable<
  Awaited<ReturnType<typeof createComplianceDocument>>
>
export type RetrieveComplianceDocumentResult = NonNullable<
  Awaited<ReturnType<typeof retrieveComplianceDocument>>
>
export type DestroyComplianceDocumentResult = NonNullable<
  Awaited<ReturnType<typeof destroyComplianceDocument>>
>
export type ListComplianceSettingsListsResult = NonNullable<
  Awaited<ReturnType<typeof listComplianceSettingsLists>>
>
export type RetrieveSingletonComplianceSettingsListResult = NonNullable<
  Awaited<ReturnType<typeof retrieveSingletonComplianceSettingsList>>
>
export type RetrieveComplianceSettingsListResult = NonNullable<
  Awaited<ReturnType<typeof retrieveComplianceSettingsList>>
>
export type PartialUpdateComplianceSettingsListResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateComplianceSettingsList>>
>
export type ListComplianceStatusSummarysResult = NonNullable<
  Awaited<ReturnType<typeof listComplianceStatusSummarys>>
>
export type RetrieveComplianceStatusSummaryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveComplianceStatusSummary>>
>
export type ListPayeeComplianceRequirementsResult = NonNullable<
  Awaited<ReturnType<typeof listPayeeComplianceRequirements>>
>
export type RetrievePayeeComplianceRequirementResult = NonNullable<
  Awaited<ReturnType<typeof retrievePayeeComplianceRequirement>>
>
export type PartialUpdatePayeeComplianceRequirementResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdatePayeeComplianceRequirement>>
>
export type ListRequirementListsResult = NonNullable<
  Awaited<ReturnType<typeof listRequirementLists>>
>
export type RetrieveRequirementListResult = NonNullable<
  Awaited<ReturnType<typeof retrieveRequirementList>>
>
export type GetCombinedComplianceSettingsListAsPayeeResult = NonNullable<
  Awaited<ReturnType<typeof getCombinedComplianceSettingsListAsPayee>>
>
export type GetComplianceSettingsListForPayeeResult = NonNullable<
  Awaited<ReturnType<typeof getComplianceSettingsListForPayee>>
>
export type ListComplianceSettingsListPayeeOverridesResult = NonNullable<
  Awaited<ReturnType<typeof listComplianceSettingsListPayeeOverrides>>
>
export type CreateComplianceSettingsListPayeeOverrideResult = NonNullable<
  Awaited<ReturnType<typeof createComplianceSettingsListPayeeOverride>>
>
export type RetrieveComplianceSettingsListPayeeOverrideResult = NonNullable<
  Awaited<ReturnType<typeof retrieveComplianceSettingsListPayeeOverride>>
>
export type PartialUpdateComplianceSettingsListPayeeOverrideResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateComplianceSettingsListPayeeOverride>>
>
export type DestroyComplianceSettingsListPayeeOverrideResult = NonNullable<
  Awaited<ReturnType<typeof destroyComplianceSettingsListPayeeOverride>>
>
export type ListEstimateApprovalSignaturesResult = NonNullable<
  Awaited<ReturnType<typeof listEstimateApprovalSignatures>>
>
export type RetrieveEstimateApprovalSignatureResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimateApprovalSignature>>
>
export type ListEstimateEmailsResult = NonNullable<Awaited<ReturnType<typeof listEstimateEmails>>>
export type RetrieveEstimateEmailResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimateEmail>>
>
export type ListEstimateEmailViewsResult = NonNullable<
  Awaited<ReturnType<typeof listEstimateEmailViews>>
>
export type RetrieveEstimateEmailViewResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimateEmailView>>
>
export type ListEstimateEmbeddedFilesResult = NonNullable<
  Awaited<ReturnType<typeof listEstimateEmbeddedFiles>>
>
export type CreateEstimateEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof createEstimateEmbeddedFile>>
>
export type RetrieveEstimateEmbeddedFileResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimateEmbeddedFile>>
>
export type RetrieveEstimateEmbeddedFileDownloadResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimateEmbeddedFileDownload>>
>
export type RetrieveEstimateFileDownloadResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimateFileDownload>>
>
export type ListEstimatePageVisitsResult = NonNullable<
  Awaited<ReturnType<typeof listEstimatePageVisits>>
>
export type CreateEstimatePageVisitResult = NonNullable<
  Awaited<ReturnType<typeof createEstimatePageVisit>>
>
export type RetrieveEstimatePageVisitResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimatePageVisit>>
>
export type ListEstimatesResult = NonNullable<Awaited<ReturnType<typeof listEstimates>>>
export type CreateEstimateResult = NonNullable<Awaited<ReturnType<typeof createEstimate>>>
export type RetrieveEstimateResult = NonNullable<Awaited<ReturnType<typeof retrieveEstimate>>>
export type PartialUpdateEstimateResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateEstimate>>
>
export type DestroyEstimateResult = NonNullable<Awaited<ReturnType<typeof destroyEstimate>>>
export type RetrieveEstimatePdfResult = NonNullable<Awaited<ReturnType<typeof retrieveEstimatePdf>>>
export type GetEstimateCoverPagePdfPreviewResult = NonNullable<
  Awaited<ReturnType<typeof getEstimateCoverPagePdfPreview>>
>
export type GetEstimateInputFromFileResult = NonNullable<
  Awaited<ReturnType<typeof getEstimateInputFromFile>>
>
export type GetNextEstimateNumberResult = NonNullable<
  Awaited<ReturnType<typeof getNextEstimateNumber>>
>
export type ListCostLibraryItemsResult = NonNullable<
  Awaited<ReturnType<typeof listCostLibraryItems>>
>
export type CreateCostLibraryItemResult = NonNullable<
  Awaited<ReturnType<typeof createCostLibraryItem>>
>
export type RetrieveCostLibraryItemResult = NonNullable<
  Awaited<ReturnType<typeof retrieveCostLibraryItem>>
>
export type PartialUpdateCostLibraryItemResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateCostLibraryItem>>
>
export type ListEstimateConfigsResult = NonNullable<Awaited<ReturnType<typeof listEstimateConfigs>>>
export type CreateEstimateConfigResult = NonNullable<
  Awaited<ReturnType<typeof createEstimateConfig>>
>
export type RetrieveEstimateConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimateConfig>>
>
export type PartialUpdateEstimateConfigResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateEstimateConfig>>
>
export type RetrieveSingletonEstimateConfigResult = NonNullable<
  Awaited<ReturnType<typeof retrieveSingletonEstimateConfig>>
>
export type ListEstimateCoverPagePhotosResult = NonNullable<
  Awaited<ReturnType<typeof listEstimateCoverPagePhotos>>
>
export type CreateEstimateCoverPagePhotoResult = NonNullable<
  Awaited<ReturnType<typeof createEstimateCoverPagePhoto>>
>
export type RetrieveEstimateCoverPagePhotoResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimateCoverPagePhoto>>
>
export type PartialUpdateEstimateCoverPagePhotoResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateEstimateCoverPagePhoto>>
>
export type DestroyEstimateCoverPagePhotoResult = NonNullable<
  Awaited<ReturnType<typeof destroyEstimateCoverPagePhoto>>
>
export type DownloadEstimateCoverPagePhotoResult = NonNullable<
  Awaited<ReturnType<typeof downloadEstimateCoverPagePhoto>>
>
export type ListEstimateCoverPagesResult = NonNullable<
  Awaited<ReturnType<typeof listEstimateCoverPages>>
>
export type CreateEstimateCoverPageResult = NonNullable<
  Awaited<ReturnType<typeof createEstimateCoverPage>>
>
export type RetrieveEstimateCoverPageResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimateCoverPage>>
>
export type PartialUpdateEstimateCoverPageResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateEstimateCoverPage>>
>
export type DestroyEstimateCoverPageResult = NonNullable<
  Awaited<ReturnType<typeof destroyEstimateCoverPage>>
>
export type DownloadEstimateCoverPageResult = NonNullable<
  Awaited<ReturnType<typeof downloadEstimateCoverPage>>
>
export type ListEstimateUploadedCoverPagesResult = NonNullable<
  Awaited<ReturnType<typeof listEstimateUploadedCoverPages>>
>
export type CreateEstimateUploadedCoverPageResult = NonNullable<
  Awaited<ReturnType<typeof createEstimateUploadedCoverPage>>
>
export type RetrieveEstimateUploadedCoverPageResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimateUploadedCoverPage>>
>
export type PartialUpdateEstimateUploadedCoverPageResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateEstimateUploadedCoverPage>>
>
export type DestroyEstimateUploadedCoverPageResult = NonNullable<
  Awaited<ReturnType<typeof destroyEstimateUploadedCoverPage>>
>
export type DownloadEstimateUploadedCoverPageResult = NonNullable<
  Awaited<ReturnType<typeof downloadEstimateUploadedCoverPage>>
>
export type ListEstimatesAsPayeePageSummarysResult = NonNullable<
  Awaited<ReturnType<typeof listEstimatesAsPayeePageSummarys>>
>
export type RetrieveEstimatesAsPayeePageSummaryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimatesAsPayeePageSummary>>
>
export type ListAccountsResult = NonNullable<Awaited<ReturnType<typeof listAccounts>>>
export type CreateAccountResult = NonNullable<Awaited<ReturnType<typeof createAccount>>>
export type RetrieveAccountResult = NonNullable<Awaited<ReturnType<typeof retrieveAccount>>>
export type PartialUpdateAccountResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateAccount>>
>
export type ListInvoiceOutboundSyncV2sResult = NonNullable<
  Awaited<ReturnType<typeof listInvoiceOutboundSyncV2s>>
>
export type RetrieveInvoiceOutboundSyncV2Result = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoiceOutboundSyncV2>>
>
export type GetLinkTokenResult = NonNullable<Awaited<ReturnType<typeof getLinkToken>>>
export type GetUpdatedTokenStatusResult = NonNullable<
  Awaited<ReturnType<typeof getUpdatedTokenStatus>>
>
export type ListProjectImportRequestsResult = NonNullable<
  Awaited<ReturnType<typeof listProjectImportRequests>>
>
export type CreateProjectImportRequestResult = NonNullable<
  Awaited<ReturnType<typeof createProjectImportRequest>>
>
export type RetrieveProjectImportRequestResult = NonNullable<
  Awaited<ReturnType<typeof retrieveProjectImportRequest>>
>
export type ListQBProjectsResult = NonNullable<Awaited<ReturnType<typeof listQBProjects>>>
export type RetrieveQBProjectResult = NonNullable<Awaited<ReturnType<typeof retrieveQBProject>>>
export type ListQBPullRequestsResult = NonNullable<Awaited<ReturnType<typeof listQBPullRequests>>>
export type RetrieveQBPullRequestResult = NonNullable<
  Awaited<ReturnType<typeof retrieveQBPullRequest>>
>
export type ListQBPushRequestsResult = NonNullable<Awaited<ReturnType<typeof listQBPushRequests>>>
export type RetrieveQBPushRequestResult = NonNullable<
  Awaited<ReturnType<typeof retrieveQBPushRequest>>
>
export type ListQboLinksResult = NonNullable<Awaited<ReturnType<typeof listQboLinks>>>
export type RetrieveQboLinkResult = NonNullable<Awaited<ReturnType<typeof retrieveQboLink>>>
export type ListRawQboLinksResult = NonNullable<Awaited<ReturnType<typeof listRawQboLinks>>>
export type RetrieveRawQboLinkResult = NonNullable<Awaited<ReturnType<typeof retrieveRawQboLink>>>
export type ListBillListRowsResult = NonNullable<Awaited<ReturnType<typeof listBillListRows>>>
export type RetrieveBillListRowResult = NonNullable<Awaited<ReturnType<typeof retrieveBillListRow>>>
export type ListCardTransactionListRowsResult = NonNullable<
  Awaited<ReturnType<typeof listCardTransactionListRows>>
>
export type RetrieveCardTransactionListRowResult = NonNullable<
  Awaited<ReturnType<typeof retrieveCardTransactionListRow>>
>
export type ListClientListRowsResult = NonNullable<Awaited<ReturnType<typeof listClientListRows>>>
export type RetrieveClientListRowResult = NonNullable<
  Awaited<ReturnType<typeof retrieveClientListRow>>
>
export type ListExpenseListRowsResult = NonNullable<Awaited<ReturnType<typeof listExpenseListRows>>>
export type RetrieveExpenseListRowResult = NonNullable<
  Awaited<ReturnType<typeof retrieveExpenseListRow>>
>
export type ListInvoiceListRowsResult = NonNullable<Awaited<ReturnType<typeof listInvoiceListRows>>>
export type RetrieveInvoiceListRowResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoiceListRow>>
>
export type ListInvoicePaymentFeeListRowsResult = NonNullable<
  Awaited<ReturnType<typeof listInvoicePaymentFeeListRows>>
>
export type RetrieveInvoicePaymentFeeListRowResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoicePaymentFeeListRow>>
>
export type ListProjectListRowsResult = NonNullable<Awaited<ReturnType<typeof listProjectListRows>>>
export type RetrieveProjectListRowResult = NonNullable<
  Awaited<ReturnType<typeof retrieveProjectListRow>>
>
export type ListVendorListRowsResult = NonNullable<Awaited<ReturnType<typeof listVendorListRows>>>
export type RetrieveVendorListRowResult = NonNullable<
  Awaited<ReturnType<typeof retrieveVendorListRow>>
>
export type ListBillsPageSummarysResult = NonNullable<
  Awaited<ReturnType<typeof listBillsPageSummarys>>
>
export type RetrieveBillsPageSummaryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveBillsPageSummary>>
>
export type ListEstimateAsPayeeListRowsResult = NonNullable<
  Awaited<ReturnType<typeof listEstimateAsPayeeListRows>>
>
export type RetrieveEstimateAsPayeeListRowResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimateAsPayeeListRow>>
>
export type ListEstimatesPageSummarysResult = NonNullable<
  Awaited<ReturnType<typeof listEstimatesPageSummarys>>
>
export type RetrieveEstimatesPageSummaryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveEstimatesPageSummary>>
>
export type ListInvoicesPageSummarysResult = NonNullable<
  Awaited<ReturnType<typeof listInvoicesPageSummarys>>
>
export type RetrieveInvoicesPageSummaryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInvoicesPageSummary>>
>
export type ListProjectsPageSummarysResult = NonNullable<
  Awaited<ReturnType<typeof listProjectsPageSummarys>>
>
export type RetrieveProjectsPageSummaryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveProjectsPageSummary>>
>
export type APIActivityResult = NonNullable<Awaited<ReturnType<typeof aPIActivity>>>
export type AggregatedPaymentListRowResult = NonNullable<
  Awaited<ReturnType<typeof aggregatedPaymentListRow>>
>
export type CustomerSummaryResult = NonNullable<Awaited<ReturnType<typeof customerSummary>>>
export type GetReadOnlyAuthCommandResult = NonNullable<
  Awaited<ReturnType<typeof getReadOnlyAuthCommand>>
>
export type GetAllowedQBBucketSearchesResult = NonNullable<
  Awaited<ReturnType<typeof getAllowedQBBucketSearches>>
>
export type ListAdminQBPullRequestsResult = NonNullable<
  Awaited<ReturnType<typeof listAdminQBPullRequests>>
>
export type RetrieveAdminQBPullRequestResult = NonNullable<
  Awaited<ReturnType<typeof retrieveAdminQBPullRequest>>
>
export type ListAdminQBPushRequestsResult = NonNullable<
  Awaited<ReturnType<typeof listAdminQBPushRequests>>
>
export type RetrieveAdminQBPushRequestResult = NonNullable<
  Awaited<ReturnType<typeof retrieveAdminQBPushRequest>>
>
export type ListAdminTransactionsResult = NonNullable<
  Awaited<ReturnType<typeof listAdminTransactions>>
>
export type RetrieveAdminTransactionResult = NonNullable<
  Awaited<ReturnType<typeof retrieveAdminTransaction>>
>
export type ListAgaveAccountsResult = NonNullable<Awaited<ReturnType<typeof listAgaveAccounts>>>
export type RetrieveAgaveAccountResult = NonNullable<
  Awaited<ReturnType<typeof retrieveAgaveAccount>>
>
export type ListBusinessNotesResult = NonNullable<Awaited<ReturnType<typeof listBusinessNotes>>>
export type CreateBusinessNoteResult = NonNullable<Awaited<ReturnType<typeof createBusinessNote>>>
export type RetrieveBusinessNoteResult = NonNullable<
  Awaited<ReturnType<typeof retrieveBusinessNote>>
>
export type PartialUpdateBusinessNoteResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateBusinessNote>>
>
export type DestroyBusinessNoteResult = NonNullable<Awaited<ReturnType<typeof destroyBusinessNote>>>
export type EmailPixelResult = NonNullable<Awaited<ReturnType<typeof emailPixel>>>
export type ListBeamBalanceTransactionsResult = NonNullable<
  Awaited<ReturnType<typeof listBeamBalanceTransactions>>
>
export type RetrieveBeamBalanceTransactionResult = NonNullable<
  Awaited<ReturnType<typeof retrieveBeamBalanceTransaction>>
>
export type ListTransfersResult = NonNullable<Awaited<ReturnType<typeof listTransfers>>>
export type CreateTransferResult = NonNullable<Awaited<ReturnType<typeof createTransfer>>>
export type RetrieveTransferResult = NonNullable<Awaited<ReturnType<typeof retrieveTransfer>>>
export type GetHubspotVisitorTokenResult = NonNullable<
  Awaited<ReturnType<typeof getHubspotVisitorToken>>
>
export type ListCardTransactionsResult = NonNullable<
  Awaited<ReturnType<typeof listCardTransactions>>
>
export type RetrieveCardTransactionResult = NonNullable<
  Awaited<ReturnType<typeof retrieveCardTransaction>>
>
export type ListExpenseCardsResult = NonNullable<Awaited<ReturnType<typeof listExpenseCards>>>
export type RetrieveExpenseCardResult = NonNullable<Awaited<ReturnType<typeof retrieveExpenseCard>>>
export type ListCardProgramsResult = NonNullable<Awaited<ReturnType<typeof listCardPrograms>>>
export type RetrieveCardProgramResult = NonNullable<Awaited<ReturnType<typeof retrieveCardProgram>>>
export type GetAccountLoginURLResult = NonNullable<Awaited<ReturnType<typeof getAccountLoginURL>>>
export type GetInvoiceCtaResult = NonNullable<Awaited<ReturnType<typeof getInvoiceCta>>>
export type GetInvoiceListCtaResult = NonNullable<Awaited<ReturnType<typeof getInvoiceListCta>>>
export type ListTutorialsResult = NonNullable<Awaited<ReturnType<typeof listTutorials>>>
export type RetrieveTutorialResult = NonNullable<Awaited<ReturnType<typeof retrieveTutorial>>>
export type PartialUpdateTutorialResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateTutorial>>
>
export type ListExpenseCodesResult = NonNullable<Awaited<ReturnType<typeof listExpenseCodes>>>
export type RetrieveExpenseCodeResult = NonNullable<Awaited<ReturnType<typeof retrieveExpenseCode>>>
export type ListIncomeCodesResult = NonNullable<Awaited<ReturnType<typeof listIncomeCodes>>>
export type RetrieveIncomeCodeResult = NonNullable<Awaited<ReturnType<typeof retrieveIncomeCode>>>
export type ListItemsResult = NonNullable<Awaited<ReturnType<typeof listItems>>>
export type RetrieveItemResult = NonNullable<Awaited<ReturnType<typeof retrieveItem>>>
export type ListLedgerAccountsResult = NonNullable<Awaited<ReturnType<typeof listLedgerAccounts>>>
export type RetrieveLedgerAccountResult = NonNullable<
  Awaited<ReturnType<typeof retrieveLedgerAccount>>
>
export type ListBillOcrRawResultLineItemsResult = NonNullable<
  Awaited<ReturnType<typeof listBillOcrRawResultLineItems>>
>
export type RetrieveBillOcrRawResultLineItemResult = NonNullable<
  Awaited<ReturnType<typeof retrieveBillOcrRawResultLineItem>>
>
export type ListBillOcrRawResultsResult = NonNullable<
  Awaited<ReturnType<typeof listBillOcrRawResults>>
>
export type RetrieveBillOcrRawResultResult = NonNullable<
  Awaited<ReturnType<typeof retrieveBillOcrRawResult>>
>
export type ListMfaRequirementsResult = NonNullable<Awaited<ReturnType<typeof listMfaRequirements>>>
export type RetrieveMfaRequirementResult = NonNullable<
  Awaited<ReturnType<typeof retrieveMfaRequirement>>
>
export type GetAuthRequestLinkResult = NonNullable<Awaited<ReturnType<typeof getAuthRequestLink>>>
export type ListCompanyCamAccountsResult = NonNullable<
  Awaited<ReturnType<typeof listCompanyCamAccounts>>
>
export type CreateCompanyCamAccountResult = NonNullable<
  Awaited<ReturnType<typeof createCompanyCamAccount>>
>
export type RetrieveCompanyCamAccountResult = NonNullable<
  Awaited<ReturnType<typeof retrieveCompanyCamAccount>>
>
export type RetrieveSingletonCompanyCamAccountResult = NonNullable<
  Awaited<ReturnType<typeof retrieveSingletonCompanyCamAccount>>
>
export type ListProcoreAccountsResult = NonNullable<Awaited<ReturnType<typeof listProcoreAccounts>>>
export type CreateProcoreAccountResult = NonNullable<
  Awaited<ReturnType<typeof createProcoreAccount>>
>
export type RetrieveProcoreAccountResult = NonNullable<
  Awaited<ReturnType<typeof retrieveProcoreAccount>>
>
export type RetrieveSingletonProcoreAccountResult = NonNullable<
  Awaited<ReturnType<typeof retrieveSingletonProcoreAccount>>
>
export type ListQboAccountsResult = NonNullable<Awaited<ReturnType<typeof listQboAccounts>>>
export type CreateQboAccountResult = NonNullable<Awaited<ReturnType<typeof createQboAccount>>>
export type RetrieveQboAccountResult = NonNullable<Awaited<ReturnType<typeof retrieveQboAccount>>>
export type RetrieveSingletonQboAccountResult = NonNullable<
  Awaited<ReturnType<typeof retrieveSingletonQboAccount>>
>
export type ListCompanyCamLinksResult = NonNullable<Awaited<ReturnType<typeof listCompanyCamLinks>>>
export type RetrieveCompanyCamLinkResult = NonNullable<
  Awaited<ReturnType<typeof retrieveCompanyCamLink>>
>
export type ListDocumentsResult = NonNullable<Awaited<ReturnType<typeof listDocuments>>>
export type RetrieveDocumentResult = NonNullable<Awaited<ReturnType<typeof retrieveDocument>>>
export type ListPhotosResult = NonNullable<Awaited<ReturnType<typeof listPhotos>>>
export type RetrievePhotoResult = NonNullable<Awaited<ReturnType<typeof retrievePhoto>>>
export type ListRenofiAdsResult = NonNullable<Awaited<ReturnType<typeof listRenofiAds>>>
export type CreateRenofiAdResult = NonNullable<Awaited<ReturnType<typeof createRenofiAd>>>
export type RetrieveRenofiAdResult = NonNullable<Awaited<ReturnType<typeof retrieveRenofiAd>>>
export type PartialUpdateRenofiAdResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateRenofiAd>>
>
export type GetRenofiAdResult = NonNullable<Awaited<ReturnType<typeof getRenofiAd>>>
export type ListRenofiAdClicksResult = NonNullable<Awaited<ReturnType<typeof listRenofiAdClicks>>>
export type RetrieveRenofiAdClickResult = NonNullable<
  Awaited<ReturnType<typeof retrieveRenofiAdClick>>
>
export type ListDealsResult = NonNullable<Awaited<ReturnType<typeof listDeals>>>
export type CreateDealResult = NonNullable<Awaited<ReturnType<typeof createDeal>>>
export type RetrieveDealResult = NonNullable<Awaited<ReturnType<typeof retrieveDeal>>>
export type PartialUpdateDealResult = NonNullable<Awaited<ReturnType<typeof partialUpdateDeal>>>
export type ListDealSummarysResult = NonNullable<Awaited<ReturnType<typeof listDealSummarys>>>
export type RetrieveDealSummaryResult = NonNullable<Awaited<ReturnType<typeof retrieveDealSummary>>>
export type SetBusinessStatusResult = NonNullable<Awaited<ReturnType<typeof setBusinessStatus>>>
export type BulkCreateInvoicePaymentResult = NonNullable<
  Awaited<ReturnType<typeof bulkCreateInvoicePayment>>
>
export type BumpInvoiceVersionResult = NonNullable<Awaited<ReturnType<typeof bumpInvoiceVersion>>>
export type CreateLienWaiverFromInvoicePdfResult = NonNullable<
  Awaited<ReturnType<typeof createLienWaiverFromInvoicePdf>>
>
export type SendInvoiceEmailResult = NonNullable<Awaited<ReturnType<typeof sendInvoiceEmail>>>
export type SendLienWaiverEmailResult = NonNullable<Awaited<ReturnType<typeof sendLienWaiverEmail>>>
export type UpdateContractTypeResult = NonNullable<Awaited<ReturnType<typeof updateContractType>>>
export type CreateLoginResult = NonNullable<Awaited<ReturnType<typeof createLogin>>>
export type CreateLogoutV2Result = NonNullable<Awaited<ReturnType<typeof createLogoutV2>>>
export type AuthenticateMagicLinkResult = NonNullable<
  Awaited<ReturnType<typeof authenticateMagicLink>>
>
export type GenerateMagicLinkTokenResult = NonNullable<
  Awaited<ReturnType<typeof generateMagicLinkToken>>
>
export type PasswordResetCompletionResult = NonNullable<
  Awaited<ReturnType<typeof passwordResetCompletion>>
>
export type PasswordResetInitializationResult = NonNullable<
  Awaited<ReturnType<typeof passwordResetInitialization>>
>
export type VerifyEmailResult = NonNullable<Awaited<ReturnType<typeof verifyEmail>>>
export type CreateMicrodepositAttemptResult = NonNullable<
  Awaited<ReturnType<typeof createMicrodepositAttempt>>
>
export type CreateSetupIntentResult = NonNullable<Awaited<ReturnType<typeof createSetupIntent>>>
export type SetInboundTransfersEnabledResult = NonNullable<
  Awaited<ReturnType<typeof setInboundTransfersEnabled>>
>
export type SetMfaRequirementResult = NonNullable<Awaited<ReturnType<typeof setMfaRequirement>>>
export type CreateFileResult = NonNullable<Awaited<ReturnType<typeof createFile>>>
export type CreateFileUploadResult = NonNullable<Awaited<ReturnType<typeof createFileUpload>>>
export type AdminCreateStripeIdentityVerificationSessionResult = NonNullable<
  Awaited<ReturnType<typeof adminCreateStripeIdentityVerificationSession>>
>
export type GenerateStripeOnboardingClientSecretResult = NonNullable<
  Awaited<ReturnType<typeof generateStripeOnboardingClientSecret>>
>
export type ConvertEstimateToContractResult = NonNullable<
  Awaited<ReturnType<typeof convertEstimateToContract>>
>
export type PublishEstimateResult = NonNullable<Awaited<ReturnType<typeof publishEstimate>>>
export type SendEstimateReminderResult = NonNullable<
  Awaited<ReturnType<typeof sendEstimateReminder>>
>
export type UploadEstimateTemplateResult = NonNullable<
  Awaited<ReturnType<typeof uploadEstimateTemplate>>
>
export type ArchiveBankAccountResult = NonNullable<Awaited<ReturnType<typeof archiveBankAccount>>>
export type GetQBBucketsResult = NonNullable<Awaited<ReturnType<typeof getQBBuckets>>>
export type TrackEmailLinkClickedResult = NonNullable<
  Awaited<ReturnType<typeof trackEmailLinkClicked>>
>
export type CategorizeTransactionResult = NonNullable<
  Awaited<ReturnType<typeof categorizeTransaction>>
>
export type CreateSampleEstimateResult = NonNullable<
  Awaited<ReturnType<typeof createSampleEstimate>>
>
export type InitiateBillOcrResult = NonNullable<Awaited<ReturnType<typeof initiateBillOcr>>>
export type CreateMfaAttemptResult = NonNullable<Awaited<ReturnType<typeof createMfaAttempt>>>
export type CreateESignatureResult = NonNullable<Awaited<ReturnType<typeof createESignature>>>
export type UpdateAuthTokenResult = NonNullable<Awaited<ReturnType<typeof updateAuthToken>>>
export type ClickLinkResult = NonNullable<Awaited<ReturnType<typeof clickLink>>>
export type RefreshDealsResult = NonNullable<Awaited<ReturnType<typeof refreshDeals>>>
export type OnboardBusinessToStripeResult = NonNullable<
  Awaited<ReturnType<typeof onboardBusinessToStripe>>
>
export type UpdateMoneyMovementResult = NonNullable<Awaited<ReturnType<typeof updateMoneyMovement>>>
export type PartialUpdateSetupIntentResult = NonNullable<
  Awaited<ReturnType<typeof partialUpdateSetupIntent>>
>
