import { CSSProperties, FC, Fragment, ReactNode, useState } from 'react'
import { TableData, useControlledOrUncontrolled } from 'msutils'
import { unreachable } from 'msutils/misc'
import { cn } from 'msutils/classnames'
import { t } from 'content'
import { useDrag, useDrop } from 'react-dnd'
import { BaseLayout } from '../baseLayout'
import useScreenSize from '../theme/useScreenSize'
import Typography from '../data/Typography'
import Icon from '../data/Icon'
import { useContext } from '../config/Provider'
import Checkbox from '../input/Checkbox'
import Divider from '../data/Divider'
import InteractionTarget from '../input/InteractionTarget'
import { useLayoutFlagContext } from './LayoutFlagContext'

type Theme = {
  cardInset: number
  cardBackground: string
  cardDivider: boolean
  cardHideNullItems: boolean
  tableBorderColor: string
  tableColumnHeaderInset: number
  tableCellDefaultInset: number | [number, number]
  tableContentXInset: number
  tableVerticalAlign: 'top' | undefined
  tableHeader: 'standard' | 'hidden'
  tableHeaderBorderStyle: 'standard' | 'full'
  tableFooterBackground: string
  tableErrorBorderStyle: string
  tableStandardBorderStyle: string
  tableShowRowBorder: boolean
  showEmptyState: boolean
  linkColor: string
}

function LinkOrPassthrough({ href, children }: { href: string | null; children: ReactNode }) {
  const { Link } = useContext()

  if (href) {
    return <Link href={href}>{children}</Link>
  } else {
    return <>{children}</>
  }
}

type CellProps = {
  colspan?: number
  style?: CSSProperties
  href?: string | null
  children?: ReactNode
}

function TD({ colspan, style, href, children }: CellProps) {
  return (
    <td colSpan={colspan} style={{ padding: 0, ...style }}>
      <LinkOrPassthrough href={href ?? null}>{children}</LinkOrPassthrough>
    </td>
  )
}

function TH({ colspan, style, children }: CellProps) {
  return (
    <th colSpan={colspan} style={{ padding: 0, ...style }}>
      {children}
    </th>
  )
}

function DefaultValueWrapper({ children }: { children: ReactNode }) {
  return <>{children}</>
}

type RowStateControl = {
  state: TableData.RowState
  isDisabled: (id: string) => string | false
}

type SortState = { name: string; direction: 'asc' | 'desc' }

type Props<TDataRow, TCtx> = {
  tableData: TableData.TableDataBuild<TDataRow, TCtx>
  selection?: Partial<RowStateControl> & { disableSelectAll?: boolean }
  selfCollapse?: Partial<RowStateControl>
  subrowCollapse?: Partial<RowStateControl>
  move?: (aId: string, bId: string) => void
  theme: Theme
  ValueWrapper?: FC<{ align?: 'right' | 'left'; children: ReactNode }>
  empty?: string
  sort?: {
    initialValue?: SortState
  }
}

type TableControl = {
  selection?: RowStateControl & { disableSelectAll: boolean }
  selfCollapse?: RowStateControl
  subrowCollapse?: RowStateControl
  sort: [SortState | null, (newValue: SortState | null) => void] | null
  move?: (aId: string, bId: string) => void
}

function SubrowCollapseControl({ rowId, control }: { rowId: string; control: RowStateControl }) {
  const isActive = control.state.isActive(rowId)
  return control.isDisabled(rowId) ? null : (
    <Icon
      name={['chevron', isActive ? '90' : '0']}
      onClick={() => control.state.setIsActive(rowId, !isActive)}
      height={16}
      cursor="pointer"
      color="text-th-brown-2"
    />
  )
}

type DragControlProps = {
  dragRef: any
  isDragging: boolean
}

export function DragControl({ dragRef, isDragging }: DragControlProps) {
  return (
    <div
      ref={dragRef}
      className={cn(
        'h-fit text-th-brown-2 transition-all duration-200',
        isDragging ? 'cursor-grabbing' : 'cursor-grab',
      )}
    >
      <Icon name="drag-indicator" height={14} />
    </div>
  )
}

function SelectionControl({ rowId, control }: { rowId: string; control: RowStateControl }) {
  return (
    <Checkbox
      value={control.state.isActive(rowId)}
      update={(newValue) => control.state.setIsActive?.(rowId, newValue)}
      disabled={control.isDisabled(rowId)}
      expandHitbox
    />
  )
}

function SelectAllControl<TDataRow, TCtx>({
  tableData: td,
  control,
}: {
  tableData: TableData.TableDataBuild<TDataRow, TCtx>
  control: RowStateControl
}) {
  const validRowIds = Object.keys(td.map(() => null)).filter((x) => !control.isDisabled(x))
  const selectedRowIds = validRowIds.filter((x) => control.state.isActive(x))

  return (
    <Checkbox
      value={selectedRowIds.length === validRowIds.length && validRowIds.length > 0}
      update={(newValue) => control.state.setIsActive(validRowIds, newValue)}
      disabled={validRowIds.length === 0}
      showDashWhenUnselected={selectedRowIds.length > 0}
      expandHitbox
    />
  )
}

function CardsList<TDataRow, TCtx>(
  props: Props<TDataRow, TCtx> & { control: TableControl } & { depth: number },
) {
  // control.subrowCollapse - mobile view doesn't support this
  const { tableData, theme, control, depth, ValueWrapper = DefaultValueWrapper } = props
  const { disableCardsListPadding } = useLayoutFlagContext()

  if (tableData.data.length === 0) return null
  return (
    <BaseLayout.NativeDiv paddingLeft={depth * 20}>
      <BaseLayout.VStack gap={5}>
        {tableData.data.map((rowOrSection, i) => {
          if (rowOrSection.type === 'section') {
            return (
              <BaseLayout.VStack key={rowOrSection.id} gap={5}>
                {rowOrSection.header}
                <CardsList {...props} tableData={{ ...tableData, data: rowOrSection.rows }} />
                {rowOrSection.footer}
              </BaseLayout.VStack>
            )
          } else if (rowOrSection.type === 'row') {
            const titleCells = rowOrSection.cells.filter((x) => x.mobilePosition === 'title')
            const subtitleCells = rowOrSection.cells.filter((x) => x.mobilePosition === 'subtitle')
            const widgetCells = rowOrSection.cells.filter((x) => x.mobilePosition === 'widget')
            const standardCells = rowOrSection.cells.filter((x) => x.mobilePosition === 'standard')

            return (
              <BaseLayout.VStack key={rowOrSection.id} gap={5}>
                {theme.cardDivider && (i > 0 || depth > 0) && <Divider />}
                {rowOrSection.header}
                <LinkOrPassthrough href={rowOrSection.href}>
                  <BaseLayout.VStack
                    gap={3}
                    view={{
                      background: theme.cardBackground,
                      inset: !disableCardsListPadding ? 5 : undefined,
                    }}
                  >
                    {(rowOrSection.startWidget || rowOrSection.endWidget) && (
                      <BaseLayout.HStack justify="between">
                        <BaseLayout.View>{rowOrSection.startWidget}</BaseLayout.View>
                        <BaseLayout.View>{rowOrSection.endWidget}</BaseLayout.View>
                      </BaseLayout.HStack>
                    )}
                    <BaseLayout.HStack justify="between">
                      <BaseLayout.VStack gap={2}>
                        <BaseLayout.HStack gap={2}>
                          {control.selection && (
                            <SelectionControl rowId={rowOrSection.id} control={control.selection} />
                          )}
                          <BaseLayout.VStack gap={2}>
                            {titleCells.map((x, j) => (
                              <BaseLayout.HStack key={`${x.column.name}-${j}`} gap={1}>
                                {x.icon}
                                <BaseLayout.VStack gap={2}>
                                  <BaseLayout.HStack gap={2}>
                                    <Typography variant="bodybold" preserveWhitespace>
                                      <ValueWrapper align={x.column.align}>
                                        {x.formattedValue}
                                      </ValueWrapper>
                                    </Typography>
                                    {x.badge}
                                  </BaseLayout.HStack>
                                  {x.caption && (
                                    <Typography variant="label" className="text-th-text-secondary">
                                      {x.caption}
                                    </Typography>
                                  )}
                                </BaseLayout.VStack>
                              </BaseLayout.HStack>
                            ))}
                          </BaseLayout.VStack>
                        </BaseLayout.HStack>
                        {subtitleCells.map((x, j) => (
                          <BaseLayout.HStack key={`${x.column.name}-${j}`} gap={1}>
                            {x.icon}
                            <Typography
                              variant={x.bold ? 'bodybold' : 'body'}
                              className="text-th-text-secondary"
                            >
                              <ValueWrapper align={x.column.align}>{x.formattedValue}</ValueWrapper>
                            </Typography>
                            {x.badge}
                          </BaseLayout.HStack>
                        ))}
                      </BaseLayout.VStack>
                      <BaseLayout.HStack gap={1}>
                        {widgetCells.map((x, j) => (
                          <Fragment key={`${x.column.name}-${j}`}>
                            <ValueWrapper align={x.column.align}>{x.formattedValue}</ValueWrapper>
                          </Fragment>
                        ))}
                        {rowOrSection.href && (
                          <Icon name="chevron" height={20} color="text-th-brown-2" />
                        )}
                      </BaseLayout.HStack>
                    </BaseLayout.HStack>
                    {standardCells.map((x, j) =>
                      theme.cardHideNullItems && !x.formattedValue ? null : (
                        <BaseLayout.HStack key={`${x.column.name}-${j}`} justify="between">
                          {x.mobileLabel !== 'hidden' && <Typography>{x.column.name}</Typography>}
                          <BaseLayout.HStack gap={1}>
                            {x.icon}
                            <LinkOrPassthrough href={x.href}>
                              <Typography
                                variant="bodybold"
                                rawColor={x.href ? theme.linkColor : undefined}
                              >
                                <ValueWrapper align={x.column.align}>
                                  {x.formattedValue}
                                </ValueWrapper>
                              </Typography>
                            </LinkOrPassthrough>
                          </BaseLayout.HStack>
                        </BaseLayout.HStack>
                      ),
                    )}
                  </BaseLayout.VStack>
                </LinkOrPassthrough>
                <CardsList
                  {...props}
                  tableData={{ ...tableData, data: rowOrSection.subdata }}
                  depth={depth + 1}
                />
                {rowOrSection.footer}
              </BaseLayout.VStack>
            )
          } else {
            return unreachable(rowOrSection)
          }
        })}
      </BaseLayout.VStack>
    </BaseLayout.NativeDiv>
  )
}

function FullRow({ style, children }: { style?: CSSProperties; children: ReactNode }) {
  return (
    <tr style={style}>
      <TD colspan={10000}>{children}</TD>
    </tr>
  )
}

function Row<TDataRow, TCtx>({
  row,
  depth,
  index,
  control,
  ...props
}: Props<TDataRow, TCtx> & {
  row: TableData.EnrichedRow<TDataRow, TCtx>
  index: number
  depth: number
  control: TableControl
}) {
  const { theme, ValueWrapper = DefaultValueWrapper } = props
  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: row.dragId ?? '--',
      item: { id: row.id },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    }),
    [row],
  )

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: row.dropIds,
      collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
      drop: (item: { id: string }) => {
        if (item.id !== row.id) {
          try {
            control.move?.(item.id, row.id)
          } catch (e) {
            // eslint-disable-next-line
            console.error(`Failed to move ${item.id} to ${row.id}: ${e}`)
          }
        }
      },
    }),
    [row],
  )

  const dndRef = (el: any) => {
    drop(el)
    dragPreview(el)
  }

  return (
    <tr
      style={{
        ...((index !== 0 || depth > 0) && theme.tableShowRowBorder
          ? { borderTop: `1px solid ${theme.tableBorderColor}` }
          : undefined),
        opacity: isOver && canDrop ? '50%' : undefined,
      }}
      ref={dndRef}
      className={cn(row.href && 'hover:bg-th-bg-slate')}
    >
      <TD href={row.href}>
        <BaseLayout.View inset={[0, theme.tableContentXInset / 2]} />
      </TD>
      <TD href={row.href} style={{ verticalAlign: 'middle', width: 0 }}>
        {row.dragId && <DragControl dragRef={drag} isDragging={isDragging} />}
        {control.selection && <SelectionControl rowId={row.id} control={control.selection} />}
        {control.subrowCollapse && row.subdata.length > 0 && (
          <SubrowCollapseControl rowId={row.id} control={control.subrowCollapse} />
        )}
        {row.startWidget && (
          <BaseLayout.NativeDiv paddingRight={8}>{row.startWidget}</BaseLayout.NativeDiv>
        )}
      </TD>
      {row.cells.map((cell, j) => {
        if (cell.void) {
          return null
        } else {
          return (
            <TD
              key={`${cell.column.name}-${j}`}
              href={row.href}
              colspan={cell.colspan}
              style={{
                paddingLeft: j === 0 ? depth * 20 : undefined,
                maxWidth: cell.column.fixedWidthDONOTUSE ?? undefined,
                minWidth: cell.column.fixedWidthDONOTUSE ?? undefined,
                width: cell.column.fixedWidthDONOTUSE
                  ? cell.column.fixedWidthDONOTUSE
                  : cell.column.maximizeWidth
                  ? '100%'
                  : undefined,
                border:
                  cell.borderStyle === null
                    ? undefined
                    : cell.borderStyle === 'error'
                    ? theme.tableErrorBorderStyle
                    : cell.borderStyle === 'standard'
                    ? theme.tableStandardBorderStyle
                    : unreachable(cell.borderStyle),
              }}
            >
              <BaseLayout.HStack
                gap={1}
                view={{ inset: cell.inset ?? theme.tableCellDefaultInset }}
                align="start"
                justify={
                  cell.column.align === 'right'
                    ? 'end'
                    : cell.column.align === 'left'
                    ? 'start'
                    : unreachable(cell.column.align)
                }
              >
                {cell.icon}
                <BaseLayout.VStack gap={1} view={{ fillWidth: true }}>
                  <BaseLayout.HStack gap={1}>
                    <LinkOrPassthrough href={cell.href}>
                      <Typography
                        variant={cell.bold ? 'bodybold' : 'body'}
                        rawColor={cell.color ?? (cell.href ? theme.linkColor : '')}
                        className={cn(
                          '!block',
                          cell.nowrap ? 'whitespace-nowrap' : 'whitespace-pre-wrap',
                          // if text wraps, width doesn't get applied properly so justify-end doesn't work for alignment
                          cell.column.align === 'right'
                            ? 'text-right'
                            : cell.column.align === 'left'
                            ? 'text-left'
                            : unreachable(cell.column.align),
                        )}
                      >
                        <ValueWrapper align={cell.column.align}>{cell.formattedValue}</ValueWrapper>
                      </Typography>
                    </LinkOrPassthrough>
                    {cell.badge}
                  </BaseLayout.HStack>
                  {cell.caption && (
                    <Typography variant="label" className="text-th-text-secondary">
                      {cell.caption}
                    </Typography>
                  )}
                </BaseLayout.VStack>
              </BaseLayout.HStack>
            </TD>
          )
        }
      })}
      <TD href={row.href} style={{ width: 0, verticalAlign: 'middle' }}>
        {row.href && <Icon name="chevron" height={20} color="text-th-brown-2" />}
        {row.endWidget && (
          <BaseLayout.NativeDiv paddingLeft={8}>{row.endWidget}</BaseLayout.NativeDiv>
        )}
      </TD>
      <TD href={row.href}>
        <BaseLayout.View inset={[0, theme.tableContentXInset / 2]} />
      </TD>
    </tr>
  )
}

function TableRowsList<TDataRow, TCtx>(
  props: Props<TDataRow, TCtx> & { control: TableControl } & { depth: number },
) {
  const { tableData, depth, control, theme } = props

  return (
    <>
      {tableData.data.map((rowOrSection, i) => {
        if (rowOrSection.type === 'section') {
          return (
            <Fragment key={rowOrSection.id}>
              {rowOrSection.header && (
                <FullRow style={{ background: theme.tableFooterBackground }}>
                  {rowOrSection.header}
                </FullRow>
              )}
              <TableRowsList {...props} tableData={{ ...tableData, data: rowOrSection.rows }} />
              {rowOrSection.footer && (
                <FullRow style={{ background: theme.tableFooterBackground }}>
                  {rowOrSection.footer}
                </FullRow>
              )}
            </Fragment>
          )
        } else if (rowOrSection.type === 'row') {
          return (
            <Fragment key={rowOrSection.id}>
              {rowOrSection.header && (
                <FullRow style={{ background: theme.tableFooterBackground }}>
                  {rowOrSection.header}
                </FullRow>
              )}
              <Row row={rowOrSection} index={i} {...props} />
              {(!control.subrowCollapse ||
                control.subrowCollapse.state.isActive(rowOrSection.id)) && (
                <TableRowsList
                  {...props}
                  tableData={{ ...tableData, data: rowOrSection.subdata }}
                  depth={depth + 1}
                />
              )}
              {rowOrSection.footer &&
                (!control.subrowCollapse ||
                  control.subrowCollapse.state.isActive(rowOrSection.id)) && (
                  <FullRow style={{ background: theme.tableFooterBackground }}>
                    {rowOrSection.footer}
                  </FullRow>
                )}
            </Fragment>
          )
        } else {
          return unreachable(rowOrSection)
        }
      })}
    </>
  )
}

function DesktopTable<TDataRow, TCtx>(props: Props<TDataRow, TCtx> & { control: TableControl }) {
  const { tableData, theme, control } = props
  const { disabledTableInset } = useLayoutFlagContext()
  const thBorder =
    theme.tableHeaderBorderStyle === 'full' ? `1px solid ${theme.tableBorderColor}` : undefined

  return (
    <div className={cn(!disabledTableInset && '-mx-5')}>
      <BaseLayout.Scroller scrollX>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          {theme.tableHeader !== 'hidden' && (
            <thead>
              <tr
                style={{
                  borderBottom: (() =>
                    theme.tableHeaderBorderStyle === 'standard'
                      ? `2px solid ${theme.tableBorderColor}`
                      : undefined)(),
                }}
              >
                <TH style={{ borderBottom: thBorder, borderTop: thBorder }} />
                <TH style={{ borderBottom: thBorder, borderTop: thBorder }}>
                  {control.selection && !control.selection.disableSelectAll && (
                    <SelectAllControl tableData={tableData} control={control.selection} />
                  )}
                </TH>
                {tableData.columns.map((col, i) => (
                  <TH key={`${col.name}-${i}`} style={{ textAlign: col.align, border: thBorder }}>
                    <InteractionTarget
                      onClick={
                        !!col.compare && !!control.sort
                          ? () => {
                              if (!control.sort?.[0] || control.sort?.[0].name !== col.name) {
                                control.sort?.[1]({ name: col.name, direction: 'desc' })
                              } else if (control.sort[0]?.direction === 'asc') {
                                control.sort?.[1](null)
                              } else if (control.sort[0]?.direction === 'desc') {
                                control.sort?.[1]({ name: col.name, direction: 'asc' })
                              }
                            }
                          : undefined
                      }
                    >
                      <BaseLayout.HStack
                        gap={2}
                        justify={
                          col.align === 'right'
                            ? 'end'
                            : col.align === 'left'
                            ? 'start'
                            : unreachable(col.align)
                        }
                        view={{ inset: theme.tableColumnHeaderInset }}
                      >
                        <Typography
                          variant="label"
                          className="text-th-coolgrey-1 whitespace-nowrap"
                        >
                          {col.name}
                        </Typography>
                        {!!col.compare &&
                          !!control.sort &&
                          (control.sort[0]?.name === col.name ? (
                            <Icon
                              name={['arrow', control.sort[0]?.direction === 'desc' ? '180' : '0']}
                              height={10}
                              color="text-th-orange-beam"
                              cursor="pointer"
                            />
                          ) : (
                            <Icon
                              name="sort"
                              height={12}
                              color="text-th-coolgrey-1"
                              cursor="pointer"
                            />
                          ))}
                      </BaseLayout.HStack>
                    </InteractionTarget>
                  </TH>
                ))}
                <TH style={{ borderBottom: thBorder, borderTop: thBorder }} />
                <TH style={{ borderBottom: thBorder, borderTop: thBorder }} />
              </tr>
            </thead>
          )}
          <tbody style={{ verticalAlign: theme.tableVerticalAlign }}>
            <TableRowsList {...props} depth={0} />
            {tableData.footer && (
              <FullRow style={{ background: theme.tableFooterBackground }}>
                {tableData.footer}
              </FullRow>
            )}
          </tbody>
        </table>
      </BaseLayout.Scroller>
    </div>
  )
}

export default function Table<TDataRow, TCtx>(props: Props<TDataRow, TCtx>) {
  const sz = useScreenSize()
  const selectionState = useControlledOrUncontrolled(
    props.selection?.state,
    TableData.useRowState(),
  )
  const selfCollapseState = useControlledOrUncontrolled(
    props.selfCollapse?.state,
    TableData.useRowState(),
  )
  const subrowCollapseState = useControlledOrUncontrolled(
    props.subrowCollapse?.state,
    TableData.useRowState(props.tableData.map(() => true)),
  )

  const [sort, setSort] = useState<SortState | null>(props.sort?.initialValue ?? null)
  const sortColumnIndex = sort
    ? props.tableData.columns.findIndex((x) => x.name === sort.name)
    : null

  const tableData = {
    ...props.tableData,
    data:
      sortColumnIndex !== null
        ? props.tableData.sort((rmA, rmB) => {
            const sortResult =
              props.tableData.columns[sortColumnIndex].compare?.(
                rmA.cells[sortColumnIndex].value,
                rmB.cells[sortColumnIndex].value,
              ) ?? 0
            if (sort?.direction === 'desc') {
              return sortResult * -1
            } else {
              return sortResult
            }
          })
        : props.tableData.data,
  }

  const tableState: TableControl = {
    sort: props.sort ? [sort, setSort] : null,
    selection: props.selection
      ? {
          isDisabled: props.selection.isDisabled ?? (() => false),
          state: selectionState,
          disableSelectAll: props.selection.disableSelectAll ?? false,
        }
      : undefined,
    selfCollapse: props.selfCollapse
      ? {
          isDisabled: props.selfCollapse.isDisabled ?? (() => false),
          state: selfCollapseState,
        }
      : undefined,
    subrowCollapse: props.subrowCollapse
      ? {
          isDisabled: props.subrowCollapse.isDisabled ?? (() => false),
          state: subrowCollapseState,
        }
      : undefined,
    move: props.move,
  }

  if (props.tableData.data.length === 0 && props.theme.showEmptyState) {
    return (
      <BaseLayout.VStack align="center" view={{ inset: 5 }}>
        <Typography className="text-th-text-disabled">{props.empty ?? t('No items')}</Typography>
      </BaseLayout.VStack>
    )
  }
  if (sz === 'sm') {
    return (
      <BaseLayout.VStack gap={5}>
        <CardsList {...props} tableData={tableData} depth={0} control={tableState} />
        {props.tableData.footer && (
          <BaseLayout.View background={props.theme.cardBackground}>
            {props.tableData.footer}
          </BaseLayout.View>
        )}
      </BaseLayout.VStack>
    )
  } else {
    return <DesktopTable {...props} tableData={tableData} control={tableState} />
  }
}
