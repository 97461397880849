import { ReactNode } from 'react'
import { BaseLayout } from 'compass/baseLayout'
import { cn } from 'msutils/classnames'
import AnimatedHeight from 'react-animate-height'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import { InputBaseUtils } from 'compass-local/InputBase'
import Typography from 'compass/data/Typography'
import Tooltip from 'compass/layout/Tooltip'

type Props = InputProps<boolean> &
  InputBaseUtils.ExternalProps & {
    size?: 'small' | 'large'
    hidden?: boolean
    disabledMessage?: ReactNode
  }

export default function Toggle(props: Props) {
  const {
    value,
    update,
    focus,
    blur,
    disabled,
    disabledMessage,
    title,
    subtitle,
    hidden,
    size = 'large',
    error,
    didUpdate,
    willUpdate,
  } = getInputProps(props)

  const updateInLifecycle = async (newValue: boolean) => {
    if (willUpdate) await willUpdate(value, newValue)
    update?.(newValue)
    didUpdate?.(value, newValue)
  }

  if (hidden) return null
  return (
    <div className="vflex w-fit">
      <Tooltip inactive={!disabled || !disabledMessage} message={disabledMessage}>
        <div
          className={cn(
            'flex gap-2 items-center w-fit',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          )}
          onClick={disabled ? undefined : () => updateInLifecycle(!value)}
          onFocus={focus}
          onBlur={blur}
        >
          <div
            className={cn(
              'relative shrink-0',
              size === 'large' && 'w-[32px] h-[16px]',
              size === 'small' && 'w-[24px] h-[12px]',
            )}
          >
            <input
              type="checkbox"
              checked={value}
              onChange={disabled ? () => undefined : () => updateInLifecycle(!value)}
              className="hidden"
            />
            <div
              className={cn(
                'absolute inset-0 rounded-full border-2 transition-all duration-75',
                disabled
                  ? 'bg-th-coolgrey-3 border-th-coolgrey-2'
                  : value
                  ? 'bg-th-orange-beam border-th-orange-dark1'
                  : 'bg-th-warmgrey-1 border-th-warmgrey-dark',
              )}
            >
              <div
                className={cn(
                  'absolute inset-y-0 transition-all duration-100 aspect-square',
                  value
                    ? 'right-0'
                    : size === 'large'
                    ? 'right-[calc(100%-12px)]'
                    : size === 'small'
                    ? 'right-[calc(100%-8px)]'
                    : undefined,
                )}
              >
                <div
                  className={cn(
                    'absolute -inset-1 box-border border-2 rounded-full',
                    disabled
                      ? 'border-th-coolgrey-2 bg-th-coolgrey-3'
                      : 'border-th-brown-2 bg-white',
                  )}
                />
              </div>
            </div>
          </div>
          <BaseLayout.VStack gap={1}>
            <Typography
              variant={size === 'small' ? 'label' : value ? 'bodybold' : 'body'}
              className={disabled ? 'text-th-text-disabled' : 'text-th-text-primary'}
            >
              {title}
            </Typography>
            {subtitle && <Typography variant="label">{subtitle}</Typography>}
          </BaseLayout.VStack>
        </div>
      </Tooltip>
      <AnimatedHeight
        height={error ? 'auto' : 0}
        className="text-th-red-warning"
        duration={150}
        animateOpacity
      >
        <div className="h-2" />
        {error}
      </AnimatedHeight>
    </div>
  )
}
